import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import CommonCheckbox from 'common/CheckBox/Checkbox';
import { Controller } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';
interface Data {
  [key: string]: any;
}
type Order = 'asc' | 'desc';
interface PricelistServicesTableProps {
  form?: any;
  columns: any[];
  rows: any[];
  tableData: any;
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  assignPrivilege: boolean;
  unassignPrivilege: boolean;
  handleBlurItem: (row: any) => void;
  pageNo: number;
  pageSize: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const PricelistServicesTable: React.FC<PricelistServicesTableProps> = (props) => {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('');
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data,) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  return (
    <Box className='assignTables' id="inventorytable">
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={'small'}>
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.numeric ? 'right' : 'left'}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={props.columns.length + 1} align="center" className='py-3'>
                  <Typography className="font-Saira text-primary-color font-[500] text-[16px] italic">{t('noRecordFound')}</Typography>
                </TableCell>
              </TableRow>
            ) : props.rows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                  {props.columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.numeric ? 'right' : 'left'}
                      padding={column.disablePadding ? 'none' : 'normal'}
                      component={column.id === 'serviceId' ? 'th' : undefined}
                      id={column.id === 'serviceId' ? labelId : undefined}
                      scope={column.id === 'serviceId' ? 'row' : undefined}
                      sx={{ width: column.id === 'serviceId' ? '40px' : 'auto' }}
                    >
                      {column.id === 'serviceId' ? <Controller
                        name={`serviceId-${row.id}`}
                        control={props.form.control}
                        defaultValue={row.linked}
                        render={({ field }) => (
                          <Box
                            sx={{
                              "& .MuiCheckbox-root": { padding: "0 9px !important" },
                              "& label": { paddingTop: "4px !important" },
                            }}
                          >
                            <CommonCheckbox
                              {...field}
                              label=""
                              onChange={async (event) => {
                                field.onChange(event.target.checked);
                                const isValid = await props.form.trigger(`price-${row.id}`);
                                if (isValid) props.assignUnassignSingleEntity(event.target.checked, {...row, 
                                  price: props.form.watch(`price-${row.id}`) });
                              }}
                              checked={(row as any).linked}
                              color="success"
                              disabled={(props?.assignPrivilege && !row.linked) ? false 
                                : (props?.unassignPrivilege && row.linked) ? false
                                : true
                              }
                            />
                          </Box>
                        )}
                      />
                        : column.id === 'price' ? <Controller
                          name={`price-${row.id}`}
                          control={props.form.control}
                          rules={{ required: true }}
                          defaultValue={row.plPrice}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              onChange={(e) => {
                                const value = e.target.value ? Number(e.target.value) : '';
                                field.onChange(value && value < 0 ? 0 : value);
                                props.form.trigger(`price-${row.id}`);
                              }}
                              onBlur={(e) => {
                                if(row.linked && (Number(e.target.value) !== row.plPrice)) {
                                  props.handleBlurItem({...row, price: props.form.watch(`price-${row.id}`)});
                                }
                              }}
                              placeholder={t("price")}
                              fullWidth
                              error={!!props.form.formState.errors[`price-${row.id}`]}
                              helperText={props.form.formState.errors[`price-${row.id}`]?.message as string}
                              InputLabelProps={{ shrink: true }}
                              sx={{ '& .MuiInputBase-formControl': { borderRadius: '8px' } }}
                            />
                          )}
                        />                      
                        : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 200]}
        component="div"
        count={props.tableData && props.tableData.totalElements ? props.tableData.totalElements : 0}
        rowsPerPage={props.pageSize}
        page={props.pageNo - 1}
        onPageChange={props.handleChangePage}
        onRowsPerPageChange={props.handleChangeRowsPerPage}
        className='hide-on-print'
        labelRowsPerPage={t('rowsPerPage')}
      />
    </Box>
  );
};