import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BrandingResponse } from "subModule/src/services/allcommon/interface";
import { RootState } from "app/store";

const initialState: BrandingResponse = {
    id: 0,
    title: '',
    secondaryTitle: '',
    whiteLabel: false,
    active: false,
    attributes: {
      serviceTitle: '',
      footerText: '',
      about: '',
      privacyPolicyLink: '',
      termsLink: '',
      hostUrl: '',
      adminUrl: '',
      serverIp: '',
      logo: '',
      logInPageImage: '',
      favIcon: '',
    }
};
const brandingSlice = createSlice({
    name: 'branding',
    initialState,
    reducers: {
      setBranding: (state, action: PayloadAction<any>) => {
        return {
          ...state,
          ...action.payload,
        };
      },
    },
});

export const { setBranding } = brandingSlice.actions;
export default brandingSlice.reducer;

export const brandingInfo = (state: RootState) => state.branding;