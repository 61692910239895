import { Button, ButtonGroup, Grid, InputAdornment, TextField, Tooltip, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Items } from "assets/SideBarIcons/items.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetItemByIdResponse } from "subModule/src/services/items/interface";
import Loader from "layout/Loader";
import DynamicSelect from "common/Select/Select";
import { ItemsProfileService, TaxProcedureProfileService } from "subModule/src/core/services";
import { useSelector } from "react-redux";
import { selectUserInfo } from "features/user/userSlice";
import { useGetManufacturerDropdownService } from 'subModule/src/services/manufacturer/useManufacturer';
import { useGetUnitsDropdownService } from 'subModule/src/services/units/useUnits';
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import CommonCheckbox from "common/CheckBox/Checkbox";
import ItemTabs from "./ItemTabs";
import { useGetItemGroupDropdownService } from "subModule/src/services/itemgroup/useItemGroup";
import FormDialog from "common/Modal/Modal";
import ItemGroupModal from "pages/ItemGroup/ItemGroupModal";
import { ReactComponent as ModalBtn } from "assets/Modal/ModalBtn.svg"
import { useGetTaxProcedureDropdownService } from "subModule/src/services/taxProcedure/useTaxProcedure";
import Currency from 'common/Others/Currency';
import { useGetItemsDropdownService } from "subModule/src/services/items/useItems";
import { useGetStoreDropdownService } from "subModule/src/services/store/useStore";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { GetDefaultTaxProcedureResponse } from "@subModule/src/services/taxProcedure/interface";
interface FormData { [key: string]: any; }
export default function ItemsModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, control, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, setValue, watch } = useForm<FormData>({ defaultValues: {
        stockQuantity: 1
    } });
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [showSecTitleField, setShowSecTitleField] = useState<boolean>(false);
    const [addNew, setAddNew] = useState<boolean>(false);
    const [prevFormData, setPrevFormData] = useState<any>({});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const loggedInUserCurrency = loggedInUserData?.currency;
    const defaultPriceList = loggedInUserData?.priceListTitle;
    const { data: manufacturersData, refetch: manufacturersRefetch } = useGetManufacturerDropdownService({ pageNumber: 1, size: 1000 });
    const { data: unitsData, refetch: unitsRefetch } = useGetUnitsDropdownService({ pageNumber: 1, size: 1000 });
    const { data: itemGroupsData, refetch: itemGroupsRefetch } = useGetItemGroupDropdownService({ pageNumber: 1, size: 1000 });
    const { data: taxProceduresData, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 1000 });
    const { data: scrapItemsData, refetch: scrapItemsRefetch } = loggedInUserData?.scrapItemGroupId ? useGetItemsDropdownService({ pageNumber: 1, size: 5000 }, loggedInUserData?.scrapItemGroupId) : useGetItemsDropdownService({ pageNumber: 1, size: 5000 });
    const { data: storesData, refetch: storesRefetch } = useGetStoreDropdownService({ pageNumber: 1, size: 1000 });
    const manufacturers = (manufacturersData?.pages[0]?.data?.content || []).map((item: { id?: number, title?: string }) => ({ value: item.id, label: item.title }));
    const units = (unitsData?.pages[0]?.data?.content || []).map((item: { id: number, unitTitle: string }) => ({ value: item.id, label: item.unitTitle }));
    const itemGroups = (itemGroupsData?.pages[0]?.data?.content || []).map((item: { id?: number, title?: string }) => ({ value: item.id, label: item.title }));
    const taxProcedures = (taxProceduresData?.pages[0]?.data?.content || []).map((item: { id: number, title: string, percentageValue?: number }) => ({ value: item.id, label: item.title, percentageValue: item.percentageValue }))
    const scrapItems = (scrapItemsData?.pages[0]?.data?.content || []).map((item: { id?: number, title?: string }) => ({ value: item.id, label: item.title }));
    const stores = (storesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const shippingMethods = [
        { value: 1, label: t('Air') },
        { value: 2, label: t('Sea') },
        { value: 3, label: t('Ground') },
    ]
    const currencyOptions = Currency.map((item) => ({ value: item.value, label: item.title, symbol: item.symbol }));
    const currencyLabel = currencyOptions.find((option:any) => option.value === loggedInUserCurrency)?.symbol || '';
    useEffect(() => {
        manufacturersRefetch();
        unitsRefetch();
        itemGroupsRefetch();
        taxProceduresRefetch();
        scrapItemsRefetch();
        storesRefetch();
        if(!params?.id) getDefaultTaxProcedure();
    }, []);
    useEffect(() => {
        if (params?.id && unitsData && itemGroupsData && storesData) {
            if(loading) getByID();
        }
    }, [params, unitsData, itemGroupsData, storesData]);
    useEffect(() => {
        if(isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getDefaultTaxProcedure = () => {
        TaxProcedureProfileService.getDefaultTaxProcedure(2).then((res: GetDefaultTaxProcedureResponse) => {
            if (res.success) {
                setValue('taxProcedureId', { value: res.data?.id, label: res.data?.title, percentageValue: res.data?.percentageValue });
                setLoading(false);
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const getByID = () => {
        ItemsProfileService.getItemByID(+(params as any)?.id).then((res: GetItemByIdResponse) => {
            if (res.success) {
                reset({
                    ...res?.data || {},
                    manufacturer: res.data?.manufacturer ? { value: res.data?.manufacturer, label: res.data?.manufacturerTitle } : null,
                    unitId: res.data?.unitId ? { value: res.data?.unitId, label: units.find((v) => v.value === res.data?.unitId)?.label || res.data?.unitId } : null,
                    itemGroupId: res.data?.itemGroupId ? { value: res.data?.itemGroupId, label: itemGroups.find((v) => v.value === res.data?.itemGroupId)?.label || res.data?.itemGroupId } : null,
                    taxProcedureId: res.data?.taxProcedureId ? { value: res.data?.taxProcedureId, label: taxProcedures.find((v) => v.value === res.data?.taxProcedureId)?.label || res.data?.taxProcedureId, percentageValue: taxProcedures.find((v) => v.value === res.data?.taxProcedureId)?.percentageValue } : null,
                    scrapItemId: res.data?.scrapItemId ? {  value: res.data?.scrapItemId, label: scrapItems.find((v) => v.value === res.data?.scrapItemId)?.label || res.data?.scrapItemId } : null,
                    storeId: res.data?.storeId ? { value: res.data?.storeId, label: res.data?.storeTitle } : null,
                    shippingMethod: res.data?.shippingMethod ? { value: res.data?.shippingMethod, label: shippingMethods.find((v) => v.value === res.data?.shippingMethod)?.label } : null,
                    stockQuantity: res?.data?.baseItemQuantity || 0,
                    taxValue: ((res?.data.price || 0) * ((taxProcedures.find((v) => v.value === res.data?.taxProcedureId)?.percentageValue || 0)/100))?.toFixed(2),
                });
                setPrevFormData(res?.data || {});
                setLoading(false);
                setValue('priceWithTax', (parseFloat(watch('price')) + parseFloat(watch('taxValue'))));
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        delete data.taxValue; delete data.priceWithTax; delete data.tax;
        if (params?.id) {
            ItemsProfileService.updateItems(+params?.id, {
                ...data,
                manufacturer: data?.manufacturer?.value || 0,
                unitId: data?.unitId?.value || 0,
                itemGroupId: data?.itemGroupId?.value || 0,
                taxProcedureId: data?.taxable && data?.taxProcedureId?.value ? data?.taxProcedureId?.value : 0,
                scrapItemId: data?.scrapItemId?.value || 0,
                storeId: data?.storeId?.value || 0,
                shippingMethod: data?.shippingMethod?.value || 0,
                storeItems: [{ storeId: data.storeId.value, itemCost: +data.itemCost || 0, stockQuantity: +data?.stockQuantity || 1 }]
            }).then((response: any) => {
                if (response.success) {
                    getByID();
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Item') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            ItemsProfileService.createItems({
                ...data,
                manufacturer: data?.manufacturer?.value || 0,
                unitId: data?.unitId?.value || 0,
                itemGroupId: data?.itemGroupId?.value || 0,
                taxProcedureId: data?.taxable && data?.taxProcedureId?.value ? data?.taxProcedureId?.value : 0,
                scrapItemId: data?.scrapItemId?.value || 0,
                storeId: data?.storeId?.value || 0,
                shippingMethod: data?.shippingMethod?.value || 0,
                storeItems: [{ storeId: data.storeId.value, itemCost: +data.itemCost || 0, stockQuantity: +data?.stockQuantity || 1 }],
            }).then((response: any) => {
                if (response.success) {
                    navigate('/parts');
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Item') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate('/parts')}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('items') : t('addItems')}
            icon={<Items height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_ITEM_CREATE")}
        />
        <div className={`${params?.id ? 'flex' : ''}`}>
            {params?.id ? <ItemTabs params={params} selectedIndex={0} /> : null}
            {loading ? <Loader /> : <SimpleModal
                visable={true}
                title={params?.id ? t('items') : t('addItems')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={<>
                    <Grid container component="form" id="form" spacing={2} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12}>
                                <Typography className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('general')}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("itemCode", { validate: { required: (value) => value?.trim() !== '' || t('This field is required.'), } })}
                                    label={t('partNo') + ' *'}
                                    fullWidth
                                    error={!!errors.itemCode}
                                    helperText={errors.itemCode?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("title", { validate: { required: (value) => value?.trim() !== '' || t('This field is required.'), } })}
                                    label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                    fullWidth
                                    error={!!errors.title}
                                    helperText={errors.title?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                            <Group onClick={() => setShowSecTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                        </InputAdornment>)
                                    }}
                                    sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                                />
                                {showSecTitleField && <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                        <TextField
                                            {...register("secondaryTitle")}
                                            label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                            fullWidth
                                            error={!!errors.secondaryTitle}
                                            helperText={errors.secondaryTitle?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                </div>}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("partNo")}
                                    label={t('itemCode')}
                                    fullWidth
                                    error={!!errors.partNo}
                                    helperText={errors.partNo?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="manufacturer"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t('manufacturer')}
                                            {...field}
                                            options={manufacturers}
                                            isSearchable
                                            isClearable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.manufacturer?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="unitId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t('This field is required.'), }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t('baseUnitId') + ' *'}
                                            {...field}
                                            options={units}
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.unitId?.message as string}
                                            isDisabled={params?.id && prevFormData?.unitId}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} className='grid' sx={{ gridTemplateColumns: '85% 10%' }}>
                                <Controller
                                    name="itemGroupId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t('itemGroup')}
                                            {...field}
                                            options={itemGroups}
                                            isSearchable
                                            isClearable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.itemGroupId?.message as string}
                                        />
                                    )}
                                />
                                {checkPrivileges('ROLE_ITEMGROUP_CREATE') && <Tooltip onClick={() => setAddNew(true)} title={t('addItemGroup')}><ModalBtn className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'/></Tooltip>}
                                {addNew && <FormDialog
                                    open={addNew}
                                    onClose={(event, reason) => event && reason !== 'backdropClick' ? setAddNew(false) : ''}
                                    title={t('addItemGroup')}
                                    createPopup={true}
                                    fullWidth
                                    hideActions={true}
                                    content={<ItemGroupModal setAddNew={setAddNew} refetch={itemGroupsRefetch} />}
                                />}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="taxable"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <CommonCheckbox
                                            {...field}
                                            label={t("taxable")}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                field.onChange(event.target.checked);
                                                if(!(event.target.checked)) {setValue('taxProcedureId', null);}
                                                if(!params?.id && event.target.checked) getDefaultTaxProcedure();
                                            }}
                                            checked={field.value}
                                            color="success"
                                        />
                                    )}
                                />
                            </Grid>
                            {watch("taxable") ? <Grid item xs={12} md={3}>
                                <Controller
                                    name="taxProcedureId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t("This field is required.") }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("taxProcedure") + " *"}
                                            {...field}
                                            options={taxProcedures}
                                            isSearchable
                                            onChange={(selectedOption: any) => { 
                                                field.onChange(selectedOption); setValue('taxValue', (watch('price') * (watch('taxProcedureId')?.percentageValue/100))?.toFixed(2)); 
                                                setValue('priceWithTax', parseFloat(watch('price')) + ((watch('price') * (selectedOption.percentageValue/100))));
                                            }}
                                            error={errors.taxProcedureId?.message as string}
                                        />
                                    )}
                                />
                            </Grid> : null}
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12}>
                                <Typography className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('price')}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t(defaultPriceList)}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("price", { required: t('This field is required.') })}
                                    onChange={(e: any) => {
                                        const value = parseFloat(e.target.value);
                                        e.target.value = value < 0 ? 0 : value || 0;
                                        setValue('priceWithTax', (parseFloat(e.target.value) + (
                                            parseFloat(e.target.value) * 
                                            parseFloat((taxProcedures.find((option:any) => (option.value === watch('taxProcedureId')?.value))?.percentageValue || 0) as any)/100
                                          ))?.toFixed(2));
                                          setValue('taxValue', (parseFloat(e.target.value) * (watch('taxProcedureId')?.percentageValue/100))?.toFixed(2));
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t('price') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '') + ' *'}
                                    fullWidth
                                    error={!!errors.price}
                                    helperText={errors.price?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            {watch("taxable") && watch("taxProcedureId")?.value ? <>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        type="number"
                                        {...register("tax")}
                                        label={t('tax') + (' (%)')}
                                        fullWidth
                                        error={!!errors.tax}
                                        helperText={errors.tax?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true, endAdornment: (<InputAdornment position="end"><span className='font-Saira text-[14px]'>{(watch('taxValue') || 0) + (loggedInUserCurrency ? (' ' + currencyLabel) : '')}</span></InputAdornment>) }}
                                        value={taxProcedures.find((option: any) => option.value === watch('taxProcedureId')?.value)?.percentageValue || 0}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        type="number"
                                        {...register("priceWithTax")}
                                        label={t('priceWithTax') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '')}
                                        fullWidth
                                        error={!!errors.priceWithTax}
                                        helperText={errors.priceWithTax?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            e.target.value = value < 0 ? 0 : value || 0;
                                            const price = parseFloat(e.target.value) / (1 + (taxProcedures.find((option: any) => option.value === watch('taxProcedureId')?.value)?.percentageValue || 0)/100);
                                            setValue('price', (price - Math.floor(price)) > 0.9 ? Math.round(price) : price?.toFixed(2));
                                            setValue('taxValue', (watch('price') * (watch('taxProcedureId')?.percentageValue/100))?.toFixed(2));
                                            if(!IsBtnEnabled) setIsBtnEnabled(true);
                                        }}
                                    />
                                </Grid>
                            </> : null}
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="haveScrapItem"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <CommonCheckbox
                                            {...field}
                                            label={t("haveScrapItem")}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                field.onChange(event.target.checked);
                                                if(!(event.target.checked)) {setValue('scrapItemId', null);}
                                            }}
                                            checked={field.value}
                                            color="success"
                                        />
                                    )}
                                />
                            </Grid>
                            {watch("haveScrapItem") ? <Grid item xs={12} md={3}>
                                <Controller
                                    name="scrapItemId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t("This field is required.") }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("scrapItem") + " *"}
                                            {...field}
                                            options={scrapItems}
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.scrapItemId?.message as string}
                                        />
                                    )}
                                />
                            </Grid> : null}
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12}>
                                <Typography className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('stockInfo')}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("stockReferanceNumber")}
                                    label={t("stockReferanceNumber")}
                                    fullWidth
                                    error={!!errors.stockReferanceNumber}
                                    helperText={errors.stockReferanceNumber?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("itemCost", { required: t('This field is required.') })}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        e.target.value = value < 0 ? 0 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t('itemCost') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '') + ' *'}
                                    fullWidth
                                    error={!!errors.itemCost}
                                    helperText={errors.itemCost?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={params?.id && prevFormData?.itemCost}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="storeId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t('This field is required.'), }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t('stores') + ' *'}
                                            {...field}
                                            options={stores}
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.storeId?.message as string}
                                            isDisabled={params?.id && prevFormData?.storeId}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography className="font-Saira font-[600] text-left text-[14px] text-txt-color line-clamp-1">{t('Quantity')}</Typography>
                                <ButtonGroup className='flex'>
                                    <Button aria-label="reduce" sx={{ borderRadius: '8px 0 0 8px', background: '#F2E5E9 !important', borderColor: '#FC5C57', width: '36px' }}
                                        onClick={() => setValue('stockQuantity', Math.max(watch('stockQuantity') - 1, 0))}
                                        disabled={params?.id && prevFormData?.stockQuantity}
                                    >
                                        <RemoveIcon fontSize="small" sx={{ color: '#FC5C57' }} />
                                    </Button>
                                    <TextField
                                        type="number"
                                        {...register("stockQuantity", { required: t('This field is required.') })}
                                        onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            e.target.value = value < 0 ? 0 : value;
                                            setValue('stockQuantity', e.target.value);
                                            if(!IsBtnEnabled) setIsBtnEnabled(true);
                                        }}
                                        onBlur={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            e.target.value = (value < 0  || !value) ? 0 : value;
                                            setValue('stockQuantity', e.target.value);
                                            if(!IsBtnEnabled) setIsBtnEnabled(true);
                                        }}
                                        fullWidth
                                        error={!!errors.stockQuantity}
                                        helperText={errors.stockQuantity?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ '& .MuiInputBase-formControl': { borderRadius: '0 !important' },
                                            '& input[type=number]': { MozAppearance: 'textfield', },
                                            '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                                WebkitAppearance: 'none', margin: 0,
                                            },  
                                        }}
                                        disabled={params?.id && prevFormData?.stockQuantity}
                                    />
                                    <Button aria-label="increase" sx={{ borderRadius: '0 8px 8px 0', background: '#17AE361A !important', borderColor: '#17AE3680', width: '36px' }}
                                        onClick={() => setValue('stockQuantity', parseFloat(watch('stockQuantity')) + 1)}
                                        disabled={params?.id && prevFormData?.stockQuantity}
                                    >
                                    <AddIcon fontSize="small" sx={{ color: '#17AE36' }} />
                                </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("minPrice")}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        e.target.value = value < 0 ? 0 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t('minPrice') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '')}
                                    fullWidth
                                    error={!!errors.minPrice}
                                    helperText={errors.minPrice?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("minQuantity")}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        e.target.value = value < 0 ? 0 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t('minQuantity')}
                                    fullWidth
                                    error={!!errors.minQuantity}
                                    helperText={errors.minQuantity?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("maxQuantity", {
                                        validate: (value) => {
                                          const minValue = watch("minQuantity");
                                          return !minValue || Number(value) >= Number(minValue) || t("maxQuantityGreater");
                                        },
                                    })}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        e.target.value = value < 0 ? 0 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t('maxQuantity')}
                                    fullWidth
                                    error={!!errors.maxQuantity}
                                    helperText={errors.maxQuantity?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("itemSupplierNumber")}
                                    label={t("itemSupplierNumber")}
                                    fullWidth
                                    error={!!errors.itemSupplierNumber}
                                    helperText={errors.itemSupplierNumber?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("warranty")}
                                    label={t("warranty")}
                                    fullWidth
                                    error={!!errors.warranty}
                                    helperText={errors.warranty?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        e.target.value = value < 0 ? 0 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("itemWarrantyServiceId")}
                                    onChange={() =>{ if(!IsBtnEnabled) setIsBtnEnabled(true);}}
                                    label={t('itemWarrantyServiceId') + t('Number of Days')}
                                    fullWidth
                                    error={!!errors.itemWarrantyServiceId}
                                    helperText={errors.itemWarrantyServiceId?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="shippingMethod"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t('shippingMethod')}
                                            {...field}
                                            options={shippingMethods}
                                            isSearchable
                                            isClearable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.shippingMethod?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("salesMarkupPercentage")}
                                    onChange={() =>{ if(!IsBtnEnabled) setIsBtnEnabled(true);}}
                                    label={t('salesMarkupPercentage')}
                                    fullWidth
                                    error={!!errors.salesMarkupPercentage}
                                    helperText={errors.salesMarkupPercentage?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="sellBelowCost"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <CommonCheckbox
                                            {...field}
                                            label={t("sellBelowCost")}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                            checked={field.value}
                                            color="success"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12}>
                                <Typography className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('replacementTime')}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("itemEveryMile")}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        e.target.value = value < 0 ? 0 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t("Mileage (km)")}
                                    fullWidth
                                    error={!!errors.itemEveryMile}
                                    helperText={errors.itemEveryMile?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("itemEveryPeriodType")}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        e.target.value = value < 0 ? 0 : parseInt(value) || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t("Number of Days")}
                                    fullWidth
                                    error={!!errors.itemEveryPeriodType}
                                    helperText={errors.itemEveryPeriodType?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12}>
                                <Typography className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('extraNotes')}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField 
                                    type="number"
                                    {...register("sortOrder")} 
                                    onChange={(e: any) => {
                                        const value = parseInt(e.target.value);
                                        e.target.value = value < 1 ? 1 : value > 100 ? 100 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t("Sort Order (Value 1-100)")}
                                    fullWidth
                                    error={!!errors.sortOrder} 
                                    helperText={errors.sortOrder?.message as string} 
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("extraNotes")}
                                    label={t('extraNotes')}
                                    fullWidth
                                    error={!!errors.extraNotes}
                                    helperText={errors.extraNotes?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    rows={1}
                                    sx={{
                                        '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                        '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '78px' }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
            />}
        </div>
    </>)
}