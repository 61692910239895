import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import BasicDatePicker from 'common/Pickers/Pickers';
// import CommonCheckbox from 'common/CheckBox/Checkbox';
import { useMediaQuery } from '@mui/material';
import StyledInputArea from 'common/TextArea/TextArea';
import { useTranslation } from 'react-i18next';
import FormDialog from 'common/Modal/Modal';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PlateNumberModal from './PlateNumberModal';

interface VehicleModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  clientsData: any;
  makesData: any;
  modelsData: any;
  modelEnginesData: any;
  vehicleTypesData: any;
  primaryLanguage: string;
  secondaryLanguage: string;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  clearModel: boolean;
  clearModelEngine: boolean;
}

const VehicleModalComponent: React.FC<VehicleModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  clientsData,
  makesData,
  modelsData,
  modelEnginesData,
  vehicleTypesData,
  openForm,
  enableEdit,
  errorMessages,
  primaryLanguage,
  secondaryLanguage,
  isBtnEnabled,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  clearModel,
  clearModelEngine
}) => {
  const isLargeScreen = useMediaQuery('(min-width:1200px)');
  const isSmallScreen = useMediaQuery('(min-width:600px) and (max-width:899px)');
  const [openPlateNumberDetails, setOpenPlateNumberDetails] = useState<boolean>(false);
  const transmissionOptions = [
    { value: 1, label: 'Manual' },
    { value: 2, label: 'Automatic' },
  ];
  const fuelTypeOptions = [
    { value: 1, label: 'PETROL' },
    { value: 2, label: 'DIESEL' },
    { value: 3, label: 'ETHANOL' },
    { value: 4, label: 'BIODIESEL' },
    { value: 5, label: 'CNG' },
    { value: 6, label: 'LPG' },
    { value: 7, label: 'HYDROGEN' },
    { value: 8, label: 'HYBRID' },
    { value: 9, label: 'PHEV' },
    { value: 10, label: 'ELECTRIC' },
  ];
  const registrationStatusOptions = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Expired' },
  ];
  const insuranceStatusOptions = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Expired' },
  ];
  // const vehicleStatusOptions = [
  //   { value: 1, label: 'Active' },
  //   { value: 2, label: 'Expired' },
  // ];
  const clientOptions = clientsData.map((item: { id: number, name: string }) => ({ value: item.id, label: item.name }));
  const makeOptions = makesData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const modelOptions = formData.makeId ? modelsData.map((item: { id: number, title: string }) => ({
    value: item.id,
    label: item.title
  })) : [];
  const modelEngineOptions = formData.modelId ? modelEnginesData.map((item: { id: number, title: string }) => ({
    value: item.id,
    label: item.title
  })) : [];
  const vehicleTypeOptions = vehicleTypesData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const { t } = useTranslation();
  const handleScroll = () => {
  };
  const handleSearch = () => {
  }

  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={""}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? clientOptions.find((option: any) => option.value === formData?.userId) : null}
                value={clientOptions.find((option: any) => option.value === formData?.userId)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="userId"
                options={clientOptions}
                onScroll={handleScroll}
                onInputChange={(e) => handleDropdownSearch('userId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('userId', e)}
                error={errorMessages?.userIdError}
                label="Client"
                onMenuScrollToBottom={(e) => { loadNext('userId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('userId', e); }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className='relative'>
              <StyledField
                fullWidth={true}
                label={`${!primaryLanguage ? t('primary') : ''} ${t('plateNumber')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                placeholder="Write plate number here"
                handleChange={(e) => handleChange('plateNumber', e.target.value)}
                // search='language'
                // handleSecField={() => handleSecField('plateNumber')}
                required={true}
                error={errorMessages?.plateNumber}
                value={formData?.plateNumber || ''}
                readOnly={true}
                onClick={() => setOpenPlateNumberDetails(true)}
              />
              {formData?.plateNumber.length > 0 && <CloseIcon className='cursor-pointer absolute top-[45px] right-[8px] w-[18px]' sx={{ '& svg': { color: '#b1b1b1' } }} onClick={() => handleChange('plateNumber', '')} />}
              {openPlateNumberDetails &&
                <FormDialog
                  open={openPlateNumberDetails}
                  onClose={(event, reason) => event && reason !== 'backdropClick' ? setOpenPlateNumberDetails(false) : ''}
                  title="Plate Number Details"
                  createPopup={true}
                  fullWidth
                  hideActions={true}
                  content={ 
                    <PlateNumberModal setOpenPlateNumberDetails={setOpenPlateNumberDetails} handleChange={handleChange} {...{formData}} />
                  }
                />
              }
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <StyledField
                fullWidth={true}
                label={`${!secondaryLanguage ? t('secondary') : ''} ${t('plateNumber')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                placeholder="Write secondary plate number here"
                handleChange={(e) => handleChange('secondaryPlateNumber', e.target.value)}
                required={false}
                // error={errorMessages?.secondaryPlateNumber}
                value={formData?.secondaryPlateNumber || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                fullWidth={true}
                label="vin"
                placeholder="Enter VIN here"
                handleChange={(e) => handleChange('vin', e.target.value)}
                required={false}
                // error={errorMessages?.vin}
                value={formData?.vin || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className=''>
              <DynamicSelect
                defaultValue={enableEdit ? vehicleTypeOptions.find((option: any) => option.value === formData?.vehicleTypeId) : null}
                value={vehicleTypeOptions.find((option: any) => option.value === formData?.vehicleTypeId)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="vehicleTypeId"
                options={vehicleTypeOptions}
                onScroll={handleScroll}
                onInputChange={(e) => handleDropdownSearch('vehicleTypeId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('vehicleTypeId', e)}
                error={errorMessages?.vehicleTypeIdError}
                label="vehicleType"
                onMenuScrollToBottom={(e) => { loadNext('vehicleTypeId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('vehicleTypeId', e); }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? makeOptions.find((option: any) => option.value === formData?.makeId) : null}
                value={makeOptions.find((option: any) => option.value === formData?.makeId)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="makeId"
                options={makeOptions}
                onScroll={handleScroll}
                onInputChange={(e) => handleDropdownSearch('makeId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('makeId', e)}
                error={errorMessages?.makeIdError}
                label="Make"
                onMenuScrollToBottom={(e) => { loadNext('makeId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('makeId', e); }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? modelOptions.find((option: any) => option.value === formData?.modelId) : null}
                value={formData.makeId && !clearModel ? modelOptions.find((option: any) => option.value === formData?.modelId) : 0}
                isDisabled={formData.makeId ? false : true}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="modelId"
                options={modelOptions}
                onScroll={handleScroll}
                onInputChange={(e) => handleDropdownSearch('modelId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('modelId', e)}
                error={errorMessages?.modelIdError}
                label="Model"
                onMenuScrollToBottom={(e) => { loadNext('modelId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('modelId', e); }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? modelEngineOptions.find((option: any) => option.value === formData?.modelEngineId) : null}
                value={formData.modelId && !clearModelEngine ? modelEngineOptions.find((option: any) => option.value === formData?.modelEngineId) : null}
                isDisabled={formData.modelId && formData.makeId ? false : true}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="modelEngineId"
                options={modelEngineOptions}
                onScroll={handleScroll}
                onInputChange={(e) => handleDropdownSearch('modelEngineId', e)}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('modelEngineId', e)}
                // error={errorMessages?.modelEngineIdError}
                label="modelEngine"
                onMenuScrollToBottom={(e) => { loadNext('modelEngineId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('modelEngineId', e); }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                fullWidth={true}
                label="year"
                placeholder="Enter Year here"
                handleChange={(e) => handleChange('year', e.target.value)}
                required={false}
                // error={errorMessages?.year}
                value={formData?.year || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? transmissionOptions.find((option: any) => option.value === formData?.transmission) : null}
                value={transmissionOptions.find((option: any) => option.value === formData?.transmission)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="transmission"
                options={transmissionOptions}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('transmission', e)}
                // error={errorMessages?.transmissionError}
                label="transmissionType"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DynamicSelect
                defaultValue={enableEdit ? fuelTypeOptions.find((option: any) => option.value === formData?.fuelType) : null}
                value={fuelTypeOptions.find((option: any) => option.value === formData?.fuelType)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="fuelType"
                options={fuelTypeOptions}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('fuelType', e)}
                // error={errorMessages?.statusError}
                label="fuelType"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                fullWidth={true}
                label="color"
                placeholder="Write Color here"
                handleChange={(e) => handleChange('color', e.target.value)}
                required={false}
                // error={errorMessages?.color}
                value={formData?.color || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                type="number"
                fullWidth={true}
                label="odometer"
                placeholder="Enter odometer here"
                handleChange={(e) => handleChange('odometer', e.target.value)}
                required={false}
                // error={errorMessages?.odometerError}
                value={formData?.odometer || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                type="number"
                fullWidth={true}
                label="Average Daily Mileage"
                placeholder="Enter Daily Mileage here"
                handleChange={(e) => handleChange('dailyMileage', e.target.value)}
                required={false}
                // error={errorMessages?.dailyMileage}
                value={formData?.dailyMileage || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                type="number"
                fullWidth={true}
                label="avgDailyFuelConsumption"
                placeholder="Enter Avg. Daily Fuel Consumption here"
                handleChange={(e) => handleChange('avgDailyFuelConsumption', e.target.value)}
                required={false}
                // error={errorMessages?.avgDailyFuelConsumption}
                value={formData?.avgDailyFuelConsumption || ''}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? statusOptions.find((option: any) => option.value === formData?.status) : null}
                value={statusOptions.find((option: any) => option.value === formData?.status)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="status"
                options={statusOptions}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('status', e)}
                error={errorMessages?.statusError}
                label="status"
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3} className=''>
              <StyledField
                type="number"
                fullWidth={true}
                label="seatingCapacity"
                placeholder="Enter Seating Capacity here"
                handleChange={(e) => handleChange('seatingCapacity', e.target.value)}
                required={false}
                // error={errorMessages?.seatingCapacity}
                value={formData?.seatingCapacity || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label="fleetCode"
                handleChange={(e) => handleChange('fleetCode', e.target.value)}
                required={false}
                fullWidth
                // error={errorMessages?.fleetCode}
                value={formData?.fleetCode || ''}
              />
            </Grid>
            {/* <Grid item  xs={12} sm={6} md={4} lg={3} >
              <BasicDatePicker
                type = "datePicker"
                label="lastServiceDate"
                fullWidth
                onChange={(e) => handleChange('lastServiceDate', e)}
                required={false}
                // error={errorMessages?.lastServiceDate}
                value={enableEdit ? formData?.lastServiceDate?.split('T')[0] : null}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? registrationStatusOptions.find((option: any) => option.value === formData?.registrationStatus) : null}
                value={registrationStatusOptions.find((option: any) => option.value === formData?.registrationStatus)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="registrationStatus"
                options={registrationStatusOptions}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('registrationStatus', e)}
                // error={errorMessages?.statusError}
                label="registrationStatus"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className={`pt-${isLargeScreen || isSmallScreen ? '[16px]' : '[16px]'}`}>
              <BasicDatePicker
                type="datePicker"
                label="registrationExpiryDate"
                // disable={!formData?.registrationStatus || formData?.registrationStatus === 2}
                fullWidth
                onChange={(e) => handleChange('registrationExpiryDate', e)}
                required={false}
                error={errorMessages?.registrationExpiryDate}
                value={enableEdit ? formData?.registrationExpiryDate?.split('T')[0] : null}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? vehicleStatusOptions.find((option: any) => option.value === formData?.vehicleStatus) : null}
                value={vehicleStatusOptions.find((option: any) => option.value === formData?.vehicleStatus)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="vehicleStatus"
                options={vehicleStatusOptions}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('vehicleStatus', e)}
                // error={errorMessages?.statusError}
                label="vehicleStatus"
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledInputArea
                rowsNo={1}
                label="tyreInformation"
                handleChange={(e) => handleChange('tyreInformation', e.target.value)}
                required={false}
                fullWidth
                // error={errorMessages?.tyreInformation}
                value={formData?.tyreInformation || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <StyledInputArea
                rowsNo={1}
                label="notes"
                handleChange={(e) => handleChange('notes', e.target.value)}
                required={false}
                fullWidth
                // error={errorMessages?.notes}
                value={formData?.notes || ''}
              />
            </Grid>
            {/* <Grid item  xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-start`}>
              <CommonCheckbox
                label="ac"
                onChange={(e) => handleChange('ac', e.target.checked)}
                color="success"
                required={false}
                // error={errorMessages?.ac}
                checked={formData?.ac}
              />
            </Grid> */}
            <Grid container spacing={2} className='my-[0px] ml-[3px]'>
              <Grid item xs={12} sm={12} md={12} lg={12} className='px-4'>
                <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>Insurance</h1>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <DynamicSelect
                  defaultValue={enableEdit ? insuranceStatusOptions.find((option: any) => option.value === formData?.insuranceStatus) : null}
                  value={insuranceStatusOptions.find((option: any) => option.value === formData?.insuranceStatus)}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  // fullWidth={false}
                  isSearchable={true}
                  name="insuranceStatus"
                  options={insuranceStatusOptions}
                  onScroll={handleScroll}
                  onInputChange={handleSearch}
                  // isMulti
                  isRequired={false}
                  onChange={(e) => handleChange('insuranceStatus', e)}
                  // error={errorMessages?.insuranceStatusError}
                  label="insuranceStatus"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} >
                <BasicDatePicker
                  type="datePicker"
                  label="insuranceExpiryDate"
                  // disable={!formData?.insuranceStatus || formData?.insuranceStatus === 2 }
                  fullWidth
                  onChange={(e) => handleChange('insuranceExpiryDate', e)}
                  required={false}
                  error={errorMessages?.insuranceExpiryDate}
                  value={enableEdit ? formData?.insuranceExpiryDate?.split('T')[0] : null}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default VehicleModalComponent;