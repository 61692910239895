// bankAccountService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  BankAccountRequest,
  BankAccountResponse,
  ChangeStatusBankAccountResponse,
  DeleteBankAccountResponse,
  GetBankAccountByIdResponse,
  GetBankAccountsDropdownResponse,
  GetBankAccountsResponse,
} from './interface';

export class BankAccountProfile extends BaseService {
  async createBankAccount(req: BankAccountRequest) {
    return this.post<BankAccountResponse, BankAccountRequest>(`${this.apiUrl}/api/bankaccounts`, req);
  }

  async getBankAccounts(params: PaginationParams) {
    return this.get<GetBankAccountsResponse>(`${this.apiUrl}/api/bankaccounts/paged`, params);
  }

  async getBankAccountsDropdown(params: PaginationParams) {
    return this.get<GetBankAccountsDropdownResponse>(`${this.apiUrl}/api/bankaccounts/get`, params);
  }

  async updateBankAccount(id: number, req: BankAccountRequest) {
    return this.put<BankAccountResponse, BankAccountRequest>(`${this.apiUrl}/api/bankaccounts/${id}`, req);
  }

  async deleteBankAccount(id: number) {
    return this.delete<DeleteBankAccountResponse>(`${this.apiUrl}/api/bankaccounts/${id}`);
  }

  async changeStatusBankAccount(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusBankAccountResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/bankaccounts/${active}/${id}`,
      req
    );
  }

  async getBankAccountByID(id: number) {
    return this.get<GetBankAccountByIdResponse>(`${this.apiUrl}/api/bankaccounts/${id}`);
  }
}
