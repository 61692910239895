import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Station } from "assets/SideBarIcons/station.svg";
import { Grid } from '@mui/material';
import StyledInput from "common/SearchField/Search";
import { useAssignAllBayService, useAssignBayService, useGetStationBayService, useUnassignAllBayService, useUnassignBayService } from 'subModule/src/services/station/useStation';
import StationTabs from './StationTabs';

export default function StationBayTable() {
    const params = useParams();
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<any>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetStationBayService({ pageNumber: pageNo, size: pageSize, title: searchTerm }, +(params as any).id) : useGetStationBayService({ pageNumber: pageNo, size: pageSize }, +(params as any).id);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    let allBaysAssigned: unknown = tableData?.pages[0].data?.content.map(item => item.linked).every(linked => linked === true);
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (searchTerm !== null && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 1000);
        return () => clearTimeout(getData)
    }, [searchTerm, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if (searchTerm) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setLoading(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
    };
    const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') handleSearch(trimmedSearch);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };
    const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoading(true);
        if (isChecked) {
            onAssignBay({ stationId: +(params as any).id, bayId: row.id });
        } else {
            onUnassignBay({ stationId: +(params as any).id, bayId: row.id });
        }
    }
    const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoading(true);
        if (isChecked) {
            onAssignAllBays({ stationId: +(params as any).id });
        } else {
            onUnassignAllBays({ stationId: +(params as any).id });
        }
    }
    const { onAssignBay } = useAssignBayService({
        onSuccess: async (msg) => {
            const response: any = {};
            Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
            if (response.success) {
                if (tableData?.pages[0].data.content.length === tableData?.pages[0].data.content.filter(bay => bay.linked).length) {
                    allBaysAssigned = true;
                }
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bay') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
            if (err instanceof Error) {
                console.error(err.stack);
            }
        }
    })
    const { onUnassignBay } = useUnassignBayService({
        onSuccess: async (data) => {
            const response: any = {};
            Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
            if (response.success) {
                if (tableData?.pages[0].data.content.length !== tableData?.pages[0].data.content.filter(bay => bay.linked).length) {
                    allBaysAssigned = false;
                }
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bay') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            console.log("err====,  ", err);
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
    });
    const { onAssignAllBays } = useAssignAllBayService({
        onSuccess: async (msg) => {
            const response: any = {};
            Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
            if (response.success) {
                allBaysAssigned = true;
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('bays') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
            if (err instanceof Error) {
                console.error(err.stack);
            }
        }
    })
    const { onUnassignAllBays } = useUnassignAllBayService({
        onSuccess: async (data) => {
            const response: any = {};
            Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
            if (response.success) {
                allBaysAssigned = false;
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('bays') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            console.log("err====,  ", err);
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
    });

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/stations')}
                heading={t('stations')}
                icon={<Station height={28} width={28} />}
                btnText={"back"}
                btnType={"back"}
                showCreateBtn={false}
            />
            <Box>
                <div className='flex'>
                    {params?.id ? <StationTabs params={params} selectedIndex={4} /> : null}
                    <BackendPaginationTable
                        columns={[
                            { id: 'title', label: 'title', numeric: false },
                        ]}
                        data={tableData?.pages[0]?.data?.content || []}
                        pageNo={pageNo}
                        pageSize={pageSize}
                        tableData={tableData?.pages[0]?.data}
                        showCheckbox={true}
                        showActions={true}
                        enableSorting={true}
                        showHeader={true}
                        showSearch={true}
                        showFilter={true}
                        showCreateButton={false}
                        actions={[]}
                        handleChangePage={handleChangePage}
                        handleChangeRows={handleChangeRows}
                        assignUnassignSingleEntity={assignUnassignSingleEntity}
                        assignUnassignAllEntities={assignUnassignAllEntities}
                        allEntitiesAssigned={allBaysAssigned}
                        loadingSearch={loading}
                        assignPrivilege={checkPrivileges('ROLE_STATION_BAY_ASSIGN')}
                        unassignPrivilege={checkPrivileges('ROLE_STATION_BAY_UNASSIGN')}
                        headerComponent={<Grid container spacing={2}>
                            <Grid item xs={12} md={7} lg={5} className='mt-3'>
                                <StyledInput
                                    fullWidth
                                    placeholder={t("title")}
                                    handleSearchClick={handleSearchClick}
                                    handleChange={handleInputChange}
                                    value={search}
                                    clearSearch={clearSearch}
                                />
                            </Grid>
                        </Grid>}
                    />
                </div>
            </Box>
        </Box>
    )
}