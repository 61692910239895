import { Grid, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { BankDepositProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Bank } from "assets/SideBarIcons/bank.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetBankDepositByIdResponse } from "subModule/src/services/bankdeposit/interface";
import Loader from "layout/Loader";
import DynamicSelect from "common/Select/Select";
import { useGetStationDropdownService } from "subModule/src/services/station/useStation";
import { useGetBankAccountsDropdownService } from "subModule/src/services/bankaccount/useBankAccount";
import BasicDatePicker from "common/Pickers/Pickers";

interface FormData { [key: string]: any; }
export default function BankDepositModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, control, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset } = useForm<FormData>({ defaultValues: {} });
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const { data: stationsData, refetch: stationsRefetch } = useGetStationDropdownService({ pageNumber: 1, size: 1000 });
    const { data: bankAccountsData, refetch: bankAccountsRefetch } = useGetBankAccountsDropdownService({ pageNumber: 1, size: 1000 });
    const stations = (stationsData?.pages[0]?.data?.content || []).map((item: { id: number, name: string }) => ({ value: item.id, label: item.name }));
    const bankAccounts = (bankAccountsData?.pages[0]?.data?.content || []).map((item: { id: number, bankName: string }) => ({ value: item.id, label: item.bankName }));
    useEffect(() => {
        stationsRefetch();
        bankAccountsRefetch();
    }, []);
    useEffect(() => {
        if (params?.id && stationsData && bankAccountsData) {
            if(loading) getByID();
        }
    }, [params, stationsData, bankAccountsData]);
    useEffect(() => {
        if(isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getByID = () => {
        BankDepositProfileService.getBankDepositByID(+(params as any)?.id).then((res: GetBankDepositByIdResponse) => {
            if (res.success) {
                reset({ ...res?.data || {}, 
                    bankAccountId: { value: res.data?.bankAccountId, label: res?.data?.bankName},
                    stationId: { value: res.data?.stationId, label: stations?.find((option) => option.value === res?.data?.stationId)?.label || res.data?.stationId}
                });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if (params?.id) {
            BankDepositProfileService.updateBankDeposit(+params?.id, {...data, 
                stationId: data.stationId.value,
                bankAccountId: data.bankAccountId.value,
            }).then((response: any) => {
                if (response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bankDeposit') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
                setIsBtnEnabled(true);
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            BankDepositProfileService.createBankDeposit({...data, 
                stationId: data.stationId.value,
                bankAccountId: data.bankAccountId.value
            }).then((response: any) => {
                if (response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bankDeposit') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate(-1)}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editBankDeposit') : t('addBankDeposit')}
            icon={<Bank height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_BANKDEPOSIT_CREATE")}
        />
        {loading ? <Loader /> : <SimpleModal
            visable={true}
            title={params?.id ? "updateBankDeposit" : "addBankDeposit"}
            isButtonVisable={true}
            isVisableBtn={IsBtnEnabled}
            btnclosetext="Back"
            buttonText={params?.id ? "update" : "submit"}
            attBtnNotshow={false}
            notVisableBackbtn={true}
            showPortList={handleSubmit(onSubmit)}
            formSubmit={handleSubmit(onSubmit)}
            content={
                <Grid container component="form" id="form" className="items-baseline" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            type="number"
                            {...register("number", { required: t('This field is required.') })}
                            onChange={(e: any) => {
                                const value = e.target.value;
                                e.target.value = value < 0 ? 0 : parseInt(value) || "";
                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                            }}
                            label={t("accountNumber")}
                            fullWidth
                            error={!!errors.number}
                            helperText={errors.number?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...register("receiptNumber", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })}
                            label={t("receiptNumber")}
                            fullWidth
                            error={!!errors.receiptNumber}
                            helperText={errors.receiptNumber?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="stationId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.') }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('station')}
                                    {...field}
                                    options={stations}
                                    isClearable
                                    isSearchable
                                    onChange={(selectedOption: any) => { field.onChange(selectedOption); }}
                                    error={errors.stationId?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="bankAccountId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.') }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('bankAccount')}
                                    {...field}
                                    options={bankAccounts}
                                    isClearable
                                    isSearchable
                                    onChange={(selectedOption: any) => { field.onChange(selectedOption); }}
                                    error={errors.bankAccountId?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="date"
                            control={control}
                            rules={{
                                validate: (value) => {
                                    const date = new Date(value);
                                    return isNaN(date.getTime()) ? t("Invalid Date") : true;
                                }
                            }}
                            render={({ field }) => (
                                <BasicDatePicker
                                    type="datePicker"
                                    {...field}
                                    label="Date"
                                    fullWidth
                                    onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                    required={false}
                                    error={errors.date?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            type="number"
                            {...register("amount", { required: t('This field is required.') })}
                            onChange={(e: any) => {
                                const value = e.target.value;
                                e.target.value = value < 0 ? 0 : value || "";
                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                            }}
                            label={t("amount")}
                            fullWidth
                            error={!!errors.amount}
                            helperText={errors.amount?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            }
        />}
    </>)
}