import { BaseService } from '../../core/BaseService';
import {
  BrandingRequest,
  ExportParamsRequest,
  ExportRequest,
  GetBrandingResponse,
  ImportResponse,
  UploadRequest,
  UploadResponse,
} from './interface';

export class CommonProfile extends BaseService {
  async uploadEntity(req: UploadRequest) {
    const headers = { 'Content-Type': 'multipart/form-data' };

    const formData = new FormData();

    Object.entries(req).forEach(([key, value]) => {
      if (value !== null) {
        formData.append(key, value);
      }
    });

    return this.post<UploadResponse, FormData>(`${this.apiUrl}/api/${req.typeName}/upload`, formData, { headers });
  }

  async importEntity(req: unknown, typeName: string) {
    return this.post<ImportResponse, unknown>(`${this.apiUrl}/api/${typeName}/create/excel`, req);
  }

  async exportEntity(req: ExportRequest) {
    return this.get<Blob>(`${this.apiUrl}/api/${req.typeName}/export/sample`, undefined, {
      responseType: 'blob',
    });
  }

  async exportEntityWithParams(req: ExportParamsRequest, typeName: string) {
    return this.get<Blob>(`${this.apiUrl}/api/${typeName}`, req, {
      responseType: 'blob',
    });
  }

  async getBrandingDetails(params: BrandingRequest) {
    return this.get<GetBrandingResponse>(`${this.apiUrl}/api/businesses/labeling`, params);
  }
}
