import { BaseService } from '../../core/BaseService';
import {
  GetLoginResponse,
  LoginAsUserRequest,
  LoginAsUserResponse,
  LoginRequest,
  LogoutRequest,
  LogoutResponse,
} from './interface';

export class UserProfile extends BaseService {
  async signIn(req: LoginRequest) {
    return this.post<GetLoginResponse, LoginRequest>(`${this.apiUrl}/api/auth/signin`, req);
  }

  async signOut(req: LogoutRequest) {
    return this.post<LogoutResponse, LogoutRequest>(`${this.apiUrl}/api/auth/signout`, req);
  }

  async loginAsUser(req: LoginAsUserRequest) {
    const headers = { 'Authorization': `Bearer ` + `${req.authToken}` };

    return this.post<LoginAsUserResponse, LoginAsUserRequest>(
      `${this.apiUrl}/api/users/loginas/${req.token}`,
      {},
      { headers }
    );
  }
}
