import { selectUserInfo } from "features/user/userSlice";
import { Box, Grid, InputAdornment, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { InspectionSectionProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Inspection  } from "assets/SideBarIcons/inspection.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetInspectionSectionByIdResponse } from "subModule/src/services/inspectionsection/interface";
import Loader from "layout/Loader";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import InspectionFormTabs from "./InspectionFormTabs";
import ISHeader from "components/InspectionSection/ISHeader";

interface FormData { [key: string]: any;}
export default function InspectionSectionModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset } = useForm<FormData>({defaultValues: {}});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.isid ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.isid ? false : true);
    const [showSecAccountTitleField, setShowSecAccountTitleField] = useState<boolean>(false);
    useEffect(() => {
        if(params?.isid) {
            if(loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.isid) setIsBtnEnabled(true);
      }, [isDirty, dirtyFields]);
    const getByID = () => {
        InspectionSectionProfileService.getInspectionSectionById(+(params as any)?.isid).then((res: GetInspectionSectionByIdResponse) => {
            if(res.success) {
                reset({ ...res?.data || {} });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if(params?.isid) {
            InspectionSectionProfileService.updateInspectionSection({ ...data, 
                inspectionFormId: +(params as any)?.id,
            }).then((response: any) => {
                if(response.success) {
                    navigate(-1);
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('InspectionSection') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            InspectionSectionProfileService.createInspectionSection({ ...data, 
                inspectionFormId: +(params as any)?.id,
             }).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('InspectionSection') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={false}
            onClick={() => navigate(`/inspection-forms`)}
            heading={t('inspectionForms')}
            icon={<Inspection height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            showCreateBtn={false}
        />
        <div className={`flex`}>
            <InspectionFormTabs params={params} selectedIndex={1} />
            <Box className='w-[100%] mt-[10px]'>
                <ISHeader
                    showBtn={false}
                    showSaveBtn={true}
                    onClick={() => navigate(-1)}
                    onSubmit={handleSubmit(onSubmit)}
                    heading={params?.isid ? t('editInspectionSection') : t('addInspectionSection')}
                    icon={<Inspection height={28} width={28} />}
                    btnText={"back"}
                    btnType={"back"}
                    isBtnEnabled={IsBtnEnabled}
                    showCreateBtn={checkPrivileges("ROLE_INSPECTIONFORM_SECTION_CREATE")}
                />
                {loading ? <Loader /> : <SimpleModal
                    visable={true}
                    title={params?.isid ? t('editInspectionSection') : t('addInspectionSection')}
                    isButtonVisable={true}
                    isVisableBtn={IsBtnEnabled}
                    btnclosetext="Back"
                    buttonText={params?.isid ? "update" : "submit"}
                    attBtnNotshow={false}
                    notVisableBackbtn={true}
                    showPortList={handleSubmit(onSubmit)}
                    formSubmit={handleSubmit(onSubmit)}
                    content={ 
                        <Grid container component="form" id="form" className="items-baseline mb-2" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12} md={3}>
                                <TextField 
                                    {...register("title", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                                    label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                    fullWidth
                                    error={!!errors.title} 
                                    helperText={errors.title?.message as string} 
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ endAdornment: (<InputAdornment position="end">
                                        <Group onClick={() => setShowSecAccountTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                    </InputAdornment>)}}
                                    sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                                />
                                {showSecAccountTitleField && <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
                                        <TextField 
                                            {...register("secondaryTitle")} 
                                            label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                            fullWidth
                                            error={!!errors.secondaryTitle} 
                                            helperText={errors.secondaryTitle?.message as string} 
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                </div>}
                            </Grid>
                        </Grid>
                    }
                />}
            </Box>
        </div>
    </>)
}