// PackageGroupService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignPackageRequest,
  AssignUnassignResponse,
  ChangeStatusPackageGroupResponse,
  DeletePackageGroupResponse,
  GePackageGroupPackageResponse,
  GetPackageGroupByIdResponse,
  GetPackageGroupResponse,
  PackageGroupRequest,
  PackageGroupResponse,
} from './interface';

export class PackageGroupProfile extends BaseService {
  async createPackageGroup(req: PackageGroupRequest) {
    return this.post<PackageGroupResponse, PackageGroupRequest>(`${this.apiUrl}/api/packagegroups`, req);
  }

  async getPackageGroup(params: PaginationParams) {
    return this.get<GetPackageGroupResponse>(`${this.apiUrl}/api/packagegroups/paged`, params);
  }

  async getPackageGroupDropdown(params: PaginationParams) {
    return this.get<GetPackageGroupResponse>(`${this.apiUrl}/api/packagegroups/get`, params);
  }

  async updatePackageGroup(id: number, req: PackageGroupRequest) {
    return this.put<PackageGroupResponse, PackageGroupRequest>(`${this.apiUrl}/api/packagegroups/${id}`, req);
  }

  async deletePackageGroup(id: number) {
    return this.delete<DeletePackageGroupResponse>(`${this.apiUrl}/api/packagegroups/${id}`);
  }

  async changeStatusPackageGroup(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusPackageGroupResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/packagegroups/${active}/${id}`,
      req
    );
  }

  async getPackageGroupPackage(params: PaginationParams & { packageGroupId: number }) {
    return this.get<GePackageGroupPackageResponse>(`${this.apiUrl}/api/packages/bypackagegroupid`, params);
  }

  async assignPackage(req: AssignUnassignPackageRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignPackageRequest>(
      `${this.apiUrl}/api/packagegroups/package/assign`,
      req
    );
  }

  async unassignPackage(req: AssignUnassignPackageRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignPackageRequest>(
      `${this.apiUrl}/api/packagegroups/package/unassign`,
      req
    );
  }

  async assignAllPackages(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packagegroups/package/assignall`,
      req
    );
  }

  async unassignAllPackages(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packagegroups/package/unassignall`,
      req
    );
  }

  async getPackageGroupById(id: number | undefined) {
    return this.get<GetPackageGroupByIdResponse>(`${this.apiUrl}/api/packagegroups/${id}`);
  }
}
