// InspectionSectionProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { InspectionSectionProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusInspectionSectionResponse,
  DeleteInspectionSectionResponse,
  InspectionSectionRequest,
  InspectionSectionResponse,
} from './interface';
import { useCallback } from 'react';

type UseInspectionSectionServiceProps = {
  onSuccess: (data: InspectionSectionResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateInspectionSectionServiceProps = {
  onSuccess: (data: InspectionSectionResponse) => void;
  onError: (err: unknown) => void;
};

type DeleteInspectionSectionServiceProps = {
  onSuccess: (data: DeleteInspectionSectionResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type ChangeStatusInspectionSectionProps = {
  onSuccess: (data: ChangeStatusInspectionSectionResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useInspectionSectionService = ({ onError, onSuccess }: UseInspectionSectionServiceProps) => {
  const {
    mutateAsync: createInspectionSection,
    isPending: isCreateInspectionSectionLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-inspection-section-request'],
    mutationFn: (req: InspectionSectionRequest) => InspectionSectionProfileService.createInspectionSection(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateInspectionSection = useCallback(
    async (InspectionSectionData: InspectionSectionRequest) => {
      try {
        createInspectionSection(InspectionSectionData);
      } catch (err) {
        console.error(err);
      }
    },
    [createInspectionSection]
  );

  return {
    onCreateInspectionSection,
    isCreateInspectionSectionLoading,
    data,
    error,
    isError,
  };
};

export const useGetInspectionSectionService = (initialParams: PageSize, id: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Items-data'],
      queryFn: async ({ pageParam }) => {
        return InspectionSectionProfileService.getInspectionSection({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          id: id,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateInspectionSectionService = ({ onError, onSuccess }: UpdateInspectionSectionServiceProps) => {
  const {
    mutateAsync: updateInspectionSection,
    isPending: isUpdateInspectionSectionLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-inspection-section-request'],
    mutationFn: (req: InspectionSectionRequest) => InspectionSectionProfileService.updateInspectionSection(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateInspectionSection = useCallback(
    async (req: InspectionSectionRequest) => {
      try {
        updateInspectionSection(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateInspectionSection]
  );

  return {
    onUpdateInspectionSection,
    isUpdateInspectionSectionLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteInspectionSectionService = ({ id, onError, onSuccess }: DeleteInspectionSectionServiceProps) => {
  const {
    mutateAsync: deleteInspectionSection,
    isPending: isDeleteInspectionSectionLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-inspection-section-request'],
    mutationFn: () => InspectionSectionProfileService.deleteInspectionSection(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteInspectionSection = useCallback(async () => {
    try {
      deleteInspectionSection();
    } catch (err) {
      console.error(err);
    }
  }, [deleteInspectionSection]);

  return {
    onDeleteInspectionSection,
    isDeleteInspectionSectionLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusInspectionSectionService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusInspectionSectionProps) => {
  const {
    mutateAsync: changeStatusInspectionSection,
    isPending: isChangeStatusInspectionSectionLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-inspection-section-request'],
    mutationFn: (req: ChangeStatusRequest) =>
      InspectionSectionProfileService.ChangeStatusInspectionSection(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusInspectionSection = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusInspectionSection(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusInspectionSection]
  );

  return {
    onChangeStatusInspectionSection,
    isChangeStatusInspectionSectionLoading,
    data,
    error,
    isError,
  };
};
