import { Box, Button, Divider, Grid, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import FormDialog from "common/Modal/Modal";
import AddIcon from '@mui/icons-material/Add';
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService } from "subModule/src/core/services";
import { GetCreditNoteResponse, NewPurchaseOrderResponse, SaveCreditNote, SaveInvoice, SaveInvoiceResponse } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import { DeleteData } from "subModule/src/type";
import { useGetServiceService } from "subModule/src/services/service/useService";
import { InventoryServicesTable } from "components/Inventory/InventoryServicesTable";
import BasicDatePicker from "common/Pickers/Pickers";

interface FormData {
    [key: string]: any;
}
interface CreditNoteProps {
    steps: string[];
    setCurrentSteps?: any;
}

const CreditNote: React.FC<CreditNoteProps> = ({ steps, setCurrentSteps }) => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField} = useForm<FormData>({defaultValues: {
        pCDNoteItems: [],
        pCDNoteService: []
    }});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 5000 });
    const { data: servicesData, refetch: servicesRefetch } = useGetServiceService({ pageNumber: 1, size: 500 });
    const [currentStep, setCurrentStep] = useState<number>(steps.indexOf('Debit Note'));
    const [confirm, setConfirm] = useState<boolean>(false);
    const [pCDNoteItems, setpCDNoteItems] = useState<any[]>([]);
    const [pCDNoteService, setpCDNoteService] = useState<any[]>([]);
    const [entity, setEntity] = useState<FormData>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [invoice, setCreditNote] = useState<SaveInvoice>();
    const [IsDataAvailable, setIsDataAvailable] = useState<boolean>(false);
    const [checkStatus, setCheckStatus] = useState<number | null>(1);

    useEffect(() => {
        itemsRefetch();
        servicesRefetch();
        if(params?.id) {
            getCreditNoteByPOID();
        }
    }, [params]);
    const getCreditNoteByPOID = () => {
        InventoryProfileService.getCreditNoteByPOID(params?.id).then((res: GetCreditNoteResponse) => {
            if(!res.data) {
                setIsDataAvailable(false);
                getInvoiceById();
            } else {
                setIsDataAvailable(true);
                setLoading(false);
                setCheckStatus((res.data as any)?.status);
                setCreditNote((res.data as any));
                setpCDNoteItems(res.data.pcdnoteItemResponse || []);
                setValue('pCDNoteItems', res.data.pcdnoteItemResponse || []);
                setpCDNoteService(res.data.pcdnoteServiceResponse || []);
                setValue('pCDNoteService', res.data?.pcdnoteServiceResponse || []);
                setValue('poId', params?.id);
                setValue('storeId', res.data?.storeId);
                setValue('supplierId', res.data?.supplierId);
                setValue('type', res.data?.type);
                setValue('adjustmentType', res.data?.adjustmentType);
                setValue('referenceNumber', res.data?.referenceNumber);
                setValue('creditDebitDate', res.data?.creditDebitDate?.split('T')[0]);
                setValue('reason', res.data?.reason);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const getInvoiceById = () => {
        InventoryProfileService.getInvoiceByPOID(params?.id).then((res: SaveInvoiceResponse) => {
            if(res.success) {
                setCreditNote((res.data as any));
                setpCDNoteItems((res.data as any)?.purchaseInvoiceItem || []);
                setValue('pCDNoteItems', res.data.purchaseInvoiceItem || []);
                setpCDNoteService((res.data as any)?.purchaseInvoiceService || []);
                setValue('pCDNoteService', res.data?.purchaseInvoiceService || []);
                setValue('type', 6);
                setValue('poId', params?.id);
                setValue('storeId', res.data?.storeId);
                setValue('supplierId', res.data?.supplierId);
                setValue('adjustmentType', 1);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'supplierSKU', label: t('SKU'), numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitTitle' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const columnsService = [
        { id: 'serviceId' },
        { id: 'serviceCode'},
        { id: 'serviceName', label: t('service') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const DeleteItem = (row: any, typeName: string) => {
        if(typeName === "pCDNoteItems" && IsDataAvailable) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'purchasecreditdebitnote/item').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getCreditNoteByPOID();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else if(typeName === "pCDNoteItems" && !IsDataAvailable) {
            setValue('pCDNoteItems', pCDNoteItems.filter((rows) => rows.id !== row.id));
            setpCDNoteItems((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        } 
        if(typeName === "pCDNoteService" && IsDataAvailable) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'purchasecreditdebitnote/service').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getCreditNoteByPOID();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else if(typeName === "pCDNoteService" && !IsDataAvailable) {
            setValue('pCDNoteService', pCDNoteService.filter((rows) => rows.id !== row.id));
            setpCDNoteService((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        }
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "pCDNoteItems") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!IsDataAvailable) (newItem as any).id = Date.now();
            setpCDNoteItems((prevRows) => [...prevRows, newItem]);
        } else {
            const newService = columnsService.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!IsDataAvailable) (newService as any).id = Date.now();
            setpCDNoteService((prevRows) => [...prevRows, newService]);
        }
    };
    const handleItem = (row: any, typeName: string, action: string) => {
        if(typeName === "pCDNoteItems" && IsDataAvailable) {
            if(action === 'create') {
                InventoryProfileService.createCreditNoteItem({
                    ...row, poId: params?.id, purchaseCreditDebitNoteId: invoice?.id, storeId: invoice?.storeId
                }).then((response: GetCreditNoteResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Item') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getCreditNoteByPOID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getCreditNoteByPOID();
                });
            } else {
                InventoryProfileService.updateCreditNoteItem({
                    ...row, poId: params?.id, purchaseCreditDebitNoteId: invoice?.id
                }).then((response: GetCreditNoteResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Item') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getCreditNoteByPOID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getCreditNoteByPOID();
                });
            }
        } else if(typeName === "pCDNoteService" && IsDataAvailable) {
            if(action === 'create') {
                InventoryProfileService.createCreditNoteService({
                    ...row, poId: params?.id, purchaseCreditDebitNoteId: invoice?.id, storeId: invoice?.storeId
                }).then((response: GetCreditNoteResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Service') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getCreditNoteByPOID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getCreditNoteByPOID();
                });
            } else {
                InventoryProfileService.updateCreditNoteService({
                    ...row, poId: params?.id, purchaseCreditDebitNoteId: invoice?.id, storeId: invoice?.storeId
                }).then((response: GetCreditNoteResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Service') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getCreditNoteByPOID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getCreditNoteByPOID();
                });
            }
        }
    }

    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "pCDNoteItems") {
            const updatedItems = [...pCDNoteItems];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setpCDNoteItems(updatedItems);
        } else {
            const updatedServices = [...pCDNoteService];
            updatedServices[index][columnId] = value;
            setpCDNoteService(updatedServices);
        }
    };
    const onSubmit = (data: FormData | SaveCreditNote) => {
        if(Object.values(data)?.length) {
            if(checkStatus === 1) {
                const filteredItems = pCDNoteItems.filter((item: any) => item?.itemName);
                const filteredServices = pCDNoteService.filter((service: any) => service?.serviceName);
                if(filteredItems.length === 0 && filteredServices.length === 0) {
                    enqueueSnackbar(<AlertMessages text="Please add at least one item" />, { variant: 'error' });
                    return;
                }
                if(IsDataAvailable) {
                    setConfirm(true);
                    setEntity({...data});
                } else {
                    setLoading(true);
                    filteredItems.every((row:any) => delete row.unitTitle);
                    InventoryProfileService.saveCreditNote({...data, 
                        pCDNoteItems: filteredItems,
                        pCDNoteService: filteredServices?.length ? filteredServices.map((row: any) => ({ ...row, purchaseInvoiceId: invoice?.id })) : []
                    }).then((response: GetCreditNoteResponse) => {
                        if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note') + ' ' + t('saved') + ' ' + t('successfully')} />, { variant: 'success' });
                        else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                        getCreditNoteByPOID();
                        setLoading(false);
                    }).catch((err:any) => { 
                        console.error("===", err);
                        setLoading(false);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                }
            } else {
                setCurrentStep(steps.indexOf('Unstock'));
                setCurrentSteps(steps.indexOf('Unstock'));
            }
        }
    };
    const calculateTotals = () => {
        const itemTotals = pCDNoteItems.reduce((acc, item) => {
            return {
                unitPrice: parseFloat(acc.unitPrice) + parseFloat(item.unitPrice || 0),
                discount: parseFloat(acc.discount) + parseFloat(item.discount || 0),
                subTotal: parseFloat(acc.subTotal) + parseFloat(item.unitPrice || 0) * parseInt(item.quantity || 0),
                total: parseFloat(acc.total) + ((parseFloat(item.unitPrice || 0) * parseFloat(item.quantity) || 0)) - parseFloat(item.discount || 0),
            };
        }, { unitPrice: 0, discount: 0, subTotal: 0, total: 0 });
    
        const serviceTotals = pCDNoteService?.reduce((acc, service) => {
            return {
                unitPrice: parseFloat(acc?.unitPrice) + parseFloat(service?.unitPrice || 0),
                discount: parseFloat(acc?.discount) + parseFloat(service?.discount || 0),
                subTotal: parseFloat(acc?.subTotal) + parseFloat(service?.unitPrice || 0) * parseInt(service?.quantity || 0),
                total: parseFloat(acc?.total) + ((parseFloat(service?.unitPrice || 0) * parseFloat(service?.quantity) || 0)) - parseFloat(service?.discount || 0),
            };
        }, { unitPrice: 0, discount: 0, subTotal: 0, total: 0 });
    
        return {
            unitPrice: itemTotals.unitPrice + serviceTotals?.unitPrice,
            discount: itemTotals.discount + serviceTotals?.discount,
            subTotal: itemTotals.subTotal + serviceTotals?.subTotal,
            total: itemTotals.total + serviceTotals?.total,
        };
    };
    const totals = calculateTotals();

    return (
        loading ? <Loader /> : <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{IsDataAvailable ? t('Debit Note') : t('New Debit Note')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{IsDataAvailable ? (checkStatus === 1 ? t('Authorize') : t('Next')) : t('save')}</Button>
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            {!IsDataAvailable ? <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{t('New Debit Note')}</Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Payment')} # </span> <span>{invoice?.invoiceNumber || ''}</span></Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Date')}</span> <span>{invoice?.invoiceDate?.split('T')[0] || ''}</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Due')} {t('Date')}</span> <br /><span className="font-medium">{invoice?.dueDate?.split('T')[0] || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Requested')}</span> <br /><span className="font-medium">{invoice?.totalQuantity || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Received')}</span> <br /><span className="font-medium">{invoice?.totalQuantity || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Subtotal')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((invoice as SaveInvoice)?.subtotalAmount || 0) || 0}</span></Typography>
                        {/* <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">Tax</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((invoice as SaveInvoice)?.taxAmount || 0) || 0}</span></Typography> */}
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Discount')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((invoice as SaveInvoice)?.discount || 0) || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Total')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((invoice as SaveInvoice)?.totalAmount || 0) || 0}</span></Typography>
                    </div>
                </Grid>
            </Grid> : null}
            <Grid container item xs={12} gap={2}>
                <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('Debit Note')} {t("Details")}</Typography></Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <TextField 
                        {...register("referenceNumber", { required: t('This field is required.') })} 
                        placeholder="REF-001"
                        label={t('referenceNo') + ' *'}
                        fullWidth
                        error={!!errors.referenceNumber} 
                        helperText={errors.referenceNumber?.message as string} 
                        InputLabelProps={{ shrink: true }}
                        disabled={checkStatus === 2}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="creditDebitDate"
                        control={control}
                        rules={{ validate: (value) => {
                            const date = new Date(value);
                            return isNaN(date.getTime()) ? t("Invalid Date") : true;
                        }}}
                        render={({ field }) => (
                            <BasicDatePicker
                                type="datePicker"
                                {...field}
                                label={t('Debit Note') + ' ' + t('Date')}
                                fullWidth
                                onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                required={true}
                                error={errors.creditDebitDate?.message as string}
                                disable={checkStatus === 2}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container><Grid item xs={12}>
                <NewPurchaseSteps form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} addNewItem={addNewItem} handleInputChange={handleInputChange} items={itemsData?.pages[0]?.data?.content || []} services={servicesData?.pages[0]?.data?.content || []} DeleteItem={DeleteItem} handleItem={handleItem} {...{currentStep, setCurrentStep, columns, columnsService, pCDNoteItems, pCDNoteService, loggedInUserCurrency, params, IsDataAvailable, steps, invoice, checkStatus, setCurrentSteps, t}} />
            </Grid></Grid>
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left mb-[-5px]">{t("Reason")}</Typography>
            <Grid container xs={12} spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField 
                        {...register("reason")} 
                        placeholder={t("Reason")} 
                        fullWidth
                        error={!!errors.reason} 
                        helperText={errors.reason?.message as string}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        sx={{ '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%' }}}
                        disabled={checkStatus === 2}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className="bg-[#D8D1E0] rounded-[8px]" sx={{ border: '1px solid #5f6770', width: 'calc(100% + 8px)', padding: '8px 14px' }}>
                        <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Subtotal')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(totals.subTotal || (invoice as SaveInvoice)?.subtotalAmount || 0)}</Typography>
                        </Box>
                        {/* <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">Tax</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format((invoice as SaveInvoice)?.taxAmount || 0)}</Typography>
                        </Box> */}
                        <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Discount')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(!IsDataAvailable ? totals.discount : (invoice as SaveInvoice)?.discount || 0)}</Typography>
                        </Box>
                        <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[auto]">{t('Total')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[20%] text-end"><span className="uppercase">({loggedInUserCurrency})</span> {new Intl.NumberFormat('en-US', {}).format(totals.total || (invoice as SaveInvoice)?.totalAmount || 0)}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{IsDataAvailable ? (checkStatus === 1 ? t('Authorize') : t('Next')) : t('save')}</Button>
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            {confirm &&
                <FormDialog
                  open={confirm}
                  onClose={(event, reason) => event && reason !== 'backdropClick' ? setConfirm(false) : ''}
                  title={t('Authorize') + ' ' + t(steps[currentStep])}
                  saveFunction={() => {
                    delete entity.typeName;
                    entity.pCDNoteItems.every((row:any) => delete row.total);
                    entity.pCDNoteService.every((row:any) => delete row.total);
                    setConfirm(false);
                    InventoryProfileService.changeStatusEntity({typeName: 'purchasecreditdebitnote', entityId: invoice?.id, status: 2}).then((response: NewPurchaseOrderResponse) => {
                        if(response.success) {
                            setConfirm(false);
                            setCurrentStep(steps.indexOf('Unstock'));
                            setCurrentSteps(steps.indexOf('Unstock'));
                            getCreditNoteByPOID();
                            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Status') + ' ' + t('changed') + ' ' + t('successfully')} />, { variant: 'success' });
                        } else {
                            setConfirm(false);
                            enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                        }
                    }).catch((err:any) => { 
                        console.error("===", err);
                        setConfirm(false);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                }}
                  content={  
                    <><Typography>{t('Are you sure?')}</Typography></>
                  }
                  submitButtonText="OK"
                />
              }
        </Grid>
    )
}
interface NewPurchaseStepsProps {
    form: FormData;
    addNewItem: (typeName: string) => void;
    handleInputChange: (index: number, columnId: string, value: any, typeName: string, type: string) => void;
    currentStep: number;
    setCurrentStep: any;
    setCurrentSteps: any;
    columns: any[];
    columnsService: any[];
    pCDNoteItems: any[];
    pCDNoteService: any[];
    items: any[];
    services: any[];
    loggedInUserCurrency?: string;
    DeleteItem: (row: any, typeName: string) => void;
    params?: any;
    handleItem?: (row: any, typeName: string, action: string) => void;
    IsDataAvailable?: boolean;
    steps: string[];
    invoice?: any;
    checkStatus?: number | null;
    t?:any;
}

export const NewPurchaseSteps: React.FC<NewPurchaseStepsProps> = ({ form, addNewItem, handleInputChange, currentStep, columns, columnsService, pCDNoteItems, pCDNoteService, items, services, loggedInUserCurrency, DeleteItem, params, handleItem, IsDataAvailable, steps, checkStatus, setCurrentStep, setCurrentSteps, t }) => {
    return (<>
        <Stepper className='hide-on-print' activeStep={currentStep} connector={<></>}>
            {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode; } = {};
                return (
                    <Step key={index} {...stepProps} onClick={() => {
                        if(document.querySelector(`.MuiStep-root:nth-child(${index + 1})`)?.classList.contains('Mui-completed')) {
                            setCurrentStep(steps.indexOf(label));
                            setCurrentSteps(steps.indexOf(label));
                        }
                    }} className={`${document.querySelector(`.MuiStep-root:nth-child(${index + 1})`)?.classList.contains('Mui-completed') ? 'cursor-pointer' : ''}`}>
                        <StepLabel {...labelProps}>{t(label)}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
        <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t(steps[currentStep])}</Typography>
            <InventoryItemsTable form={form} columns={columns} handleInputChange={handleInputChange} rows={pCDNoteItems} typeName="pCDNoteItems" items={items} currency={loggedInUserCurrency} DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={checkStatus} viewDelete={IsDataAvailable ? false : true} />
            <Grid item xs={12} sm={6} className="hide-on-print">
                <div className="flex gap-2 justify-start">
                    <Button className="secondary flex gap-1" variant="contained" disableElevation disabled={checkStatus === 2} onClick={() => addNewItem('pCDNoteItems')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                    {/* <Button className="secondary" variant="contained" disableElevation disabled={checkStatus === 2}>{t('Import')}</Button>
                    <Button className="secondary" variant="contained" disableElevation disabled={checkStatus === 2}>{t("Export")}</Button> */}
                </div>
            </Grid>
        </Box>
        <>
            <Box className="flex gap-2 justify-start mt-[16px]">
                <Button className="secondary cursor-text" variant="contained" disableElevation>{t('Additional Costs')}</Button>
            </Box>
            <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
                <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('Additional Cost')}</Typography>
                <InventoryServicesTable form={form} columns={columnsService} handleInputChange={handleInputChange} rows={pCDNoteService} typeName="pCDNoteService" services={services} currency={loggedInUserCurrency}  DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={checkStatus} viewDelete={IsDataAvailable ? false : true} />
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-start">
                        <Button className="secondary flex gap-1" variant="contained" disableElevation disabled={checkStatus === 2} onClick={() => addNewItem('pCDNoteService')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                    </div>
                </Grid>
                <Grid item xs={12}><Divider className="bg-[#AEB3C4] mt-[15px] mb-[8px]" /></Grid>
            </Box>
        </>
    </>);
}

export default CreditNote;
