// ClientProfileService.tsx
import { useInfiniteQuery } from '@tanstack/react-query';
import { ClientProfileService } from '../../core/services';
import { PageSize } from '../../type';

export const useGetClientsService = (initialParams: PageSize, typeName: string) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-clients-data'],
      queryFn: async ({ pageParam }) => {
        return ClientProfileService.getClients(
          {
            page: initialParams.pageNumber ?? pageParam,
            size: initialParams.size,
            name: initialParams.name,
            userName: initialParams.userName,
            accountTitle: initialParams.accountTitle,
            contractNumber: initialParams.contractNumber,
          },
          typeName
        );
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetClientsDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-clients-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return ClientProfileService.getClientsDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          name: initialParams.name,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetCustomersService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-customers-data'],
      queryFn: async ({ pageParam }) => {
        return ClientProfileService.getCustomers({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          name: initialParams.name,
          userName: initialParams.userName,
          phone: initialParams.phone,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};
