import { BaseService } from '../../core/BaseService';
import { DeleteData, PaginationParams } from '../../type';
import { ExpenseType, ExpenseTypeResponse, GetExpenseTypeByIdResponse, GetExpenseTypeResponse } from './interface';

export class ExpenseProfile extends BaseService {
  async createExpenseType(req: ExpenseType) {
    return this.post<ExpenseTypeResponse, ExpenseType>(`${this.apiUrl}/api/expensetype`, req);
  }

  async getExpenseType(params: PaginationParams) {
    return this.get<GetExpenseTypeResponse>(`${this.apiUrl}/api/expensetype/paged`, params);
  }

  async updateExpenseType(req: ExpenseType) {
    return this.put<ExpenseType, ExpenseType>(`${this.apiUrl}/api/expensetype/update`, req);
  }

  async deleteEntity(id: number, typeName: string) {
    return this.delete<DeleteData>(`${this.apiUrl}/api/${typeName}/${id}`);
  }

  async getExpenseTypeById(id: number | undefined) {
    return this.get<GetExpenseTypeByIdResponse>(`${this.apiUrl}/api/expensetype/byid/${id}`);
  }
}
