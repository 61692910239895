import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useNavigate } from "react-router-dom";

interface tabProps {
    params: any;
    selectedIndex: number;
}
export default function InspectionFormTabs(props: tabProps) {
    const navigate = useNavigate();
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) navigate(`/inspection-form/${props.params?.id}`);
        else if (tabId === 1) navigate(`/inspection-sections/${props.params?.id}`);
        else if (tabId === 2) navigate(`/inspection-form-items/${props.params?.id}`);
    }
    const tabs = [
        { id: 0, title: `edit`, child: <></> },
        { id: 1, title: `inspectionSections`, child: <></> },
        { id: 2, title: `inspectionFormItems`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
                return !checkPrivileges('ROLE_INSPECTIONFORM_UPDATE');
            case 1:
                return !checkPrivileges('ROLE_INSPECTIONFORM_SECTION_VIEW');
            case 2:
                return !checkPrivileges('ROLE_INSPECTIONFORMITEM_VIEW');
            default:
                return false;
        }
    });

    return (<>
        <VerticalTabs tabs={tabs} selectedIndex={props.selectedIndex} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
    </>);
}