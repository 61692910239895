import { Box, Button, Grid, InputAdornment, TextField, useMediaQuery } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CommonCheckbox from "common/CheckBox/Checkbox";
import userIcon from 'assets/Login/loginUser.svg';
import lock from 'assets/Login/lock.svg';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuthService } from "subModule/src/services/userProfile/useProfile";
import AlertMessages from "common/Alert/AlertMessages";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from 'features/user/userSlice';
import { brandingInfo } from "features/server/brandingSlice";

interface FormData { [key: string]: any;}
export default function LoginPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const branding = useSelector(brandingInfo);
    const isScreen600pxOrSmaller = useMediaQuery('(max-width:600px)');
    const { register, control, handleSubmit, formState: { errors }, watch, setValue } = useForm<FormData>({defaultValues: {}});
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
    const onSubmit = (data: any) => {
        setIsBtnEnabled(true);
        onLogIn(data.email, data.password);
    };
    const { onLogIn } = useAuthService({
        onSuccess: (response) => {
            if(response.success) {
                dispatch(setUserInfo(response?.data as any));
                localStorage.setItem('selectedSubItem', 'users');
                navigate('/users');
            } else {
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                setIsBtnEnabled(false);
            }
        },
        onError: (err: any) => {
          if (err) {
            const errorMessage = err?.data?.message || 'An error occurred';
            enqueueSnackbar(errorMessage, { variant: 'error' });
          }
          setIsBtnEnabled(false);
        },
    });
    return (<Box className={`bg-cover bg-center min-h-screen flex items-center justify-end ${isScreen600pxOrSmaller ? 'py-10 px-0' : 'py-10 px-[80px]'}`} style={{ backgroundImage: `url(${branding?.attributes?.logInPageImage || ''})`}}>
        <Grid container component="form" id="form"  justifyContent="flex-end" alignItems="center" rowSpacing={2} onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => e.key === "Enter" && handleSubmit(onSubmit)()} className={`rounded-[8px] ${isScreen600pxOrSmaller ? 'p-5' : 'px-7 py-10'} bg-signbg-color ${isScreen600pxOrSmaller ? 'w-[100%]' : 'w-[390px]'}`}>
            <Grid item xs={12}>
                <img src={branding?.attributes?.logo || ''} className="h-[60px] object-fill mx-auto w-[140px]" />
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    {...register("email", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                    label={t('email') + ' *'}
                    fullWidth
                    error={!!errors.email} 
                    helperText={errors.email?.message as string} 
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ startAdornment: (<InputAdornment position="start">
                        <img src={userIcon} alt="User Icon" height={18} width={18} className="text-primary-color" />
                    </InputAdornment>)}}
                    sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                 />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type={watch('showCurrentPassword') ? 'text' : 'password'}
                    {...register('password', { required: t('This field is required.'), })}
                    label={t('password') + ' *'}
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password?.message as string}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">
                            <img src={lock} alt="Lock Icon" height={15} width={15} className="text-primary-color" />
                        </InputAdornment>),
                        endAdornment: (watch('password') ? <InputAdornment position="end" className="cursor-pointer">
                            {watch('showCurrentPassword') ? (
                                <VisibilityIcon className="text-primary-color" onClick={() => setValue('showCurrentPassword', false)} />
                            ) : (
                                <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showCurrentPassword', true)} />
                            )}
                        </InputAdornment> : null),
                    }}
                />
            </Grid>
            <Grid item xs={12} className="pt-0">
                <Controller
                    name="rememberMe"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (<div className="relative top-[10px]">
                        <CommonCheckbox
                            {...field}
                            label={t("rememberMe")}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                            checked={field.value}
                            color="success"
                        />
                    </div>)}
                />
            </Grid>
            <Grid item xs={12} className="pt-0">
                <Button type="submit" disabled={IsBtnEnabled} className="primary" variant="contained" disableElevation onClick={() => onSubmit}>{t('Log In')}</Button>
            </Grid>
        </Grid>
    </Box>)
}