import { selectUserInfo } from "features/user/userSlice";
import { Button, Grid, InputAdornment, Step, StepLabel, Stepper, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ClientProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Client  } from "assets/SideBarIcons/customer.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import Loader from "layout/Loader";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import DynamicSelect from "common/Select/Select";
import ClientTabs from "./ClientTabs";
import CommonCheckbox from "common/CheckBox/Checkbox";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GetClientByIdResponse } from "subModule/src/services/client/interface";
import BasicDatePicker from "common/Pickers/Pickers";
import { useGetPriceListDropdownService } from "subModule/src/services/pricelist/usePriceList";

interface FormData { [key: string]: any; }
const steps = ['Details', 'contractManagement'];
export default function ClientsModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, control, formState: { errors, dirtyFields, isDirty }, watch, setValue, reset, trigger, getValues } = useForm<FormData>({ defaultValues: {} });
    const { data: priceListData, refetch: priceListRefetch } = useGetPriceListDropdownService({ pageNumber: 1, size: 1000 });
    const priceLists = (priceListData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const loggedInUserData = useSelector(selectUserInfo);
    const loggedInUserType = loggedInUserData.type;
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [showSecTitleField, setShowSecTitleField] = useState<boolean>(false);
    const [showTitleField, setShowTitleField] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const companyTypes = [
        { value: 1000, label: t('Individual') },
        { value: 1100, label: t('Corporate') },
        { value: 1200, label: t('Government') },
    ]
    useEffect(() => {
        priceListRefetch();
        if (params?.id) {
            if (loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        setCurrentStep(0);
    }, []);
    useEffect(() => {
        if(currentStep === 0 && loggedInUserType === -1 && watch('account.contractClient')) {
            setIsBtnEnabled(true);
        }
    }, [currentStep]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getByID = () => {
        ClientProfileService.getClientByID(+(params as any)?.id, loggedInUserType === -1 ? 'clients/contractclient' : 'clients').then((res: GetClientByIdResponse) => {
            if (res.success) {
                reset({ ...res?.data || {}, 
                    username: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test((res?.data as any)?.username) ? res?.data?.username : '',
                    account: {...res?.data?.account,
                        title: res.data.account?.title ? res.data.account?.title : '',
                        companyType: res.data.account?.companyType ? { value: res.data.account?.companyType || '', label: companyTypes[companyTypes.findIndex((v) => v.value === res.data.account?.companyType)]?.label || '' } : null,
                        priceListId: res.data.account?.priceListId ? { value: res.data.account?.priceListId, label: res?.data?.account?.priceListTitle || res.data.account?.priceListId } : null,
                    }
                });
                if(currentStep === 0 && loggedInUserType === -1 && res?.data?.account?.contractClient) {
                    setIsBtnEnabled(true);
                }
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        if(loggedInUserType === -1 && currentStep === 0 && watch('account.contractClient')) {
            setCurrentStep(currentStep + 1);
        } else {
            setIsBtnEnabled(false);
            if (params?.id) {
                delete data?.account?.userId;
                ClientProfileService.updateClient(+params?.id, { ...data, type: 160,
                    email: data.username,
                    account: {...data.account, 
                        companyType: data?.account?.companyType?.value ? data.account?.companyType.value: 0, 
                        priceListId: data.account?.priceListId.value 
                    }
                }, loggedInUserType === -1 ? 'clients/contractclient' : 'clients').then((response: any) => {
                    if (response.success) {
                        getByID();
                        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    } else {
                        enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                        setIsBtnEnabled(true);
                    }
                }).catch((err: any) => {
                    console.error("===", err);
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                });
            } else {
                delete data.showConfirmPassword; delete data.showPassword; delete data.confirmPassword;
                ClientProfileService.createClient({ ...data, 
                    type: 160,
                    email: data.username,
                    active: true,
                    business: {},
                    account: {...data?.account, companyType: data?.account?.companyType?.value ? data.account?.companyType.value: 0, priceListId: data.account?.priceListId.value }
                }, loggedInUserType === -1 ? 'clients/contractclient' : 'clients').then((response: any) => {
                    if (response.success) {
                        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                        navigate('/clients');
                    } else {
                        setIsBtnEnabled(true);
                        enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    }
                }).catch((err: any) => {
                    console.error("===", err);
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                });
            }
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={false}
            onClick={() => navigate('/clients')}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editClient') : t('addClient')}
            icon={<Client height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_CLIENT_CREATE")}
        />
        <div className={`${params?.id ? 'flex' : ''}`}>
            {params?.id ? <ClientTabs params={params} selectedIndex={0} /> : null}
            {loading ? <Loader /> : 
                <SimpleModal
                    visable={true}
                    title={params?.id ? t('editClient') : t('addClient')}
                    isButtonVisable={true}
                    isVisableBtn={IsBtnEnabled}
                    btnclosetext="Back"
                    buttonText={loggedInUserType === -1 && currentStep === 0 && watch('account.contractClient') ? "Next" : (params?.id ? "update" : "submit")}
                    attBtnNotshow={false}
                    notVisableBackbtn={true}
                    showPortList={handleSubmit(onSubmit)}
                    formSubmit={handleSubmit(onSubmit)}
                    content={
                        <Grid container component="form" id="form" className="mb-2" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                            {loggedInUserType === -1 ? <>
                                <Stepper className='mt-5 ms-[15px] reportSteps w-[100%]' activeStep={currentStep}>
                                    {steps.map((label, index) => {
                                        const stepProps: { completed?: boolean } = {};
                                        const labelProps: { optional?: React.ReactNode; } = {};
                                        return (
                                            <Step key={index} {...stepProps}><StepLabel {...labelProps} className={`${index === 1 && !watch('account.contractClient') ? 'opacity-25' : 'opacity-100'}`}>{t(label)}</StepLabel></Step>
                                        );
                                    })}
                                </Stepper>
                                {currentStep === 0 ? <Grid item xs={12} md={3} className="pt-0 mb-[-20px]">
                                    <Controller
                                        name="account.contractClient"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (<div>
                                                <CommonCheckbox
                                                    {...field}
                                                    label={t("contractClient")}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                                    checked={field.value}
                                                    color="success"
                                                />
                                            </div>)}
                                        />
                                </Grid> : null}
                            </>: null}
                            <Grid item container xs={12} rowSpacing={3} columnSpacing={3}>
                                {currentStep === 0 ? <>
                                    <Grid item xs={12}>
                                        <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('accountDetails')}</h1>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            {...register("account.title", { validate: { required: (value) => value?.trim() !== '' || t('This field is required.'), } })}
                                            label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${primaryLanguage})` : ''} *`}
                                            fullWidth
                                            error={!!(errors.account as any)?.title}
                                            helperText={(errors.account as any)?.title?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (<InputAdornment position="end">
                                                    <Group onClick={() => setShowTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                                </InputAdornment>)
                                            }}
                                            sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                                        />
                                        {showTitleField && <div style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                                <TextField
                                                    {...register("account.secondaryTitle")}
                                                    label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                                                    fullWidth
                                                    error={!!(errors.account as any)?.secondaryTitle}
                                                    helperText={(errors.account as any)?.secondaryTitle?.message as string}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>
                                        </div>}
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            {...register("account.taxNumber")}
                                            label={t('VAT Number')}
                                            fullWidth
                                            error={!!(errors.account as any)?.taxNumber}
                                            helperText={(errors.account as any)?.taxNumber?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            type="email"
                                            {...register("account.email", {
                                                required: t('This field is required.'),
                                                pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: t('validEmail') }
                                            },)}
                                            label={t("email") + ' *'}
                                            fullWidth
                                            error={!!(errors.account as any)?.email}
                                            helperText={(errors.account as any)?.email?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            type="text"
                                            {...register("account.phone", {
                                                validate: {
                                                    phoneLength: (value) =>
                                                        value === '' || (value?.length >= 5 && value?.length <= 17) || t('phoneLength'),
                                                    nonNegative: (value) =>
                                                        value === '' || /^\+?[\p{N}]+$/u.test(value) || t('onlyPositiveNumbers'),
                                                },
                                            })}
                                            label={t("phoneNumber")}
                                            fullWidth
                                            error={!!(errors.account as any)?.phone}
                                            helperText={(errors.account as any)?.phone?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            type="number"
                                            {...register("account.creditLimit")}
                                            onChange={(e: any) => {
                                                const value = parseFloat(e.target.value);
                                                e.target.value = value < 0 ? 0 : value || "";
                                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                                            }}
                                            label={t("creditLimit")}
                                            fullWidth
                                            error={!!(errors.account as any)?.creditLimit}
                                            helperText={(errors.account as any)?.creditLimit?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            type="number"
                                            {...register("account.creditPeriod")}
                                            onChange={(e: any) => {
                                                const value = parseFloat(e.target.value);
                                                e.target.value = value < 0 ? 0 : value || "";
                                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                                            }}
                                            label={t("creditPeriod")}
                                            fullWidth
                                            error={!!(errors.account as any)?.creditPeriod}
                                            helperText={(errors.account as any)?.creditPeriod?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            name="account.companyType"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <DynamicSelect
                                                    label={t("companyType")}
                                                    {...field}
                                                    options={companyTypes}
                                                    isSearchable
                                                    isClearable
                                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                                    error={(errors.account as any)?.companyType?.message as string}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            {...register("account.address")}
                                            label={t('address')}
                                            fullWidth
                                            error={!!(errors.account as any)?.address}
                                            helperText={(errors.account as any)?.address?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                            multiline
                                            rows={1}
                                            sx={{
                                                '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                                '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            name="account.priceListId"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: t('This field is required.') }}
                                            render={({ field }) => (
                                                <DynamicSelect
                                                    label={t("priceList") + ' *'}
                                                    {...field}
                                                    options={priceLists}
                                                    isSearchable
                                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                                    error={(errors.account as any)?.priceListId?.message as string}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </> : null}
                                {loggedInUserType === -1 && currentStep === 1 ? <>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            {...register("account.contractNumber", { validate: { required: (value) => !watch("account.contractClient") || value?.trim() !== '' || t('This field is required.'), } })}
                                            label={t('contractNumber') + (watch("account.contractClient") ? ' *' : '')}
                                            fullWidth
                                            error={!!(errors.account as any)?.contractNumber}
                                            helperText={(errors.account as any)?.contractNumber?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            name="account.contractStart"
                                            control={control}
                                            rules={{ validate: (value) => {
                                                const date = new Date(value);
                                                return isNaN(date.getTime()) && value ? t("Invalid Date") : true;
                                            } }}
                                            render={({ field }) => (
                                                <BasicDatePicker
                                                    type="datePicker"
                                                    {...field}
                                                    label={t('contractStartDate')}
                                                    fullWidth
                                                    onChange={(value: any) => {field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-')); trigger('account.contractStart');}}
                                                    required={false}
                                                    error={(errors.account as any)?.contractStart?.message as string}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            name="account.contractEnd"
                                            control={control}
                                            rules={{ validate: (value) => {
                                                const date = new Date(value);
                                                const startDate = new Date(getValues("account.contractStart"));
                                                if (startDate && date < startDate) return t("endDateOrder");
                                                return true;
                                            } }}
                                            render={({ field }) => (
                                                <BasicDatePicker
                                                    type="datePicker"
                                                    {...field}
                                                    label={t('contractEndDate')}
                                                    fullWidth
                                                    onChange={(value: any) => {field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-')); trigger('account.contractEnd');}}
                                                    required={false}
                                                    error={(errors.account as any)?.contractEnd?.message as string}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('hostingDetails')}</h1>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            {...register("account.hostDetails.url", { validate: { required: (value) => !watch("account.contractClient") || value?.trim() !== '' || t('This field is required.'), } })}
                                            label={t('url') + (watch("account.contractClient") ? ' *' : '')}
                                            fullWidth
                                            error={!!(errors.account as any)?.hostDetails?.url}
                                            helperText={(errors.account as any)?.hostDetails?.url?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            {...register("account.hostDetails.username", { validate: { required: (value) => !watch("account.contractClient") || value?.trim() !== '' || t('This field is required.'), } })}
                                            label={t('userName') + (watch("account.contractClient") ? ' *' : '')}
                                            fullWidth
                                            error={!!(errors.account as any)?.hostDetails?.username}
                                            helperText={(errors.account as any)?.hostDetails?.username?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            {...register("account.hostDetails.password", { validate: { required: (value) => !watch("account.contractClient") || value?.trim() !== '' || t('This field is required.'), } })}
                                            label={t('password') + (watch("account.contractClient") ? ' *' : '')}
                                            fullWidth
                                            error={!!(errors.account as any)?.hostDetails?.password}
                                            helperText={(errors.account as any)?.hostDetails?.password?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </> : null}
                            </Grid>
                            {currentStep === 0 ? <>
                                <Grid item xs={12}>
                                    <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('userDetails')}</h1>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        {...register("name", { validate: { required: (value) => value?.trim() !== '' || t('This field is required.'), } })}
                                        label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                        fullWidth
                                        error={!!errors.name}
                                        helperText={errors.name?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">
                                                <Group onClick={() => setShowSecTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                            </InputAdornment>)
                                        }}
                                        sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                                    />
                                    {showSecTitleField && <div style={{ position: 'relative' }}>
                                        <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                            <TextField
                                                {...register("secondaryName")}
                                                label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                                fullWidth
                                                error={!!errors.secondaryName}
                                                helperText={errors.secondaryName?.message as string}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    </div>}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        type="email"
                                        {...register("username", {
                                            required: t('This field is required.'),
                                            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: t('validEmail') }
                                        },)}
                                        label={t("email") + ' *'}
                                        fullWidth
                                        error={!!errors.username}
                                        helperText={errors.username?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                {params?.id ? null : <>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            type={watch('showPassword') ? 'text' : 'password'}
                                            {...register('password', {
                                                required: t('This field is required.'),
                                                validate: { passwordLength: (value) => value?.length >= 8 || t('minPasswordLength'), },
                                            })}
                                            label={t('password') + ' *'}
                                            fullWidth
                                            error={!!errors.password}
                                            helperText={errors.password?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (watch('password') ? <InputAdornment position="end" className="cursor-pointer">
                                                    {watch('showPassword') ? (
                                                        <VisibilityIcon className="text-primary-color" onClick={() => setValue('showPassword', false)}/>
                                                    ) : (
                                                        <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showPassword', true)} />
                                                    )}
                                                </InputAdornment> : null),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            type={watch('showConfirmPassword') ? 'text' : 'password'}
                                            {...register("confirmPassword", {
                                                required: t('This field is required.'),
                                                validate: { passwordLength: (value) => (value === watch('password')) || t('confirmPasswordNewPassword'), },
                                            },)}
                                            label={t("confirmNewPassword") + ' *'}
                                            fullWidth
                                            error={!!errors.confirmPassword}
                                            helperText={errors.confirmPassword?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (watch('confirmPassword') ? <InputAdornment position="end" className="cursor-pointer">
                                                    {watch('showConfirmPassword') ? (
                                                        <VisibilityIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', false)}/>
                                                    ) : (
                                                        <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', true)} />
                                                    )}
                                                </InputAdornment> : null),
                                            }}
                                        />
                                    </Grid>
                                </>}
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        type="text"
                                        {...register("phone", {
                                            validate: {
                                                phoneLength: (value) =>
                                                    value === '' || (value?.length >= 5 && value?.length <= 17) || t('phoneLength'),
                                                nonNegative: (value) =>
                                                    value === '' || /^\+?[\p{N}]+$/u.test(value) || t('onlyPositiveNumbers'),
                                            },
                                        })}
                                        label={t("phoneNumber")}
                                        fullWidth
                                        error={!!errors.phone}
                                        helperText={errors.phone?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        {...register("address")}
                                        label={t('address')}
                                        fullWidth
                                        error={!!errors.address}
                                        helperText={errors.address?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                        multiline
                                        rows={1}
                                        sx={{
                                            '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                            '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        {...register("source")}
                                        label={t('source')}
                                        fullWidth
                                        error={!!errors.source}
                                        helperText={errors.source?.message as string}
                                    />
                                </Grid>
                            </> : null}
                            {currentStep > 0 ? <Grid item xs={12} className="text-start">
                                <Button variant="contained" 
                                    className="absolute font-Saira font-semibold leading-[26px] bg-white text-black mt-[26px] px-[20px]"
                                    onClick={() => setCurrentStep(0)}
                                >{t('Previous')}</Button>
                            </Grid> : null}
                        </Grid>
                    }
                />}
        </div>
    </>)
}