import { Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

interface ImageClickCoordinatesProps {
  src: string,
  alt: string,
  callback: (coordinates: number[]) => void,
  value: number[],
  setIsBtnEnabled?: any;
}
const ImageClickCoordinates: React.FC<ImageClickCoordinatesProps> = ({ src, alt, callback, value, setIsBtnEnabled }) => {
  const [coordinates, setCoordinates] = useState({ x: value[0], y: value[1] });
  const imgRef = useRef(null);

  const handleImageClick = (event: any) => {
    if (imgRef.current) {
      const rect = (imgRef.current as any).getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCoordinates({ x: Math.round(x), y: Math.round(y) });
      callback([Math.round(x), Math.round(y)])
    }
    setIsBtnEnabled?.(true);
  };

  return (
    <div className="relative">
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        onClick={handleImageClick}
        className="cursor-pointer block !max-w-none !max-h-none w-[300px]"
      />
      <Typography className="font-Saira font-[600] text-[14px] text-txt-color line-clamp-1 mt-[18px]">
        Clicked coordinates: ({coordinates.x}, {coordinates.y})
      </Typography>
      {coordinates.x || coordinates.y ? <span
        className={'-ml-4 -mt-4 w-8 h-8 flex items-center justify-center rounded-full absolute bg-white text-red-500'}
        style={{ left: coordinates.x, top: coordinates.y }}
      >
        <span className='block m-auto w-4 h-4 rounded-full bg-black' />
      </span> : null}
    </div>
  );
};

export default ImageClickCoordinates;