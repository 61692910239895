// BusinessGroupService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignBusinessRequest,
  AssignUnassignResponse,
  AssignUnassignStationRequest,
  BusinessGroupRequest,
  BusinessGroupResponse,
  ChangeStatusBusinessGroupResponse,
  DeleteBusinessGroupResponse,
  GetBusinessGroupBusinessResponse,
  GetBusinessGroupByIdResponse,
  GetBusinessGroupDropdownResponse,
  GetBusinessGroupResponse,
  GetBusinessGroupStationResponse,
} from './interface';

export class BusinessGroupProfile extends BaseService {
  async createBusinessGroup(req: BusinessGroupRequest) {
    return this.post<BusinessGroupResponse, BusinessGroupRequest>(`${this.apiUrl}/api/businessgroups`, req);
  }

  async getBusinessGroup(params: PaginationParams) {
    return this.get<GetBusinessGroupResponse>(`${this.apiUrl}/api/businessgroups/paged`, params);
  }

  async getBussinessGroupDropdown(params: PaginationParams) {
    return this.get<GetBusinessGroupDropdownResponse>(`${this.apiUrl}/api/businessgroups/get`, params);
  }

  async updateBusinessGroup(id: number, req: BusinessGroupRequest) {
    return this.put<BusinessGroupResponse, BusinessGroupRequest>(`${this.apiUrl}/api/businessgroups/${id}`, req);
  }

  async deleteBusinessGroup(id: number) {
    return this.delete<DeleteBusinessGroupResponse>(`${this.apiUrl}/api/businessgroups/${id}`);
  }

  async changeStatusBusinessGroup(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusBusinessGroupResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/businessgroups/${active}/${id}`,
      req
    );
  }

  async getBusinessGroupStation(params: PaginationParams & { businessGroupId: number }) {
    return this.get<GetBusinessGroupStationResponse>(`${this.apiUrl}/api/stations/bybusinessgroupid`, params);
  }

  async assignStation(req: AssignUnassignStationRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignStationRequest>(
      `${this.apiUrl}/api/businessgroups/station/assign`,
      req
    );
  }

  async unassignStation(req: AssignUnassignStationRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignStationRequest>(
      `${this.apiUrl}/api/businessgroups/station/unassign`,
      req
    );
  }

  async assignAllStations(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/businessgroups/station/assignall`,
      req
    );
  }

  async unassignAllStations(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/businessgroups/station/unassignall`,
      req
    );
  }

  async getBusinessGroupBusiness(params: PaginationParams & { businessGroupId: number }) {
    return this.get<GetBusinessGroupBusinessResponse>(`${this.apiUrl}/api/businesses/bybusinessgroupid`, params);
  }

  async assignBusiness(req: AssignUnassignBusinessRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignBusinessRequest>(
      `${this.apiUrl}/api/businessgroups/business/assign`,
      req
    );
  }

  async unassignBusiness(req: AssignUnassignBusinessRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignBusinessRequest>(
      `${this.apiUrl}/api/businessgroups/business/unassign`,
      req
    );
  }

  async assignAllBusinesses(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/businessgroups/business/assignall`,
      req
    );
  }

  async unassignAllBusinesses(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/businessgroups/business/unassignall`,
      req
    );
  }

  async getBusinessGroupByID(id: number) {
    return this.get<GetBusinessGroupByIdResponse>(`${this.apiUrl}/api/businessgroups/${id}`);
  }
}
