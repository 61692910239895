import { Routes, Route, Navigate } from 'react-router-dom';
import BankAccountListing from 'pages/BankAccount/BankAccountListing';
import Error404 from 'pages/404/Index';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import NewPurchase from 'pages/Inventory/NewPurchase';
import PurchaseOrderListing from 'pages/Inventory/PurchaseOrderListing';
import InventorySteps from 'pages/Inventory/InventorySteps';
import PurchasePaymentListing from 'pages/Inventory/PurchasePaymentListing';
import PurchasePaymentCreateUpdate from 'pages/Inventory/PurchasePaymentCreateUpdate';
import StockReceivedListing from 'pages/Inventory/StockReceivedListing';
import CreditNoteListing from 'pages/Inventory/CreditNoteListing';
import StockReceivedCreateUpdate from 'pages/Inventory/StockReceivedCreateUpdate';
import CreditNoteCreateUpdate from 'pages/Inventory/CreditNoteCreateUpdate';
import UnstockListing from 'pages/Inventory/UnstockListing';
import UnstockCreateUpdate from 'pages/Inventory/UnstockCreateUpdate';
import BankAccountModal from 'pages/BankAccount/BankAccountModal';
import BankDepositListing from 'pages/BankDeposit/BankDepositListing';
import BankDepositModal from 'pages/BankDeposit/BankDepositModal';
import JobCardListing from 'pages/JobCard/JobCardListing';
import JobCardModal from 'pages/JobCard/JobCardModal';
import WorkOrderListing from 'pages/WorkOrder/WorkOrderListing';
import WorkOrderModal from 'pages/WorkOrder/WorkOrderModal';
import InvoiceListing from 'pages/Invoice/InvoiceListing';
import InvoiceModal from 'pages/Invoice/InvoiceModal';
import PaymentModeListing from 'pages/PaymentMode/PaymentModeListing';
import PaymentModeModal from 'pages/PaymentMode/PaymentModeModal';
import ExpenseTypeListing from 'pages/ExpenseType/ExpenseTypeListing';
import ExpenseTypeModal from 'pages/ExpenseType/ExpenseTypeModal';
import PaymentModal from 'pages/Payment/PaymentModal';
import PaymentListing from 'pages/Payment/PaymentListing';
import VehicleListing from 'pages/Vehicle/VehicleListing';
import VehicleModal from 'pages/Vehicle/VehicleModal';
import CheckListItemListing from 'pages/ChecklistItem/CheckListItemListing';
import ChecklistItemModal from 'pages/ChecklistItem/CheckListItemModal';
import ChecklistListing from 'pages/Checklist/ChecklistListing';
import ChecklistModal from 'pages/Checklist/ChecklistModal';
import StockTransferListing from 'pages/Inventory/StockTransferListing';
import StockTransferCreateUpdate from 'pages/Inventory/StockTransferCreateUpdate';
import StockAdjustmentListing from 'pages/Inventory/StockAdjustmentListing';
import StockAdjustmentCreateUpdate from 'pages/Inventory/StockAdjustmentCreateUpdate';
import MakeListing from 'pages/Make/MakeListing';
import MakeModal from 'pages/Make/MakeModal';
import ModelListing from 'pages/Model/ModelListing';
import ModelModal from 'pages/Model/ModelModal';
import ModelEngineListing from 'pages/ModelEngine/ModelEngineListing';
import ModelServiceTable from 'pages/Model/ModelServiceTable';
import ModelEngineModal from 'pages/ModelEngine/ModelEngineModal';
import VehicleTypeListing from 'pages/VehicleType/VehicleTypeListing';
import VehicleTypeModal from 'pages/VehicleType/VehicleTypeModal';
import VehicleStatusListing from 'pages/VehicleStatus/VehicleStatusListing';
import VehicleStatusModal from 'pages/VehicleStatus/VehicleStatusModal';
import UsersListing from 'pages/Users/UsersListing';
import UsersModal from 'pages/Users/UsersModal';
import UpdatePassword from 'pages/Users/UpdatePassword';
import ActivityLogsListing from 'pages/ActivityLogs/ActivityLogsListing';
import Reports from 'pages/Reports';
import BusinessListing from 'pages/Business/BusinessListing';
import BusinessModal from 'pages/Business/BusinessModal';
import RolesListing from 'pages/Roles/RolesListing';
import RolesModal from 'pages/Roles/RolesModal';
import RolePrivileges from 'pages/Roles/RolePrivileges';
import ManufacturersListing from 'pages/Manufacturer/ManufacturersListing';
import ManufacturersModal from 'pages/Manufacturer/ManufacturersModal';
import BusinessGroupsListing from 'pages/BusinessGroup/BusinessGroupListing';
import BusinessGroupsModal from 'pages/BusinessGroup/BusinessGroupModal';
import BusinessGroupStationTable from 'pages/BusinessGroup/BusinessGroupStationTable';
import ClientsListing from 'pages/Client/ClientsListing';
import ClientsModal from 'pages/Client/ClientsModal';
import UpdatePasswordClient from 'pages/Client/UpdatePassword';
import CustomersModal from 'pages/Customer/CustomersModal';
import UpdatePasswordCustomer from 'pages/Customer/UpdatePassword';
import CustomersListing from 'pages/Customer/CustomersListing';
import ClientGroupsListing from 'pages/ClientGroup/ClientGroupListing';
import ClientGroupsModal from 'pages/ClientGroup/ClientGroupModal';
import ClientGroupClientTable from 'pages/ClientGroup/ClientGroupCientTable';
import UserGroupsListing from 'pages/UserGroup/UserGroupListing';
import UserGroupsModal from 'pages/UserGroup/UserGroupModal';
import UserGroupUserTable from 'pages/UserGroup/UserGroupUserTable';
import StationGroupsListing from 'pages/StationGroup/StationGroupListing';
import StationGroupsModal from 'pages/StationGroup/StationGroupModal';
import StationGroupStationTable from 'pages/StationGroup/StationGroupStationTable';
import BaysListing from 'pages/Bay/BaysListing';
import BaysModal from 'pages/Bay/BaysModal';
import StoreListing from 'pages/Store/StoreListing';
import StoreModal from 'pages/Store/StoreModal';
import StoreItemsTable from 'pages/Store/StoreItemsTable';
import StateListing from 'pages/State/StateListing';
import StateModal from 'pages/State/StateModal';
import StationListing from 'pages/Station/StationListing';
import StationModal from 'pages/Station/StationModal';
import StationStoreTable from 'pages/Station/StationStoreTable';
import StationBankAccountTable from 'pages/Station/StationBankAccountTable';
import StationUserTable from 'pages/Station/StationUserTable';
import StationBayTable from 'pages/Station/StationBayTable';
import StationWarehouseTable from 'pages/Station/StationWarehouseTable';
import InspectionFormListing from 'pages/InspectionForm/InspectionFormListing';
import InspectionFormModal from 'pages/InspectionForm/InspectionFormModal';
import InspectionSectionListing from 'pages/InspectionForm/InspectionSectionListing';
import InspectionSectionModal from 'pages/InspectionForm/InspectionSectionModal';
import InspectionFormItemListing from 'pages/InspectionForm/InspectionFormItemListing';
import InspectionFormItemModal from 'pages/InspectionForm/InspectionFormItemModal';
import ChangePassword from 'pages/AccountSettings/ChangePassword';
import BusinessSettings from 'pages/AccountSettings/BusinessSettings';
import InvoiceTemplatesTable from 'pages/AccountSettings/InvoiceTemplatesTable';
import LoginPage from 'pages/Login/Login';
import ItemGroupListing from 'pages/ItemGroup/ItemGroupListing';
import ItemGroupModal from 'pages/ItemGroup/ItemGroupModal';
import ItemGroupItemTable from 'pages/ItemGroup/ItemGroupItemTable';
import ItemsListing from 'pages/Items/ItemsListing';
import ItemsModal from 'pages/Items/ItemsModal';
import ItemModelTable from 'pages/Items/ItemsModelTable';
import ItemVendorTable from 'pages/Items/ItemVendorTable';
import ItemsUnitListing from 'pages/Items/ItemsUnit/Index';
import ItemsPriceListListing from 'pages/Items/ItemsPriceList/Index';
import ItemsStoreListing from 'pages/Items/ItemsStore/Index';
import ServiceGroupListing from 'pages/ServiceGroup/ServiceGroupListing';
import ServiceGroupModal from 'pages/ServiceGroup/ServiceGroupModal';
import ServiceGroupServiceTable from 'pages/ServiceGroup/ServiceGroupServiceTable';
import ServicesListing from 'pages/Service/ServicesListing';
import ServicesModal from 'pages/Service/ServicesModal';
import ServiceItemsTable from 'pages/Service/ServiceItemsTable';
import ServiceVehicleTypesTable from 'pages/Service/ServiceVehicleTypes';
import ServiceStationsTable from 'pages/Service/ServiceStationsTable';
import ServiceModelsTable from 'pages/Service/ServiceModelsTable';
import ServicesPricelistListing from 'pages/Service/ServicePricelists/Index';
import PackageGroupListing from 'pages/PackageGroup/PackageGroupListing';
import PackageGroupModal from 'pages/PackageGroup/PackageGroupModal';
import PackageGroupPackageTable from 'pages/PackageGroup/PackageGroupPackageTable';
import PackagesListing from 'pages/Packages/PackagesListing';
import PackagesModal from 'pages/Packages/PackagesModal';
import PackageServicesTable from 'pages/Packages/PackageServicesTable';
import PackageItemsTable from 'pages/Packages/PackageItemsTable';
import PackageStationsTable from 'pages/Packages/PackageStationsTable';
import PackageModelsTable from 'pages/Packages/PackageModelsTable';
import PackagePricelistsListing from 'pages/Packages/PackagePricelists/Index';
import ItemCategoriesListing from 'pages/ItemCatagories/ItemCategoriesListing';
import ItemCategoryModal from 'pages/ItemCatagories/ItemCategoryModal';
import UnitModal from 'pages/Units/UnitModal';
import UnitsListing from 'pages/Units/UnitsListing';
import TaxProcedureListing from 'pages/TaxProcedure/TaxProcedureListing';
import TaxProcedureModal from 'pages/TaxProcedure/TaxProcedureModal';
import VendorListing from 'pages/Vendor/VendorListing';
import VendorModal from 'pages/Vendor/VendorModal';
import WarehouseListing from 'pages/Warehouse/WarehouseListing';
import WarehouseModal from 'pages/Warehouse/WarehouseModal';
import StatusesListing from 'pages/Statuses/StatusesListing';
import StatusesModal from 'pages/Statuses/StatusesModal';
import PricelistListing from 'pages/PriceList/PricelistListing';
import PricelistsModal from 'pages/PriceList/PricelistModal';
import PricelistItemsListing from 'pages/PriceList/PricelistItems/Index';
import PricelistServicesListing from 'pages/PriceList/PricelistServices/Index';
import PricelistPackagesListing from 'pages/PriceList/PricelistPackages/Index';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';

const AppRoutes = () => {
  const loggedInUserData = useSelector(selectUserInfo);
  return (
    <Routes>
      <Route path="*" element={<Error404/>} />
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/users" element={checkPrivileges("ROLE_USER_VIEW") && <UsersListing />} />
      <Route path="/user/create" element={checkPrivileges("ROLE_USER_CREATE") && <UsersModal />} />
      <Route path="/user/:id" element={checkPrivileges("ROLE_USER_UPDATE") && <UsersModal />} />
      <Route path="/user/update-password/:id" element={checkPrivileges("ROLE_CHANGE_PASSWORD") && <UpdatePassword />} />
      <Route path="/businesses" element={(checkPrivileges("ROLE_BUSINESS_VIEW") && (loggedInUserData?.type === -1 || loggedInUserData?.type === 100)) ? <BusinessListing/> : null} />
      <Route path="/business/create" element={(checkPrivileges("ROLE_BUSINESS_CREATE") && (loggedInUserData?.type === -1 || loggedInUserData?.type === 100)) ? <BusinessModal/> : null} />
      <Route path="/business/:id" element={(checkPrivileges("ROLE_BUSINESS_UPDATE") && (loggedInUserData?.type === -1 || loggedInUserData?.type === 100)) ? <BusinessModal/> : null} />
      <Route path="/roles" element={checkPrivileges("ROLE_ROLE_VIEW") && <RolesListing />} />
      <Route path="/role/create" element={checkPrivileges("ROLE_ROLE_CREATE") && <RolesModal />} />
      <Route path="/role/:id" element={checkPrivileges("ROLE_ROLE_UPDATE") && <RolesModal />} />
      <Route path="/role/assign/privilege/:id" element={(checkPrivileges('ROLE_ROLE_PRIVILEGE_ASSIGN') || checkPrivileges('ROLE_ROLE_PRIVILEGE_UNASSIGN')) ? <RolePrivileges /> : null} />
      <Route path="/user-groups" element={checkPrivileges("ROLE_USERGROUP_VIEW") && <UserGroupsListing/>} />
      <Route path="/user-group/create" element={checkPrivileges("ROLE_USERGROUP_CREATE") && <UserGroupsModal />} />
      <Route path="/user-group/:id" element={checkPrivileges("ROLE_USERGROUP_UPDATE") && <UserGroupsModal />} />
      <Route path="/user-group/assign/user/:id" element={(checkPrivileges('ROLE_USERGROUP_USER_ASSIGN') || checkPrivileges('ROLE_USERGROUP_USER_UNASSIGN')) ? <UserGroupUserTable /> : null} />
      <Route path="/clients" element={checkPrivileges("ROLE_CLIENT_VIEW") && <ClientsListing />} />
      <Route path="/client/create" element={checkPrivileges("ROLE_CLIENT_CREATE") && <ClientsModal />} />
      <Route path="/client/:id" element={checkPrivileges("ROLE_CLIENT_UPDATE") && <ClientsModal />} />
      <Route path="/client/update-password/:id" element={checkPrivileges("ROLE_CHANGE_PASSWORD") && <UpdatePasswordClient />} />
      <Route path="/customers" element={checkPrivileges("ROLE_CUSTOMER_VIEW") && <CustomersListing />} />
      <Route path="/customer/create" element={checkPrivileges("ROLE_CUSTOMER_CREATE") && <CustomersModal />} />
      <Route path="/customer/:id" element={checkPrivileges("ROLE_CUSTOMER_UPDATE") && <CustomersModal />} />
      <Route path="/customer/update-password/:id" element={checkPrivileges("ROLE_CHANGE_PASSWORD") && <UpdatePasswordCustomer />} />
      <Route path="/makes" element={checkPrivileges("ROLE_MAKE_VIEW") && <MakeListing />} />
      <Route path="/make/create" element={checkPrivileges("ROLE_MAKE_CREATE") && <MakeModal />} />
      <Route path="/make/:id" element={checkPrivileges("ROLE_MAKE_UPDATE") && <MakeModal />} />
      <Route path="/models" element={checkPrivileges("ROLE_MODEL_VIEW") && <ModelListing />} />
      <Route path="/model/create" element={checkPrivileges("ROLE_MODEL_CREATE") && <ModelModal />} />
      <Route path="/model/:id" element={checkPrivileges("ROLE_MODEL_UPDATE") && <ModelModal />} />
      <Route path="/model/assign/service/:id" element={(checkPrivileges('ROLE_MODEL_SERVICE_ASSIGN') || checkPrivileges('ROLE_MODEL_SERVICE_UNASSIGN')) ? <ModelServiceTable /> : null} />
      <Route path="/model-engines" element={checkPrivileges("ROLE_MODELENGINE_VIEW") && <ModelEngineListing />} />
      <Route path="/model-engine/create" element={checkPrivileges("ROLE_MODELENGINE_CREATE") && <ModelEngineModal />} />
      <Route path="/model-engine/:id" element={checkPrivileges("ROLE_MODELENGINE_UPDATE") && <ModelEngineModal />} />
      <Route path="/vehicle-types" element={checkPrivileges("ROLE_VEHICLETYPE_VIEW") && <VehicleTypeListing/>} />
      <Route path="/vehicle-type/create" element={checkPrivileges("ROLE_VEHICLETYPE_CREATE") && <VehicleTypeModal/>} />
      <Route path="/vehicle-type/:id" element={checkPrivileges("ROLE_VEHICLETYPE_UPDATE") && <VehicleTypeModal/>} />
      <Route path="/vehicles" element={checkPrivileges("ROLE_VEHICLE_VIEW") && <VehicleListing/>} />
      <Route path="/vehicle/create" element={checkPrivileges("ROLE_VEHICLE_CREATE") && <VehicleModal/>} />
      <Route path="/vehicle/:id" element={checkPrivileges("ROLE_VEHICLE_UPDATE") && <VehicleModal/>} />
      <Route path="/stations" element={checkPrivileges("ROLE_STATION_VIEW") && <StationListing/>} />
      <Route path="/station/create" element={checkPrivileges("ROLE_STATION_CREATE") && <StationModal/>} />
      <Route path="/station/:id" element={checkPrivileges("ROLE_STATION_UPDATE") && <StationModal/>} />
      <Route path="/station/assign/store/:id" element={(checkPrivileges('ROLE_STATION_STORE_ASSIGN') || checkPrivileges('ROLE_STATION_STORE_UNASSIGN')) ? <StationStoreTable /> : null} />
      <Route path="/station/assign/bank-account/:id" element={(checkPrivileges('ROLE_STATION_BANKACCOUNT_ASSIGN') || checkPrivileges('ROLE_STATION_BANKACCOUNT_UNASSIGN')) ? <StationBankAccountTable /> : null} />
      <Route path="/station/assign/user/:id" element={(checkPrivileges('ROLE_STATION_USER_ASSIGN') || checkPrivileges('ROLE_STATION_USER_ASSIGN')) ? <StationUserTable /> : null} />
      <Route path="/station/assign/bay/:id" element={(checkPrivileges('ROLE_STATION_BAY_ASSIGN') || checkPrivileges('ROLE_STATION_BAY_UNASSIGN')) ? <StationBayTable /> : null} />
      <Route path="/station/assign/warehouse/:id" element={(checkPrivileges('ROLE_STATION_WAREHOUSE_ASSIGN') || checkPrivileges('ROLE_STATION_WAREHOUSE_UNASSIGN')) ? <StationWarehouseTable /> : null} />
      <Route path="/bank-accounts" element={checkPrivileges("ROLE_BANKACCOUNT_VIEW") && <BankAccountListing/>} />
      <Route path="/bank-account/create" element={checkPrivileges("ROLE_BANKACCOUNT_CREATE") && <BankAccountModal/>} />
      <Route path="/bank-account/:id" element={checkPrivileges("ROLE_BANKACCOUNT_UPDATE") && <BankAccountModal/>} />
      <Route path="/bank-deposits" element={checkPrivileges("ROLE_BANKDEPOSIT_VIEW") && <BankDepositListing/>} />
      <Route path="/bank-deposit/create" element={checkPrivileges("ROLE_BANKDEPOSIT_CREATE") && <BankDepositModal/>} />
      <Route path="/bank-deposit/:id" element={checkPrivileges("ROLE_BANKDEPOSIT_UPDATE") && <BankDepositModal/>} />
      <Route path="/parts" element={checkPrivileges("ROLE_ITEM_VIEW") && <ItemsListing/>} />
      <Route path="/part/create" element={checkPrivileges("ROLE_ITEM_CREATE") && <ItemsModal/>} />
      <Route path="/part/:id" element={checkPrivileges("ROLE_ITEM_UPDATE") && <ItemsModal/>} />
      <Route path="/part/assign/unit/:id" element={(checkPrivileges('ROLE_ITEM_UNIT_ASSIGN') || checkPrivileges('ROLE_ITEM_UNIT_UNASSIGN')) ? <ItemsUnitListing /> : null} />
      <Route path="/part/assign/model/:id" element={(checkPrivileges('ROLE_ITEM_MODEL_ASSIGN') || checkPrivileges('ROLE_ITEM_MODEL_UNASSIGN')) ? <ItemModelTable /> : null} />
      <Route path="/part/assign/vendor/:id" element={(checkPrivileges('ROLE_ITEM_VENDOR_ASSIGN') || checkPrivileges('ROLE_ITEM_VENDOR_UNASSIGN')) ? <ItemVendorTable /> : null} />
      <Route path="/part/assign/price-list/:id" element={(checkPrivileges('ROLE_ITEM_PRICELIST_ASSIGN') || checkPrivileges('ROLE_ITEM_PRICELIST_UNASSIGN')) ? <ItemsPriceListListing /> : null} />
      <Route path="/part/assign/store/:id" element={(checkPrivileges('ROLE_STORE_ITEM_ASSIGN') || checkPrivileges('ROLE_STORE_ITEM_UNASSIGN')) ? <ItemsStoreListing /> : null} />
      <Route path="/packages" element={checkPrivileges("ROLE_PACKAGE_VIEW") && <PackagesListing/>} />
      <Route path="/package/create" element={checkPrivileges("ROLE_PACKAGE_CREATE") && <PackagesModal/>} />
      <Route path="/package/:id" element={checkPrivileges("ROLE_PACKAGE_UPDATE") && <PackagesModal/>} />
      <Route path="/package/assign/service/:id" element={(checkPrivileges('ROLE_PACKAGE_SERVICE_ASSIGN') || checkPrivileges('ROLE_PACKAGE_SERVICE_UNASSIGN')) ? <PackageServicesTable /> : null} />
      <Route path="/package/assign/part/:id" element={(checkPrivileges('ROLE_PACKAGE_ITEM_ASSIGN') || checkPrivileges('ROLE_PACKAGE_ITEM_UNASSIGN')) ? <PackageItemsTable /> : null} />
      <Route path="/package/assign/station/:id" element={(checkPrivileges('ROLE_PACKAGE_STATION_ASSIGN') || checkPrivileges('ROLE_PACKAGE_STATION_UNASSIGN')) ? <PackageStationsTable /> : null} />
      <Route path="/package/assign/model/:id" element={(checkPrivileges('ROLE_PACKAGE_MODEL_ASSIGN') || checkPrivileges('ROLE_PACKAGE_MODEL_UNASSIGN')) ? <PackageModelsTable /> : null} />
      <Route path="/package/assign/price-list/:id" element={(checkPrivileges('ROLE_PACKAGE_PRICELIST_ASSIGN') || checkPrivileges('ROLE_PACKAGE_PRICELIST_UNASSIGN')) ? <PackagePricelistsListing /> : null} />
      <Route path="/part-categories" element={checkPrivileges("ROLE_ITEMCATEGORY_VIEW") && <ItemCategoriesListing/>} />
      <Route path="/part-category/create" element={checkPrivileges("ROLE_ITEMCATEGORY_CREATE") && <ItemCategoryModal/>} />
      <Route path="/part-category/:id" element={checkPrivileges("ROLE_ITEMCATEGORY_UPDATE") && <ItemCategoryModal/>} />
      <Route path="/bays" element={checkPrivileges("ROLE_BAY_VIEW") && <BaysListing/>} />
      <Route path="/bay/create" element={checkPrivileges("ROLE_BAY_CREATE") && <BaysModal/>} />
      <Route path="/bay/:id" element={checkPrivileges("ROLE_BAY_UPDATE") && <BaysModal/>} />
      <Route path="/client-groups" element={checkPrivileges("ROLE_CLIENTGROUP_VIEW") && <ClientGroupsListing/>} />
      <Route path="/client-group/create" element={checkPrivileges("ROLE_CLIENTGROUP_CREATE") && <ClientGroupsModal />} />
      <Route path="/client-group/:id" element={checkPrivileges("ROLE_CLIENTGROUP_UPDATE") && <ClientGroupsModal />} />
      <Route path="/client-group/assign/client/:id" element={(checkPrivileges('ROLE_CLIENTGROUP_CLIENT_ASSIGN') || checkPrivileges('ROLE_CLIENTGROUP_CLIENT_UNASSIGN')) ? <ClientGroupClientTable /> : null} />
      <Route path="/parts-group" element={checkPrivileges("ROLE_ITEMGROUP_VIEW") && <ItemGroupListing />} />
      <Route path="/part-group/create" element={checkPrivileges("ROLE_ITEMGROUP_CREATE") && <ItemGroupModal />} />
      <Route path="/part-group/:id" element={checkPrivileges("ROLE_ITEMGROUP_UPDATE") && <ItemGroupModal />} />
      <Route path="/part-group/assign/part/:id" element={(checkPrivileges('ROLE_ITEMGROUP_ITEM_ASSIGN') || checkPrivileges('ROLE_ITEMGROUP_ITEM_UNASSIGN')) ? <ItemGroupItemTable /> : null} />
      <Route path="/services-group" element={checkPrivileges("ROLE_SERVICEGROUP_VIEW") && <ServiceGroupListing/>} />
      <Route path="/service-group/create" element={checkPrivileges("ROLE_SERVICEGROUP_CREATE") && <ServiceGroupModal />} />
      <Route path="/service-group/:id" element={checkPrivileges("ROLE_SERVICEGROUP_UPDATE") && <ServiceGroupModal />} />
      <Route path="/service-group/assign/service/:id" element={(checkPrivileges('ROLE_SERVICEGROUP_SERVICE_ASSIGN') || checkPrivileges('ROLE_SERVICEGROUP_SERVICE_UNASSIGN')) ? <ServiceGroupServiceTable /> : null} />
      <Route path="/inspection-forms" element={checkPrivileges("ROLE_INSPECTIONFORM_VIEW") && <InspectionFormListing/>} />
      <Route path="/inspection-form/create" element={checkPrivileges("ROLE_INSPECTIONFORM_CREATE") && <InspectionFormModal />} />
      <Route path="/inspection-form/:id" element={checkPrivileges("ROLE_INSPECTIONFORM_UPDATE") && <InspectionFormModal />} />
      <Route path="/inspection-sections/:id" element={checkPrivileges("ROLE_INSPECTIONFORM_SECTION_VIEW") && <InspectionSectionListing/>} />
      <Route path="/inspection-section/:id/create" element={checkPrivileges("ROLE_INSPECTIONFORM_SECTION_CREATE") && <InspectionSectionModal />} />
      <Route path="/inspection-section/:id/edit/:isid" element={checkPrivileges("ROLE_INSPECTIONFORM_SECTION_UPDATE") && <InspectionSectionModal />} />
      <Route path="/inspection-form-items/:id" element={checkPrivileges("ROLE_INSPECTIONFORMITEM_VIEW") && <InspectionFormItemListing/>} />
      <Route path="/inspection-form-item/:id/create" element={checkPrivileges("ROLE_INSPECTIONFORMITEM_CREATE") && <InspectionFormItemModal />} />
      <Route path="/inspection-form-item/:id/edit/:ifid" element={checkPrivileges("ROLE_INSPECTIONFORMITEM_UPDATE") && <InspectionFormItemModal />} />
      <Route path="/price-lists" element={checkPrivileges("ROLE_PRICELIST_VIEW") && <PricelistListing/>} />
      <Route path="/price-list/create" element={checkPrivileges("ROLE_PRICELIST_CREATE") && <PricelistsModal/>} />
      <Route path="/price-list/:id" element={checkPrivileges("ROLE_PRICELIST_UPDATE") && <PricelistsModal/>} />
      <Route path="/price-list/assign/package/:id" element={(checkPrivileges('ROLE_PACKAGE_PRICELIST_ASSIGN') || checkPrivileges('ROLE_PACKAGE_PRICELIST_UNASSIGN')) ? <PricelistPackagesListing /> : null} />
      <Route path="/price-list/assign/service/:id" element={(checkPrivileges('ROLE_SERVICE_PRICELIST_ASSIGN') || checkPrivileges('ROLE_SERVICE_PRICELIST_UNASSIGN')) ? <PricelistServicesListing /> : null} />
      <Route path="/price-list/assign/part/:id" element={(checkPrivileges('ROLE_ITEM_PRICELIST_ASSIGN') || checkPrivileges('ROLE_ITEM_PRICELIST_UNASSIGN')) ? <PricelistItemsListing /> : null} />
      <Route path="/stores" element={checkPrivileges("ROLE_STORE_VIEW") && <StoreListing/>} />
      <Route path="/store/create" element={checkPrivileges("ROLE_STORE_CREATE") && <StoreModal />} />
      <Route path="/store/:id" element={checkPrivileges("ROLE_STORE_UPDATE") && <StoreModal />} />
      <Route path="/store/assign/item/:id" element={(checkPrivileges('ROLE_STORE_ITEM_ASSIGN') || checkPrivileges('ROLE_STORE_ITEM_UNASSIGN')) ? <StoreItemsTable /> : null} />
      <Route path="/services" element={checkPrivileges("ROLE_SERVICE_VIEW") && <ServicesListing/>} />
      <Route path="/service/create" element={checkPrivileges("ROLE_SERVICE_CREATE") && <ServicesModal/>} />
      <Route path="/service/:id" element={checkPrivileges("ROLE_SERVICE_UPDATE") && <ServicesModal/>} />
      <Route path="/service/assign/part/:id" element={(checkPrivileges('ROLE_SERVICE_ITEM_ASSIGN') || checkPrivileges('ROLE_SERVICE_ITEM_UNASSIGN')) ? <ServiceItemsTable /> : null} />
      <Route path="/service/assign/vehicle-type/:id" element={(checkPrivileges('ROLE_SERVICE_VEHICLETYPE_ASSIGN') || checkPrivileges('ROLE_SERVICE_VEHICLETYPE_UNASSIGN')) ? <ServiceVehicleTypesTable /> : null} />
      <Route path="/service/assign/station/:id" element={(checkPrivileges('ROLE_SERVICE_STATION_ASSIGN') || checkPrivileges('ROLE_SERVICE_STATION_UNASSIGN')) ? <ServiceStationsTable /> : null} />
      <Route path="/service/assign/model/:id" element={(checkPrivileges('ROLE_SERVICE_MODEL_ASSIGN') || checkPrivileges('ROLE_SERVICE_MODEL_UNASSIGN')) ? <ServiceModelsTable /> : null} />
      <Route path="/service/assign/price-list/:id" element={(checkPrivileges('ROLE_SERVICE_PRICELIST_ASSIGN') || checkPrivileges('ROLE_SERVICE_PRICELIST_UNASSIGN')) ? <ServicesPricelistListing /> : null} />
      <Route path="/business-groups" element={checkPrivileges("ROLE_BUSINESSGROUP_VIEW") && <BusinessGroupsListing/>} />
      <Route path="/business-group/create" element={checkPrivileges("ROLE_BUSINESSGROUP_CREATE") && <BusinessGroupsModal/>} />
      <Route path="/business-group/:id" element={checkPrivileges("ROLE_BUSINESSGROUP_UPDATE") && <BusinessGroupsModal/>} />
      <Route path="/business-group/assign/station/:id" element={(checkPrivileges('ROLE_BUSINESSGROUP_STATION_ASSIGN') || checkPrivileges('ROLE_BUSINESSGROUP_STATION_UNASSIGN')) ? <BusinessGroupStationTable /> : null} />
      <Route path="/units" element={checkPrivileges("ROLE_UNIT_VIEW") && <UnitsListing/>} />
      <Route path="/unit/create" element={checkPrivileges("ROLE_UNIT_CREATE") && <UnitModal/>} />
      <Route path="/unit/:id" element={checkPrivileges("ROLE_UNIT_UPDATE") && <UnitModal/>} />
      <Route path="/warehouses" element={checkPrivileges("ROLE_WAREHOUSE_VIEW") && <WarehouseListing/>} />
      <Route path="/warehouse/create" element={checkPrivileges("ROLE_WAREHOUSE_CREATE") && <WarehouseModal/>} />
      <Route path="/warehouse/:id" element={checkPrivileges("ROLE_WAREHOUSE_UPDATE") && <WarehouseModal/>} />
      <Route path="/vendors" element={checkPrivileges("ROLE_VENDOR_VIEW") && <VendorListing/>} />
      <Route path="/vendor/create" element={checkPrivileges("ROLE_VENDOR_CREATE") && <VendorModal/>} />
      <Route path="/vendor/:id" element={checkPrivileges("ROLE_VENDOR_UPDATE") && <VendorModal/>} />
      <Route path="/station-groups" element={checkPrivileges("ROLE_STATIONGROUP_VIEW") && <StationGroupsListing/>} />
      <Route path="/station-group/create" element={checkPrivileges("ROLE_STATIONGROUP_CREATE") && <StationGroupsModal />} />
      <Route path="/station-group/:id" element={checkPrivileges("ROLE_STATIONGROUP_UPDATE") && <StationGroupsModal />} />
      <Route path="/station-group/assign/station/:id" element={(checkPrivileges('ROLE_STATIONGROUP_STATION_ASSIGN') || checkPrivileges('ROLE_STATIONGROUP_STATION_UNASSIGN')) ? <StationGroupStationTable /> : null} />
      <Route path="/packages-group" element={checkPrivileges("ROLE_PACKAGEGROUP_VIEW") && <PackageGroupListing/>} />
      <Route path="/package-group/create" element={checkPrivileges("ROLE_PACKAGEGROUP_CREATE") && <PackageGroupModal />} />
      <Route path="/package-group/:id" element={checkPrivileges("ROLE_PACKAGEGROUP_UPDATE") && <PackageGroupModal />} />
      <Route path="/package-group/assign/package/:id" element={(checkPrivileges('ROLE_PACKAGEGROUP_PACKAGE_ASSIGN') || checkPrivileges('ROLE_PACKAGEGROUP_PACKAGE_UNASSIGN')) ? <PackageGroupPackageTable /> : null} />
      <Route path="/manufacturers" element={checkPrivileges("ROLE_MANUFACTURER_VIEW") && <ManufacturersListing/>} />
      <Route path="/manufacturer/create" element={checkPrivileges("ROLE_MANUFACTURER_CREATE") && <ManufacturersModal/>} />
      <Route path="/manufacturer/:id" element={checkPrivileges("ROLE_MANUFACTURER_UPDATE") && <ManufacturersModal/>} />
      <Route path="/tax-procedures" element={checkPrivileges("ROLE_TAXPROCEDURE_VIEW") && <TaxProcedureListing/>} />
      <Route path="/tax-procedure/create" element={checkPrivileges("ROLE_TAXPROCEDURE_CREATE") && <TaxProcedureModal/>} />
      <Route path="/tax-procedure/:id" element={checkPrivileges("ROLE_TAXPROCEDURE_UPDATE") && <TaxProcedureModal/>} />
      <Route path="/account-settings" element={checkPrivileges("ROLE_BUSINESS_UPDATE") && <BusinessSettings/>} />
      <Route path="/account-settings/change-password" element={<ChangePassword/>} />
      <Route path="/account-settings/invoice-templates" element={<InvoiceTemplatesTable/>} />
      <Route path="/job-cards" element={checkPrivileges("ROLE_JOBCARD_VIEW") && <JobCardListing/>} />
      <Route path="/job-card/:id" element={checkPrivileges("ROLE_JOBCARD_UPDATE") && <JobCardModal/>} />
      <Route path="/work-orders" element={checkPrivileges("ROLE_WORKORDER_VIEW") && <WorkOrderListing/>} />
      <Route path="/work-order/:id" element={checkPrivileges("ROLE_WORKORDER_UPDATE") && <WorkOrderModal/>} />
      <Route path="/invoices" element={checkPrivileges("ROLE_INVOICE_VIEW") && <InvoiceListing/>} />
      <Route path="/invoices/:id" element={checkPrivileges("ROLE_INVOICE_UPDATE") && <InvoiceModal/>} />
      <Route path="/payments" element={checkPrivileges("ROLE_PAYMENT_VIEW") && <PaymentListing/>} />
      <Route path="/payment/create" element={checkPrivileges("ROLE_PAYMENT_CREATE") && <PaymentModal/>} />
      <Route path="/payment/:id" element={checkPrivileges("ROLE_PAYMENT_UPDATE") && <PaymentModal/>} />
      <Route path="/status/:typename" element={checkPrivileges("ROLE_BUSINESSSTATUS_VIEW") && <StatusesListing/>} />
      <Route path="/status/:typename/create" element={checkPrivileges("ROLE_BUSINESSSTATUS_CREATE") && <StatusesModal/>} />
      <Route path="/status/:typename/edit/:id" element={checkPrivileges("ROLE_BUSINESSSTATUS_UPDATE") && <StatusesModal/>} />
      <Route path="/vehicle-statuses" element={checkPrivileges("ROLE_BUSINESSSTATUS_VIEW") && <VehicleStatusListing/>} />
      <Route path="/vehicle-status/create" element={checkPrivileges("ROLE_BUSINESSSTATUS_CREATE") && <VehicleStatusModal/>} />
      <Route path="/vehicle-status/:id" element={checkPrivileges("ROLE_BUSINESSSTATUS_UPDATE") && <VehicleStatusModal/>} />
      <Route path="/payment-modes" element={checkPrivileges("ROLE_BUSINESSSTATUS_VIEW") && <PaymentModeListing/>} />
      <Route path="/payment-modes/create" element={checkPrivileges("ROLE_BUSINESSSTATUS_CREATE") && <PaymentModeModal/>} />
      <Route path="/payment-modes/:id" element={checkPrivileges("ROLE_BUSINESSSTATUS_UPDATE") && <PaymentModeModal/>} />
      <Route path="/checklists" element={checkPrivileges("ROLE_CHECKLIST_VIEW") && <ChecklistListing/>} />
      <Route path="/checklist/create" element={checkPrivileges("ROLE_CHECKLIST_CREATE") && <ChecklistModal/>} />
      <Route path="/checklist/:id" element={checkPrivileges("ROLE_CHECKLIST_UPDATE") && <ChecklistModal/>} />
      <Route path="/inventory" element={<Navigate to="/inventory/purchase-orders" />} />
      <Route path="/inventory/purchase-orders" element={checkPrivileges('ROLE_PURCHASEORDER_VIEW') && <PurchaseOrderListing/>} />
      <Route path="/inventory/new-purchase" element={checkPrivileges('ROLE_PURCHASEORDER_CREATE') && <NewPurchase/>} />
      <Route path="/inventory/purchase/:id" element={checkPrivileges('ROLE_PURCHASEORDER_AUTHORIZE') && <InventorySteps/>} />
      <Route path="/inventory/purchase-payments" element={checkPrivileges('ROLE_PURCHASEINVOICE_VIEW') && <PurchasePaymentListing/>} />
      <Route path="/inventory/new-purchase-payment/" element={checkPrivileges('ROLE_PURCHASEINVOICE_CREATE') && <PurchasePaymentCreateUpdate/>} />
      <Route path="/inventory/purchase-payment/:id" element={checkPrivileges('ROLE_PURCHASEINVOICE_AUTHORIZE') && <PurchasePaymentCreateUpdate/>} />
      <Route path="/inventory/stock-receives" element={checkPrivileges('ROLE_STOCKRECEIPT_VIEW') && <StockReceivedListing/>} />
      <Route path="/inventory/new-stock-receives/" element={checkPrivileges('ROLE_STOCKRECEIPT_CREATE') && <StockReceivedCreateUpdate/>} />
      <Route path="/inventory/stock-receives/:id" element={checkPrivileges('ROLE_STOCKRECEIPT_AUTHORIZE') && <StockReceivedCreateUpdate/>} />
      <Route path="/inventory/debit-note" element={checkPrivileges('ROLE_PURCHASE_CREDITDEBITNOTE_VIEW') && <CreditNoteListing/>} />
      <Route path="/inventory/new-debit-note" element={checkPrivileges('ROLE_PURCHASE_CREDITDEBITNOTE_CREATE') && <CreditNoteCreateUpdate/>} />
      <Route path="/inventory/debit-note/:id" element={checkPrivileges('ROLE_PURCHASE_CREDITDEBITNOTE_AUTHORIZE') && <CreditNoteCreateUpdate/>} />
      <Route path="/inventory/unstock" element={checkPrivileges('ROLE_UNSTOCK_VIEW') && <UnstockListing/>} />
      <Route path="/inventory/new-unstock/" element={checkPrivileges('ROLE_UNSTOCK_CREATE') && <UnstockCreateUpdate/>} />
      <Route path="/inventory/unstock/:id" element={checkPrivileges('ROLE_UNSTOCK_AUTHORIZE') && <UnstockCreateUpdate/>} />
      <Route path="/expense-types" element={checkPrivileges('ROLE_EXPENSE_TYPE_VIEW') && <ExpenseTypeListing/>} />
      <Route path="/expense-type/create" element={checkPrivileges("ROLE_EXPENSE_TYPE_CREATE") && <ExpenseTypeModal/>} />
      <Route path="/expense-type/:id" element={checkPrivileges("ROLE_EXPENSE_TYPE_UPDATE") && <ExpenseTypeModal/>} />
      <Route path="/checklistitems/:clid" element={checkPrivileges("ROLE_CHECKLISTITEM_VIEW") && <CheckListItemListing />} />
      <Route path="/checklistitem/:clid/create" element={checkPrivileges("ROLE_CHECKLISTITEM_CREATE") && <ChecklistItemModal />} />
      <Route path="/checklistitem/:clid/edit/:id" element={checkPrivileges("ROLE_CHECKLISTITEM_UPDATE") && <ChecklistItemModal />} />
      <Route path="/inventory/stock-transfers" element={checkPrivileges("ROLE_STOCKTRANSFER_VIEW") && <StockTransferListing />} />
      <Route path="/inventory/stock-transfer" element={checkPrivileges("ROLE_STOCKTRANSFER_CREATE") && <StockTransferCreateUpdate />} />
      <Route path="/inventory/stock-transfer/:id" element={checkPrivileges("ROLE_STOCKTRANSFER_VIEW") && <StockTransferCreateUpdate />} />
      <Route path="/inventory/stock-adjustments" element={checkPrivileges("ROLE_INVENTORYADJUSTMENT_VIEW") && <StockAdjustmentListing />} />
      <Route path="/inventory/stock-adjustment" element={checkPrivileges("ROLE_INVENTORYADJUSTMENT_CREATE") && <StockAdjustmentCreateUpdate />} />
      <Route path="/inventory/stock-adjustment/:id" element={checkPrivileges("ROLE_INVENTORYADJUSTMENT_VIEW") && <StockAdjustmentCreateUpdate />} />
      <Route path="/activity-logs" element={checkPrivileges("ROLE_REQUESTLOG_VIEW") && <ActivityLogsListing />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/states" element={checkPrivileges('ROLE_STATE_VIEW') && <StateListing/>} />
      <Route path="/state/create" element={checkPrivileges("ROLE_STATE_CREATE") && <StateModal/>} />
      <Route path="/state/:id" element={checkPrivileges("ROLE_STATE_UPDATE") && <StateModal/>} />
    </Routes>
  );
};

export default AppRoutes;