import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useGetPurchaseInvoiceService } from 'subModule/src/services/inventory/useInventory';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import Loader from 'layout/Loader';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as PaymentIcon } from "assets/SideBarIcons/money-transfer.svg";
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import FormDialog from 'common/Modal/Modal';
import { Grid, Typography } from '@mui/material';
import { InventoryProfileService } from 'subModule/src/core/services';
import { DeleteData } from 'subModule/src/type';
import { SaveInvoice } from 'subModule/src/services/inventory/interface';
import StyledInput from 'common/SearchField/Search';

export default function PurchasePaymentListing() {
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [row] = useState<SaveInvoice>();
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetPurchaseInvoiceService({ pageNumber: pageNo, size: pageSize, invoiceNumber: searchTerm }) : useGetPurchaseInvoiceService({ pageNumber: pageNo, size: pageSize });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
          if (searchTerm !== null && !isLoading && !error && loading) {
            await refetch();
            setLoading(false);
          }
        }, 1000);
        return () => clearTimeout(getData)
      }, [searchTerm, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const onEdit = (row: any) => navigate('/inventory/purchase-payment/' + row.id);
    const showRowView = (row: any) => navigate('/inventory/purchase-payment/' + row.id);
    // const onDelete = (row: any) => {
    //     setDeleteModal(true);
    //     setRow(row);
    // }
    const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setLoading(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
    };
    
    const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
    };
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_CHECKLIST_UPDATE')
            ? (checkPrivileges('ROLE_CHECKLIST_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_CHECKLIST_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/inventory/new-purchase-payment')}
                heading={t('Payments')}
                icon={<PaymentIcon style={{ fill: '#000', width: '30px', height: '30px', marginTop: '3px' }} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_CHECKLIST_CREATE")}
            />
            <Box>
                {loading ? <Loader /> :
                    <BackendPaginationTable
                        columns={[
                            { id: 'invoiceNumber', label: 'Payment Number', numeric: false },
                            { id: 'invoiceDate', label: 'Date', numeric: false },
                            { id: 'vendorTitle', label: 'Supplier', numeric: false },
                            { id: 'storeTitle', label: 'Store', numeric: false },
                            { id: 'subtotalAmount', label: 'Subtotal', numeric: false },
                            { id: 'discount', label: t('Discount'), numeric: false },
                            { id: 'totalAmount', label: t('Total'), numeric: false },
                            { id: 'actions', label: 'actions', numeric: false },
                        ]}
                        data={tableData?.pages[0]?.data?.content || []}
                        pageNo={pageNo}
                        pageSize={pageSize}
                        tableData={tableData?.pages[0]?.data || {}}
                        showCheckbox={false}
                        showActions={true}
                        enableSorting={true}
                        showHeader={true}
                        showSearch={true}
                        showFilter={true}
                        showCreateButton={true}
                        actions={adjustActions([
                            { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                            // { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                        ])}
                        handleChangePage={handleChangePage}
                        handleChangeRows={handleChangeRows}
                        rowClick={checkPrivileges("ROLE_CHECKLIST_UPDATE")}
                        showRowView={showRowView}
                        loadingSearch={false}
                        headerComponent={<Grid container spacing={2}>  
                        <Grid item xs={12} md={7} lg={5}>
                          <StyledInput
                            fullWidth
                            placeholder={t("Payment Number")}
                            handleSearchClick={handleSearchClick}
                            handleChange={handleInputChange}
                            value={search}
                            clearSearch={clearSearch}
                          />
                        </Grid>
                      </Grid>}
                    />
                }
            </Box>
            {openDeleteModal &&
                <FormDialog
                    open={openDeleteModal}
                    onClose={() => setDeleteModal(false)}
                    title="Delete Payment"
                    // icon={<div><Home/></div>}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>
                                    Are you sure you want to Delete?
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setLoading(true);
                        setDeleteModal(false);
                        InventoryProfileService.deleteEntityItem((row as any).id, 'purchaseinvoice').then((response: DeleteData) => {
                            if(response.success) {
                                setLoading(false);
                                refetch();
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Payment') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err:any) => { 
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                        
                    }}
                    submitButtonText="Delete"
                />}
        </Box>
    )
}