
import React, { FunctionComponent } from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNew from '../Button/Button';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import { Breakpoint } from '@mui/material';
interface FormDialogProps {
  open: boolean;
  onClose: (event?: any, reason?: any) => void;
  title: string;
  content: React.ReactNode;
  saveFunction?: () => void;
  icon?: React.ReactNode;
  submitButtonText?: string;
  createPopup?: boolean;
  fullWidth?: boolean;
  hideActions?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  hideClose?: boolean;
  cancelBtnText?: string;
}

const FormDialog: FunctionComponent<FormDialogProps> = ({
  open,
  onClose,
  title,
  content,
  saveFunction,
  submitButtonText,
  icon,
  createPopup,
  fullWidth,
  hideActions,
  maxWidth,
  hideClose,
  cancelBtnText
}) => {
  return (
    <Dialog open={open} onClose={onClose} disableEscapeKeyDown fullWidth={fullWidth} maxWidth={maxWidth || "lg"} >
        {/* <form 
        onSubmit={handleSubmit}
        > */}
      <DialogTitle className='bg-primary-color mb-3' style={{display:"flex", justifyContent:"space-between",  alignItems:"center"}} >
        {/* {title} */}
        <div className='flex items-center'>
            <span className='mr-[5px] invert'>{icon}</span>
            <h1 className="mt-1 font-Saira font-bold text-white text-lg ml-1">{t(title)}</h1>
          </div>
          {hideClose ? null : <CloseIcon className='cursor-pointer text-white' onClick={onClose}/>}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{minWidth:'35rem'}}>{content}</DialogContentText>
      </DialogContent>
      {hideActions ? null : <DialogActions className={createPopup ? 'justify-end mb-[10px]' : 'justify-end'}>
        {createPopup ? null :
          <div className='h-[39px] w-[102px] bg-btnborder-color rounded-md flex items-center justify-center'>
            <ButtonNew type='back' onClick={onClose} text={cancelBtnText || t('Cancel')}></ButtonNew>
          </div>
        }
        <ButtonNew type="create" onClick={saveFunction} text={submitButtonText} >
        </ButtonNew>
      </DialogActions>}
      {/* </form> */}
    </Dialog>
  );
};

export default FormDialog;
