import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import Loader from 'layout/Loader';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import { ReactComponent as Inspection } from "assets/SideBarIcons/inspection.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import StyledInput from 'common/SearchField/Search';
import { useGetChecklistItemService } from 'subModule/src/services/checklistitem/useChecklistItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ChangeStatusChecklistItemResponse, ChecklistItemResponse } from 'subModule/src/services/checklistitem/interface';
import FormDialog from 'common/Modal/Modal';
import { ChecklistItemProfileService } from 'subModule/src/core/services';
import { DeleteData } from 'subModule/src/type';
import CLHeader from './CLHeader';
import VerticalTabs from 'common/ComponentSidebar/ComponentSideBar';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import ChecklistIcon from '@mui/icons-material/Checklist';

export default function CheckListItemListing() {
    const navigate = useNavigate();
    const params = useParams();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [row, setRow] = useState<ChecklistItemResponse>();
    const [statusModal, setStatusModal] = useState(false);
    const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetChecklistItemService({ pageNumber : pageNo, size: pageSize, title: search }, +(params as any)?.clid) : useGetChecklistItemService({ pageNumber: pageNo, size: pageSize }, +(params as any)?.clid);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (searchTerm !== null && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 1000);
        return () => clearTimeout(getData)
    }, [searchTerm, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const onEdit = (row: any) => navigate(`/checklistitem/${params?.clid}/edit/` + row.id);
    const showRowView = (row: any) => navigate(`/checklistitem/${params?.clid}/edit/` + row.id);
    const onDelete = (row: any) => {
        setDeleteModal(true);
        setRow(row);
    }
    const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if (searchTerm) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setLoading(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
    };
    const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
        }
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };
    const onChangeStatus = (row: any) => {
        setRow(row);
        setStatusModal(true);
    }
    const actionslist = [
        { label: "edit", onClick: onEdit, icon: <EditIcon /> },
        { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
    ];
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_CHECKLISTITEM_UPDATE')
            ? (checkPrivileges('ROLE_CHECKLISTITEM_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_CHECKLISTITEM_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0)  navigate(`/checklist/${params?.clid}`); 
        else if (tabId === 1) navigate(`/checklistitems/${params?.clid}`);
    }
    const tabs = [
        { id: 0, title: `edit`, child: <></> },
        { id: 1, title: `${t('ChecklistItem')}`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
                return !checkPrivileges('ROLE_CHECKLIST_UPDATE');
            case 1:
                return !checkPrivileges('ROLE_CHECKLISTITEM_VIEW');
            default:
                return false;
        }
    });

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={false}
            onClick={() => navigate('/checklists')}
            heading={t('Checklists')}
            icon={<ChecklistIcon sx={{ fill: '#000', width: '30px', height: '30px', marginTop: '3px' }} />}
            btnText={"back"}
            btnType={"back"}
            showCreateBtn={checkPrivileges("ROLE_CHECKLIST_CREATE")}
        />
        <Box className='flex'>
            <VerticalTabs tabs={tabs} selectedIndex={1} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
            <div className='w-[100%] mt-[10px]'>
                <CLHeader
                    showBtn={true}
                    showSaveBtn={false}
                    onClick={() => navigate(`/checklistitem/${params?.clid}/create`)}
                    heading={t('ChecklistItem')}
                    icon={<Inspection height={28} width={28} />}
                    btnText={"create"}
                    btnType={"create"}
                    showCreateBtn={checkPrivileges("ROLE_CHECKLISTITEM_CREATE")}
                />
                <Box>
                    {loading ? <Loader /> :
                        <div id="mainListingTable">
                            <BackendPaginationTable
                                columns={[
                                    { id: 'title', label: 'title', numeric: false },
                                    { id: 'checklistitemtype', label: 'type', numeric: false },
                                    ...(checkPrivileges("ROLE_CHECKLISTITEM_ACTIVE") ? [{ id: 'active', label: 'status', numeric: false }] : []),
                                    ...(adjustActions(actionslist).length ? [{ id: 'actions', label: 'actions', numeric: false }] : []),
                                ]}
                                data={tableData?.pages[0]?.data?.content || []}
                                pageNo={pageNo}
                                pageSize={pageSize}
                                tableData={tableData?.pages[0]?.data || {}}
                                showCheckbox={false}
                                showActions={true}
                                enableSorting={true}
                                showHeader={true}
                                showSearch={true}
                                showFilter={true}
                                showCreateButton={true}
                                actions={adjustActions(actionslist)}
                                handleChangePage={handleChangePage}
                                handleChangeRows={handleChangeRows}
                                onChangeStatus={onChangeStatus}
                                rowClick={checkPrivileges("ROLE_CHECKLISTITEM_UPDATE")}
                                showRowView={showRowView}
                                loadingSearch={false}
                                headerComponent={<Grid container spacing={2}>
                                    <Grid item xs={12} md={7} lg={5}>
                                        <StyledInput
                                            fullWidth
                                            placeholder={t("title")}
                                            handleSearchClick={handleSearchClick}
                                            handleChange={handleInputChange}
                                            value={search}
                                            clearSearch={clearSearch}
                                        />
                                    </Grid>
                                </Grid>}
                            />
                        </div>
                    }
                </Box>
            </div>
            {openDeleteModal &&
                <FormDialog
                    open={openDeleteModal}
                    onClose={() => setDeleteModal(false)}
                    title={t('delete') + ' ' + t('ChecklistItem')}
                    content={<Grid container>
                        <Grid item xs={12}>
                            <Typography>{t("Are you sure you want to delete?")}</Typography>
                        </Grid>
                    </Grid>}
                    saveFunction={() => {
                        setLoading(true);
                        setDeleteModal(false);
                        ChecklistItemProfileService.deleteChecklistItem((row as any).id).then((response: DeleteData) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('ChecklistItem') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="Delete"
                />}
            {statusModal &&
                <FormDialog
                    open={statusModal}
                    onClose={() => setStatusModal(false)}
                    title="Change Status"
                    // icon={<div><Home/></div>}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t(`Are you sure?`)}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setStatusModal(false);
                        setLoading(true);
                        ChecklistItemProfileService.ChangeStatusChecklistItem((row as any).id, row?.active ? "inactive" : "active", {}).then((response: ChangeStatusChecklistItemResponse) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('status') + ' ' + t('changed') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="OK"
                />
            }
        </Box>
    </>)
}