import { Grid, InputAdornment, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { ClientProfileService, UserModuleProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Client  } from "assets/SideBarIcons/customer.svg";
import { useEffect, useState } from "react";
import CustomerTabs from "./CustomerTabs";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GetClientByIdResponse } from "@subModule/src/services/client/interface";

interface FormData { [key: string]: any; }

export default function UpdatePasswordCustomer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors }, watch, setValue, reset } = useForm<FormData>({ defaultValues: {} });
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    useEffect(() => {
        if (params?.id) getByID();
    }, [params]);
    const getByID = () => {
        ClientProfileService.getClientByID(+(params as any)?.id, 'customers').then((res: GetClientByIdResponse) => {
            if (res.success) {
                setValue('email', res?.data.email);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        delete data.showPassword; delete data.showConfirmPassword; delete data.confirmNewPassword;
        UserModuleProfileService.updatePassword(data).then((response: any) => {
            if (response.success) {
                const email = data.email;
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('password') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                reset();
                setValue('email', email)
            } else {
                enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }
            setIsBtnEnabled(true);
        }).catch((err: any) => {
            console.error("===", err);
            setIsBtnEnabled(true);
            enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
        });
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate('/customers')}
            onSubmit={handleSubmit(onSubmit)}
            heading={t('editCustomer')}
            icon={<Client height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={false}
        />
        <div className={`${params?.id ? 'flex' : ''}`}>
            {params?.id ? <CustomerTabs params={params} selectedIndex={1} /> : null}
            <SimpleModal
                visable={true}
                title={t('editCustomer')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={
                    <Grid container component="form" id="form" className="items-center mb-2" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type={watch('showPassword') ? 'text' : 'password'}
                                {...register('newPassword', {
                                    required: t('This field is required.'),
                                    validate: { passwordLength: (value) => value.length >= 8 || t('minPasswordLength'), },
                                })}
                                label={t('password') + ' *'}
                                fullWidth
                                error={!!errors.newPassword}
                                helperText={errors.newPassword?.message as string}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (watch('newPassword') ? <InputAdornment position="end" className="cursor-pointer">
                                        {watch('showPassword') ? (
                                            <VisibilityIcon className="text-primary-color" onClick={() => setValue('showPassword', false)} />
                                        ) : (
                                            <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showPassword', true)} />
                                        )}
                                    </InputAdornment> : null),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type={watch('showConfirmPassword') ? 'text' : 'password'}
                                {...register("confirmNewPassword", {
                                    required: t('This field is required.'),
                                    validate: { passwordLength: (value) => (value === watch('newPassword')) || t('confirmPasswordNewPassword'), },
                                },)}
                                label={t("confirmNewPassword") + ' *'}
                                fullWidth
                                error={!!errors.confirmNewPassword}
                                helperText={errors.confirmNewPassword?.message as string}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (watch('confirmNewPassword') ? <InputAdornment position="end" className="cursor-pointer">
                                        {watch('showConfirmPassword') ? (
                                            <VisibilityIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', false)} />
                                        ) : (
                                            <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', true)} />
                                        )}
                                    </InputAdornment> : null),
                                }}
                            />
                        </Grid>
                    </Grid>
                }
            />
        </div>
    </>)
}