import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledInputArea from 'common/TextArea/TextArea';
import CommonCheckbox from 'common/CheckBox/Checkbox';

interface VehicleStatusModalComponentProps {
  primaryLanguage: string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
}

const VehicleStatusModalComponent: React.FC<VehicleStatusModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  primaryLanguage,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateVehicleStatus" : "addVehicleStatus"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                // search='language'
                placeholder="Write your name here"
                handleChange={(e) => handleChange('name', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.name}
                value={formData?.name || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <StyledInputArea
                label="description"
                rowsNo={1}
                handleChange={(e) => handleChange('description', e.target.value)}
                required={false}
                fullWidth
                // error={errorMessages?.description}
                value={formData?.description || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                type='number'
                label="Sort Order (Value 1-100)"
                handleChange={(e) => handleChange('sortOrder', e.target.value)}
                required={false}
                fullWidth
                // error={errorMessages?.sortOrder}
                value={formData?.sortOrder || 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <CommonCheckbox
                label='isDefault'
                onChange={(e) => handleChange('default', e.target.checked)}
                color="success"
                required={false}
                // error={errorMessages?.default}
                checked={formData?.default}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default VehicleStatusModalComponent;