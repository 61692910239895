import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Pricelist } from "assets/SideBarIcons/price-tag.svg";
import StyledInput from "common/SearchField/Search";
import { useAssignPriceListPackageService, useGetPriceListPackageService, useUnassignPriceListPackageService, useUpdatePriceListPackageService } from 'subModule/src/services/pricelist/usePriceList';
import PricelistTabs from '../PricelistTabs';
import { Grid } from '@mui/material';
import { PricelistPackagesTable } from './PricelistPackagesTable';
import Loader from 'layout/Loader';
import { useForm } from 'react-hook-form';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
export default function PricelistPackagesListing() {
    const params = useParams();
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<any>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const { register, control, formState: { errors }, watch, setValue, trigger, reset } = useForm<FormData>({});
    const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetPriceListPackageService({ pageNumber: pageNo, size: pageSize, title: searchTerm }, +(params as any).id) : useGetPriceListPackageService({ pageNumber: pageNo, size: pageSize }, +(params as any).id);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const columns = [
        { id: 'packageId', label: '', numeric: false, disablePadding: false },
        { id: 'title', label: t('name'), numeric: false },
        { id: 'price', label: t('price'), numeric: false },
    ];
    useEffect(() => {
        setLoading(true);
        const getData = async() => {
            if (!isLoading && !error) await refetch();
            if (tableData) {
                setLoading(false);
                if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                    enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
                }
            }
        };
        getData();
    }, [pageNo, pageSize, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (searchTerm !== null && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 1000);
        return () => clearTimeout(getData)
    }, [searchTerm, loading]);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPageNo(newPage + 1);
        setPageSize(pageSize);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNo(1);
    };
    const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if (searchTerm) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setLoading(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
    };
    const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') handleSearch(trimmedSearch);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };
    const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoading(true);
        if (isChecked) {
            onUnassignPackage({ pricelistId: +(params as any).id, packageId: row.id });
        } else {
            onAssignPackage({ pricelistId: +(params as any).id, packageId: row.id, price: row.price });
        }
    }
    const handleBlurItem = (row: any) => {
        onUpdatePriceListPackage({ pricelistId: +(params as any).id, packageId: row.id, price: row.price });
    }
    const { onAssignPackage } = useAssignPriceListPackageService({
        onSuccess: async (msg) => {
            const response: any = {};
            Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
            if (response.success) {
                await refetch();
                reset();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('package') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
            if (err instanceof Error) {
                console.error(err.stack);
            }
        }
    })
    const { onUnassignPackage } = useUnassignPriceListPackageService({
        onSuccess: async (data) => {
            const response: any = {};
            Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
            if (response.success) {
                await refetch();
                reset();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('package') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            console.log("err====,  ", err);
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
    });
    const { onUpdatePriceListPackage } = useUpdatePriceListPackageService({
        onSuccess: async (msg) => {
            const response: any = {};
            Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
            if (response.success) {
                await refetch();
                reset();
                setLoading(false);tableData?.pages[0]?.data
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('priceList') + ' ' + t('update') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
            if (err instanceof Error) {
                console.error(err.stack);
            }
        }
    })
    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/price-lists')}
                heading={t('priceLists')}
                icon={<Pricelist height={28} width={28} />}
                btnText={"back"}
                btnType={"back"}
                showCreateBtn={false}
            />
            <Box>
                <div className='flex'>
                    {params?.id ? <PricelistTabs params={params} selectedIndex={1} /> : null}
                    <Box className='w-[100%]'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7} lg={5} className='mt-3'>
                                <StyledInput
                                    fullWidth
                                    placeholder={t("name")}
                                    handleSearchClick={handleSearchClick}
                                    handleChange={handleInputChange}
                                    value={search}
                                    clearSearch={clearSearch}
                                />
                            </Grid>
                        </Grid>
                        {loading ? <Loader /> : <PricelistPackagesTable
                            columns={columns}
                            rows={tableData?.pages[0]?.data?.content || []}
                            tableData={tableData?.pages[0]?.data || {}}
                            assignUnassignSingleEntity={assignUnassignSingleEntity}
                            form={{ register, control, formState: { errors }, watch, setValue, trigger, reset }}
                            assignPrivilege={checkPrivileges('ROLE_PACKAGE_PRICELIST_ASSIGN')}
                            unassignPrivilege={checkPrivileges('ROLE_PACKAGE_PRICELIST_UNASSIGN')}
                            handleBlurItem={handleBlurItem}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            {...{pageNo, pageSize}}
                        />}
                    </Box>
                </div>
            </Box>
        </Box>
    )
}