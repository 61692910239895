// PackagesService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignPriceListRequest,
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignItemRequest,
  AssignUnassignModelRequest,
  AssignUnassignResponse,
  AssignUnassignServiceRequest,
  AssignUnassignStationRequest,
  ChangeStatusPackagesResponse,
  DeletePackagesResponse,
  GetPackageByIdResponse,
  GetPackageItemResponse,
  GetPackageModelResponse,
  GetPackagePriceListResponse,
  GetPackageServiceResponse,
  GetPackageStationResponse,
  GetPackagesResponse,
  PackagesRequest,
  PackagesResponse,
  UnassignPriceListRequest,
} from './interface';

export class PackagesProfile extends BaseService {
  async createPackages(req: PackagesRequest) {
    return this.post<PackagesResponse, PackagesRequest>(`${this.apiUrl}/api/packages`, req);
  }

  async getPackages(params: PaginationParams) {
    return this.get<GetPackagesResponse>(`${this.apiUrl}/api/packages/paged`, params);
  }

  async updatePackages(id: number, req: PackagesRequest) {
    return this.put<PackagesResponse, PackagesRequest>(`${this.apiUrl}/api/packages/${id}`, req);
  }

  async deletePackages(id: number) {
    return this.delete<DeletePackagesResponse>(`${this.apiUrl}/api/packages/${id}`);
  }

  async changeStatusPackages(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusPackagesResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/packages/${active}/${id}`,
      req
    );
  }

  async getPackageService(params: PaginationParams & { packageId: number }) {
    return this.get<GetPackageServiceResponse>(`${this.apiUrl}/api/services/bypackageid`, params);
  }

  async assignService(req: AssignUnassignServiceRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignServiceRequest>(
      `${this.apiUrl}/api/packages/service/assign`,
      req
    );
  }

  async unassignService(req: AssignUnassignServiceRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignServiceRequest>(
      `${this.apiUrl}/api/packages/service/unassign`,
      req
    );
  }

  async assignAllServices(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packages/service/assignall`,
      req
    );
  }

  async unassignAllServices(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packages/service/unassignall`,
      req
    );
  }

  async getPackageItem(params: PaginationParams & { packageId: number; itemGroupId?: number | null }) {
    return this.get<GetPackageItemResponse>(`${this.apiUrl}/api/items/bypackageid`, params);
  }

  async assignItem(req: AssignUnassignItemRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignItemRequest>(`${this.apiUrl}/api/packages/item/assign`, req);
  }

  async unassignItem(req: AssignUnassignItemRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignItemRequest>(
      `${this.apiUrl}/api/packages/item/unassign`,
      req
    );
  }

  async assignAllItems(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packages/item/assignall`,
      req
    );
  }

  async unassignAllItems(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packages/item/unassignall`,
      req
    );
  }

  async getPackageStation(params: PaginationParams & { packageId: number }) {
    return this.get<GetPackageStationResponse>(`${this.apiUrl}/api/stations/bypackageid`, params);
  }

  async assignStation(req: AssignUnassignStationRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignStationRequest>(
      `${this.apiUrl}/api/packages/station/assign`,
      req
    );
  }

  async unassignStation(req: AssignUnassignStationRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignStationRequest>(
      `${this.apiUrl}/api/packages/station/unassign`,
      req
    );
  }

  async assignAllStations(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packages/station/assignall`,
      req
    );
  }

  async unassignAllStations(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packages/station/unassignall`,
      req
    );
  }

  async getPackageModel(params: PaginationParams & { packageId: number }) {
    return this.get<GetPackageModelResponse>(`${this.apiUrl}/api/models/bypackageid`, params);
  }

  async assignModel(req: AssignUnassignModelRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignModelRequest>(
      `${this.apiUrl}/api/packages/model/assign`,
      req
    );
  }

  async unassignModel(req: AssignUnassignModelRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignModelRequest>(
      `${this.apiUrl}/api/packages/model/unassign`,
      req
    );
  }

  async assignAllModels(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packages/model/assignall`,
      req
    );
  }

  async unassignAllModels(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/packages/model/unassignall`,
      req
    );
  }

  async getPackagePriceList(params: PaginationParams & { packageId: number }) {
    return this.get<GetPackagePriceListResponse>(`${this.apiUrl}/api/pricelists/bypackageid`, params);
  }

  async assignPriceList(req: AssignPriceListRequest) {
    return this.post<AssignUnassignResponse, AssignPriceListRequest>(
      `${this.apiUrl}/api/packages/pricelist/assign`,
      req
    );
  }

  async unassignPriceList(req: UnassignPriceListRequest) {
    return this.put<AssignUnassignResponse, UnassignPriceListRequest>(
      `${this.apiUrl}/api/packages/pricelist/unassign`,
      req
    );
  }

  async getPackageById(id: number | undefined) {
    return this.get<GetPackageByIdResponse>(`${this.apiUrl}/api/packages/${id}`);
  }
}
