import { Box, Button, Divider, Grid, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService } from "subModule/src/core/services";
import { GetStockTransferByIdResponse, StockTransfer } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import BasicDatePicker from "common/Pickers/Pickers";
import { useGetStoreDropdownService } from "subModule/src/services/store/useStore";
import DynamicSelect from "common/Select/Select";
import dayjs from "dayjs";
import AddIcon from '@mui/icons-material/Add';

interface FormData {
    [key: string]: any;
}

const StockTransferCreateUpdate: React.FC = () => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField} = useForm<FormData>({defaultValues: {
        stockTransferItem: [],
        transferDate: dayjs(new Date())?.format('YYYY-MM-DD'),
    }});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 8000, storeId: watch('sourceStoreId')?.value, active: true });
    const { data: storesData, refetch: storesRefetch } = useGetStoreDropdownService({ pageNumber: 1, size: 1000 });
    const [stockTransferItem, setStockTransferItem] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [stockTransfer, setStockTransfer] = useState<StockTransfer>();

    useEffect(() => {
        storesRefetch();
        if(params?.id) {
            if(loading) getStockTransferById();
        }
    }, [params]);
    useEffect(() => {
        if(watch('sourceStoreId')?.value) {
            setStockTransferItem([]);
            setValue('stockTransferItem', []);
            itemsRefetch();
        }
    }, [watch('sourceStoreId')]);
    const getStockTransferById = () => {
        InventoryProfileService.getStockTransferById(params?.id).then((res: any) => {
            if(res.success) {
                setLoading(false);
                setStockTransfer((res.data as any));
                setStockTransferItem((res.data as any)?.stockTransferItem || []);
                setValue('stockTransferItem', res.data.stockTransferItem || []);
                setValue('sourceStoreId', res.data?.sourceStoreId);
                setValue('destinationStoreId', res.data?.destinationStoreId);
                setValue('id', params?.id);
                setValue('transferDate', res.data?.transferDate?.split(' ')[0]);
                setValue('remark', res.data.remark || '')
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const storeOptions = (storesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        ...(params?.id ? [] : [{ id: 'baseItemQuantity', label: t('availableQuantity') }]),
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number', field: 'quantity' },
        { id: 'unitTitle' },
    ];
    const DeleteItem = (row: any) => {
        setValue('stockTransferItem', stockTransferItem.filter((rows) => rows.id !== row.id));
        setStockTransferItem((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "stockTransferItem") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!params?.id) (newItem as any).id = Date.now();
            setStockTransferItem((prevRows) => [...prevRows, newItem]);
        }
    };
    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "stockTransferItem") {
            const updatedItems = [...stockTransferItem];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setStockTransferItem(updatedItems);
        }
    };
    const onSubmit = (data: FormData) => {
        if(Object.values(data)?.length) {
            const filteredItems = stockTransferItem.filter((item: any) => item?.itemName);
            if(filteredItems.length === 0) {
                enqueueSnackbar(<AlertMessages text={t('addAtLeastOneItem')} />, { variant: 'error' });
                return;
            }
            filteredItems.every((row: any) => { delete row.id; delete row.unitTitle; delete  row.unitPrice; });
            InventoryProfileService.createStockTransfer({
                ...data,
                sourceStoreId: data.sourceStoreId.value,
                destinationStoreId: data.destinationStoreId.value,
                stockTransferItem: filteredItems
            }).then((response: GetStockTransferByIdResponse) => {
                if(response.success) {
                    InventoryProfileService.changeStatusEntity({typeName: 'stocktransfer', entityId: response?.data?.id, status: 2}).then((res: any) => {
                        if(res.success) {
                            enqueueSnackbar(<AlertMessages statusCode={res.status} text={t('StockTransfer') + ' ' + t('saved') + ' ' + t('successfully')} />, { variant: 'success' });
                            navigate('/inventory/stock-transfers');
                        } else {
                            enqueueSnackbar(<AlertMessages text={res?.message} />, { variant: 'error' });
                        }
                    }).catch((err:any) => { 
                        console.error("===", err);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                } else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (
        loading ? <Loader /> : <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2.5} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{params?.id ? t('StockTransfer') : t('newStockTransfer')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/stock-transfers')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            {params?.id ? <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{t('StockTransfer')}</Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Date')}</span> <span>{stockTransfer?.transferDate?.split('T')[0] || ''}</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('from') + ' ' + t('store')}</span> <br /><span className="font-medium">{stockTransfer?.sourceStoreTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('to') + ' ' + t('store')}</span> <br /><span className="font-medium">{stockTransfer?.destinationStoreTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('totalQuantity')}</span> <br /><span className="font-medium">{stockTransfer?.totalQuantity || 0}</span></Typography>
                    </div>
                </Grid>
            </Grid> : null}
            <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('StockTransfer')} {t("Details")}</Typography></Grid>
            <Grid container item xs={12} gap={2.5} className="flex-nowrap">
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="sourceStoreId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('from') + ' ' + t('store')}
                                {...field}
                                options={storeOptions}
                                isSearchable
                                isRequired
                                onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                error={errors.sourceStoreId?.message as string}
                                value={params?.id ? { value: stockTransfer?.sourceStoreId, label: stockTransfer?.sourceStoreTitle } : watch('sourceStoreId')}
                                isDisabled={params?.id ? true : false}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="destinationStoreId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('to') + ' ' + t('store')}
                                {...field}
                                options={storeOptions}
                                isSearchable
                                isRequired
                                onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                error={errors.destinationStoreId?.message as string}
                                value={params?.id ? { value: stockTransfer?.destinationStoreId, label: stockTransfer?.destinationStoreTitle } : watch('destinationStoreId')}
                                isDisabled={params?.id ? true : false}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="transferDate"
                        control={control}
                        rules={{ validate: (value) => {
                            const date = new Date(value);
                            return isNaN(date.getTime()) ? t("Invalid Date") : true;
                        }}}
                        render={({ field }) => (
                            <BasicDatePicker
                                type="datePicker"
                                {...field}
                                label={t('transferDate')}
                                fullWidth
                                onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                required={true}
                                error={errors.transferDate?.message as string}
                                disable={params?.id}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <TextField 
                        {...register("remark")} 
                        placeholder={t("remarks")}
                        label={t("remarks")}
                        fullWidth
                        error={!!errors.remark} 
                        helperText={errors.remark?.message as string} 
                        InputLabelProps={{ shrink: true }}
                        disabled={(params as any)?.id}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Box className="bg-white py-[12px] px-[18px] rounded-[8px]">
                        <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('StockTransfer')}</Typography>
                        <InventoryItemsTable form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} columns={columns} handleInputChange={handleInputChange} rows={stockTransferItem} typeName="stockTransferItem" items={itemsData?.pages[0]?.data?.content || []} currency={loggedInUserCurrency} DeleteItem={DeleteItem} {...{params}} checkStatus={params?.id ? 2 : 1} viewDelete={params?.id ? false : true} />
                        <Grid item xs={12} sm={6} className="hide-on-print">
                            <div className="flex gap-2 justify-start">
                                <Button className="secondary flex gap-1" disabled={params?.id ? true : false} variant="contained" disableElevation onClick={() => addNewItem('stockTransferItem')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                                {/* <Button className="secondary" variant="contained" disableElevation>{t('Import')}</Button>
                                <Button className="secondary" variant="contained" disableElevation>{t("Export")}</Button> */}
                            </div>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/stock-transfers')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StockTransferCreateUpdate;
