import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useGeUnstockService } from 'subModule/src/services/inventory/useInventory';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import Loader from 'layout/Loader';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import StockIcon from '@mui/icons-material/Inventory';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import StyledInput from 'common/SearchField/Search';

export default function UnstockListing() {
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const { data: tableData, isLoading, error, refetch } = searchTerm ? useGeUnstockService({ pageNumber: pageNo, size: pageSize, unStockNumber: searchTerm }) : useGeUnstockService({ pageNumber: pageNo, size: pageSize });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
          if (searchTerm !== null && !isLoading && !error && loading) {
            await refetch();
            setLoading(false);
          }
        }, 1000);
        return () => clearTimeout(getData)
      }, [searchTerm, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const onEdit = (row: any) => navigate('/inventory/unstock/' + row.id);
    const showRowView = (row: any) => navigate('/inventory/unstock/' + row.id);
    const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setLoading(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
    };
    
    const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
    };
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_CHECKLIST_UPDATE')
            ? (checkPrivileges('ROLE_CHECKLIST_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_CHECKLIST_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/inventory/new-unstock/')}
                heading={t('Unstock')}
                icon={<StockIcon sx={{ fill: '#000', width: '30px', height: '30px', marginTop: '3px' }} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_CHECKLIST_CREATE")}
            />
            <Box>
                {loading ? <Loader /> :
                    <BackendPaginationTable
                        columns={[
                            { id: 'unStockNumber', label: 'Unstock Number', numeric: false },
                            { id: 'storeTitle', label: 'Store', numeric: false },
                            { id: 'totalQuantity', label: 'Total Quantity', numeric: false },
                            { id: 'actions', label: 'actions', numeric: false },
                        ]}
                        data={tableData?.pages[0]?.data?.content || []}
                        pageNo={pageNo}
                        pageSize={pageSize}
                        tableData={tableData?.pages[0]?.data || {}}
                        showCheckbox={false}
                        showActions={true}
                        enableSorting={true}
                        showHeader={true}
                        showSearch={true}
                        showFilter={true}
                        showCreateButton={true}
                        actions={adjustActions([
                            { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                        ])}
                        handleChangePage={handleChangePage}
                        handleChangeRows={handleChangeRows}
                        rowClick={checkPrivileges("ROLE_CHECKLIST_UPDATE")}
                        showRowView={showRowView}
                        loadingSearch={false}
                        headerComponent={<Grid container spacing={2}>  
                        <Grid item xs={12} md={7} lg={5}>
                          <StyledInput
                            fullWidth
                            placeholder={t("Unstock Number")}
                            handleSearchClick={handleSearchClick}
                            handleChange={handleInputChange}
                            value={search}
                            clearSearch={clearSearch}
                          />
                        </Grid>
                      </Grid>}
                    />
                }
            </Box>
        </Box>
    )
}