// ChecklistProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ChecklistProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusChecklistResponse,
  ChecklistRequest,
  ChecklistResponse,
  DeleteChecklistResponse,
} from './interface';
import { useCallback } from 'react';

type UseChecklistServiceProps = {
  onSuccess: (data: ChecklistResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateChecklistServiceProps = {
  onSuccess: (data: ChecklistResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteChecklistServiceProps = {
  onSuccess: (data: DeleteChecklistResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusChecklistServiceProps = {
  onSuccess: (data: ChangeStatusChecklistResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useChecklistService = ({ onError, onSuccess }: UseChecklistServiceProps) => {
  const {
    mutateAsync: createChecklist,
    isPending: isCreateChecklistLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-checklist-request'],
    mutationFn: (req: ChecklistRequest) => ChecklistProfileService.createChecklist(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateChecklist = useCallback(
    async (checklistData: ChecklistRequest) => {
      try {
        createChecklist(checklistData);
      } catch (err) {
        console.error(err);
      }
    },
    [createChecklist]
  );

  return {
    onCreateChecklist,
    isCreateChecklistLoading,
    data,
    error,
    isError,
  };
};

export const useGetChecklistService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-checklist-data'],
      queryFn: async ({ pageParam }) => {
        // Use the provided initialPageNo and initialPageSize
        return ChecklistProfileService.getChecklist({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetChecklistsDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-checklists-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return ChecklistProfileService.getChecklistsDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          name: initialParams.name,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateChecklistService = ({ id, onError, onSuccess }: UpdateChecklistServiceProps) => {
  const {
    mutateAsync: updateChecklist,
    isPending: isUpdateChecklistLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-checklist-request'],
    mutationFn: (req: ChecklistRequest) => ChecklistProfileService.updateChecklist(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateChecklist = useCallback(
    async (req: ChecklistRequest) => {
      try {
        updateChecklist(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateChecklist]
  );

  return {
    onUpdateChecklist,
    isUpdateChecklistLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteChecklistService = ({ id, onError, onSuccess }: DeleteChecklistServiceProps) => {
  const {
    mutateAsync: deleteChecklist,
    isPending: isDeleteChecklistLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-checklist-request'],
    mutationFn: () => ChecklistProfileService.deleteChecklist(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteChecklist = useCallback(async () => {
    try {
      deleteChecklist();
    } catch (err) {
      console.error(err);
    }
  }, [deleteChecklist]);

  return {
    onDeleteChecklist,
    isDeleteChecklistLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusChecklistService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusChecklistServiceProps) => {
  const {
    mutateAsync: changeStatusChecklist,
    isPending: isChangeStatusChecklistLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-checklist-request'],
    mutationFn: (req: ChangeStatusRequest) => ChecklistProfileService.changeStatusChecklist(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusChecklist = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusChecklist(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusChecklist]
  );

  return {
    onChangeStatusChecklist,
    isChangeStatusChecklistLoading,
    data,
    error,
    isError,
  };
};
