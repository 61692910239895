import { selectUserInfo } from "features/user/userSlice";
import { Grid, InputAdornment, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { VendorProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Vendor  } from "assets/SideBarIcons/vendor.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetVendorByIdResponse } from "subModule/src/services/vendor/interface";
import Loader from "layout/Loader";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'

interface FormData { [key: string]: any;}

interface VendorProps {
    setAddNew?: any;
    vendorsRefetch?: any;
}
export default function VendorModal(props: VendorProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset } = useForm<FormData>({defaultValues: {}});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.id && !props?.setAddNew ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id && !props?.setAddNew ? false : true);
    const [showSecAccountTitleField, setShowSecAccountTitleField] = useState<boolean>(false);
    useEffect(() => {
        if(params?.id && !props?.setAddNew) {
            if(loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
      }, [isDirty, dirtyFields]);
    const getByID = () => {
        VendorProfileService.getVendorById(+(params as any)?.id).then((res: GetVendorByIdResponse) => {
            if(res.success) {
                reset({ ...res?.data || {}});
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if(params?.id && !props?.setAddNew) {
            VendorProfileService.updateVendor(+params?.id, data).then((response: any) => {
                if(response.success) {
                    navigate(-1);
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vendor') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            VendorProfileService.createVendor(data).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vendor') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    if(props?.setAddNew) {
                        props?.setAddNew('');
                        props.vendorsRefetch();
                    } else navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        {props?.setAddNew ? null : <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate(-1)}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id && !props?.setAddNew ? t('editVendor') : t('addVendor')}
            icon={<Vendor height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_VENDOR_CREATE")}
        />}
        <div className={`${params?.id && !props?.setAddNew ? 'flex' : ''}`}>
            {loading ? <Loader /> : <SimpleModal
                visable={true}
                title={params?.id && !props?.setAddNew ? t('editVendor') : t('addVendor')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id && !props?.setAddNew ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                entityPopup={props?.setAddNew}
                padding={!props?.setAddNew}
                content={ 
                    <Grid container component="form" id="form" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                {...register("title", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                fullWidth
                                error={!!errors.title} 
                                helperText={errors.title?.message as string} 
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ endAdornment: (<InputAdornment position="end">
                                    <Group onClick={() => setShowSecAccountTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                </InputAdornment>)}}
                                sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                            />
                            {showSecAccountTitleField && <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
                                    <TextField 
                                        {...register("secondaryUnitTitle")} 
                                        label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                        fullWidth
                                        error={!!errors.secondaryUnitTitle} 
                                        helperText={errors.secondaryUnitTitle?.message as string} 
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                type="text"
                                {...register("phoneNumber", {
                                    validate: {
                                        phoneLength: (value) =>
                                            value === '' || (value.length >= 5 && value.length <= 17) || t('phoneLength'),
                                        nonNegative: (value) =>
                                            value === '' || /^\+?[\p{N}]+$/u.test(value) || t('onlyPositiveNumbers'),
                                    },
                                })}
                                placeholder={t("phoneNumber")}
                                label={t("phoneNumber")}
                                fullWidth
                                error={!!errors.phoneNumber} 
                                helperText={errors.phoneNumber?.message as string} 
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                type="email"
                                {...register("email", { 
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: t('validEmail')
                                    }
                                })}
                                placeholder={t("email")}
                                label={t("email")}
                                fullWidth
                                error={!!errors.email} 
                                helperText={errors.email?.message as string} 
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                {...register("address")} 
                                placeholder={t("address")}
                                label={t("address")}
                                fullWidth
                                error={!!errors.address} 
                                helperText={errors.address?.message as string} 
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                {...register("taxNumber")} 
                                placeholder={t("taxNumber")}
                                label={t("taxNumber")}
                                fullWidth
                                error={!!errors.taxNumber} 
                                helperText={errors.taxNumber?.message as string} 
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                }
            />}
        </div>
    </>)
}