// InspectionFormService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusInspectionFormResponse,
  DeleteInspectionFormResponse,
  GetInspectionFormByIdResponse,
  GetInspectionFormDropdownResponse,
  GetInspectionFormResponse,
  InspectionFormRequest,
  InspectionFormResponse,
} from './interface';

export class InspectionFormProfile extends BaseService {
  async createInspectionForm(req: InspectionFormRequest) {
    return this.post<InspectionFormResponse, InspectionFormRequest>(`${this.apiUrl}/api/inspectionforms`, req);
  }

  async getInspectionForm(params: PaginationParams) {
    return this.get<GetInspectionFormResponse>(`${this.apiUrl}/api/inspectionforms/paged`, params);
  }

  async getInspectionFormDropdown(params: PaginationParams) {
    return this.get<GetInspectionFormDropdownResponse>(`${this.apiUrl}/api/inspectionforms/get`, params);
  }

  async updateInspectionForm(id: number, req: InspectionFormRequest) {
    return this.put<InspectionFormResponse, InspectionFormRequest>(`${this.apiUrl}/api/inspectionforms/${id}`, req);
  }

  async deleteInspectionForm(id: number) {
    return this.delete<DeleteInspectionFormResponse>(`${this.apiUrl}/api/inspectionforms/${id}`);
  }

  async changeStatusInspectionForm(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusInspectionFormResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/inspectionforms/${active}/${id}`,
      req
    );
  }

  async getInspectionFormById(id: number | undefined) {
    return this.get<GetInspectionFormByIdResponse>(`${this.apiUrl}/api/inspectionforms/${id}`);
  }
}
