import { BaseService } from '../../core/BaseService';
import {
  DownloadReportRequest,
  GetReportPreviewResponse,
  GetReportsListResponse,
  ReportListRequest,
} from './interface';

export class ReportsProfile extends BaseService {
  async downloadReport(req: DownloadReportRequest) {
    return this.post<Blob, DownloadReportRequest>(`${this.apiUrl}/api/reports/export`, req, {
      responseType: 'blob',
    });
  }

  async getReportPreview(req: DownloadReportRequest) {
    return this.get<GetReportPreviewResponse>(`${this.apiUrl}/api/reports/list`, req);
  }

  async getReportsList(req: ReportListRequest) {
    return this.get<GetReportsListResponse>(`${this.apiUrl}/api/reports/template`, req);
  }
}
