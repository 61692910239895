import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useNavigate } from 'react-router-dom';
import FormDialog from 'common/Modal/Modal';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Client  } from "assets/SideBarIcons/customer.svg";
import BackendPaginationTable from 'common/Table/TableWithPagination';
import { ClientProfileService, CommonProfileService } from 'subModule/src/core/services';
import { DeleteData } from 'subModule/src/type';
import Loader from 'layout/Loader';
import { useGetClientsService } from 'subModule/src/services/client/useClient';
import { ClientResponse, ChangeStatusClientResponse } from 'subModule/src/services/client/interface';
import ClientSearchHeader from 'components/Client/ClientSearchHeader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';

interface SearchFormData {
    [key: string]: string | number;
}
export default function ClientsListing() {
    const navigate = useNavigate();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<string[]>(['name']);
    const [search, setSearch] = useState<any>({});
    const [row, setRow] = useState<ClientResponse>({});
    const [downloading, setDownloading] = useState<boolean>(false);
    const loggedInUserData = useSelector(selectUserInfo);
    const loggedInUserType = loggedInUserData.type;
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({ name:'', ClientName:'', accountTitle:'', contractNumber:'', });
    const { data: tableData, isLoading, error, refetch } = useGetClientsService({ pageNumber: pageNo, size: pageSize, ...search }, loggedInUserType === -1 ? 'clients/contractclient' : 'clients');
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (search && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 300)
        return () => clearTimeout(getData)
    }, [search]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const onEdit = (row: any) => navigate('/client/' + row.id);
    const showRowView = (row: any) => navigate('/client/' + row.id);
    const onDelete = (row: any) => {
        setDeleteModal(true);
        setRow(row);
    }
    const clearSearch = () => {
        setFilter(['name']);
        setSearchFormData({ name:'', ClientName:'', accountTitle:'', contractNumber:'', });
        setLoading(true);
        setSearch({});
    }
    const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData =>
            Object.fromEntries(Object.keys(prevFormData).map(key =>
                [key, value.includes(key) ? prevFormData[key] : (typeof prevFormData[key] === 'string' ? '' : 0)]
            ))
        );
        if (!value.length && Object.keys(search).length) {
            clearSearch();
        }
    };
    const handleChangeSearch = (field: string, value: any) => {
        const val = field === 'type' ? (value ? parseInt(value.value) || 0 : 0) : value;
        setSearchFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
    };
    const handleSubmitSearch = () => {
        const filteredSearch: Record<string, string | number> = {};
        Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) {
                filteredSearch[key] = typeof value === 'string' ? value?.trim() : value;
            }
        });
        setLoading(true);
        setSearch(filteredSearch);
        setPageNo(1);
        setPageSize(pageSize);
    };
    const onChangeStatus = (row: any) => {
        setRow(row);
        setStatusModal(true);
    }
    const actionslist = [
        { label: "edit", onClick: onEdit, icon: <EditIcon /> },
        { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
    ];
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_CLIENT_UPDATE')
            ? (checkPrivileges('ROLE_CLIENT_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_CLIENT_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }
    const DownloadClients = () => {
        CommonProfileService.exportEntityWithParams({ ...search }, 'clients/export/excel').then((res: Blob) => {
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Clients.xlsx`);
            document.body.appendChild(link);
            link.click();

            link.remove();
            window.URL.revokeObjectURL(url);
            enqueueSnackbar(<AlertMessages text={t('downloaded') + ' ' + t('successfully')} />, { variant: 'success' });
        }).catch((err) => {
            console.error("===", err);
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        }).finally(() => {
            setDownloading(false);
        });
    }
    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/client/create')}
                heading={t('Clients')}
                icon={<Client height={28} width={28} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_CLIENT_CREATE")}
            />
            {loading ? <Loader /> : <BackendPaginationTable
                columns={[
                    { id: 'name', label: 'name', numeric: false },
                    { id: 'userNameClient', label: 'email', numeric: false },
                    { id: 'accountTitleClient', label: "accountTitle", numeric: false },
                    { id: 'accountContractNumber', label: "contractNumber", numeric: false },
                    ...(checkPrivileges("ROLE_CLIENT_ACTIVE") ? [{ id: 'active', label: 'status', numeric: false }] : []),
                    ...(adjustActions(actionslist).length ? [{ id: 'actions', label: 'actions', numeric: false }] : []),
                  ]}
                data={tableData?.pages[0]?.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={tableData?.pages[0]?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={adjustActions(actionslist)}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                rowClick={checkPrivileges("ROLE_CLIENT_UPDATE")}
                showRowView={showRowView}
                loadingSearch={false}
                headerComponent={<>
                    <ClientSearchHeader 
                        searchFormData={searchFormData}
                        handleChangeFilter={handleChangeFilter}
                        handleChangeSearch={handleChangeSearch}
                        handleSubmitSearch={handleSubmitSearch}
                        clearSearch={clearSearch}
                        {...{filter, search}}
                    />
                    {checkPrivileges('ROLE_CLIENT_EXPORT') ? <Grid item className="text-end">
                        <Button
                            id="import-button"
                            variant="contained"
                            disableElevation
                            onClick={() => {setDownloading(true); DownloadClients();}}
                            endIcon={<KeyboardArrowDownIcon />}
                            className={`bg-white text-primary-color font-Saira font-[600] text-[14px] h-[31px] px-4 py-1 cursor-pointer rounded-[6px] ms-[10px]`} 
                            sx={{ outline: '2px solid' }}
                            disabled={downloading}
                        >{downloading ? <CircularProgress className='text-primary-color me-2' size={16} /> : ''}{t('download')}</Button>
                    </Grid> : null}
                </>}
            />}
            {openDeleteModal &&
                <FormDialog
                    open={openDeleteModal}
                    onClose={() => setDeleteModal(false)}
                    title={t('delete') + ' ' + t('Client')}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t("Are you sure you want to delete?")}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setDeleteModal(false);
                        setLoading(true);
                        ClientProfileService.deleteClient((row as any).id, 'clients/archive').then((response: DeleteData) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                if (tableData?.pages[0].data.content.length === 1) handleChangePage(pageNo - 1);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Client') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="Delete"
                />}
            {statusModal &&
                <FormDialog
                    open={statusModal}
                    onClose={() => setStatusModal(false)}
                    title="Change Status"
                    // icon={<div><Home/></div>}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t(`Are you sure?`)}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setStatusModal(false);
                        setLoading(true);
                        ClientProfileService.changeStatusClient((row as any).id, row.active ? "inactive" : "active", { typeName: 'clients' }).then((response: ChangeStatusClientResponse) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('status') + ' ' + t('changed') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="OK"
                />
            }
        </Box>
    )
}