import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Roles } from "assets/SideBarIcons/roles.svg";
import Roletabs from './Roletabs';
import { useAssignPrivilegeService, useGetRolePrivilegeService, useUnassignPrivilegeService } from 'subModule/src/services/role/useRole';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Checkbox } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import Loader from 'layout/Loader';

export default function RolePrivileges() {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [accessLimit, setAccessLimit] = useState<number | null>(null);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const { data: tableData, isLoading, error, refetch } = useGetRolePrivilegeService({pageNumber: 1, size: 1000}, +(params as any).id);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    let groupSelect: any = false;
    useEffect(() => {
        if (!isLoading && !error) refetch();
    }, []);
    useEffect(() => {
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [tableData]);
    useEffect(() => {
        if (!loading) { window.scrollTo(0, scrollPosition) }
    }, [loading]);
    const handleParentCheckboxChange = (parent: any, isChecked: boolean) => {
        setAccessLimit(null);
        let groups: any[] = [];
        groups = [parent, ...(tableData?.pages[0]?.data?.content || [])
          .filter((item:any) => item.parentId === parent.id)
          .map((item) => item)
        ];
    
        groups.map((group) => {
          if ((group as any).parentId === null) {
            assignUnassignSingleEntity(!(group as any).linked, group, window.scrollY, accessLimit, true);
          } else if ((group as any).parentId !== null) {
            if (isChecked) {
              assignUnassignSingleEntity(true, group, window.scrollY, accessLimit, true);
            } else {
              assignUnassignSingleEntity(false, group, window.scrollY, accessLimit, true);
            }
          }
        });
    };
    const assignUnassignSingleEntity = (isChecked: boolean, row: any, scroll: number, accessLimit?: number | null, group?: boolean) => {
        setScrollPosition(scroll);
        setLoading(true);
        groupSelect = group;
        if(isChecked) {
            onAssignPrivilege({ roleId: +(params as any).id, privilegeId: row.id, accessLimit: Number(accessLimit) });
        } else {
            onUnassignPrivilege({ roleId: +(params as any).id, privilegeId: row.id, accessLimit: Number(accessLimit) });
        }
      }
      const { onAssignPrivilege } = useAssignPrivilegeService({
        onSuccess: async (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            await refetch();
            if(!groupSelect) enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Privilege(s)') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setLoading(false);
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setLoading(false);
      }})
      const { onUnassignPrivilege } = useUnassignPrivilegeService({
        onSuccess: async (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            await refetch();
            if(!groupSelect) enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Privilege(s)') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setLoading(false);
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          setLoading(false);
        },
    });

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/roles')}
                heading={t('editRoles')}
                icon={<Roles height={28} width={28} />}
                btnText={"back"}
                btnType={"back"}
                showCreateBtn={false}
            />
            <Box>
                <div className='flex'>
                    {params?.id ? <Roletabs params={params} selectedIndex={1} /> : null}
                    {loading ? <Loader /> : <Box className='w-[100%] mt-3'>
                        {(tableData?.pages[0]?.data?.content || [])
                        .filter((item:any) => item.parentId === null)
                        .map((parentItem: any) => (
                            <Accordion key={parentItem.id} defaultExpanded>
                                <AccordionSummary
                                expandIcon={<span><KeyboardArrowDownIcon /></span>}
                                sx={{
                                    padding: '0 5px',
                                    minHeight: '20px !important',
                                    '& .MuiAccordionSummary-content': {
                                    alignItems: 'center',
                                    margin: '3px 0',
                                    },
                                    '& .Mui-expanded': {
                                    margin: '0 !important',
                                    }
                                }}
                                >
                                <Checkbox
                                    checked={parentItem.linked && (tableData?.pages[0]?.data?.content || []).filter((item:any) => item.parentId === parentItem.id).every((childItem) => childItem.linked)}
                                    className='text-primary-color'
                                    onChange={(event) => handleParentCheckboxChange(parentItem, event.target.checked)}
                                    disabled={(checkPrivileges('ROLE_ROLE_PRIVILEGE_ASSIGN') && !parentItem.linked) ? false
                                    : (checkPrivileges('ROLE_ROLE_PRIVILEGE_UNASSIGN') && parentItem.linked) ? false
                                        : true
                                    }
                                    sx={{
                                    opacity: 1,
                                    '&.Mui-disabled': {
                                        opacity: 0.3
                                    }
                                    }}
                                />
                                <Typography className='font-Saira font-[600] text-[14px] text-txt-color'>
                                    {parentItem.erole !== 'ROLE_JOBCARD_DISCOUNT_UPDATE' && parentItem.erole !== 'ROLE_WORKORDER_DISCOUNT_UPDATE' ? t(parentItem.erole.split('_')[1]) : t(parentItem.erole)}
                                </Typography>
                                </AccordionSummary>
                                {parentItem.erole === 'ROLE_JOBCARD_DISCOUNT_UPDATE' || parentItem.erole === 'ROLE_WORKORDER_DISCOUNT_UPDATE' ?
                                    <div className='flex w-100 items-center font-Saira'>
                                        <label className='pl-[16px] pe-[10px] font-bold leading-[21px] text-[14px]'>{t('limit')} : </label>
                                        <input type="number" id="formfield"
                                        min={0}
                                        max={100}
                                        className='w-[200px]'
                                        defaultValue={parentItem.accessLimit}
                                        onChange={(e) => setAccessLimit(
                                            ((e as any).target.value < 0) ? 0
                                            : ((e as any).target.value > 100) ? 100
                                                : (e as any).target.value
                                        )}
                                        />
                                        <span className='ps-[5px] leading-[21px] text-[14px]'>%</span>
                                    </div> : ''}
                                <AccordionDetails className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[10px]'>
                                {parentItem.erole !== 'ROLE_JOBCARD_DISCOUNT_UPDATE' && parentItem.erole !== 'ROLE_WORKORDER_DISCOUNT_UPDATE' ? <Box key={parentItem.id} className='flex text-left items-center'>
                                    <Checkbox
                                    checked={parentItem.linked}
                                    className='text-primary-color'
                                    disabled={(checkPrivileges('ROLE_ROLE_PRIVILEGE_ASSIGN') && !parentItem.linked) ? false
                                        : (checkPrivileges('ROLE_ROLE_PRIVILEGE_UNASSIGN') && parentItem.linked) ? false
                                        : true
                                    }
                                    onClick={(event) => { assignUnassignSingleEntity((event.target as HTMLInputElement).checked, parentItem, window.scrollY, null, false) }}
                                    sx={{
                                        opacity: 1,
                                        '&.Mui-disabled': {
                                        opacity: 0.3
                                        }
                                    }}
                                    />
                                    <Typography className='font-Saira font-[500] text-[14px] text-txt-color'>{t(parentItem.erole)}</Typography>
                                </Box> : ''}
                                {(tableData?.pages[0]?.data?.content || [])
                                    .filter((item:any) => item.parentId === parentItem.id)
                                    .map((childItem:any) => (
                                    <Box key={childItem.id} className='flex text-left items-center'>
                                        <Checkbox
                                        checked={childItem.linked}
                                        className='text-primary-color pt-[0px]'
                                        onClick={(event) => { assignUnassignSingleEntity((event.target as HTMLInputElement).checked, childItem, window.scrollY, null, false) }}
                                        disabled={(checkPrivileges('ROLE_ROLE_PRIVILEGE_ASSIGN') && !childItem.linked) ? false
                                            : (checkPrivileges('ROLE_ROLE_PRIVILEGE_UNASSIGN') && childItem.linked) ? false
                                            : true
                                        }
                                        sx={{
                                            opacity: 1,
                                            paddingBottom: 0,
                                            '&.Mui-disabled': {
                                            opacity: 0.3
                                            },
                                        }}
                                        />
                                        <Typography className='font-Saira font-[500] text-[14px] text-txt-color'>{t(childItem.erole)}{childItem.accessLimit ? ' - Limit : ' + childItem.accessLimit : ''}</Typography>
                                    </Box>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>}
                </div>
            </Box>
        </Box>
    )
}