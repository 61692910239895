import { useMutation } from '@tanstack/react-query';
import { UserProfileService } from '../../core/services';
import { GetLoginResponse, LoginRequest, LogoutRequest, LogoutResponse } from './interface';
import { useCallback } from 'react';

type UseAuthServiceProps = {
  onSuccess: (data: GetLoginResponse) => void;
  onError: (err: unknown) => void;
};
type UseAuthLogoutServiceProps = {
  onSuccess: (data: LogoutResponse) => void;
  onError: (err: unknown) => void;
};

export const useAuthService = ({ onError, onSuccess }: UseAuthServiceProps) => {
  const {
    mutateAsync: signIn,
    isPending: isLoginLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['log-in-request'],
    mutationFn: (req: LoginRequest) => UserProfileService.signIn(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onLogIn = useCallback(
    async (username: string, password: string) => {
      try {
        await signIn({ username, password });
      } catch (err) {
        console.error(err);
      }
    },
    [signIn]
  );

  return {
    onLogIn,
    isLoginLoading,
    data,
    error,
    isError,
  };
};

export const useAuthLogoutService = ({ onError, onSuccess }: UseAuthLogoutServiceProps) => {
  const {
    mutateAsync: signOut,
    isPending: isLogoutLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['log-out-request'],
    mutationFn: (req: LogoutRequest) => UserProfileService.signOut(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onLogOut = useCallback(async () => {
    try {
      signOut({});
    } catch (err) {
      console.error(err);
    }
  }, [signOut]);

  return {
    onLogOut,
    isLogoutLoading,
    data,
    error,
    isError,
  };
};
