import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import ChecklistItemComponent from 'components/ChecklistItem/index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ChecklistItemRequest } from 'subModule/src/services/checklistitem/interface';
import { useChecklistItemService,useChangeStatusChecklistItemService,useDeleteChecklistItemService,useGetChecklistItemService,useUpdateChecklistItemService } from 'subModule/src/services/checklistitem/useChecklistItem';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  type: string,
  checkListId: number;
  value: number;
  // valueError?: string;
  id: number,
  active: boolean;
}
interface ChecklistItemProps {
  checkListId: number;
}

  const requiredFields = ['type', 'value'];

  export default function ChecklistItem ({ checkListId } : ChecklistItemProps) {
    const [showIFISecField, setShowIFISecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [ChecklistId, setChecklistId] = useState<number>(-1);
    // const [data] = useState(rows);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [formData, setFormData] = useState<FormData>({
        type: '',
        checkListId: checkListId,
        value: 0,
        id: 0,
        active: true,
    });

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetChecklistItemService({ pageNumber : pageNo, size: pageSize, type: search }, checkListId) : useGetChecklistItemService({ pageNumber: pageNo, size: pageSize }, checkListId);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

  const loggedInUserData = useSelector(selectUserInfo);
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    
    useEffect(() => {
      const fetchData = setTimeout(async() => {
        if (!isLoading && !error && !openForm && !loadingSearch) {
          await refetch();
        }
        if(tableData) {
          setLoading(false);
          if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
            enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
          }
        }
      }, 300);
      return () => clearTimeout(fetchData)
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000)
      return () => clearTimeout(getData)
    }, [searchTerm,loadingSearch]);
    
    const handleChangePage = (pageNo : number) => {
      setLoading(true);
      setPageNo(pageNo+1)
      setPageSize(pageSize)

    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize)
      setPageNo(1)
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
        setOpenForm(!openForm);
        setIsBtnEnabled(true);
        setEnableEdit(false);
        setShowIFISecField(false);
        setErrorMessages({});
        setSearch("");
        setSearchTerm("");
        setChecklistId(-1);
        setFormData({
            type: '',
            checkListId: checkListId,
            value: 0,
            id: 0,
            active: true,
        });
      };

      const handleSecField = () => {
        setShowIFISecField((prevValue) => !prevValue);
      }

      const handleChange = (field: string, value: any) => {
        const  val = field === 'value' ? (value ? parseInt(value.value) || 0 : 0) : value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.type?.trim()) {
          errors.type = 'Type is required.';
        }
        // if(!data.value) {
        //   errors.valueError = 'Value is required'
        // }
    
        return errors;
      };

      const { onCreateChecklistItem } = useChecklistItemService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('ChecklistItem') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdateChecklistItem } = useUpdateChecklistItemService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            setEnableEdit(false);
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('ChecklistItem') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const ChecklistItemData: ChecklistItemRequest = {
            ...formData, 
            type: formData.type,
            value: formData.value,
            checkListId: checkListId,
          };
     
          if (enableEdit) {
            onUpdateChecklistItem(
                ChecklistItemData
            );
          } else {
            onCreateChecklistItem(ChecklistItemData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setIsBtnEnabled(false);
        setSearch("");
        setSearchTerm("");
        // setChecklistPageParams({ number: 1, size: 20 })
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteChecklistItem } = useDeleteChecklistItemService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('ChecklistItem') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteChecklistItem();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
      };
      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusChecklistItem({});
      }

      const { onChangeStatusChecklistItem } = useChangeStatusChecklistItemService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('ChecklistItem') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          console.error("===", err)
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });

      const showRowView = (row: any) => {
        onEdit(row);
      }
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_CHECKLISTITEM_UPDATE') 
          ? (checkPrivileges('ROLE_CHECKLISTITEM_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_CHECKLISTITEM_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <ChecklistItemComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                showRowView={showRowView}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_CHECKLISTITEM_ACTIVE") ? [
                    { id: 'typeLabel', label: 'name', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'typeLabel', label: 'name', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit, pageNo, statusModal, statusText, pageSize,search, isBtnEnabled, loading, loadingSearch,showIFISecField, ChecklistId }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }