// StatusesService.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import {
  DeleteStatusesResponse,
  GetStatusByIdResponse,
  GetStatusesRequest,
  GetStatusesResponse,
  StatusesRequest,
  StatusesResponse,
} from './interface';

export class StatusesProfile extends BaseService {
  async createStatuses(req: StatusesRequest) {
    return this.post<StatusesResponse, StatusesRequest>(`${this.apiUrl}/api/businessstatus`, req);
  }

  async getStatuses(params: PaginationParams & { type: number }) {
    return this.get<GetStatusesResponse>(`${this.apiUrl}/api/businessstatus/paged`, params);
  }

  async updateStatuses(id: number, req: StatusesRequest) {
    return this.put<StatusesResponse, StatusesRequest>(`${this.apiUrl}/api/businessstatus/${id}`, req);
  }

  async deleteStatuses(id: number) {
    return this.delete<DeleteStatusesResponse>(`${this.apiUrl}/api/businessstatus/${id}`);
  }

  async getStatusByID(id: number, req: GetStatusesRequest) {
    return this.get<GetStatusByIdResponse>(`${this.apiUrl}/api/businessstatus/${id}`, req);
  }
}
