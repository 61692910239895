import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import CommonCheckbox from 'common/CheckBox/Checkbox';
import { Controller } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';

interface Data {
  [key: string]: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface ItemsPriceListTableProps {
  form?: any;
  columns: any[];
  rows: any[];
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  assignPrivilege: boolean;
  unassignPrivilege: boolean;
  defaultPriceListId: number;
}

export const ItemsPriceListTable: React.FC<ItemsPriceListTableProps> = (props) => {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data,) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const visibleRows = React.useMemo(
    () =>
      stableSort(props.rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, props.rows],
  );

  return (
    <Box className='assignTables' id="inventorytable">
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={'small'}>
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.numeric ? 'right' : 'left'}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={props.columns.length + 1} align="center" className='py-3'>
                  <Typography className="font-Saira text-primary-color font-[500] text-[16px] italic">{t('noRecordFound')}</Typography>
                </TableCell>
              </TableRow>
            ) : visibleRows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                  {props.columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.numeric ? 'right' : 'left'}
                      padding={column.disablePadding ? 'none' : 'normal'}
                      component={column.id === 'pricelistId' ? 'th' : undefined}
                      id={column.id === 'pricelistId' ? labelId : undefined}
                      scope={column.id === 'pricelistId' ? 'row' : undefined}
                      sx={{ width: column.id === 'pricelistId' ? '40px' : 'auto' }}
                    >
                      {column.id === 'pricelistId' ? <Controller
                        name={`pricelistId-${row.id}`}
                        control={props.form.control}
                        defaultValue={row.linked}
                        render={({ field }) => (
                          <Box
                            sx={{
                              "& .MuiCheckbox-root": { padding: "0 9px !important" },
                              "& label": { paddingTop: "4px !important" },
                            }}
                          >
                            <CommonCheckbox
                              {...field}
                              label=""
                              onChange={async (event) => {
                                field.onChange(event.target.checked);
                                const isValid = await props.form.trigger(`price-${row.id}`);
                                if (isValid) props.assignUnassignSingleEntity(event.target.checked, {...row, 
                                  price: props.form.watch(`price-${row.id}`) });
                              }}
                              checked={(row as any).linked}
                              color="success"
                              disabled={(() => {
                                if(row.linked && row.id === props.defaultPriceListId) { return true; }
                                if((props.assignPrivilege && !row.linked) || (props.unassignPrivilege && row.linked)) {
                                  return false;
                                } else {
                                  return true;
                                }
                              })()}
                            />
                          </Box>
                        )}
                      />
                        : column.id === 'price' ? <Controller
                          name={`price-${row.id}`}
                          control={props.form.control}
                          rules={{ required: true }}
                          defaultValue={row.plPrice}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              onChange={(e) => {
                                const value = e.target.value ? Number(e.target.value) : '';
                                field.onChange(value && value < 0 ? 0 : value);
                                props.form.trigger(`price-${row.id}`);
                              }}
                              placeholder={t("price")}
                              fullWidth
                              error={!!props.form.formState.errors[`price-${row.id}`]}
                              helperText={props.form.formState.errors[`price-${row.id}`]?.message as string}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{ readOnly: (row as any).linked }}
                              sx={{ '& .MuiInputBase-formControl': { borderRadius: '8px' } }}
                            />
                          )}
                        />                      
                        : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 200]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='hide-on-print'
        labelRowsPerPage={t('rowsPerPage')}
      />
    </Box>
  );
};