// ServiceGroupService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignResponse,
  AssignUnassignServiceRequest,
  ChangeStatusServiceGroupResponse,
  DeleteServiceGroupResponse,
  GetServiceGroupByIdResponse,
  GetServiceGroupResponse,
  GetServiceGroupServiceResponse,
  ServiceGroupRequest,
  ServiceGroupResponse,
} from './interface';

export class ServiceGroupProfile extends BaseService {
  async createServiceGroup(req: ServiceGroupRequest) {
    return this.post<ServiceGroupResponse, ServiceGroupRequest>(`${this.apiUrl}/api/servicegroups`, req);
  }

  async getServiceGroup(params: PaginationParams) {
    return this.get<GetServiceGroupResponse>(`${this.apiUrl}/api/servicegroups/paged`, params);
  }

  async getServiceGroupDropdown(params: PaginationParams) {
    return this.get<GetServiceGroupResponse>(`${this.apiUrl}/api/servicegroups/get`, params);
  }

  async updateServiceGroup(id: number, req: ServiceGroupRequest) {
    return this.put<ServiceGroupResponse, ServiceGroupRequest>(`${this.apiUrl}/api/servicegroups/${id}`, req);
  }

  async deleteServiceGroup(id: number) {
    return this.delete<DeleteServiceGroupResponse>(`${this.apiUrl}/api/servicegroups/${id}`);
  }

  async changeStatusServiceGroup(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusServiceGroupResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/servicegroups/${active}/${id}`,
      req
    );
  }

  async getServiceGroupService(params: PaginationParams & { serviceGroupId: number }) {
    return this.get<GetServiceGroupServiceResponse>(`${this.apiUrl}/api/services/byservicegroupid`, params);
  }

  async assignService(req: AssignUnassignServiceRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignServiceRequest>(
      `${this.apiUrl}/api/servicegroups/service/assign`,
      req
    );
  }

  async unassignService(req: AssignUnassignServiceRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignServiceRequest>(
      `${this.apiUrl}/api/servicegroups/service/unassign`,
      req
    );
  }

  async assignAllServices(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/servicegroups/service/assignall`,
      req
    );
  }

  async unassignAllServices(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/servicegroups/service/unassignall`,
      req
    );
  }

  async getServiceGroupById(id: number | undefined) {
    return this.get<GetServiceGroupByIdResponse>(`${this.apiUrl}/api/servicegroups/${id}`);
  }
}
