import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { closeSnackbar, useSnackbar } from "notistack";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService } from "subModule/src/core/services";
import { SaveStockReceiptResponse, StockReceiptResponse } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import { DeleteData } from "subModule/src/type";
import { useGetVendorService } from "subModule/src/services/vendor/useVendor";
import { useGetStoreDropdownService } from "subModule/src/services/store/useStore";
import DynamicSelect from "common/Select/Select";
import { useGetInventoryService } from "subModule/src/services/inventory/useInventory";
import dayjs from "dayjs";

interface FormData {
    [key: string]: any;
}

const StockReceivedCreateUpdate: React.FC = () => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField, getValues, reset} = useForm<FormData>({defaultValues: {
        stockReceiptItems: [],
    }});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 8000, active: true });
    const { data: vendorsData, refetch: vendorsRefetch } = useGetVendorService({ pageNumber: 1, size: 1000 });
    const { data: storesData, refetch: storesRefetch } = useGetStoreDropdownService({ pageNumber: 1, size: 1000 });
    const { data: poData, refetch: poRefetch } = useGetInventoryService({ pageNumber: 1, size: 500 });
    const [stockReceiptItems, setStockReceiptItems] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [sr, setSR] = useState<StockReceiptResponse>();

    useEffect(() => {
        itemsRefetch();
        vendorsRefetch();
        storesRefetch();
        poRefetch();
        if(params?.id) {
            if(loading) getStockReceiptById();
        }
    }, [params]);
    const getStockReceiptById = () => {
        InventoryProfileService.getEntityById(params?.id, 'stockreceipt').then((res: any) => {
            if(res.success) {
                setSR((res.data as any));
                setStockReceiptItems((res.data as any)?.stockReceiptItems || []);
                setValue('stockReceiptItems', (res.data as any)?.stockReceiptItems || []);
                setValue('supplierId', (res.data as any)?.supplierId);
                setValue('storeId', (res.data as any)?.storeId);
                setValue('type', 'stock');
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const getPOById = (id: string | undefined) => {
        InventoryProfileService.getPurchaseOrderById(id).then((res: any) => {
            if(res.success) {
                setSR(res.data);
                setStockReceiptItems(res.data?.purchaseOrderItems);
                setValue('stockReceiptItems', res.data.purchaseOrderItems);
                setValue('storeId', {value: res.data?.storeId, label: res.data?.storeTitle});
                setValue('supplierId', {value: res.data?.supplierId, label: res.data?.vendorTitle});
                setValue('type', 'stock');
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const supplierOptions = (vendorsData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const storeOptions = (storesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const poOptions = (poData?.pages[0]?.data?.content || [])?.map((item: any) => ({ value: item.id, label: item.poNumber }));
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'supplierSKU', label: t('SKU'), numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitTitle' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'batchNumber', label: t('Batch'), numeric: false, disablePadding: false, type: 'text' },
        { id: 'receivedDate', label: t('Received') + ' ' + t('Date'), numeric: false, disablePadding: false, type: 'date' },
        { id: 'expiryDate', label: t("Expiry") + ' ' + t('Date'), numeric: false, disablePadding: false, type: 'date' },
    ];
    const DeleteItem = (row: any, typeName: string) => {
        if(typeName === "stockReceiptItems" && params?.id) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'stockreceipt/items').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getStockReceiptById();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else {
            setValue('stockReceiptItems', stockReceiptItems.filter((rows) => rows.id !== row.id));
            setStockReceiptItems((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        }
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "stockReceiptItems") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!params?.id) {
                (newItem as any).id = Date.now();
                (newItem as any).receivedDate = dayjs(new Date()).format('YYYY-MM-DD');
            }
            setStockReceiptItems((prevRows) => [...prevRows, newItem]);
        }
    };
    const handleItem = (row: any, typeName: string, action: string) => {
        if(typeName === "stockReceiptItems" && params?.id) {
            if(action === 'create') {
                InventoryProfileService.createStockReceiptItem({
                    ...row, stockReceiptId: sr?.id, storeId: sr?.storeId, 
                    receivedDate: row.receivedDate?.split(' ')[0] || row.receivedDate || dayjs(new Date())?.format('YYYY-MM-DD'),
                    expiryDate: row.expiryDate || null
                }).then((response: SaveStockReceiptResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getStockReceiptById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getStockReceiptById();
                });
            } else {
                InventoryProfileService.updateStockReceiptItem({
                    ...row, stockReceiptId: sr?.id, storeId: sr?.storeId,
                    receivedDate: row.receivedDate?.split(' ')[0] || row.receivedDate,
                    expiryDate: row.expiryDate?.split(' ')[0] || row.expiryDate || null
                }).then((response: SaveStockReceiptResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getStockReceiptById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getStockReceiptById();
                });
            }
        }
    }

    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "stockReceiptItems") {
            const updatedItems = [...stockReceiptItems];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setStockReceiptItems(updatedItems);
        }
    };
    const onSubmit = (data: FormData) => {
        if(Object.values(data)?.length || Object.values(getValues())?.length) {
            const filteredItems = stockReceiptItems.filter((item: any) => item?.itemName);
            if(filteredItems.length === 0) {
                closeSnackbar();
                enqueueSnackbar(<AlertMessages text={t('addAtLeastOneItem')} />, { variant: 'error' });
                return;
            }
            filteredItems.every((row:any) => delete row.poId && delete row.unitTitle);
            InventoryProfileService.saveStockReceipt(Object.values(data)?.length ? {
                ...data, storeId: data.storeId.value, supplierId: data.supplierId.value, stockReceiptItems: filteredItems, poId: null,
            } : {...getValues(), storeId: watch('storeId').value, supplierId: watch('supplierId').value, stockReceiptItems: filteredItems, poId: null}).then((response: SaveStockReceiptResponse) => {
                if(response.success) {
                    InventoryProfileService.changeStatusEntity({typeName: 'stockreceipt', entityId: response?.data?.id, status: 2}).then((res: any) => {
                        if(res.success) {
                            enqueueSnackbar(<AlertMessages statusCode={res.status} text={t('Stock Received') + ' ' + t('saved') + ' ' + t('successfully')} />, { variant: 'success' });
                            navigate('/inventory/stock-receives');
                        } else {
                            enqueueSnackbar(<AlertMessages text={res?.message} />, { variant: 'error' });
                        }
                    }).catch((err:any) => { 
                        console.error("===", err);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                } else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
            }).catch((err:any) => { 
                console.error("===", err);
                setLoading(false);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (
        loading ? <Loader /> : <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2.5} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{params?.id ? t('Stock Received') : t('New Stock')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('Save as received')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/stock-receives')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            {params?.id ? <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{params?.id ? t('Stock Received') : t('New Stock')}</Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Stock')} # </span> <span>{sr?.stockNumber || ''}</span></Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Date')}</span> <span>{sr?.receivedDate?.split('T')[0] || ''}</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Supplier')} {t('name')}</span> <br /><span className="font-medium">{sr?.vendorTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('store') + ' ' + t('name')}</span> <br /><span className="font-medium">{sr?.storeTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Requested')}</span> <br /><span className="font-medium">{sr?.totalQuantity || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Received')}</span> <br /><span className="font-medium">{sr?.totalReceivedQuantity || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Amount')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format(sr?.totalAmount || 0) || 0}</span></Typography>
                    </div>
                </Grid>
            </Grid> : null}
            {params?.id ? null : 
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="poId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <DynamicSelect
                                label="Purchase Orders"
                                {...field}
                                options={poOptions}
                                isSearchable
                                isRequired={false}
                                onChange={(selectedOption: any) => {
                                    field.onChange(selectedOption);
                                    reset({ poId: selectedOption }, { keepValues: true });
                                    setStockReceiptItems([]);
                                    getPOById(selectedOption.value);
                                }}
                                error={errors.poId?.message as string}
                            />
                        )}
                    />
                </Grid>}
            <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('Stock Received')} {t("Details")}</Typography></Grid>
            <Grid container item xs={12} gap={2.5} className="flex-nowrap">
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="supplierId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('vendors')}
                                {...field}
                                options={params?.id ? [{ value: sr?.supplierId, label: sr?.vendorTitle }] : supplierOptions}
                                isSearchable
                                isRequired
                                onChange={(selectedOption: any) => {field.onChange(selectedOption);}}
                                error={errors.supplierId?.message as string}
                                value={params?.id ? { value: sr?.supplierId, label: sr?.vendorTitle } : watch('supplierId')}
                                isDisabled={params?.id as any}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="storeId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('stores')}
                                {...field}
                                options={params?.id ? [{ value: sr?.storeId, label: sr?.storeTitle }] : storeOptions}
                                isSearchable
                                isRequired
                                onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                error={errors.storeId?.message as string}
                                value={params?.id ? { value: sr?.storeId, label: sr?.storeTitle } : watch('storeId')}
                                isDisabled={params?.id as any}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container><Grid item xs={12}>
                <Box className="bg-white py-[12px] px-[18px] rounded-[8px]">
                    <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('Stock Received')}</Typography>
                    <InventoryItemsTable form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} columns={columns} handleInputChange={handleInputChange} rows={stockReceiptItems} typeName="stockReceiptItems" items={itemsData?.pages[0]?.data?.content || []} currency={loggedInUserCurrency} DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={params?.id ? 2 : 1} viewDelete={params?.id ? false : true} />
                    <Grid item xs={12} sm={6} className="hide-on-print">
                        <div className="flex gap-2 justify-start">
                            <Button className="secondary flex gap-1" variant="contained" disableElevation onClick={() => addNewItem('stockReceiptItems')} disabled={params?.id as any}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                            {/* <Button className="secondary" variant="contained" disableElevation>{t('Import')}</Button>
                            <Button className="secondary" variant="contained" disableElevation>{t("Export")}</Button> */}
                        </div>
                    </Grid>
                </Box>
            </Grid></Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('Save as received')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/stock-receives')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StockReceivedCreateUpdate;
