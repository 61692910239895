import Box from '@mui/material/Box';
import { useGetWorkOrderService } from 'subModule/src/services/workorder/useWorkOrders';
import WorkOrderComponent from 'components/WorkOrder/Index';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import AlertMessages from 'common/Alert/AlertMessages';
import { ReactComponent as Pen } from "assets/Modal/book_pen.svg";
import { ReactComponent as Delete } from "assets/Modal/delete.svg";
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { SelectChangeEvent } from '@mui/material';
import { useGetStationDropdownService } from 'subModule/src/services/station/useStation';

interface SearchFormData {
  [key: string]: string | number | boolean;
}

export default function WorkOrderHistory() {
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [formData, setFormData] = useState<object>({});
  const [openForm, setOpenForm] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [filter, setFilter] = useState<string[]>(['workOrderNo', 'isActive']);
  const [search, setSearch] = useState<any>({isActive: true});
  const [searchFormData, setSearchFormData] = useState<SearchFormData>({
    workOrderNo: '',
    stationId: 0,
    vehiclePlate: '',
    vehicleVin: '',
    isActive: true
  });

  const { data: cardsData,  isLoading, error,  refetch } = useGetWorkOrderService({ pageNumber: pageNo, size: pageSize, ...search });

  const { data: stationData, isLoading: stationIsLoading, error: stationError,  refetch: stationRefetch } = useGetStationDropdownService({pageNumber: 1, size: 50});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isLoading && !error && !openForm && !loadingSearch) {
      refetch();
    }
    if (!stationIsLoading && !stationError && !openForm && !loadingSearch) {
      stationRefetch();
    }
    if(cardsData) {
      setLoading(false);
      if((cardsData?.pages[0] as any).status && !(cardsData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(cardsData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
  }, [cardsData, pageNo, pageSize, openForm]);
  
  useEffect(() => {
    const getData = setTimeout(async () => {
      if (search && !isLoading && !error && loadingSearch) {
        await refetch();
        setLoadingSearch(false);
      }
    }, 300)
    return () => clearTimeout(getData)
  }, [search]);

  const handleChangePage = (pageNo : number) => {  
    setLoading(true);
    setPageNo(pageNo+1);
    setPageSize(pageSize);
  }
  const handleChangeRows = (pageSize : number) => {
    setLoading(true);
    setPageSize(pageSize);
    setPageNo(1);
  }

  // const [searchErrorMessages, setSearchErrorMessages] = useState<Partial<SearchFormData>>({});

  const openModal = () => {
    setOpenForm(!openForm);
    setFormData({ ...formData });
    if (!openForm) {
      clearSearch();
    }
  }
  const showRowView = (row:any) => {
    setFormData(row);
    setOpenForm(true);
    clearSearch();
  }
  const onDelete = (row:any) => {
    setFormData(row);
  }

  const clearSearch = () => {
    setFilter(['workOrderNo', 'isActive']);
    setSearchFormData({
      workOrderNo: '',
      stationId: 0,
      vehiclePlate: '',
      vehicleVin: '',
      isActive: true
    });
    // setSearchErrorMessages({});
    // if(Object.keys(search).length) {
      setLoadingSearch(true);
      setSearch({isActive: true});
    // }
  } 
  const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
    const { target: { value }, } = event;
    setFilter(typeof value === 'string' ? value.split(',') : value,);
    setSearchFormData(prevFormData => 
      Object.fromEntries(Object.keys(prevFormData).map(key => 
        [key, value.includes(key) ? prevFormData[key] : (typeof prevFormData[key] === 'string' ? '' : 0)]
      ))
    )
    if(!value.length && Object.keys(search).length) {
      clearSearch();
    }
  };
  const handleChangeSearch = (field: string, value: any) => {
    const val = field === 'stationId' ? (value ? parseInt(value.value) || 0 : 0) 
    : field === 'isActive' ? Boolean(value)  
    : value;

    setSearchFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    // if (filter.includes(field) && !val) {
    //   setSearchErrorMessages((prevErrors) => ({
    //     ...prevErrors,
    //     [field]: 'This field is required.',
    //   }));
    // } else {
    //   setSearchErrorMessages((prevErrors) => ({
    //     ...prevErrors,
    //     [field]: undefined,
    //   }));
    // }
  };
  // const validateSearchFormData = (data: SearchFormData) => {
  //   const errors: Partial<SearchFormData> = {};

  //   filter.forEach(fieldName => {
  //     if (!data[fieldName]) { 
  //       errors[fieldName] = 'Required';
  //     }
  //   });

  //   return errors;
  // };
  const handleSubmitSearch = () => {
    // const errors = validateSearchFormData(searchFormData);
    // setSearchErrorMessages(errors);

    // if (Object.keys(errors).length === 0) {
      setLoadingSearch(true);
      const filteredSearch: Record<string, string | number | boolean> = {};

      Object.entries(searchFormData).forEach(([key, value]) => {
        if (value) {
          filteredSearch[key] = typeof value === 'string' ? value?.trim() : value;
        }
      });

      setSearch(filteredSearch);
      setPageNo(1);
      setPageSize(pageSize);
    // }
  };

  const adjustActions = (actions: any[]) => {
    return checkPrivileges('ROLE_WORKORDER_UPDATE')
      ? (checkPrivileges('ROLE_WORKORDER_ARCHIVE')
        ? actions
        : actions.filter(action => action.label !== 'delete'))
      : (checkPrivileges('ROLE_WORKORDER_ARCHIVE')
        ? actions.filter(action => action.label !== 'edit')
        : []);
  }

  return (
    <Box>
      <WorkOrderComponent 
        data={cardsData?.pages[0] || {}}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        showRowView={showRowView}
        openModal={openModal}
        closeModal={openModal}
        searchFormData={searchFormData}
        handleChangeFilter={handleChangeFilter}
        handleChangeSearch={handleChangeSearch}
        handleSubmitSearch={handleSubmitSearch}
        // searchErrorMessages={searchErrorMessages}
        clearSearch={clearSearch}
        stationData={stationData?.pages[0] || {}}
        {...{ pageNo, pageSize, loading, formData, openForm, loadingSearch, filter, search }}
        actions1={adjustActions([
          { label: "edit", onClick: showRowView, icon: <Pen className="float-end" /> },
          { label: "delete", onClick: onDelete, icon: <Delete className='float-end' /> },
        ])}
      />
    </Box>
  )
}