// ClientService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusClientResponse,
  ClientRequest,
  ClientResponse,
  DeleteClientResponse,
  GetClientByIdResponse,
  GetClientsDropdownResponse,
  GetClientsResponse,
} from './interface';

export class ClientProfile extends BaseService {
  async createClient(req: ClientRequest, typeName: string) {
    return this.post<ClientResponse, ClientRequest>(`${this.apiUrl}/api/${typeName}`, req);
  }

  async getClients(params: PaginationParams, typeName: string) {
    return this.get<GetClientsResponse>(`${this.apiUrl}/api/${typeName}/paged`, params);
  }

  async getCustomers(params: PaginationParams) {
    return this.get<GetClientsResponse>(`${this.apiUrl}/api/customers/paged`, params);
  }

  async getClientsDropdown(params: PaginationParams) {
    return this.get<GetClientsDropdownResponse>(`${this.apiUrl}/api/users/client/get`, params);
  }

  async updateClient(id: number, req: ClientRequest, typeName: string) {
    return this.put<ClientResponse, ClientRequest>(`${this.apiUrl}/api/${typeName}/${id}`, req);
  }

  async deleteClient(id: number, typeName: string) {
    return this.delete<DeleteClientResponse>(`${this.apiUrl}/api/${typeName}/${id}`);
  }

  async changeStatusClient(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusClientResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/${req.typeName}/${active}/${id}`,
      req
    );
  }

  async getClientByID(id: number, typeName: string) {
    return this.get<GetClientByIdResponse>(`${this.apiUrl}/api/${typeName}/${id}`);
  }
}
