// ModelService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignResponse,
  AssignUnassignServiceRequest,
  ChangeStatusModelResponse,
  DeleteModelResponse,
  GetModelByIdResponse,
  GetModelResponse,
  GetModelServiceResponse,
  ModelRequest,
  ModelResponse,
} from './interface';

export class ModelProfile extends BaseService {
  async createModel(req: ModelRequest) {
    return this.post<ModelResponse, ModelRequest>(`${this.apiUrl}/api/models`, req);
  }

  async getModel(params: PaginationParams) {
    return this.get<GetModelResponse>(`${this.apiUrl}/api/models/paged`, params);
  }

  async updateModel(id: number, req: ModelRequest) {
    return this.put<ModelResponse, ModelRequest>(`${this.apiUrl}/api/models/${id}`, req);
  }

  async deleteModel(id: number) {
    return this.delete<DeleteModelResponse>(`${this.apiUrl}/api/models/${id}`);
  }

  async changeStatusModel(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusModelResponse, ChangeStatusRequest>(`${this.apiUrl}/api/models/${active}/${id}`, req);
  }

  async getModelService(params: PaginationParams & { modelId: number }) {
    return this.get<GetModelServiceResponse>(`${this.apiUrl}/api/services/bymodelid`, params);
  }

  async assignService(req: AssignUnassignServiceRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignServiceRequest>(
      `${this.apiUrl}/api/models/service/assign`,
      req
    );
  }

  async unassignService(req: AssignUnassignServiceRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignServiceRequest>(
      `${this.apiUrl}/api/models/service/unassign`,
      req
    );
  }

  async assignAllServices(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/models/service/assignall`,
      req
    );
  }

  async unassignAllServices(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/models/service/unassignall`,
      req
    );
  }

  async getModelByID(id: number) {
    return this.get<GetModelByIdResponse>(`${this.apiUrl}/api/models/${id}`);
  }
}
