// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'features/user/userSlice';
import brandingReducer from 'features/server/brandingSlice';
const store = configureStore({
  reducer: {
    user: userReducer,
    branding: brandingReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
