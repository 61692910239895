import { Grid, InputAdornment, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Station } from "assets/SideBarIcons/station.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetStationByIdResponse } from "subModule/src/services/station/interface";
import Loader from "layout/Loader";
import DynamicSelect from "common/Select/Select";
import { StationProfileService } from "subModule/src/core/services";
import { useSelector } from "react-redux";
import { selectUserInfo } from "features/user/userSlice";
import { useGetPriceListDropdownService } from "subModule/src/services/pricelist/usePriceList";
import { useGetBusinessGroupDropdownService } from "subModule/src/services/businessgroup/useBusinessGroup";
import COUNTRIES from "common/Others/Countries";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import CommonCheckbox from "common/CheckBox/Checkbox";
import StationMedia from './StationMedia';
import StationTabs from "./StationTabs";
import FormDialog from "common/Modal/Modal";

interface FormData { [key: string]: any; }
export default function StationModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, control, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, setValue, getValues, watch } = useForm<FormData>({ defaultValues: {} });
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [showSecTitleField, setShowSecTitleField] = useState<boolean>(false);
    const { data: priceListData, refetch: priceListRefetch } = useGetPriceListDropdownService({ pageNumber: 1, size: 1000 });
    const { data: businessGroupData, refetch: businessGroupRefetch } = useGetBusinessGroupDropdownService({ pageNumber: 1, size: 1000 });
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const priceLists = (priceListData?.pages[0]?.data?.content || []).map((item: { id?: number, title?: string }) => ({ value: item.id, label: item.title }));
    const businessGroups = (businessGroupData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const countries = COUNTRIES.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }))
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    useEffect(() => {
        priceListRefetch();
        businessGroupRefetch();
    }, []);
    useEffect(() => {
        if (params?.id && priceListData && businessGroupData) {
            if(loading) getByID();
        }
    }, [params, priceListData, businessGroupData]);
    useEffect(() => {
        if(isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getByID = () => {
        StationProfileService.getStationByID(+(params as any)?.id).then((res: GetStationByIdResponse) => {
            if (res.success) {
                reset({
                    ...res?.data || {},
                    priceListId: res.data?.priceListId ? { value: res.data?.priceListId, label: priceLists?.find((v) => v.value === res.data?.priceListId)?.label || res.data?.priceListId } : null,
                    businessGroupId: res.data?.businessGroupId ? { value: res.data?.businessGroupId, label: businessGroups.find((v) => v.value === res.data?.businessGroupId)?.label || res.data?.businessGroupId } : null,
                    country: res.data?.country ? { value: res.data?.country, label: countries.find((v) => v.value === res.data?.country)?.label } : null,
                });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if (params?.id) {
            StationProfileService.updateStation(+params?.id, {
                ...data,
                priceListId: data.priceListId?.value ? data.priceListId?.value : 0,
                businessGroupId: data.businessGroupId?.value ? data.businessGroupId?.value : 0,
                country: data.country?.value ? data.country?.value : null,
            }).then((response: any) => {
                if (response.success) {
                    getByID();
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Station') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            StationProfileService.createStation({
                ...data,
                priceListId: data.priceListId?.value ? data.priceListId?.value : 0,
                businessGroupId: data.businessGroupId?.value ? data.businessGroupId?.value : 0,
                country: data.country?.value ? data.country?.value : null,
            }).then((response: any) => {
                if (response.success) {
                    setValue('id', response.data.id);
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Station') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    setUploadModal(true);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate('/stations')}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('stations') : t('addStations')}
            icon={<Station height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_Station_CREATE")}
        />
        <div className={`${params?.id ? 'flex' : ''}`}>
            {params?.id ? <StationTabs params={params} selectedIndex={0} /> : null}
            {loading ? <Loader /> : <SimpleModal
                visable={true}
                title={params?.id ? t('stations') : t('addStations')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={<>
                    <Grid container component="form" id="form" className="items-center" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("stationCode", { validate: { required: (value) => value?.trim() !== '' || t('This field is required.'), } })}
                                label={t('stationCode') + ' *'}
                                fullWidth
                                error={!!errors.stationCode}
                                helperText={errors.stationCode?.message as string}
                                InputLabelProps={{ shrink: true }}           
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("name", { validate: { required: (value) => value?.trim() !== '' || t('This field is required.'), } })}
                                label={`${!primaryLanguage ? t('primary') : ''} ${t('stationName')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                fullWidth
                                error={!!errors.name}
                                helperText={errors.name?.message as string}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (<InputAdornment position="end">
                                        <Group onClick={() => setShowSecTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                    </InputAdornment>)
                                }}
                                sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                            />
                            {showSecTitleField && <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                    <TextField
                                        {...register("secondaryName")}
                                        label={`${!secondaryLanguage ? t('secondary') : ''} ${t('stationName')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                        fullWidth
                                        error={!!errors.secondaryName}
                                        helperText={errors.secondaryName?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type="number"
                                {...register("numberOfTerminal")}
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    e.target.value = value < 0 ? 0 : value || "";
                                    if(!IsBtnEnabled) setIsBtnEnabled(true);
                                }}
                                label={t("numberOfTerminal")}
                                fullWidth
                                error={!!errors.numberOfTerminal}
                                helperText={errors.numberOfTerminal?.message as string}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="priceListId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <DynamicSelect
                                        label={t('priceList')}
                                        {...field}
                                        options={priceLists}
                                        isSearchable
                                        isClearable
                                        onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                        error={errors.priceListId?.message as string}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="businessGroupId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <DynamicSelect
                                        label={t('businessGroup')}
                                        {...field}
                                        options={businessGroups}
                                        isSearchable
                                        isClearable
                                        onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                        error={errors.businessGroupId?.message as string}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type="number"
                                {...register("target", { required: t('This field is required.') })}
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    e.target.value = value < 0 ? 0 : value || "";
                                    if(!IsBtnEnabled) setIsBtnEnabled(true);
                                }}
                                label={t("annualSalesTarget") + ' *'}
                                fullWidth
                                error={!!errors.target}
                                helperText={errors.target?.message as string}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("taxNumber")}
                                label={t('taxNumber')}
                                fullWidth
                                error={!!errors.taxNumber}
                                helperText={errors.taxNumber?.message as string}
                                InputLabelProps={{ shrink: true }}                   
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type="text"
                                {...register("phoneNumber1", {
                                    validate: {
                                        phoneLength: (value) =>
                                            value === '' || (value?.length >= 5 && value?.length <= 17) || t('phoneLength'),
                                        nonNegative: (value) =>
                                            value === '' || /^\+?[\p{N}]+$/u.test(value) || t('onlyPositiveNumbers'),
                                    },
                                })}
                                label={t("phoneNumber1")}
                                fullWidth
                                error={!!errors.phoneNumber1}
                                helperText={errors.phoneNumber1?.message as string}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type="text"
                                {...register("phoneNumber2", {
                                    validate: {
                                        phoneLength: (value) =>
                                            value === '' || (value?.length >= 5 && value?.length <= 17) || t('phoneLength'),
                                        nonNegative: (value) =>
                                            value === '' || /^\+?[\p{N}]+$/u.test(value) || t('onlyPositiveNumbers'),
                                    },
                                })}
                                label={t("phoneNumber2")}
                                fullWidth
                                error={!!errors.phoneNumber2}
                                helperText={errors.phoneNumber2?.message as string}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                type="email"
                                {...register("email", { 
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: t('validEmail')
                                    }
                                })}
                                placeholder={t("email")}
                                label={t("email")}
                                fullWidth
                                error={!!errors.email} 
                                helperText={errors.email?.message as string} 
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("website")}
                                label={t('website')}
                                fullWidth
                                error={!!errors.website}
                                helperText={errors.website?.message as string}
                                InputLabelProps={{ shrink: true }}                   
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("googleListingAddress")}
                                label={t('googleListingAddress')}
                                fullWidth
                                error={!!errors.googleListingAddress}
                                helperText={errors.googleListingAddress?.message as string}
                                InputLabelProps={{ shrink: true }}                   
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("description")}
                                label={t('description')}
                                fullWidth
                                error={!!errors.description}
                                helperText={errors.description?.message as string}
                                InputLabelProps={{ shrink: true }}
                                multiline
                                rows={1}
                                sx={{
                                    '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                    '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("extraNotes")}
                                label={t('extraNotes')}
                                fullWidth
                                error={!!errors.extraNotes}
                                helperText={errors.extraNotes?.message as string}
                                InputLabelProps={{ shrink: true }}
                                multiline
                                rows={1}
                                sx={{
                                    '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                    '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="checkTyre"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (<div className="relative top-[10px]">
                                    <CommonCheckbox
                                        {...field}
                                        label={t("Check Tyre")}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                        checked={field.value}
                                        color="success"
                                    />
                                </div>)}
                            />
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12} className='px-4'>
                                <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('address')}</h1>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("postalCode")}
                                    label={t('postalCode')}
                                    fullWidth
                                    error={!!errors.postalCode}
                                    helperText={errors.postalCode?.message as string}
                                    InputLabelProps={{ shrink: true }}                   
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("buildingNumber")}
                                    label={t('buildingNumber')}
                                    fullWidth
                                    error={!!errors.buildingNumber}
                                    helperText={errors.buildingNumber?.message as string}
                                    InputLabelProps={{ shrink: true }}                   
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("street")}
                                    label={t('street')}
                                    fullWidth
                                    error={!!errors.street}
                                    helperText={errors.street?.message as string}
                                    InputLabelProps={{ shrink: true }}                   
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("location")}
                                    label={t('location')}
                                    fullWidth
                                    error={!!errors.location}
                                    helperText={errors.location?.message as string}
                                    InputLabelProps={{ shrink: true }}                   
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("region")}
                                    label={t('region')}
                                    fullWidth
                                    error={!!errors.region}
                                    helperText={errors.region?.message as string}
                                    InputLabelProps={{ shrink: true }}                   
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("city")}
                                    label={t('city')}
                                    fullWidth
                                    error={!!errors.city}
                                    helperText={errors.city?.message as string}
                                    InputLabelProps={{ shrink: true }}                   
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="country"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t('country')}
                                            {...field}
                                            options={countries}
                                            isSearchable
                                            isClearable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.country?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("address")}
                                    label={t('address')}
                                    fullWidth
                                    error={!!errors.address}
                                    helperText={errors.address?.message as string}
                                    InputLabelProps={{ shrink: true }}                   
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("latitude")}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        e.target.value = value < 0 ? 0 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t("latitude")}
                                    fullWidth
                                    error={!!errors.latitude}
                                    helperText={errors.latitude?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("longitude")}
                                    onChange={(e: any) => {
                                        const value = e.target.value;
                                        e.target.value = value < 0 ? 0 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t("longitude")}
                                    fullWidth
                                    error={!!errors.longitude}
                                    helperText={errors.longitude?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12} className='px-4'>
                                <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('others')}</h1>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    {...register("termAndCondition")}
                                    label={t('Terms & Conditions') + ' (' + t('primary') + ')'}
                                    fullWidth
                                    error={!!errors.termAndCondition}
                                    helperText={errors.termAndCondition?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    rows={3}
                                    sx={{
                                        '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                        '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    {...register("termAndConditionSecondary")}
                                    label={t('Terms & Conditions') + ' (' + t('secondary') + ')'}
                                    fullWidth
                                    error={!!errors.termAndConditionSecondary}
                                    helperText={errors.termAndConditionSecondary?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    rows={3}
                                    sx={{
                                        '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                        '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {params?.id ? <Grid container rowSpacing={2} className='my-[0px] ml-[3px]'>
                        <Grid item xs={12} md={4} lg={3}>
                            <Typography className="font-Saira font-[600] text-[14px] text-txt-color text-start">
                                {t('Upload') + ' ' + t('station') + ' ' + t('logo')}
                            </Typography>
                            <StationMedia formData={getValues()} method="update" callback={(url: string) => setValue('imgUrl', url)} setIsBtnEnabled={setIsBtnEnabled} />
                            <img src={watch("imgUrl") || ''} className='w-[70px] mt-2' alt='' />
                        </Grid>
                    </Grid> : null}
                    {uploadModal && <FormDialog
                        open={uploadModal}
                        onClose={(event: any, reason: any) => { if(event && (reason !== 'backdropClick')) { navigate('/stations'); }}}
                        title={t('Upload') + ' ' + t('station') + ' ' + t('logo')}
                        createPopup={true}
                        hideActions={true}
                        content={<StationMedia formData={getValues()} method="create" />}
                    />}
                </>}
            />}
        </div>
    </>)
}