import { selectUserInfo } from "features/user/userSlice";
import { Grid, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TaxProcedureProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as TaxProcedure } from "assets/SideBarIcons/tax-procedure.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetDefaultTaxProcedureResponse } from "subModule/src/services/taxProcedure/interface";
import Loader from "layout/Loader";
import DynamicSelect from "common/Select/Select";

interface FormData { [key: string]: any;}

interface TaxProcedureProps {
    setAddNew?: any;
    taxProceduresRefetch?: any;
}
export default function TaxProcedureModal(props: TaxProcedureProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, control, formState: { errors, dirtyFields, isDirty }, reset } = useForm<FormData>({defaultValues: {}});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.id && !props?.setAddNew ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id && !props?.setAddNew ? false : true);
    const taxProcedures = [
        { value: 1, label: 'Normal' },
        { value: 2, label: 'Part Default' },
        { value: 3, label: 'Service Default' },
        { value: 4, label: 'Package Default' },
    ];
    useEffect(() => {
        if(params?.id && !props?.setAddNew) {
            if(loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
      }, [isDirty, dirtyFields]);
    const getByID = () => {
        TaxProcedureProfileService.getTaxProcedureById(+(params as any)?.id).then((res: GetDefaultTaxProcedureResponse) => {
            if(res.success) {
                reset({ ...res?.data || {}, type: { value: res.data?.type, label: taxProcedures.find((v) => v.value === res.data?.type)?.label || res?.data?.type }, });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if(params?.id && !props?.setAddNew) {
            TaxProcedureProfileService.updateTaxProcedure(+params?.id, {...data, type: data.type.value}).then((response: any) => {
                if(response.success) {
                    navigate(-1);
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('taxProcedure') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            TaxProcedureProfileService.createTaxProcedure({...data, type: data.type.value}).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('taxProcedure') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    if(props?.setAddNew) {
                        props?.setAddNew('');
                        props.taxProceduresRefetch();
                    } else navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        {props?.setAddNew ? null : <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate(-1)}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id && !props?.setAddNew ? t('editTaxProcedure') : t('addTaxProcedure')}
            icon={<TaxProcedure height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_TAXPROCEDURE_CREATE")}
        />}
        <div className={`${params?.id && !props?.setAddNew ? 'flex' : ''}`}>
            {loading ? <Loader /> : <SimpleModal
                visable={true}
                title={params?.id && !props?.setAddNew ? t('editTaxProcedure') : t('addTaxProcedure')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id && !props?.setAddNew ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                entityPopup={props?.setAddNew}
                padding={!props?.setAddNew}
                content={ 
                    <Grid container component="form" id="form" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                {...register("title", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                fullWidth
                                error={!!errors.title} 
                                helperText={errors.title?.message as string} 
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="type"
                                control={control}
                                defaultValue={null}
                                rules={{ required: t('This field is required.') }}
                                render={({ field }) => (
                                    <DynamicSelect
                                        label={t("type") + ' *'}
                                        {...field}
                                        options={taxProcedures}
                                        isClearable
                                        isSearchable
                                        onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                        error={errors.type?.message as string}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type="number"
                                {...register("percentageValue", { required: t('This field is required.') })}
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    e.target.value = value < 0 ? 0 : value || "";
                                    if(!IsBtnEnabled) setIsBtnEnabled(true);
                                }}
                                label={t('percentageValue') + ' *'}
                                fullWidth
                                error={!!errors.percentageValue}
                                helperText={errors.percentageValue?.message as string}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                }
            />}
        </div>
    </>)
}