import { Box, Button, Divider, Grid, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService } from "subModule/src/core/services";
import { PurchaseOrderResponse, SaveInvoice, SaveInvoiceResponse } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import { DeleteData } from "subModule/src/type";
import { useGetServiceService } from "subModule/src/services/service/useService";
import { InventoryServicesTable } from "components/Inventory/InventoryServicesTable";
import BasicDatePicker from "common/Pickers/Pickers";
import { useGetStoreService } from "subModule/src/services/store/useStore";
import { useGetVendorService } from "subModule/src/services/vendor/useVendor";
import DynamicSelect from "common/Select/Select";
import { useGetInventoryService } from "subModule/src/services/inventory/useInventory";
import dayjs from "dayjs";

interface FormData {
    [key: string]: any;
}

const PurchasePaymentCreateUpdate: React.FC = () => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField, reset} = useForm<FormData>({defaultValues: {
        purchaseInvoiceItem: [],
        purchaseInvoiceService: [],
        invoiceDate: dayjs(new Date())?.format('YYYY-MM-DD'),
    }});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 5000 });
    const { data: servicesData, refetch: servicesRefetch } = useGetServiceService({ pageNumber: 1, size: 500 });
    const { data: vendorsData, refetch: vendorsRefetch } = useGetVendorService({ pageNumber: 1, size: 1000 });
    const { data: storesData, refetch: storesRefetch } = useGetStoreService({ pageNumber: 1, size: 1000 });
    const { data: poData, refetch: poRefetch } = useGetInventoryService({ pageNumber: 1, size: 500 });
    const [purchaseInvoiceItem, setPurchaseInvoiceItem] = useState<any[]>([]);
    const [purchaseInvoiceService, setPurchaseInvoiceService] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [invoice, setInvoice] = useState<SaveInvoice>();

    useEffect(() => {
        vendorsRefetch();
        storesRefetch();
        itemsRefetch();
        servicesRefetch();
        poRefetch();
        if(params?.id) {
            if(loading) getInvoiceById();
        }
    }, [params]);
    const getInvoiceById = () => {
        InventoryProfileService.getEntityById(params?.id, 'purchaseinvoice').then((res: any) => {
            if(res.success) {
                setLoading(false);
                setInvoice((res.data as any));
                setPurchaseInvoiceItem((res.data as any)?.purchaseInvoiceItem || []);
                setValue('purchaseInvoiceItem', res.data.purchaseInvoiceItem || []);
                setPurchaseInvoiceService((res.data as any)?.purchaseInvoiceService || []);
                setValue('purchaseInvoiceService', res.data?.purchaseInvoiceService || []);
                setValue('storeId', res.data?.storeId);
                setValue('id', params?.id);
                setValue('invoiceIndex', res.data?.invoiceIndex);
                setValue('invoiceNumber', res.data?.invoiceNumber);
                setValue('invoiceDate', res.data?.invoiceDate?.split(' ')[0]);
                setValue('dueDate', res.data?.dueDate?.split(' ')[0] || null);
                setValue('comments', res.data?.comments);
                setValue('supplierId', res.data?.supplierId);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const getPOById = (id: string | undefined) => {
        InventoryProfileService.getPurchaseOrderById(id).then((res: any) => {
            if(res.success) {
                setInvoice(res.data);
                setPurchaseInvoiceItem(res.data?.purchaseOrderItems || []);
                setValue('purchaseInvoiceItem', res.data.purchaseOrderItems || []);
                setPurchaseInvoiceService(res.data?.purchaseOrderSvc || []);
                setValue('purchaseInvoiceService', res.data?.purchaseOrderSvc || []);
                setValue('storeId', {value: res.data?.storeId, label: res.data?.storeTitle});
                setValue('supplierId', {value: res.data?.supplierId, label: res.data?.vendorTitle});
                setValue('comments', res.data?.poMemo);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const supplierOptions = (vendorsData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const storeOptions = (storesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const poOptions = (poData?.pages[0]?.data?.content || [])?.map((item: any) => ({ value: item.id, label: item.poNumber }));
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'supplierSKU', label: t('SKU'), numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitTitle' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const columnsService = [
        { id: 'serviceId' },
        { id: 'serviceCode'},
        { id: 'serviceName', label: t('service') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const DeleteItem = (row: any, typeName: string) => {
        if(typeName === "purchaseInvoiceItem" && params?.id) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'purchaseinvoiceitems').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getInvoiceById();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else if(typeName === "purchaseInvoiceItem" && !params?.id) {
            setValue('purchaseInvoiceItem', purchaseInvoiceItem.filter((rows) => rows.id !== row.id));
            setPurchaseInvoiceItem((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        } 
        if(typeName === "purchaseInvoiceService" && params?.id) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'purchaseinvoiceservices').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getInvoiceById();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else if(typeName === "purchaseInvoiceService" && !params?.id) {
            setValue('purchaseInvoiceService', purchaseInvoiceService.filter((rows) => rows.id !== row.id));
            setPurchaseInvoiceService((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        }
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "purchaseInvoiceItem") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!params?.id) (newItem as any).id = Date.now();
            setPurchaseInvoiceItem((prevRows) => [...prevRows, newItem]);
        } else {
            const newService = columnsService.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!params?.id) (newService as any).id = Date.now();
            setPurchaseInvoiceService((prevRows) => [...prevRows, newService]);
        }
    };
    const handleItem = (row: any, typeName: string, action: string) => {
        if(typeName === "purchaseInvoiceItem" && params?.id) {
            if(action === 'create') {
                InventoryProfileService.createPurchaseInvoiceItem({
                    ...row, purchaseInvoiceId: invoice?.id, storeId: invoice?.storeId
                }).then((response: SaveInvoiceResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Payment Item') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getInvoiceById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getInvoiceById();
                });
            } else {
                InventoryProfileService.updatePurchaseInvoiceItem({
                    ...row, purchaseInvoiceId: invoice?.id, storeId: invoice?.storeId
                }).then((response: SaveInvoiceResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Payment Item') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getInvoiceById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getInvoiceById();
                });
            }
        } else if(typeName === "purchaseInvoiceService" && params?.id) {
            if(action === 'create') {
                InventoryProfileService.createPurchaseInvoiceService({...row, purchaseInvoiceId: invoice?.id, storeId: invoice?.storeId }).then((response: SaveInvoiceResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Purchase Order Service') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getInvoiceById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getInvoiceById();
                });
            } else {
                InventoryProfileService.updatePurchaseInvoiceService({...row, purchaseInvoiceId: invoice?.id, storeId: invoice?.storeId }).then((response: SaveInvoiceResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Purchase Order Service') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getInvoiceById();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getInvoiceById();
                });
            }
        }
    }

    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "purchaseInvoiceItem") {
            const updatedItems = [...purchaseInvoiceItem];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setPurchaseInvoiceItem(updatedItems);
        } else {
            const updatedServices = [...purchaseInvoiceService];
            updatedServices[index][columnId] = value;
            setPurchaseInvoiceService(updatedServices);
        }
    };
    const onSubmit = (data: FormData) => {
        if(Object.values(data)?.length) {
            const filteredItems = purchaseInvoiceItem.filter((item: any) => item?.itemName);
            const filteredServices = purchaseInvoiceService.filter((service: any) => service?.serviceName);
            if(filteredItems.length === 0) {
                enqueueSnackbar(<AlertMessages text="Please add at least one item" />, { variant: 'error' });
                return;
            }
            filteredItems.every((row:any) => delete row.total && delete row.id && row.unitTitle);
            filteredServices.every((row:any) => delete row.total && delete row.id);
            InventoryProfileService.saveStockInvoice({
                ...data, storeId: data.storeId.value, supplierId: data.supplierId.value, poId: data.poId?.value || null,
                purchaseInvoiceItem: filteredItems, purchaseInvoiceService: filteredServices
            }).then((response: SaveInvoiceResponse) => {
                if(response.success) {
                    InventoryProfileService.changeStatusEntity({typeName: 'purchaseinvoice', entityId: response?.data?.id, status: 2}).then((res: any) => {
                        if(res.success) {
                            enqueueSnackbar(<AlertMessages statusCode={res.status} text={t('Payment') + ' ' + t('saved') + ' ' + t('successfully')} />, { variant: 'success' });
                            navigate('/inventory/purchase-payments');
                        } else {
                            enqueueSnackbar(<AlertMessages text={res?.message} />, { variant: 'error' });
                        }
                    }).catch((err:any) => { 
                        console.error("===", err);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                } else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };
    const calculateTotals = () => {
        const itemTotals = purchaseInvoiceItem.reduce((acc, item) => {
            return {
                unitPrice: parseFloat(acc.unitPrice) + parseFloat(item.unitPrice || 0),
                discount: parseFloat(acc.discount) + parseFloat(item.discount || 0),
                subTotal: parseFloat(acc.subTotal) + parseFloat(item.unitPrice || 0) * parseInt(item.quantity || 0),
                total: parseFloat(acc.total) + ((parseFloat(item.unitPrice || 0) * parseFloat(item.quantity) || 0)) - parseFloat(item.discount || 0),
            };
        }, { unitPrice: 0, discount: 0, subTotal: 0, total: 0 });
    
        const serviceTotals = purchaseInvoiceService?.reduce((acc, service) => {
            return {
                unitPrice: parseFloat(acc?.unitPrice) + parseFloat(service?.unitPrice || 0),
                discount: parseFloat(acc?.discount) + parseFloat(service?.discount || 0),
                subTotal: parseFloat(acc?.subTotal) + parseFloat(service?.unitPrice || 0) * parseInt(service?.quantity || 0),
                total: parseFloat(acc?.total) + ((parseFloat(service?.unitPrice || 0) * parseFloat(service?.quantity) || 0)) - parseFloat(service?.discount || 0),
            };
        }, { unitPrice: 0, discount: 0, subTotal: 0, total: 0 });
    
        return {
            unitPrice: itemTotals.unitPrice + serviceTotals?.unitPrice,
            discount: itemTotals.discount + serviceTotals?.discount,
            subTotal: itemTotals.subTotal + serviceTotals?.subTotal,
            total: itemTotals.total + serviceTotals?.total,
        };
    };
    const totals = calculateTotals();

    return (
        loading ? <Loader /> : <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{params?.id ? t('Payment') : t('New Payment')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/purchase-payments')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            {params?.id ? <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{t('Payment')}</Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Payment')} # </span> <span>{invoice?.invoiceNumber || ''}</span></Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Date')}</span> <span>{invoice?.invoiceDate?.split('T')[0] || ''}</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Supplier')} {t('name')}</span> <br /><span className="font-medium">{invoice?.vendorTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('store') + ' ' + t('name')}</span> <br /><span className="font-medium">{invoice?.storeTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Requested')}</span> <br /><span className="font-medium">{invoice?.totalQuantity || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Received')}</span> <br /><span className="font-medium">{invoice?.totalReceivedQuantity || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Subtotal')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((invoice as PurchaseOrderResponse)?.subtotalAmount || 0) || 0}</span></Typography>
                        {/* <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">Tax</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((invoice as PurchaseOrderResponse)?.taxAmount || 0) || 0}</span></Typography> */}
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Discount')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((invoice as PurchaseOrderResponse)?.discount || 0) || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Total')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((invoice as PurchaseOrderResponse)?.totalAmount || 0) || 0}</span></Typography>
                    </div>
                </Grid>
            </Grid> : null}
            {params?.id ? null : 
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="poId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <DynamicSelect
                                label="Purchase Orders"
                                {...field}
                                options={poOptions}
                                isSearchable
                                isRequired={false}
                                onChange={(selectedOption: any) => {
                                    field.onChange(selectedOption);
                                    reset({ poId: selectedOption }, { keepValues: true });
                                    setPurchaseInvoiceItem([]);
                                    setPurchaseInvoiceService([]);
                                    getPOById(selectedOption.value);
                                }}
                                error={errors.poId?.message as string}
                            />
                        )}
                    />
                </Grid>}
            <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('Payment')} {t("Details")}</Typography></Grid>
            <Grid container item xs={12} gap={2} className="flex-nowrap">
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="invoiceDate"
                        control={control}
                        rules={{ validate: (value) => {
                            const date = new Date(value);
                            return isNaN(date.getTime()) ? t("Invalid Date") : true;
                        }}}
                        render={({ field }) => (
                            <BasicDatePicker
                                type="datePicker"
                                {...field}
                                label={t('Payment') + ' ' + t('Date')}
                                fullWidth
                                onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                required={true}
                                error={errors.invoiceDate?.message as string}
                                disable={params?.id}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="dueDate"
                        control={control}
                        rules={{ validate: (value) => {
                            const date = new Date(value);
                            return isNaN(date.getTime()) && value ? t("Invalid Date") : true;
                        }}}
                        render={({ field }) => (
                            <BasicDatePicker
                                type="datePicker"
                                {...field}
                                label={t('Due') + ' ' + t('Date')}
                                fullWidth
                                onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                required={false}
                                error={errors.dueDate?.message as string}
                                disable={params?.id}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="supplierId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('vendors')}
                                {...field}
                                options={params?.id ? [{ value: invoice?.supplierId, label: invoice?.vendorTitle }] : supplierOptions}
                                isSearchable
                                isRequired
                                onChange={(selectedOption: any) => {field.onChange(selectedOption);}}
                                error={errors.supplierId?.message as string}
                                value={params?.id ? { value: invoice?.supplierId, label: invoice?.vendorTitle } : watch('supplierId')}
                                isDisabled={params?.id ? true : false}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="storeId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('stores')}
                                {...field}
                                options={params?.id ? [{ value: invoice?.storeId, label: invoice?.storeTitle }] : storeOptions}
                                isSearchable
                                isRequired
                                onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                error={errors.storeId?.message as string}
                                value={params?.id ? { value: invoice?.storeId, label: invoice?.storeTitle } : watch('storeId')}
                                isDisabled={params?.id ? true : false}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Box className="bg-white py-[12px] px-[18px] rounded-[8px]">
                        <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">Payment</Typography>
                        <InventoryItemsTable form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} columns={columns} handleInputChange={handleInputChange} rows={purchaseInvoiceItem} typeName="purchaseInvoiceItem" items={itemsData?.pages[0]?.data?.content || []} currency={loggedInUserCurrency} DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={params?.id ? 2 : 1} viewDelete={params?.id ? false : true} />
                        <Grid item xs={12} sm={6} className="hide-on-print">
                            <div className="flex gap-2 justify-start">
                                <Button className="secondary flex gap-1" disabled={params?.id ? true : false} variant="contained" disableElevation onClick={() => addNewItem('purchaseInvoiceItem')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                                {/* <Button className="secondary" variant="contained" disableElevation>{t('Import')}</Button>
                                <Button className="secondary" variant="contained" disableElevation>{t("Export")}</Button> */}
                            </div>
                        </Grid>
                    </Box>
                    <Box className="flex gap-2 justify-start mt-[16px]">
                        <Button className="secondary cursor-text" variant="contained" disableElevation>{t('Additional Costs')}</Button>
                    </Box>
                    <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
                        <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('Additional Cost')}</Typography>
                        <InventoryServicesTable form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} columns={columnsService} handleInputChange={handleInputChange} rows={purchaseInvoiceService} typeName="purchaseInvoiceService" services={servicesData?.pages[0]?.data?.content || []} currency={loggedInUserCurrency}  DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={params?.id ? 2 : 1} viewDelete={params?.id ? false : true} />
                        <Grid item xs={12} sm={6} className="hide-on-print">
                            <div className="flex gap-2 justify-start">
                                <Button className="secondary flex gap-1" disabled={params?.id ? true : false} variant="contained" disableElevation onClick={() => addNewItem('purchaseInvoiceService')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}><Divider className="bg-[#AEB3C4] mt-[15px] mb-[8px]" /></Grid>
                    </Box>
                </Grid>
            </Grid>
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left mb-[-5px]">{t("Comments")}</Typography>
            <Grid container xs={12} spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField 
                        {...register("comments")} 
                        placeholder={t("Comments")} 
                        fullWidth
                        error={!!errors.comments} 
                        helperText={errors.comments?.message as string}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        sx={{ '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%' }}}
                        disabled={params?.id ? true : false}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className="bg-[#D8D1E0] rounded-[8px]" sx={{ border: '1px solid #5f6770', width: 'calc(100% + 8px)', padding: '8px 14px' }}>
                        <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Subtotal')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(totals.subTotal || (invoice as PurchaseOrderResponse)?.subtotalAmount || 0)}</Typography>
                        </Box>
                        {/* <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">Tax</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format((invoice as PurchaseOrderResponse)?.taxAmount || 0)}</Typography>
                        </Box> */}
                        <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Discount')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(!params?.id ? totals.discount : (invoice as PurchaseOrderResponse)?.discount || 0)}</Typography>
                        </Box>
                        <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[auto]">{t('Total')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[20%] text-end"><span className="uppercase">({loggedInUserCurrency})</span> {new Intl.NumberFormat('en-US', {}).format(totals.total || (invoice as PurchaseOrderResponse)?.totalAmount || 0)}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/purchase-payments')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PurchasePaymentCreateUpdate;
