// PriceListService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignItemRequest,
  AssignPackageRequest,
  AssignServiceRequest,
  AssignUnassignResponse,
  ChangeStatusPriceListResponse,
  DeletePriceListResponse,
  GetPriceListDropdownResponse,
  GetPriceListItemResponse,
  GetPriceListPackageResponse,
  GetPriceListResponse,
  GetPriceListServiceResponse,
  GetPricelistByIdResponse,
  PriceListRequest,
  PriceListResponse,
  UnassignItemRequest,
  UnassignPackageRequest,
  UnassignServiceRequest,
} from './interface';

export class PriceListProfile extends BaseService {
  async createPriceList(req: PriceListRequest) {
    return this.post<PriceListResponse, PriceListRequest>(`${this.apiUrl}/api/pricelists`, req);
  }

  async getPriceList(params: PaginationParams) {
    return this.get<GetPriceListResponse>(`${this.apiUrl}/api/pricelists/paged`, params);
  }

  async getPriceListDropdown(params: PaginationParams) {
    return this.get<GetPriceListDropdownResponse>(`${this.apiUrl}/api/pricelists/get`, params);
  }

  async updatePriceList(id: number, req: PriceListRequest) {
    return this.put<PriceListResponse, PriceListRequest>(`${this.apiUrl}/api/pricelists/${id}`, req);
  }

  async deletePriceList(id: number) {
    return this.delete<DeletePriceListResponse>(`${this.apiUrl}/api/pricelists/${id}`);
  }

  async changeStatusPriceList(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusPriceListResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/pricelists/${active}/${id}`,
      req
    );
  }

  async getPriceListPackage(params: PaginationParams & { pricelistId: number }) {
    return this.get<GetPriceListPackageResponse>(`${this.apiUrl}/api/packages/bypricelistid`, params);
  }

  async assignPackage(req: AssignPackageRequest) {
    return this.post<AssignUnassignResponse, AssignPackageRequest>(`${this.apiUrl}/api/packages/pricelist/assign`, req);
  }

  async unassignPackage(req: UnassignPackageRequest) {
    return this.put<AssignUnassignResponse, UnassignPackageRequest>(
      `${this.apiUrl}/api/packages/pricelist/unassign`,
      req
    );
  }

  async getPriceListService(params: PaginationParams & { pricelistId: number }) {
    return this.get<GetPriceListServiceResponse>(`${this.apiUrl}/api/services/bypricelistid`, params);
  }

  async assignService(req: AssignServiceRequest) {
    return this.post<AssignUnassignResponse, AssignServiceRequest>(`${this.apiUrl}/api/services/pricelist/assign`, req);
  }

  async unassignService(req: UnassignServiceRequest) {
    return this.put<AssignUnassignResponse, UnassignServiceRequest>(
      `${this.apiUrl}/api/services/pricelist/unassign`,
      req
    );
  }

  async getPriceListItem(params: PaginationParams & { pricelistId: number }) {
    return this.get<GetPriceListItemResponse>(`${this.apiUrl}/api/items/bypricelistid`, params);
  }

  async assignItem(req: AssignItemRequest) {
    return this.post<AssignUnassignResponse, AssignItemRequest>(`${this.apiUrl}/api/items/pricelist/assign`, req);
  }

  async unassignItem(req: UnassignItemRequest) {
    return this.put<AssignUnassignResponse, UnassignItemRequest>(`${this.apiUrl}/api/items/pricelist/unassign`, req);
  }

  async updatePriceListItem(req: AssignItemRequest) {
    return this.put<AssignUnassignResponse, AssignItemRequest>(`${this.apiUrl}/api/items/pricelist/update`, req);
  }

  async updatePriceListService(req: AssignServiceRequest) {
    return this.put<AssignUnassignResponse, AssignServiceRequest>(`${this.apiUrl}/api/services/pricelist/update`, req);
  }

  async updatePriceListPackage(req: AssignPackageRequest) {
    return this.put<AssignUnassignResponse, AssignPackageRequest>(`${this.apiUrl}/api/packages/pricelist/update`, req);
  }

  async getPricelistById(id: number) {
    return this.get<GetPricelistByIdResponse>(`${this.apiUrl}/api/pricelists/${id}`);
  }
}
