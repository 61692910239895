import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useNavigate } from "react-router-dom";

interface tabProps {
    params: any;
    selectedIndex: number;
}
export default function Roletabs(props: tabProps) {
    const navigate = useNavigate();
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) navigate(`/role/${props.params?.id}`);
        else if (tabId === 1) navigate(`/role/assign/privilege/${props.params?.id}`);
    }
    const tabs = [
        { id: 0, title: `edit`, child: <></> },
        { id: 1, title: `Privileges`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
              return !checkPrivileges('ROLE_ROLE_UPDATE');
            case 1:
              return !checkPrivileges('ROLE_ROLE_PRIVILEGE_ASSIGN') && !checkPrivileges('ROLE_ROLE_PRIVILEGE_UNASSIGN');
            default:
              return false;
        }
    });

    return (<>
        <VerticalTabs tabs={tabs} selectedIndex={props.selectedIndex} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
    </>);
}