import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
// import DynamicSelect from 'common/Select/Select';
interface ChecklistItemModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleSecField: () => void;
  showIFISecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
}

const ChecklistItemModalComponent: React.FC<ChecklistItemModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  enableEdit,
  handleSecField, 
  errorMessages,
  isBtnEnabled,
}) => {
  // const valueOptions = [
  //   { value: 1, label: 'Textfield' },
  //   { value: 2, label: 'Radio' },
  //   { value: 3, label: 'Checkbox' },
  //   { value: 4, label: 'Text Area' },
  //   { value: 5, label: 'Date' },
  //   { value: 6, label: 'Dropdown' },
  // ];

  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateVehicleInspection" : "addVehicleInspection"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`name`}
                handleSecField={handleSecField}
                placeholder="Write your type here"
                handleChange={(e) => handleChange('type', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.type}
                value={formData?.type || ''}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? valueOptions.find((option: any) => option.value === parseInt(formData?.value)) : null}
                value={valueOptions.find((option: any) => option.value === parseInt(formData?.value))}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="color"
                options={valueOptions}
                // onScroll={handleScroll}
                // onInputChange={handleSearch}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('value', e)}
                error={errorMessages?.valueError}
                label="Value"
              />
            </Grid> */}
          </Grid>
        }
      />
    </div>
  );
};

export default ChecklistItemModalComponent;