import DynamicSelect from "common/Select/Select";
import { Button, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";

interface FormData { [key: string]: any;}
interface PlateNumberProps {
    handleChange?: (field: string, value: any) => void;
    setOpenPlateNumberDetails?: any;
    formData?: any;
}
export default function PlateNumberModal(props: PlateNumberProps) {
    const { t } = useTranslation();
    const statesList = [
        { label: 'Abu Dhabi', value: 'Abu Dhabi',},
        { label: 'Dubai', value: 'Dubai',},
        { label: 'Sharjah', value: 'Sharjah',},
        { label: 'Ajman', value: 'Ajman',},
        { label: 'Umm Al Quwain', value: 'Umm Al Quwain',},
        { label: 'Ras Al Khaimah', value: 'Ras Al Khaimah',},
        { label: 'Fujairah', value: 'Fujairah',},
    ];
    const { register, control, handleSubmit, formState: { errors }, watch, resetField } = useForm<FormData>({defaultValues: {
        plateRegion: props?.formData?.plateRegion || 1,
        state: props?.formData?.plateRegion == 1 && props?.formData?.plateNumber.length > 0 ? {label: statesList?.find(state => props?.formData?.plateNumber?.includes(state?.value))?.label, value: statesList?.find(state => props?.formData?.plateNumber?.includes(state?.value))?.value} : null,
        code: props?.formData?.plateNumber?.replace(new RegExp(`(${statesList.map(state => state.value).join('|')})\\s*|\\s*\\d+$`, 'g'), '')?.trim() || '',
        number: props?.formData?.plateNumber?.substring(props?.formData?.plateNumber?.lastIndexOf(' ') + 1) || ''
    }});
    const onSubmit = (data: any) => {
        const fullPlateNumber = watch('plateRegion') === 1 ? data.state.value + ' ' + (data.code as string).replace(/ /g, '') + ' ' + data.number : data.code;
        props?.handleChange?.('plateNumber', fullPlateNumber);
        props?.handleChange?.('plateRegion', data.plateRegion);
        props?.setOpenPlateNumberDetails(false);
    };
    return (
        <Grid container component="form" id="form" className="items-baseline" columnSpacing={3} onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
                <Controller
                    name="plateRegion"
                    control={control}
                    render={({ field }) => (<>
                        <label className="text-left block mb-2">{t('Select Plate Region') + ':'}</label>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" {...field} onChange={(e: any) => {
                            const value = parseInt(e.target.value, 10);
                            field.onChange(value);
                            if (value === 1) { resetField('state'); resetField('number'); }
                        }}>
                            <FormControlLabel value={1} control={<Radio />} label={t('UAE')} sx={{ '& .MuiTypography-root': { position: 'relative', top: '-8px' }, }} />
                            <FormControlLabel value={2} control={<Radio />} label={t('Foreign')} sx={{ '& .MuiTypography-root': { position: 'relative', top: '-8px', whiteSpace: 'nowrap', width: '100px', }, }} />
                        </RadioGroup>
                    </>)}
                />
            </Grid>
            {watch('plateRegion') == 1 ? <Grid item xs={12} md={4}>
                <Controller
                    name="state"
                    control={control}
                    defaultValue={null}
                    rules={{ required: watch("plateRegion") == 1 ? "This field is required." : false }}
                    render={({ field }) => (
                        <DynamicSelect
                            label={t("State") + ' *'}
                            {...field}
                            options={statesList}
                            isSearchable
                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                            error={errors.state?.message as string}
                        />
                    )}
                />
            </Grid> : null}
            <Grid item xs={12} md={4}>
                <TextField 
                    {...register("code", { required: "This field is required." })} 
                    placeholder={t('Code')}
                    label={`${t('Code') + ' *'}`}
                    fullWidth
                    error={!!errors.code} 
                    helperText={errors.code?.message as string} 
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            {watch('plateRegion') == 1 ? <Grid item xs={12} md={4}>
                <TextField 
                    type="number"
                    {...register("number", { 
                        required: t('This field is required.'), 
                        min: { value: 0, message: t('Negative numbers not allowed.') }
                    })} 
                    placeholder={t("Number")}
                    label={t("Number") + ' *'}
                    fullWidth
                    error={!!errors.number} 
                    helperText={errors.number?.message as string} 
                    InputLabelProps={{ shrink: true }}
                />
            </Grid> : null}
            <Grid item xs={12}>
                <div className="flex gap-2 justify-end">
                    <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => handleSubmit(onSubmit)}>{t('submit')}</Button>
                </div>
            </Grid>
        </Grid>
    )
}