import { Grid, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { PaymentProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Payment } from "assets/SideBarIcons/money-transfer.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetPaymentByIdResponse, GetPaymentInvoiceResponse, GetPaymentsCreditResponse } from "subModule/src/services/payment/interface";
import Loader from "layout/Loader";
import DynamicSelect from "common/Select/Select";
import BasicDatePicker from "common/Pickers/Pickers";
import { useGetClientsDropdownService } from "subModule/src/services/client/useClient";
import { useGetStatusesService } from "subModule/src/services/statuses/useStatuses";
import CommonCheckbox from "common/CheckBox/Checkbox";
import { PaymentInvoicesTable } from "components/Payment/PaymentInvoicesTable";
import StyledInput from "common/SearchField/Search";

interface FormData { [key: string]: any; }
export default function PaymentModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, control, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, watch, setValue } = useForm<FormData>({
        defaultValues: {
            adjustmentType: { value: 591, label: t('FIFO') },
            invoices: []
        }
    });
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [credit, setCredit] = useState<number>(0);
    const [invoices, setInvoices] = useState<any>([]);
    const [searchInvoice, setSearchInvoice] = useState('');
    const [searchTermInvoice, setSearchTermInvoice] = useState<any>(null);
    const { data: clientsData, refetch: clientsRefetch } = useGetClientsDropdownService({ pageNumber: 1, size: 1000 });
    const { data: paymentModesData, refetch: paymentModesRefetch } = useGetStatusesService({ pageNumber: 1, size: 50 }, 7);
    const clients = (clientsData?.pages[0]?.data?.content || []).map((item: { id?: number, name?: string }) => ({ value: item.id, label: item.name }));
    const paymentModes = (paymentModesData?.pages[0]?.data?.content || []).map((item: any) => ({ value: item.id, label: item.name }));
    const adjustmentTypeOptions = [
        { value: 591, label: t('FIFO') },
        { value: 592, label: t('MANUAL') },
    ];
    const invoicesColumns = [
        { id: 'invoicesId', label: '', numeric: false, disablePadding: false },
        { id: 'invoiceNo', label: t('invoiceNo'), numeric: false },
        { id: 'balanceAmount', label: t('balanceAmount'), numeric: false },
        { id: 'totalAmount', label: t('totalAmount'), numeric: false },
    ]
    useEffect(() => {
        clientsRefetch();
        paymentModesRefetch();
        if (params?.id) {
            if (loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        const getData = setTimeout(async() => {
          if (searchTermInvoice !== null) {
            fetchInvoices(watch('clientId').value, searchTermInvoice)
          }
        }, 300);
        return () => clearTimeout(getData)
      }, [searchTermInvoice]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getByID = () => {
        PaymentProfileService.getPaymentByID(+(params as any)?.id).then((res: GetPaymentByIdResponse) => {
            if (res.success) {
                setTimeout(() => fetchCredit(res?.data?.clientId || 0), 700);
                if (res?.data?.adjustmentType === 592) fetchInvoices(res?.data?.clientId || 0);
                reset({
                    ...res?.data || {},
                    clientId: { value: res.data?.clientId, label: res.data?.clientName },
                    paymentMode: { value: res.data?.paymentMode, label: res?.data?.paymentModeTitle },
                    adjustmentType: { value: res.data?.adjustmentType, label: adjustmentTypeOptions?.find((option: any) => option.value === res?.data?.adjustmentType)?.label || res.data?.adjustmentType },
                });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const fetchCredit = (clientId: number) => {
        PaymentProfileService.getPaymentsCredit(clientId).then((response: GetPaymentsCreditResponse) => {
            if (response.success) {
                setCredit(response?.data || 0);
            } else {
                setLoading(false);
            }
        }).catch((err: any) => {
            console.error("===", err);
            setLoading(false);
        });
    }
    const fetchInvoices = (clientId: number, invoiceNo?: any) => {
        PaymentProfileService.getPaymentsInvoice({ page: 1, size: 1000, clientId: clientId, invoiceNo: invoiceNo }).then((response: GetPaymentInvoiceResponse) => {
            if (response.success) {
                setInvoices(response?.data?.content || []);
            } else {
                setLoading(false);
                enqueueSnackbar(<AlertMessages text={response.message} />, { variant: 'error' });
            }
        }).catch((err: any) => {
            console.error("===", err);
            setLoading(false);
            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
        });
    }
    const clearSearchInvoice = () => {
        setSearchInvoice('');
        setSearchTermInvoice('');
        if (searchTermInvoice) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setSearchTermInvoice(search);
    };
    const handleSearchClick = () => {
        const trimmedSearch = searchInvoice.trim();
        if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
        }
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInvoice(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if (params?.id) {
            PaymentProfileService.updatePayment(+params?.id, {
                ...data,
                clientId: data.clientId.value,
                paymentMode: data.paymentMode.value,
                adjustmentType: data.adjustmentType.value
            }).then((response: any) => {
                if (response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Payment') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
                setIsBtnEnabled(true);
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            PaymentProfileService.createPayment({
                ...data,
                clientId: data.clientId.value,
                paymentMode: data.paymentMode.value,
                adjustmentType: data.adjustmentType.value
            }).then((response: any) => {
                if (response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Payment') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate(-1)}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editPayment') : t('addPayment')}
            icon={<Payment height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_PAYMENT_CREATE")}
        />
        {loading ? <Loader /> : <SimpleModal
            visable={true}
            title={params?.id ? "updatePayment" : "addPayment"}
            isButtonVisable={true}
            isVisableBtn={IsBtnEnabled}
            btnclosetext="Back"
            buttonText={params?.id ? "update" : "submit"}
            attBtnNotshow={false}
            notVisableBackbtn={true}
            showPortList={handleSubmit(onSubmit)}
            formSubmit={handleSubmit(onSubmit)}
            content={
                <Grid container component="form" id="form" className="items-baseline" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="clientId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.') }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('Client') + ' *'}
                                    {...field}
                                    options={clients}
                                    isSearchable
                                    onChange={(selectedOption: any) => {
                                        field.onChange(selectedOption);
                                        setTimeout(() => fetchCredit(selectedOption.value), 700);
                                        if (watch('adjustmentType').value === 592) fetchInvoices(selectedOption.value);
                                    }}
                                    error={errors.clientId?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="paymentMode"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.') }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('PaymentMode') + ' *'}
                                    {...field}
                                    options={paymentModes}
                                    isSearchable
                                    onChange={(selectedOption: any) => { field.onChange(selectedOption); }}
                                    error={errors.paymentMode?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            type="number"
                            {...register("amount", { required: t('This field is required.') })}
                            onChange={(e: any) => {
                                const value = e.target.value;
                                e.target.value = value < 0 ? 0 : value || "";
                                if (!IsBtnEnabled) setIsBtnEnabled(true);
                            }}
                            label={t("amount") + ' *'}
                            fullWidth
                            error={!!errors.amount}
                            helperText={errors.amount?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...register("referenceNo", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })}
                            label={t("referenceNo") + ' *'}
                            fullWidth
                            error={!!errors.referenceNo}
                            helperText={errors.referenceNo?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="adjustmentType"
                            control={control}
                            defaultValue={null}
                            rules={{
                                required: t('This field is required.'),
                                validate: (value) => {
                                    if (value?.value === 592 && watch('invoices')?.length === 0) {
                                        return t('atLeastOneInvoice');
                                    }
                                    return true;
                                }
                            }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('adjustmentType')}
                                    {...field}
                                    options={adjustmentTypeOptions}
                                    isSearchable
                                    onChange={(selectedOption: any) => {
                                        field.onChange(selectedOption);
                                        if (watch('clientId') && selectedOption.value === 592) fetchInvoices(watch('clientId').value);
                                    }}
                                    error={errors.adjustmentType?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="paymentDate"
                            control={control}
                            rules={{
                                validate: (value) => {
                                    const date = new Date(value);
                                    return isNaN(date.getTime()) ? t("Invalid Date") : true;
                                }
                            }}
                            render={({ field }) => (
                                <BasicDatePicker
                                    type="datePicker"
                                    {...field}
                                    label={t('paymentDate') + ' *'}
                                    fullWidth
                                    onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                    required={false}
                                    error={errors.paymentDate?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="recipientDate"
                            control={control}
                            rules={{
                                validate: (value) => {
                                    const date = new Date(value);
                                    return isNaN(date.getTime()) ? t("Invalid Date") : true;
                                }
                            }}
                            render={({ field }) => (
                                <BasicDatePicker
                                    type="datePicker"
                                    {...field}
                                    label={t('recipentDate') + ' *'}
                                    fullWidth
                                    onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                    required={false}
                                    error={errors.recipientDate?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...register("remarks")}
                            label={t('remarks')}
                            fullWidth
                            error={!!errors.remarks}
                            helperText={errors.remarks?.message as string}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            rows={1}
                            sx={{
                                '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="useCredit"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <CommonCheckbox
                                    {...field}
                                    label={t("useCredit")}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                    checked={field.value}
                                    color="success"
                                />
                            )}
                        />
                        {watch('clientId') ? <Typography className="font-Saira text-start font-[600] text-[18px] leading-[22px] mt-[-10px]" >
                            {t('creditAmount')} = {credit || 0}
                        </Typography> : null}
                    </Grid>
                    {watch('clientId') && (watch('adjustmentType').value === 592) ? <Grid container spacing={2} className='mt-[5px] ps-[25px]'>
                        <Grid item xs={12} md={7} lg={5}>
                            <StyledInput
                                fullWidth
                                placeholder={t("invoiceNo")}
                                handleSearchClick={handleSearchClick}
                                handleChange={handleInputChange}
                                value={searchInvoice}
                                clearSearch={clearSearchInvoice}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PaymentInvoicesTable rows={invoices} columns={invoicesColumns} form={{ register, control, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, watch, setValue }} />
                        </Grid> 
                    </Grid> : null}
                </Grid>
            }
        />}
    </>)
}