import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import DynamicSelect from 'common/Select/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import BasicDatePicker from 'common/Pickers/Pickers';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface Data {
  [key: string]: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface InventoryItemsTableProps {
  form?: any;
  columns: any[];
  handleInputChange: (index: number, columnId: string, value: any, typeName: string, type: string) => void;
  rows: any[];
  typeName: string;
  items?: any[];
  currency?: string;
  DeleteItem: (row: any, typeName: string) => void;
  params?: any;
  handleItem?: (row: any, typeName: string, action: string) => void;
  checkStatus?: number | null;
  viewDelete?: boolean;
}

export const InventoryItemsTable: React.FC<InventoryItemsTableProps> = ({ form, columns, handleInputChange, rows, typeName, items, currency, DeleteItem, params, handleItem, checkStatus, viewDelete }) => {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const previousValueRef = React.useRef<string | number | null>(null);

  const handleRequestSort = ( event: React.MouseEvent<unknown>, property: keyof Data, ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  return (
    <Box sx={{ width: '100%' }} id="inventorytable">
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
          <TableHead>
            <TableRow>
              {columns.filter((column) => column.id !== 'itemId' && column.id !== 'itemCode' && column.id !== 'unitId').map((column) => (
                <TableCell
                  key={column.id}
                  align={column.numeric ? 'right' : 'left'}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                  {columns.filter((column) => column.id !== 'itemId' && column.id !== 'itemCode' && column.id !== 'unitId').map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.numeric ? 'right' : 'left'}
                      padding={column.disablePadding ? 'none' : 'normal'}
                      component={column.id === 'itemName' ? 'th' : undefined}
                      id={column.id === 'itemName' ? labelId : undefined}
                      scope={column.id === 'itemName' ? 'row' : undefined}
                      sx={{ width: column.id === 'itemName' ? '200px' : 'auto' }}
                    >
                      {column.id === 'itemName' ? <Controller
                        name={`${typeName}.${index}.${column.id}`}
                        control={form.control}
                        render={({ field }) => (<Box sx={{ '& .dropdown': { height: '0 !important' } }}>
                          <DynamicSelect
                            label=""
                            {...field}
                            value={form.watch(`${typeName}.${index}.itemId`) ? {value: form.watch(`${typeName}.${index}.itemId`), label: form.watch(`${typeName}.${index}.itemName`) || '' + ' ' + form.watch(`${typeName}.${index}.itemCode`), itemCode: form.watch(`${typeName}.${index}.itemCode`) || '', unitId: form.watch(`${typeName}.${index}.unitId`), unitTitle: form.watch(`${typeName}.${index}.unitTitle`)} : null}
                            options={items?.map((item: { id: number, title: string, itemCode: string, unitId: number | null, stockQuantity: number, itemCost: number, unitTitle: string }) => ({ value: item.id, label: item.title + ` ` + item.itemCode, itemCode: item.itemCode, unitId: item.unitId, stockQuantity: item?.stockQuantity || 1, itemCost: item?.itemCost || 0, unitTitle: item?.unitTitle || '' }))}
                            isSearchable
                            onChange={(selectedOption: any) => { field.onChange(selectedOption, column.type); 
                              handleInputChange(index, 'itemId', selectedOption.value, typeName, column.type);
                              handleInputChange(index, 'itemName', selectedOption.label, typeName, column.type);
                              handleInputChange(index, 'itemCode', selectedOption.itemCode, typeName, column.type);
                              handleInputChange(index, 'unitId', selectedOption.unitId, typeName, column.type);
                              handleInputChange(index, 'unitTitle', selectedOption.unitTitle, typeName, column.type);
                              handleInputChange(index, 'quantity', 1, typeName, column.type);
                              handleInputChange(index, 'unitPrice', selectedOption.itemCost, typeName, column.type);
                              form.setValue(`${typeName}.${index}.${'itemId'}`, selectedOption.value);
                              form.setValue(`${typeName}.${index}.${'itemName'}`, selectedOption.label);
                              form.setValue(`${typeName}.${index}.${'itemCode'}`, selectedOption.itemCode);
                              form.setValue(`${typeName}.${index}.${'unitId'}`, selectedOption.unitId);
                              form.setValue(`${typeName}.${index}.${'unitTitle'}`, selectedOption.unitTitle);
                              form.setValue(`${typeName}.${index}.${'quantity'}`, 1);
                              form.setValue(`${typeName}.${index}.${'unitPrice'}`, selectedOption.itemCost);
                              if(params?.id && !row.id) handleItem?.(row, typeName, 'create');
                              else if(params?.id && row.id) handleItem?.(row, typeName, 'update');
                            }}
                            isDisabled={checkStatus === 2}
                          />
                        </Box>)}
                      /> 
                      : column.id === 'unitTitle' ? 
                      <span className='font-bold'>{form.watch(`${typeName}.${index}.${'unitTitle'}`) || row?.unitTitle || t('unit(s)')}</span>
                      : column.id === 'total' ? 
                        <span className='font-bold'>{new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: currency || '',
                        }).format(
                          (parseInt(form.watch(`${typeName}.${index}.${'quantity'}`)) * 
                          parseFloat(form.watch(`${typeName}.${index}.${'unitPrice'}`))) - 
                          parseFloat(form.watch(`${typeName}.${index}.${'discount'}`) || 0)
                          || 0
                        )}</span> 
                      : column.type === 'date' ? <Controller
                      name={`${typeName}.${index}.${column.id}`}
                      control={form.control}
                      rules={{ validate: (value) => {
                          const date = new Date(value);
                          return isNaN(date.getTime()) ? t("Invalid Date") : true;
                      } }}
                      render={({ field }) => (<Box sx={{ '& .dateerror': { height: '0 !important' } }}>
                          <BasicDatePicker
                              type="datePicker"
                              {...field}
                              fullWidth
                              onChange={(value: any) => {
                                field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'));
                                form.setValue(`${typeName}.${index}.${column.id}`, new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'));
                                handleInputChange(index, column.id, new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'), typeName, column.type);
                                if(params?.id && !row.id) handleItem?.(row, typeName, 'create');
                                else if(params?.id && row.id) handleItem?.(row, typeName, 'update');
                              }}
                              disable={checkStatus === 2}
                              maxDate={column.id === 'receivedDate' ? dayjs() : null}
                              value={column.id === 'receivedDate' ? dayjs(new Date())?.format('YYYY-MM-DD') : form.watch(`${typeName}.${index}.${column.id}`)}
                          />
                      </Box>)}
                  /> : <Controller
                        name={`${typeName}.${index}.${column.id}`}
                        control={form.control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type={column.type}
                            placeholder={column.label}
                            fullWidth
                            onChange={(e) => {
                              let value:string | number = e.target.value;
                              if (column.id === 'quantity') value = Math.max(0, Math.floor(Number(value)));
                              if (column.type === 'number' && (Number(value) < 0 || !Number(value)))  value = '0';
                              handleInputChange(index, column.id, value, typeName, column.type);
                              field.onChange(value);
                            }}
                            onFocus={(e) => {
                              if (e.target.value === '0') {e.target.value = ''; field.onChange('');}
                              params?.id ? previousValueRef.current = e.target.value : ''
                            }}
                            onBlur={(e) => {
                              if (e.target.value === '' && column.type === 'number') {
                                e.target.value = '0';
                                field.onChange('0');
                              }
                              if(params?.id && !row.id) {
                                if(previousValueRef.current !== e.target.value) {
                                  handleItem?.(row, typeName, 'create');
                                }
                              } else if(params?.id && row.id) {
                                if(previousValueRef.current !== e.target.value) {
                                  handleItem?.(row, typeName, 'update');
                                }
                              }
                            }}
                            defaultValue={column.id === 'unitPrice' || column.id === 'discount' || column.id === 'quantity' ? 0 : ''}
                            disabled={checkStatus === 2}
                          />
                        )}
                      />}
                    </TableCell>
                  ))}
                  {row.id || viewDelete ? <TableCell onClick={() => {
                    if(checkStatus === 1 || !params?.id) {
                      DeleteItem(row, typeName)
                      columns.map((column) => form.resetField(`${typeName}.${index}.${column.id}`))
                    }
                  }} className='text-end hide-on-print'><DeleteIcon /></TableCell> : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='hide-on-print'
        labelRowsPerPage={t('rowsPerPage')}
      />
    </Box>
  );
};