export const APP_BASE_URL: string | undefined = process.env.REACT_APP_BASE_URL;

// export const APP_BASE_URL = 'https://dummy.restapiexample.com/api/v1';

export const URL_ENCODED_HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

export const STATUS_CODE_200 = '200';

export const STATUS_CODE_204 = '204';

export const STATUS_CODE_400 = '400';

export const STATUS_CODE_401 = '401';

export const STATUS_CODE_403 = '403';

export const STATUS_CODE_404 = '404';

export const STATUS_CODE_440 = '440';

export const SESSION_EXPIRE_MESSAGE = 'No-Session/Session-Expired';

export const ACCESS_TOKEN = 'ACCESS_TOKEN';
