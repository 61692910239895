import { Grid, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Vehicle } from "../../assets/SideBarIcons/vehicle.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetModelEnginesByModelResponse, GetModelsByMakeResponse, GetVehicleByIdResponse, VehicleRequest } from "subModule/src/services/vehicle/interface";
import Loader from "layout/Loader";
import DynamicSelect from "common/Select/Select";
import BasicDatePicker from "common/Pickers/Pickers";
import { useGetClientsDropdownService } from "subModule/src/services/client/useClient";
import { VehicleProfileService } from "subModule/src/core/services";
import { useGetMakeDropdownService } from "subModule/src/services/make/useMake";
import { useGetVehicleTypeDropdownService } from "subModule/src/services/vehicletype/useVehicleType";
import CloseIcon from '@mui/icons-material/Close';
import FormDialog from "common/Modal/Modal";
import PlateNumberModal from "./PlateNumberModal";
import { useSelector } from "react-redux";
import { selectUserInfo } from "features/user/userSlice";
import { useGetStateService } from "subModule/src/services/state/useState";

interface FormData { [key: string]: any; }
export default function VehicleModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, control, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, watch, setValue } = useForm<FormData>({ defaultValues: {} });
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [openPlateNumberDetails, setOpenPlateNumberDetails] = useState<boolean>(false);
    const [models, setModels] = useState<any>([]);
    const [modelEngines, setModelEngines] = useState<any>([]);
    const [updateModal, setUpdateModal] = useState<boolean>(false);
    const [vehicle, setVehicle] = useState<any>({});
    const { data: clientsData, refetch: clientsRefetch } = useGetClientsDropdownService({ pageNumber: 1, size: 1000 });
    const { data: makesData, refetch: makesRefetch } = useGetMakeDropdownService({ pageNumber: 1, size: 1000 });
    const { data: vehicleTypesData, refetch: vehicleTypesRefetch } = useGetVehicleTypeDropdownService({ pageNumber: 1, size: 1000 });
    const { data: statesData, refetch: statesRefetch } = useGetStateService({ pageNumber: 1, size: 1000 });
    const statesList = (statesData?.pages[0]?.data?.content || []).map((item: { id?: number, name?: string }) => ({ value: item.id, label: item.name }));
    const clients = (clientsData?.pages[0]?.data?.content || []).map((item: { id?: number, name?: string }) => ({ value: item.id, label: item.name }));
    const makes = (makesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const vehicleTypes = (vehicleTypesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const transmissions = [
        { value: 1, label: t('manual') },
        { value: 2, label: t('automatic') },
    ];
    const fuelTypes = [
        { value: 1, label: t('PETROL') },
        { value: 2, label: t('DIESEL') },
        { value: 3, label: t('ETHANOL') },
        { value: 4, label: t('BIODIESEL') },
        { value: 5, label: t('CNG') },
        { value: 6, label: t('LPG') },
        { value: 7, label: t('HYDROGEN') },
        { value: 8, label: t('HYBRID') },
        { value: 9, label: t('PHEV') },
        { value: 10, label: t('ELECTRIC') },
    ];
    const registrationStatuses = [
        { value: 1, label: t('active') },
        { value: 2, label: t('expired') },
    ];
    const insuranceStatuses = [
        { value: 1, label: t('active') },
        { value: 2, label: t('expired') },
    ];
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    useEffect(() => {
        clientsRefetch();
        makesRefetch();
        vehicleTypesRefetch();
        statesRefetch();
        if (params?.id) {
            if (loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if(isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const fetchModels = (make: any) => {
        VehicleProfileService.getModelsByMake({ page: 1, size: 1000 }, make).then((response: GetModelsByMakeResponse) => {
            if (response.success) {
                setModels(response?.data?.content?.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title })) || []);
            } else {
                enqueueSnackbar(<AlertMessages text={response.message} />, { variant: 'error' });
            }
        }).catch((err: any) => {
            console.error("===", err);
        });
    }
    const fetchModelEngines = (model: any) => {
        VehicleProfileService.getModelEnginesByModel({ page: 1, size: 1000 }, model).then((response: GetModelEnginesByModelResponse) => {
            if (response.success) {
                setModelEngines(response?.data?.content?.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title })) || []);
            } else {
                enqueueSnackbar(<AlertMessages text={response.message} />, { variant: 'error' });
            }
        }).catch((err: any) => {
            console.error("===", err);
        });
    }
    const getByID = () => {
        VehicleProfileService.getVehicleByID(+(params as any)?.id).then((res: GetVehicleByIdResponse) => {
            if (res.success) {
                reset({
                    ...res?.data || {},
                    userId: { value: res.data?.userId, label: res.data?.user?.name },
                    vehicleTypeId: { value: res.data?.vehicleTypeId, label: res?.data?.vehicleTypeTitle },
                    makeId: { value: res.data?.makeId, label: res?.data?.makeTitle },
                    modelId: { value: res.data?.modelId, label: res?.data?.modelTitle },
                    modelEngineId: { value: res.data?.modelEngineId || null, label: res?.data?.modelEngineTitle || '' },
                    year: res.data?.year && res.data?.year !== '0' ? { value: res.data?.year, label: res?.data?.year } : null,
                    transmission: { value: res.data?.transmission || 0, label: transmissions[transmissions.findIndex((v) => v.value === res.data.transmission)]?.label || '' },
                    fuelType: { value: res.data?.fuelType || 0, label: fuelTypes[fuelTypes.findIndex((v) => v.value === res.data.fuelType)]?.label || '' },
                    registrationStatus: { value: res.data?.registrationStatus || 0, label: registrationStatuses[registrationStatuses.findIndex((v) => v.value === res.data.registrationStatus)]?.label || '' },
                    insuranceStatus: { value: res.data?.fuelType || 0, label: insuranceStatuses[insuranceStatuses.findIndex((v) => v.value === res.data.insuranceStatus)]?.label || '' },
                });
                setVehicle(res?.data);
                setTimeout(() => { fetchModels(res.data?.makeId); }, 500);
                fetchModelEngines(res.data?.modelId);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if (params?.id) {
            const payload = {
                ...data,
                userId: data.userId.value,
                vehicleTypeId: data.vehicleTypeId.value,
                makeId: data.makeId.value,
                modelId: data.modelId.value,
                modelEngineId: data.modelEngineId?.value || null,
                year: data.year?.value || '',
                transmission: data.transmission?.value || 0,
                fuelType: data.fuelType?.value || 0,
                registrationStatus: data.registrationStatus?.value || 0,
                insuranceStatus: data.insuranceStatus?.value || 0,
            };
            setVehicle(payload);
            if(vehicle?.jobCardId || vehicle?.workOrderId) {
                setVehicle({...payload, updateImpact: true});
                setUpdateModal(true);
            } else {
                updateVehicle({...payload, updateImpact: false});
            }
        } else {
            VehicleProfileService.createVehicle({
                ...data,
                userId: data.userId.value,
                vehicleTypeId: data.vehicleTypeId.value,
                makeId: data.makeId.value,
                modelId: data.modelId.value,
                modelEngineId: data.modelEngineId?.value || null,
                year: data.year?.value || '',
                transmission: data.transmission?.value || 0,
                fuelType: data.fuelType?.value || 0,
                registrationStatus: data.registrationStatus?.value || 0,
                insuranceStatus: data.insuranceStatus?.value || 0,
            }).then((response: any) => {
                if (response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Vehicle') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };
    const updateVehicle = (payload: VehicleRequest) => {
        VehicleProfileService.updateVehicle(+(params as any)?.id, payload).then((response: any) => {
            if (response.success) {
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Vehicle') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                navigate(-1);
            } else {
                enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }
            setIsBtnEnabled(true);
        }).catch((err: any) => {
            console.error("===", err);
            setIsBtnEnabled(true);
            enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
        });
    }
    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate(-1)}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editVehicles') : t('addVehicle')}
            icon={<Vehicle height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_VEHICLE_CREATE")}
        />
        {loading ? <Loader /> : <SimpleModal
            visable={true}
            title={params?.id ? "updateVehicle" : "addVehicle"}
            isButtonVisable={true}
            isVisableBtn={IsBtnEnabled}
            btnclosetext="Back"
            buttonText={params?.id ? "update" : "submit"}
            attBtnNotshow={false}
            notVisableBackbtn={true}
            showPortList={handleSubmit(onSubmit)}
            formSubmit={handleSubmit(onSubmit)}
            content={
                <Grid container component="form" id="form" className="items-baseline" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="userId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.') }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('Client')}
                                    {...field}
                                    options={clients}
                                    isSearchable
                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                    error={errors.userId?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className='relative'>
                        <TextField
                            {...register("plateNumber", { required: t('This field is required.') })}
                            label={`${!primaryLanguage ? t('primary') : ''} ${t('plateNumber')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                            fullWidth
                            error={!!errors.plateNumber}
                            helperText={errors.plateNumber?.message as string}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            onClick={() => setOpenPlateNumberDetails(true)}
                        />
                        {watch('plateNumber')?.length > 0 && <CloseIcon className='cursor-pointer absolute top-[52px] right-[8px] w-[20px]' sx={{ '& svg': { color: '#b1b1b1' } }} onClick={() => {
                            setValue('plateNumber', ''); 
                            if(!IsBtnEnabled) setIsBtnEnabled(true);
                        }} />}
                        {openPlateNumberDetails &&
                            <FormDialog
                                open={openPlateNumberDetails}
                                onClose={(event, reason) => event && reason !== 'backdropClick' ? setOpenPlateNumberDetails(false) : ''}
                                title={t('plateNumber') + ' ' + t('Details')}
                                createPopup={true}
                                fullWidth
                                hideActions={true}
                                content={ 
                                    <PlateNumberModal setOpenPlateNumberDetails={setOpenPlateNumberDetails} setValue={setValue} plate={watch('plateNumber')} region={watch('plateRegion')} state={watch('stateId')} {...{setIsBtnEnabled, statesList}} />
                                }
                            />
                        }
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...register("secondaryPlateNumber")}
                            label={`${!secondaryLanguage ? t('secondary') : ''} ${t('plateNumber')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                            fullWidth
                            error={!!errors.secondaryPlateNumber}
                            helperText={errors.secondaryPlateNumber?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...register("vin")}
                            label={t('vin')}
                            fullWidth
                            error={!!errors.vin}
                            helperText={errors.vin?.message as string}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                                setValue("vin", sanitizedValue, { shouldDirty: true });
                            }}              
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="vehicleTypeId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.'), }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('vehicleType')}
                                    {...field}
                                    options={vehicleTypes}
                                    isSearchable
                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                    error={errors.vehicleTypeId?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="makeId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.'), }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('Make')}
                                    {...field}
                                    options={makes}
                                    isSearchable
                                    onChange={(selectedOption: any) => {
                                        field.onChange(selectedOption);
                                        fetchModels(selectedOption.value);
                                        setValue('modelId', null); setValue('modelEngineId', null);
                                    }}
                                    error={errors.makeId?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="modelId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.'), }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('Model')}
                                    {...field}
                                    options={models}
                                    isSearchable
                                    isDisabled={!watch('makeId')?.value}
                                    onChange={(selectedOption: any) => {
                                        field.onChange(selectedOption);
                                        fetchModelEngines(selectedOption.value);
                                        setValue('modelEngineId', null);
                                    }}
                                    error={errors.modelId?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="modelEngineId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('modelEngine')}
                                    {...field}
                                    options={modelEngines}
                                    isSearchable
                                    isDisabled={!watch('makeId')?.value || !watch('modelId')?.value}
                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                    error={errors.modelEngineId?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="year"
                            control={control}
                            defaultValue={null}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('year')}
                                    {...field}
                                    options={Array.from({ length: 60 }, (_, i) => ({label: new Date().getFullYear() - i, value: new Date().getFullYear() - i}))}
                                    isSearchable
                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                    error={errors.year?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="transmission"
                            control={control}
                            defaultValue={null}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('transmissionType')}
                                    {...field}
                                    options={transmissions}
                                    isSearchable
                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                    error={errors.transmission?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="fuelType"
                            control={control}
                            defaultValue={null}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('fuelType')}
                                    {...field}
                                    options={fuelTypes}
                                    isSearchable
                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                    error={errors.fuelType?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...register("color")}
                            label={t('color')}
                            fullWidth
                            error={!!errors.color}
                            helperText={errors.color?.message as string}
                            InputLabelProps={{ shrink: true }}                   
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            type="number"
                            {...register("odometer")}
                            onChange={(e: any) => {
                                const value = e.target.value;
                                e.target.value = value < 0 ? 0 : value || "";
                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                            }}
                            label={t("odometer")}
                            fullWidth
                            error={!!errors.odometer}
                            helperText={errors.odometer?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            type="number"
                            {...register("dailyMileage")}
                            onChange={(e: any) => {
                                const value = e.target.value;
                                e.target.value = value < 0 ? 0 : value || "";
                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                            }}
                            label={t("avgDailyMileage")}
                            fullWidth
                            error={!!errors.dailyMileage}
                            helperText={errors.dailyMileage?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            type="number"
                            {...register("avgDailyFuelConsumption")}
                            onChange={(e: any) => {
                                const value = e.target.value;
                                e.target.value = value < 0 ? 0 : value || "";
                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                            }}
                            label={t("avgDailyFuelConsumption")}
                            fullWidth
                            error={!!errors.avgDailyFuelConsumption}
                            helperText={errors.avgDailyFuelConsumption?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            type="number"
                            {...register("seatingCapacity")}
                            onChange={(e: any) => {
                                const value = e.target.value;
                                e.target.value = value < 0 ? 0 : value || "";
                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                            }}
                            label={t("seatingCapacity")}
                            fullWidth
                            error={!!errors.seatingCapacity}
                            helperText={errors.seatingCapacity?.message as string}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...register("fleetCode")}
                            label={t('fleetCode')}
                            fullWidth
                            error={!!errors.fleetCode}
                            helperText={errors.fleetCode?.message as string}
                            InputLabelProps={{ shrink: true }}              
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="registrationStatus"
                            control={control}
                            defaultValue={null}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <DynamicSelect
                                    label={t('registrationStatus')}
                                    {...field}
                                    options={registrationStatuses}
                                    isSearchable
                                    onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                    error={errors.registrationStatus?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="registrationExpiryDate"
                            control={control}
                            rules={{
                                validate: (value) => {
                                    const date = new Date(value);
                                    return isNaN(date.getTime()) && value ? t("Invalid Date") : true;
                                }
                            }}
                            render={({ field }) => (
                                <BasicDatePicker
                                    type="datePicker"
                                    {...field}
                                    label="registrationExpiryDate"
                                    fullWidth
                                    onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                    required={false}
                                    error={errors.registrationExpiryDate?.message as string}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...register("tyreInformation")}
                            label={t('tyreInformation')}
                            fullWidth
                            error={!!errors.tyreInformation}
                            helperText={errors.tyreInformation?.message as string}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            rows={1}
                            sx={{
                                '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...register("notes")}
                            label={t('notes')}
                            fullWidth
                            error={!!errors.notes}
                            helperText={errors.notes?.message as string}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            rows={1}
                            sx={{
                                '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                            }}
                        />
                    </Grid>
                    <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                        <Grid item xs={12} className='px-4'>
                            <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('insurance')}</h1>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="insuranceStatus"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <DynamicSelect
                                        label={t('insuranceStatus')}
                                        {...field}
                                        options={insuranceStatuses}
                                        isSearchable
                                        onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                        error={errors.insuranceStatus?.message as string}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="insuranceExpiryDate"
                                control={control}
                                rules={{
                                    validate: (value) => {
                                        const date = new Date(value);
                                        return isNaN(date.getTime()) && value ? t("Invalid Date") : true;
                                    }
                                }}
                                render={({ field }) => (
                                    <BasicDatePicker
                                        type="datePicker"
                                        {...field}
                                        label="insuranceExpiryDate"
                                        fullWidth
                                        onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                        required={false}
                                        error={errors.insuranceExpiryDate?.message as string}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
        />}
        {updateModal && <FormDialog
            open={updateModal}
            onClose={(event: any, reason: any) => { if(event && (reason !== 'backdropClick')) { setUpdateModal(false); updateVehicle({...vehicle, updateImpact: false}); }}}
            hideClose={true}
            title={t('applyChangesToJCWO')}
            content={<Typography>{t(`wantToApplyChanges`)}</Typography>}
            saveFunction={() => { setUpdateModal(false); updateVehicle(vehicle); }}
            submitButtonText="yes"
            cancelBtnText='no'
        />}
    </>)
}