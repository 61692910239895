import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Groups  } from "assets/SideBarIcons/Groups.svg";
import { useGetServiceGroupServiceService, useAssignServiceService, useUnassignServiceService, useAssignAllServiceService, useUnassignAllServiceService } from 'subModule/src/services/servicegroup/useServiceGroup';
import ServiceGroupTabs from './ServiceGroupTabs';
import ServiceGroupServiceSearchHeader from 'components/ServiceGroup/ServiceGroupServiceSearchHeader';
import { SelectChangeEvent } from '@mui/material';

interface SearchFormData {
    [key: string]: string | any;
}
export default function ServiceGroupServiceTable() {
    const params = useParams();
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<string[]>(['title']);
    const [search, setSearch] = useState<any>({});
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({ title: '', serviceCode: '' });
    const { data: tableData, isLoading, error, refetch } = useGetServiceGroupServiceService({ pageNumber: pageNo, size: pageSize, ...search }, +(params as any).id);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    let allServicesAssigned: unknown = tableData?.pages[0].data?.content.map(service => service.linked).every(linked => linked === true);
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (search && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 300)
        return () => clearTimeout(getData)
    }, [search, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const clearSearch = () => {
        setFilter(['title']);
        setSearchFormData({ title: '', serviceCode: '' });
        if (Object.keys(search).length) {
            setLoading(true);
            setSearch({});
        }
    }
    const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData =>
            Object.fromEntries(Object.keys(prevFormData).map(key =>
                [key, value.includes(key) ? prevFormData[key] : '']
            ))
        );
        if (!value.length && Object.keys(search).length) {
            clearSearch();
        }
    };
    const handleChangeSearch = (field: string, value: any) => {
        const val = value;
        setSearchFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
    };
    const handleSubmitSearch = () => {
        const filteredSearch: Record<string, string> = {};
        Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) {
                filteredSearch[key] = value?.trim();
            }
        });
        if (Object.keys(filteredSearch).length > 0 && Object.values(filteredSearch).some(value => value)) {
            setLoading(true);
            setSearch(filteredSearch);
            setPageNo(1);
            setPageSize(pageSize);
        }
    };
    const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoading(true);
        if (isChecked) {
            onAssignService({ serviceGroupId: +(params as any).id, serviceId: row.id });
        } else {
            onUnassignService({ serviceGroupId: +(params as any).id, serviceId: row.id });
        }
    }
    const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoading(true);
        if (isChecked) {
            onAssignAllServices({ serviceGroupId: +(params as any).id });
        } else {
            onUnassignAllServices({ serviceGroupId: +(params as any).id });
        }
    }
    const { onAssignService } = useAssignServiceService({
        onSuccess: async (msg) => {
            const response: any = {};
            Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
            if (response.success) {
                if (tableData?.pages[0].data.content.length === tableData?.pages[0].data.content.filter(service => service.linked).length) {
                    allServicesAssigned = true;
                }
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('service') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
            if (err instanceof Error) {
                console.error(err.stack);
            }
        }
    })
    const { onUnassignService } = useUnassignServiceService({
        onSuccess: async (data) => {
            const response: any = {};
            Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
            if (response.success) {
                if (tableData?.pages[0].data.content.length !== tableData?.pages[0].data.content.filter(service => service.linked).length) {
                    allServicesAssigned = false;
                }
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('service') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            console.log("err====,  ", err);
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
    });
    const { onAssignAllServices } = useAssignAllServiceService({
        onSuccess: async (msg) => {
            const response: any = {};
            Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
            if (response.success) {
                allServicesAssigned = true;
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('services') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
            if (err instanceof Error) {
                console.error(err.stack);
            }
        }
    })
    const { onUnassignAllServices } = useUnassignAllServiceService({
        onSuccess: async (data) => {
            const response: any = {};
            Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
            if (response.success) {
                allServicesAssigned = false;
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('services') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            console.log("err====,  ", err);
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
    });

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/services-group')}
                heading={t('servicesGroup')}
                icon={<Groups height={28} width={28} />}
                btnText={"back"}
                btnType={"back"}
                showCreateBtn={false}
            />
            <Box>
                <div className='flex'>
                    {params?.id ? <ServiceGroupTabs params={params} selectedIndex={1} /> : null}
                    <BackendPaginationTable
                        columns={[
                            { id: 'title', label: 'title', numeric: false },
                            { id: 'serviceCode', label: 'serviceCode', numeric: false },
                        ]}
                        data={tableData?.pages[0]?.data?.content || []}
                        pageNo={pageNo}
                        pageSize={pageSize}
                        tableData={tableData?.pages[0]?.data}
                        showCheckbox={true}
                        showActions={true}
                        enableSorting={true}
                        showHeader={true}
                        showSearch={true}
                        showFilter={true}
                        showCreateButton={false}
                        actions={[]}
                        handleChangePage={handleChangePage}
                        handleChangeRows={handleChangeRows}
                        assignUnassignSingleEntity={assignUnassignSingleEntity}
                        assignUnassignAllEntities={assignUnassignAllEntities}
                        allEntitiesAssigned={allServicesAssigned}
                        loadingSearch={loading}
                        assignPrivilege={checkPrivileges('ROLE_SERVICEGROUP_SERVICE_ASSIGN')}
                        unassignPrivilege={checkPrivileges('ROLE_SERVICEGROUP_SERVICE_UNASSIGN')}
                        headerComponent={<ServiceGroupServiceSearchHeader 
                            searchFormData={searchFormData}
                            handleChangeFilter={handleChangeFilter}
                            handleChangeSearch={handleChangeSearch}
                            handleSubmitSearch={handleSubmitSearch}
                            clearSearch={clearSearch}
                            search={search}
                            {...{filter}}
                        />}
                    />
                </div>
            </Box>
        </Box>
    )
}