import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Stationgroup  } from "../../assets/SideBarIcons/station-group.svg";
import { useGetStationGroupStationService, useAssignStationService, useUnassignStationService, useAssignAllStationService, useUnassignAllStationsService } from 'subModule/src/services/stationgroup/useStationGroup';
import StationGroupTabs from './StationGroupTabs';
import StationGroupStationSearchHeader from 'components/StationGroup/StationGroupStationSearchHeader';
import { SelectChangeEvent } from '@mui/material';

interface SearchFormData {
    [key: string]: string | any;
}
export default function StationGroupStationTable() {
    const params = useParams();
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<string[]>(['name']);
    const [search, setSearch] = useState<any>({});
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({ name: '', phone: '', email: '', stationCode: '' });
    const { data: tableData, isLoading, error, refetch } = useGetStationGroupStationService({ pageNumber: pageNo, size: pageSize, ...search }, +(params as any).id);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    let allStationsAssigned: unknown = tableData?.pages[0].data?.content.map(item => item.linked).every(linked => linked === true);
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (search && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 300)
        return () => clearTimeout(getData)
    }, [search, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const clearSearch = () => {
        setFilter(['name']);
        setSearchFormData({ name: '', phone: '', email: '', stationCode: '' });
        if (Object.keys(search).length) {
            setLoading(true);
            setSearch({});
        }
    }
    const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData =>
            Object.fromEntries(Object.keys(prevFormData).map(key =>
                [key, value.includes(key) ? prevFormData[key] : (typeof prevFormData[key] === 'string' ? '' : 0)]
            ))
        );
        if (!value.length && Object.keys(search).length) {
            clearSearch();
        }
    };
    const handleChangeSearch = (field: string, value: any) => {
        const val = field === 'type' ? (value ? parseInt(value.value) || 0 : 0) : value;
        setSearchFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
    };
    const handleSubmitSearch = () => {
        const filteredSearch: Record<string, string> = {};
        Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) filteredSearch[key] = typeof value === 'string' ? value?.trim() : value;
        });
        if(Object.keys(filteredSearch).length > 0 && Object.values(filteredSearch).some(value => value)) {
            setLoading(true);
            setSearch(filteredSearch);
            setPageNo(1);
            setPageSize(pageSize);
        }
    };
    const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoading(true);
        if (isChecked) {
            onAssignStation({ stationGroupId: +(params as any).id, stationId: row.id });
        } else {
            onUnassignStation({ stationGroupId: +(params as any).id, stationId: row.id });
        }
    }
    const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoading(true);
        if (isChecked) {
            onAssignAllStations({ stationGroupId: +(params as any).id });
        } else {
            onUnassignAllStations({ stationGroupId: +(params as any).id });
        }
    }
    const { onAssignStation } = useAssignStationService({
        onSuccess: async (msg) => {
            const response: any = {};
            Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
            if (response.success) {
                if (tableData?.pages[0].data.content.length === tableData?.pages[0].data.content.filter(station => station.linked).length) {
                    allStationsAssigned = true;
                }
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('station') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
            if (err instanceof Error) {
                console.error(err.stack);
            }
        }
    })
    const { onUnassignStation } = useUnassignStationService({
        onSuccess: async (data) => {
            const response: any = {};
            Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
            if (response.success) {
                if (tableData?.pages[0].data.content.length !== tableData?.pages[0].data.content.filter(station => station.linked).length) {
                    allStationsAssigned = false;
                }
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('station') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            console.log("err====,  ", err);
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
    });
    const { onAssignAllStations } = useAssignAllStationService({
        onSuccess: async (msg) => {
            const response: any = {};
            Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
            if (response.success) {
                allStationsAssigned = true;
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stations') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
            if (err instanceof Error) {
                console.error(err.stack);
            }
        }
    })
    const { onUnassignAllStations } = useUnassignAllStationsService({
        onSuccess: async (data) => {
            const response: any = {};
            Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
            if (response.success) {
                allStationsAssigned = false;
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('stations') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            console.log("err====,  ", err);
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
    });

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/station-groups')}
                heading={t('stationsGroup')}
                icon={<Stationgroup height={28} width={28} />}
                btnText={"back"}
                btnType={"back"}
                showCreateBtn={false}
            />
            <Box>
                <div className='flex'>
                    {params?.id ? <StationGroupTabs params={params} selectedIndex={1} /> : null}
                    <BackendPaginationTable
                        columns={[
                            { id: 'name', label: 'name', numeric: false },
                            { id: 'stationCode', label: 'branchCode', numeric: false },
                            { id: 'phoneNumber1', label: 'phoneNumber', numeric: false },
                            { id: 'email', label: 'email', numeric: false },
                        ]}
                        data={tableData?.pages[0]?.data?.content || []}
                        pageNo={pageNo}
                        pageSize={pageSize}
                        tableData={tableData?.pages[0]?.data}
                        showCheckbox={true}
                        showActions={true}
                        enableSorting={true}
                        showHeader={true}
                        showSearch={true}
                        showFilter={true}
                        showCreateButton={false}
                        actions={[]}
                        handleChangePage={handleChangePage}
                        handleChangeRows={handleChangeRows}
                        assignUnassignSingleEntity={assignUnassignSingleEntity}
                        assignUnassignAllEntities={assignUnassignAllEntities}
                        allEntitiesAssigned={allStationsAssigned}
                        loadingSearch={loading}
                        assignPrivilege={checkPrivileges('ROLE_STATIONGROUP_STATION_ASSIGN')}
                        unassignPrivilege={checkPrivileges('ROLE_STATIONGROUP_STATION_UNASSIGN')}
                        headerComponent={<StationGroupStationSearchHeader 
                            searchFormData={searchFormData}
                            handleChangeFilter={handleChangeFilter}
                            handleChangeSearch={handleChangeSearch}
                            handleSubmitSearch={handleSubmitSearch}
                            clearSearch={clearSearch}
                            search={search}
                            {...{filter}}
                        />}
                    />
                </div>
            </Box>
        </Box>
    )
}