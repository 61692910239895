import { Box, Button, Divider, Grid, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService } from "subModule/src/core/services";
import { GetCreditNoteResponse, SaveCreditNote } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import { DeleteData } from "subModule/src/type";
import { useGetServiceService } from "subModule/src/services/service/useService";
import { InventoryServicesTable } from "components/Inventory/InventoryServicesTable";
import BasicDatePicker from "common/Pickers/Pickers";
import { useGetVendorService } from "subModule/src/services/vendor/useVendor";
import { useGetStoreService } from "subModule/src/services/store/useStore";
import DynamicSelect from "common/Select/Select";
import { useGetInventoryService } from "subModule/src/services/inventory/useInventory";

interface FormData {
    [key: string]: any;
}

const CreditNoteCreateUpdate: React.FC = () => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField, reset} = useForm<FormData>({defaultValues: {
        pCDNoteItems: [],
        pCDNoteService: []
    }});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 500 });
    const { data: servicesData, refetch: servicesRefetch } = useGetServiceService({ pageNumber: 1, size: 500 });
    const { data: vendorsData, refetch: vendorsRefetch } = useGetVendorService({ pageNumber: 1, size: 1000 });
    const { data: storesData, refetch: storesRefetch } = useGetStoreService({ pageNumber: 1, size: 1000 });
    const { data: poData, refetch: poRefetch } = useGetInventoryService({ pageNumber: 1, size: 500 });
    const [pCDNoteItems, setpCDNoteItems] = useState<any[]>([]);
    const [pCDNoteService, setpCDNoteService] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [creditnote, setCreditNote] = useState<SaveCreditNote>();

    useEffect(() => {
        vendorsRefetch();
        storesRefetch();
        itemsRefetch();
        servicesRefetch();
        poRefetch();
        if(params?.id) {
            if(loading) getCreditNoteByID();
        }
    }, [params]);
    const getCreditNoteByID = () => {
        InventoryProfileService.getEntityById(params?.id, 'purchasecreditdebitnote').then((res: any) => {
            if(res.success) {
                setLoading(false);
                setCreditNote((res.data as any));
                setpCDNoteItems((res.data as any)?.pcdnoteItemResponse || []);
                setValue('pCDNoteItems', res.data.pcdnoteItemResponse || []);
                setpCDNoteService((res.data as any)?.pcdnoteServiceResponse || []);
                setValue('pCDNoteService', res.data?.pcdnoteServiceResponse || []);
                setValue('type', 6);
                setValue('poId', null);
                setValue('storeId', res.data?.storeId);
                setValue('supplierId', res.data?.supplierId);
                setValue('adjustmentType', 1);
                setValue('referenceNumber', res.data?.referenceNumber);
                setValue('creditDebitDate', res.data?.creditDebitDate?.split('T')[0]);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const getPOById = (id: string | undefined) => {
        InventoryProfileService.getPurchaseOrderById(id).then((res: any) => {
            if(res.success) {
                setCreditNote(res.data);
                setpCDNoteItems(res.data?.purchaseOrderItems || []);
                setValue('pCDNoteItems', res.data.purchaseOrderItems || []);
                setpCDNoteService(res.data?.purchaseOrderSvc || []);
                setValue('pCDNoteService', res.data?.purchaseOrderSvc || []);
                setValue('storeId', {value: res.data?.storeId, label: res.data?.storeTitle});
                setValue('supplierId', {value: res.data?.supplierId, label: res.data?.vendorTitle});
                setValue('reason', res.data?.poMemo);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const supplierOptions = (vendorsData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const storeOptions = (storesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const poOptions = (poData?.pages[0]?.data?.content || [])?.map((item: any) => ({ value: item.id, label: item.poNumber }));
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'supplierSKU', label: t('SKU'), numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const columnsService = [
        { id: 'serviceId' },
        { id: 'serviceCode'},
        { id: 'serviceName', label: t('service') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'total', label: t('Total') },
    ];
    const DeleteItem = (row: any, typeName: string) => {
        if(typeName === "pCDNoteItems" && params?.id) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'purchasecreditdebitnote/item').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getCreditNoteByID();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else if(typeName === "pCDNoteItems" && !params?.id) {
            setValue('pCDNoteItems', pCDNoteItems.filter((rows) => rows.id !== row.id));
            setpCDNoteItems((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        } 
        if(typeName === "pCDNoteService" && params?.id) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'purchasecreditdebitnote/service').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getCreditNoteByID();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else if(typeName === "pCDNoteService" && !params?.id) {
            setValue('pCDNoteService', pCDNoteService.filter((rows) => rows.id !== row.id));
            setpCDNoteService((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        }
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "pCDNoteItems") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!params?.id) (newItem as any).id = Date.now();
            setpCDNoteItems((prevRows) => [...prevRows, newItem]);
        } else {
            const newService = columnsService.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!params?.id) (newService as any).id = Date.now();
            setpCDNoteService((prevRows) => [...prevRows, newService]);
        }
    };
    const handleItem = (row: any, typeName: string, action: string) => {
        if(typeName === "pCDNoteItems" && params?.id) {
            if(action === 'create') {
                InventoryProfileService.createCreditNoteItem({
                    ...row, poId: params?.id || null, purchaseCreditDebitNoteId: creditnote?.id, storeId: creditnote?.storeId
                }).then((response: GetCreditNoteResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Item') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getCreditNoteByID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getCreditNoteByID();
                });
            } else {
                InventoryProfileService.updateCreditNoteItem({
                    ...row, poId: params?.id || null, purchaseCreditDebitNoteId: creditnote?.id
                }).then((response: GetCreditNoteResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Item') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getCreditNoteByID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getCreditNoteByID();
                });
            }
        } else if(typeName === "pCDNoteService" && params?.id) {
            if(action === 'create') {
                InventoryProfileService.createCreditNoteService({
                    ...row, poId: params?.id || null, purchaseCreditDebitNoteId: creditnote?.id, storeId: creditnote?.storeId
                }).then((response: GetCreditNoteResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Service') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getCreditNoteByID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getCreditNoteByID();
                });
            } else {
                InventoryProfileService.updateCreditNoteService({
                    ...row, poId: params?.id || null, purchaseCreditDebitNoteId: creditnote?.id, storeId: creditnote?.storeId
                }).then((response: GetCreditNoteResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note Service') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getCreditNoteByID();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getCreditNoteByID();
                });
            }
        }
    }

    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "pCDNoteItems") {
            const updatedItems = [...pCDNoteItems];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setpCDNoteItems(updatedItems);
        } else {
            const updatedServices = [...pCDNoteService];
            updatedServices[index][columnId] = value;
            setpCDNoteService(updatedServices);
        }
    };
    const onSubmit = (data: FormData | SaveCreditNote) => {
        if(Object.values(data)?.length) {
            const filteredItems = pCDNoteItems.filter((item: any) => item?.itemName);
            const filteredServices = pCDNoteService.filter((service: any) => service?.serviceName);
            if(filteredItems.length === 0) {
                enqueueSnackbar(<AlertMessages text="Please add at least one item" />, { variant: 'error' });
                return;
            }
            filteredItems.every((row:any) => delete row.total && delete row.id);
            filteredServices.every((row:any) => delete row.total && delete row.id);
            InventoryProfileService.saveCreditNote({...data, type: 6,
                storeId: data.storeId.value, supplierId: data.supplierId.value, poId: data.poId?.value || null,
                pCDNoteItems: filteredItems?.length ? filteredItems.map((row: any) => ({ ...row, purchaseInvoiceId: null })) : [],
                pCDNoteService: filteredServices?.length ? filteredServices.map((row: any) => ({ ...row, purchaseInvoiceId: null })) : []
            }).then((response: GetCreditNoteResponse) => {
                    if(response.success) {
                        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Debit Note') + ' ' + t('saved') + ' ' + t('successfully')} />, { variant: 'success' });
                        navigate('/inventory/debit-note');
                    } else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setLoading(false);
                }).catch((err:any) => { 
                    console.error("===", err);
                    setLoading(false);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                });
            }
    };
    const calculateTotals = () => {
        const itemTotals = pCDNoteItems.reduce((acc, item) => {
            return {
                unitPrice: parseFloat(acc.unitPrice) + parseFloat(item.unitPrice || 0),
                discount: parseFloat(acc.discount) + parseFloat(item.discount || 0),
                subTotal: parseFloat(acc.subTotal) + parseFloat(item.unitPrice || 0) * parseInt(item.quantity || 0),
                total: parseFloat(acc.total) + ((parseFloat(item.unitPrice || 0) * parseFloat(item.quantity) || 0)) - parseFloat(item.discount || 0),
            };
        }, { unitPrice: 0, discount: 0, subTotal: 0, total: 0 });
    
        const serviceTotals = pCDNoteService?.reduce((acc, service) => {
            return {
                unitPrice: parseFloat(acc?.unitPrice) + parseFloat(service?.unitPrice || 0),
                discount: parseFloat(acc?.discount) + parseFloat(service?.discount || 0),
                subTotal: parseFloat(acc?.subTotal) + parseFloat(service?.unitPrice || 0) * parseInt(service?.quantity || 0),
                total: parseFloat(acc?.total) + ((parseFloat(service?.unitPrice || 0) * parseFloat(service?.quantity) || 0)) - parseFloat(service?.discount || 0),
            };
        }, { unitPrice: 0, discount: 0, subTotal: 0, total: 0 });
    
        return {
            unitPrice: itemTotals.unitPrice + serviceTotals?.unitPrice,
            discount: itemTotals.discount + serviceTotals?.discount,
            subTotal: itemTotals.subTotal + serviceTotals?.subTotal,
            total: itemTotals.total + serviceTotals?.total,
        };
    };
    const totals = calculateTotals();

    return (
        loading ? <Loader /> : <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{params?.id ? t('Debit Note') : t('New Debit Note')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/debit-note')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            {params?.id ? <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{t('Debit Note')}</Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Debit Note')} # </span> <span>{creditnote?.creditDebitNoteNumber || ''}</span></Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Date')}</span> <span>{creditnote?.creditDebitDate?.split('T')[0] || ''}</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Supplier')} {t('name')}</span> <br /><span className="font-medium">{creditnote?.vendorTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('store') + ' ' + t('name')}</span> <br /><span className="font-medium">{creditnote?.storeTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('referenceNo')}</span> <br /><span className="font-medium">{creditnote?.referenceNumber || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Amount')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((creditnote as SaveCreditNote)?.amount || 0) || 0}</span></Typography>
                    </div>
                </Grid>
            </Grid> : null}
            {params?.id ? null : 
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="poId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <DynamicSelect
                                label="Purchase Orders"
                                {...field}
                                options={poOptions}
                                isSearchable
                                isRequired={false}
                                onChange={(selectedOption: any) => {
                                    field.onChange(selectedOption);
                                    reset({ poId: selectedOption }, { keepValues: true });
                                    setpCDNoteItems([]);
                                    setpCDNoteService([]);
                                    getPOById(selectedOption.value);
                                }}
                                error={errors.poId?.message as string}
                            />
                        )}
                    />
                </Grid>}
            <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('Debit Note')} {t("Details")}</Typography></Grid>
            <Grid container item xs={12} gap={2} className="flex-nowrap">
                <Grid item xs={12} md={4} lg={3}>
                    <TextField 
                        {...register("referenceNumber", { required: t('This field is required.') })} 
                        placeholder="REF-001"
                        label={t('referenceNo') + ' *'}
                        fullWidth
                        error={!!errors.referenceNumber} 
                        helperText={errors.referenceNumber?.message as string} 
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="creditDebitDate"
                        control={control}
                        rules={{ validate: (value) => {
                            const date = new Date(value);
                            return isNaN(date.getTime()) ? t("Invalid Date") : true;
                        }}}
                        render={({ field }) => (
                            <BasicDatePicker
                                type="datePicker"
                                {...field}
                                label={t('Debit Note') + ' ' + t('Date')}
                                fullWidth
                                onChange={(value: any) => field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-'))}
                                required={true}
                                error={errors.creditDebitDate?.message as string}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="supplierId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('vendors')}
                                {...field}
                                options={params?.id ? [{ value: creditnote?.supplierId, label: creditnote?.vendorTitle }] : supplierOptions}
                                isSearchable
                                isRequired
                                onChange={(selectedOption: any) => {field.onChange(selectedOption);}}
                                error={errors.supplierId?.message as string}
                                value={params?.id ? { value: creditnote?.supplierId, label: creditnote?.vendorTitle } : watch('supplierId')}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="storeId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('stores')}
                                {...field}
                                options={params?.id ? [{ value: creditnote?.storeId, label: creditnote?.storeTitle }] : storeOptions}
                                isSearchable
                                isRequired
                                onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                error={errors.storeId?.message as string}
                                value={params?.id ? { value: creditnote?.storeId, label: creditnote?.storeTitle } : watch('storeId')}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container><Grid item xs={12}>
                <Box className="bg-white py-[12px] px-[18px] rounded-[8px]">
                    <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('Debit Note')}</Typography>
                    <InventoryItemsTable form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} columns={columns} handleInputChange={handleInputChange} rows={pCDNoteItems} typeName="pCDNoteItems" items={itemsData?.pages[0]?.data?.content || []} currency={loggedInUserCurrency} DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={1} viewDelete={params?.id ? false : true} />
                    <Grid item xs={12} sm={6} className="hide-on-print">
                        <div className="flex gap-2 justify-start">
                            <Button className="secondary flex gap-1" variant="contained" disableElevation onClick={() => addNewItem('pCDNoteItems')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                            {/* <Button className="secondary" variant="contained" disableElevation>{t('Import')}</Button>
                            <Button className="secondary" variant="contained" disableElevation>{t("Export")}</Button> */}
                        </div>
                    </Grid>
                </Box>
                <>
                    <Box className="flex gap-2 justify-start mt-[16px]">
                        <Button className="secondary cursor-text" variant="contained" disableElevation>{t('Additional Costs')}</Button>
                    </Box>
                    <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
                        <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('Additional Cost')}</Typography>
                        <InventoryServicesTable form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} columns={columnsService} handleInputChange={handleInputChange} rows={pCDNoteService} typeName="pCDNoteService" services={servicesData?.pages[0]?.data?.content || []} currency={loggedInUserCurrency}  DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={1} viewDelete={params?.id ? false : true} />
                        <Grid item xs={12} sm={6} className="hide-on-print">
                            <div className="flex gap-2 justify-start">
                                <Button className="secondary flex gap-1" variant="contained" disableElevation onClick={() => addNewItem('pCDNoteService')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}><Divider className="bg-[#AEB3C4] mt-[15px] mb-[8px]" /></Grid>
                    </Box>
                </>
            </Grid></Grid>
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left mb-[-5px]">{t("Reason")}</Typography>
            <Grid container xs={12} spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField 
                        {...register("reason")} 
                        placeholder={t("Reason")} 
                        fullWidth
                        error={!!errors.reason} 
                        helperText={errors.reason?.message as string}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        sx={{ '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%' }}}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className="bg-[#D8D1E0] rounded-[8px]" sx={{ border: '1px solid #5f6770', width: 'calc(100% + 8px)', padding: '8px 14px' }}>
                        <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Subtotal')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(totals.subTotal || 0)}</Typography>
                        </Box>
                        {/* <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">Tax</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format((creditnote as SaveCreditNote)?.taxAmount || 0)}</Typography>
                        </Box> */}
                        <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[auto]">{t('Discount')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] w-[20%] text-end">{new Intl.NumberFormat('en-US', {}).format(totals.discount || 0)}</Typography>
                        </Box>
                        <Box className="flex justify-end gap-12 mt-[10px]">
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[auto]">{t('Total')}</Typography>
                            <Typography className="font-Saira text-[12px] text-[#3A3A3A] font-semibold w-[20%] text-end"><span className="uppercase">({loggedInUserCurrency})</span> {new Intl.NumberFormat('en-US', {}).format(totals.total || (creditnote as SaveCreditNote)?.amount || 0)}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/debit-note')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CreditNoteCreateUpdate;
