// InvoiceService.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import { GetInvoiceByIdResponse, GetInvoiceResponse } from './interface';

export class InvoiceProfile extends BaseService {
  async getInvoice(params: PaginationParams) {
    return this.get<GetInvoiceResponse>(`${this.apiUrl}/api/invoices/paged`, params);
  }

  async getInvoiceByID(id: number) {
    return this.get<GetInvoiceByIdResponse>(`${this.apiUrl}/api/invoices/${id}`);
  }
}
