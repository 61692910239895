// ServiceService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignPriceListRequest,
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignItemRequest,
  AssignUnassignModelRequest,
  AssignUnassignResponse,
  AssignUnassignStationRequest,
  AssignUnassignVehicleTypeRequest,
  ChangeStatusServiceResponse,
  DeleteServiceResponse,
  GetServiceByIdResponse,
  GetServiceItemResponse,
  GetServiceModelResponse,
  GetServicePriceListResponse,
  GetServiceResponse,
  GetServiceStationResponse,
  GetServiceVehicleTypeResponse,
  ServiceRequest,
  ServiceResponse,
  UnassignPriceListRequest,
} from './interface';

export class ServiceProfile extends BaseService {
  async createService(req: ServiceRequest) {
    return this.post<ServiceResponse, ServiceRequest>(`${this.apiUrl}/api/services`, req);
  }

  async getService(params: PaginationParams) {
    return this.get<GetServiceResponse>(`${this.apiUrl}/api/services/paged`, params);
  }

  async updateService(id: number, req: ServiceRequest) {
    return this.put<ServiceResponse, ServiceRequest>(`${this.apiUrl}/api/services/${id}`, req);
  }

  async deleteService(id: number) {
    return this.delete<DeleteServiceResponse>(`${this.apiUrl}/api/services/${id}`);
  }

  async changeStatusService(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusServiceResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/services/${active}/${id}`,
      req
    );
  }

  async getServiceItem(params: PaginationParams & { serviceId: number; itemGroupId?: number | null }) {
    return this.get<GetServiceItemResponse>(`${this.apiUrl}/api/items/byserviceid`, params);
  }

  async assignItem(req: AssignUnassignItemRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignItemRequest>(`${this.apiUrl}/api/services/item/assign`, req);
  }

  async unassignItem(req: AssignUnassignItemRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignItemRequest>(
      `${this.apiUrl}/api/services/item/unassign`,
      req
    );
  }

  async assignAllItems(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/services/item/assignall`,
      req
    );
  }

  async unassignAllItems(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/services/item/unassignall`,
      req
    );
  }

  async getServiceVehicleType(params: PaginationParams & { serviceId: number }) {
    return this.get<GetServiceVehicleTypeResponse>(`${this.apiUrl}/api/vehicletypes/byserviceid`, params);
  }

  async assignVehicleType(req: AssignUnassignVehicleTypeRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignVehicleTypeRequest>(
      `${this.apiUrl}/api/services/vehicletype/assign`,
      req
    );
  }

  async unassignVehicleType(req: AssignUnassignVehicleTypeRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignVehicleTypeRequest>(
      `${this.apiUrl}/api/services/vehicletype/unassign`,
      req
    );
  }

  async assignAllVehicleTypes(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/services/vehicletype/assignall`,
      req
    );
  }

  async unassignAllVehicleTypes(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/services/vehicletype/unassignall`,
      req
    );
  }

  async getServiceStation(params: PaginationParams & { serviceId: number }) {
    return this.get<GetServiceStationResponse>(`${this.apiUrl}/api/stations/byserviceid`, params);
  }

  async assignStation(req: AssignUnassignStationRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignStationRequest>(
      `${this.apiUrl}/api/services/station/assign`,
      req
    );
  }

  async unassignStation(req: AssignUnassignStationRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignStationRequest>(
      `${this.apiUrl}/api/services/station/unassign`,
      req
    );
  }

  async assignAllStations(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/services/station/assignall`,
      req
    );
  }

  async unassignAllStations(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/services/station/unassignall`,
      req
    );
  }

  async getServiceModel(params: PaginationParams & { serviceId: number }) {
    return this.get<GetServiceModelResponse>(`${this.apiUrl}/api/models/byserviceid`, params);
  }

  async assignModel(req: AssignUnassignModelRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignModelRequest>(
      `${this.apiUrl}/api/services/model/assign`,
      req
    );
  }

  async unassignModel(req: AssignUnassignModelRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignModelRequest>(
      `${this.apiUrl}/api/services/model/unassign`,
      req
    );
  }

  async assignAllModels(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/services/model/assignall`,
      req
    );
  }

  async unassignAllModels(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/services/model/unassignall`,
      req
    );
  }

  async getServicePriceList(params: PaginationParams & { serviceId: number }) {
    return this.get<GetServicePriceListResponse>(`${this.apiUrl}/api/pricelists/byserviceid`, params);
  }

  async assignPriceList(req: AssignPriceListRequest) {
    return this.post<AssignUnassignResponse, AssignPriceListRequest>(
      `${this.apiUrl}/api/services/pricelist/assign`,
      req
    );
  }

  async unassignPriceList(req: UnassignPriceListRequest) {
    return this.put<AssignUnassignResponse, UnassignPriceListRequest>(
      `${this.apiUrl}/api/services/pricelist/unassign`,
      req
    );
  }

  async getServiceById(id: number | undefined) {
    return this.get<GetServiceByIdResponse>(`${this.apiUrl}/api/services/${id}`);
  }
}
