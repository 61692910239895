// ServiceProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ServiceProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignPriceListRequest,
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignItemRequest,
  AssignUnassignModelRequest,
  AssignUnassignResponse,
  AssignUnassignStationRequest,
  AssignUnassignVehicleTypeRequest,
  ChangeStatusServiceResponse,
  DeleteServiceResponse,
  ServiceRequest,
  ServiceResponse,
  UnassignPriceListRequest,
} from './interface';
import { useCallback } from 'react';

type UseServiceServiceProps = {
  onSuccess: (data: ServiceResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateServiceServiceProps = {
  onSuccess: (data: ServiceResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteServiceServiceProps = {
  onSuccess: (data: DeleteServiceResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusServiceServiceProps = {
  onSuccess: (data: ChangeStatusServiceResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};
type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useServiceService = ({ onError, onSuccess }: UseServiceServiceProps) => {
  const {
    mutateAsync: createService,
    isPending: isCreateServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-Service-request'],
    mutationFn: (req: ServiceRequest) => ServiceProfileService.createService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateService = useCallback(
    async (ServiceData: ServiceRequest) => {
      try {
        createService(ServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [createService]
  );

  return {
    onCreateService,
    isCreateServiceLoading,
    data,
    error,
    isError,
  };
};

export const useGetServiceService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Service-data'],
      queryFn: async ({ pageParam }) => {
        return ServiceProfileService.getService({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateServiceService = ({ id, onError, onSuccess }: UpdateServiceServiceProps) => {
  const {
    mutateAsync: updateService,
    isPending: isUpdateServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-Service-request'],
    mutationFn: (req: ServiceRequest) => ServiceProfileService.updateService(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateService = useCallback(
    async (req: ServiceRequest) => {
      try {
        updateService(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateService]
  );

  return {
    onUpdateService,
    isUpdateServiceLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteServiceService = ({ id, onError, onSuccess }: DeleteServiceServiceProps) => {
  const {
    mutateAsync: deleteService,
    isPending: isDeleteServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-Service-request'],
    mutationFn: () => ServiceProfileService.deleteService(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteService = useCallback(async () => {
    try {
      deleteService();
    } catch (err) {
      console.error(err);
    }
  }, [deleteService]);

  return {
    onDeleteService,
    isDeleteServiceLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusServiceService = ({ active, id, onError, onSuccess }: ChangeStatusServiceServiceProps) => {
  const {
    mutateAsync: changeStatusService,
    isPending: isChangeStatusServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-service-request'],
    mutationFn: (req: ChangeStatusRequest) => ServiceProfileService.changeStatusService(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusService = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusService(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusService]
  );

  return {
    onChangeStatusService,
    isChangeStatusServiceLoading,
    data,
    error,
    isError,
  };
};

export const useGetServiceItemService = (initialParams: PageSize, serviceId: number, itemGroupId?: number | null) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-services-items-data'],
      queryFn: async ({ pageParam }) => {
        return ServiceProfileService.getServiceItem({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          serviceId: serviceId,
          title: initialParams.title,
          itemGroupId: itemGroupId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignItemService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignItem,
    isPending: isAssignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-items-request'],
    mutationFn: (req: AssignUnassignItemRequest) => ServiceProfileService.assignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignItem = useCallback(
    async (assignItemData: AssignUnassignItemRequest) => {
      try {
        assignItem(assignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignItem]
  );

  return {
    onAssignItem,
    isAssignItemLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignItemService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignItem,
    isPending: isUnassignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-items-request'],
    mutationFn: (req: AssignUnassignItemRequest) => ServiceProfileService.unassignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignItem = useCallback(
    async (unassignItemData: AssignUnassignItemRequest) => {
      try {
        unassignItem(unassignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignItem]
  );

  return {
    onUnassignItem,
    isUnassignItemLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllItemService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllItems,
    isPending: isAssignAllItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-items-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceProfileService.assignAllItems(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllItems = useCallback(
    async (assignAllItemData: AssignUnassignAllRequest) => {
      try {
        assignAllItems(assignAllItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllItems]
  );

  return {
    onAssignAllItems,
    isAssignAllItemLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllItemService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllItems,
    isPending: isUnassignAllItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-items-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceProfileService.unassignAllItems(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllItems = useCallback(
    async (unassignAllItemData: AssignUnassignAllRequest) => {
      try {
        unassignAllItems(unassignAllItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllItems]
  );

  return {
    onUnassignAllItems,
    isUnassignAllItemLoading,
    data,
    error,
    isError,
  };
};

export const useGetServiceVehicleTypeService = (initialParams: PageSize, serviceId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-services-vehicletypes-data'],
      queryFn: async ({ pageParam }) => {
        return ServiceProfileService.getServiceVehicleType({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          serviceId: serviceId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignVehicleTypeService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignVehicleType,
    isPending: isAssignVehicleTypeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-vehicletypes-request'],
    mutationFn: (req: AssignUnassignVehicleTypeRequest) => ServiceProfileService.assignVehicleType(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignVehicleType = useCallback(
    async (assignVehicleTypeData: AssignUnassignVehicleTypeRequest) => {
      try {
        assignVehicleType(assignVehicleTypeData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignVehicleType]
  );

  return {
    onAssignVehicleType,
    isAssignVehicleTypeLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignVehicleTypeService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignVehicleType,
    isPending: isUnassignVehicleTypeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-vehicletypes-request'],
    mutationFn: (req: AssignUnassignVehicleTypeRequest) => ServiceProfileService.unassignVehicleType(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignVehicleType = useCallback(
    async (unassignVehicleTypeData: AssignUnassignVehicleTypeRequest) => {
      try {
        unassignVehicleType(unassignVehicleTypeData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignVehicleType]
  );

  return {
    onUnassignVehicleType,
    isUnassignVehicleTypeLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllVehicleTypeService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllVehicleTypes,
    isPending: isAssignAllVehicleTypeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-vehicletypes-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceProfileService.assignAllVehicleTypes(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllVehicleTypes = useCallback(
    async (assignAllVehicleTypeData: AssignUnassignAllRequest) => {
      try {
        assignAllVehicleTypes(assignAllVehicleTypeData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllVehicleTypes]
  );

  return {
    onAssignAllVehicleTypes,
    isAssignAllVehicleTypeLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllVehicleTypeService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllVehicleTypes,
    isPending: isUnassignAllVehicleTypeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-vehicletypes-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceProfileService.unassignAllVehicleTypes(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllVehicleTypes = useCallback(
    async (unassignAllVehicleTypeData: AssignUnassignAllRequest) => {
      try {
        unassignAllVehicleTypes(unassignAllVehicleTypeData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllVehicleTypes]
  );

  return {
    onUnassignAllVehicleTypes,
    isUnassignAllVehicleTypeLoading,
    data,
    error,
    isError,
  };
};

export const useGetServiceStationService = (initialParams: PageSize, serviceId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-services-stations-data'],
      queryFn: async ({ pageParam }) => {
        return ServiceProfileService.getServiceStation({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          serviceId: serviceId,
          name: initialParams.name,
          phone: initialParams.phone,
          email: initialParams.email,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignStationService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignStation,
    isPending: isAssignStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-stations-request'],
    mutationFn: (req: AssignUnassignStationRequest) => ServiceProfileService.assignStation(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignStation = useCallback(
    async (assignStationData: AssignUnassignStationRequest) => {
      try {
        assignStation(assignStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignStation]
  );

  return {
    onAssignStation,
    isAssignStationLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignStationService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignStation,
    isPending: isUnassignStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-stations-request'],
    mutationFn: (req: AssignUnassignStationRequest) => ServiceProfileService.unassignStation(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignStation = useCallback(
    async (unassignStationData: AssignUnassignStationRequest) => {
      try {
        unassignStation(unassignStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignStation]
  );

  return {
    onUnassignStation,
    isUnassignStationLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllStationService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllStations,
    isPending: isAssignAllStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-stations-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceProfileService.assignAllStations(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllStations = useCallback(
    async (assignAllStationData: AssignUnassignAllRequest) => {
      try {
        assignAllStations(assignAllStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllStations]
  );

  return {
    onAssignAllStations,
    isAssignAllStationLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllStationService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllStations,
    isPending: isUnassignAllStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-stations-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceProfileService.unassignAllStations(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllStations = useCallback(
    async (unassignAllStationData: AssignUnassignAllRequest) => {
      try {
        unassignAllStations(unassignAllStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllStations]
  );

  return {
    onUnassignAllStations,
    isUnassignAllStationLoading,
    data,
    error,
    isError,
  };
};

export const useGetServiceModelService = (initialParams: PageSize, serviceId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-services-models-data'],
      queryFn: async ({ pageParam }) => {
        return ServiceProfileService.getServiceModel({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          serviceId: serviceId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignModelService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignModel,
    isPending: isAssignModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-models-request'],
    mutationFn: (req: AssignUnassignModelRequest) => ServiceProfileService.assignModel(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignModel = useCallback(
    async (assignModelData: AssignUnassignModelRequest) => {
      try {
        assignModel(assignModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignModel]
  );

  return {
    onAssignModel,
    isAssignModelLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignModelService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignModel,
    isPending: isUnassignModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-models-request'],
    mutationFn: (req: AssignUnassignModelRequest) => ServiceProfileService.unassignModel(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignModel = useCallback(
    async (unassignModelData: AssignUnassignModelRequest) => {
      try {
        unassignModel(unassignModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignModel]
  );

  return {
    onUnassignModel,
    isUnassignModelLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllModelService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllModels,
    isPending: isAssignAllModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-models-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceProfileService.assignAllModels(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllModels = useCallback(
    async (assignAllModelData: AssignUnassignAllRequest) => {
      try {
        assignAllModels(assignAllModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllModels]
  );

  return {
    onAssignAllModels,
    isAssignAllModelLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllModelService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllModels,
    isPending: isUnassignAllModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-models-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceProfileService.unassignAllModels(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllModels = useCallback(
    async (unassignAllModelData: AssignUnassignAllRequest) => {
      try {
        unassignAllModels(unassignAllModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllModels]
  );

  return {
    onUnassignAllModels,
    isUnassignAllModelLoading,
    data,
    error,
    isError,
  };
};

export const useGetServicePriceListService = (initialParams: PageSize, serviceId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-services-pricelists-data'],
      queryFn: async ({ pageParam }) => {
        return ServiceProfileService.getServicePriceList({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          serviceId: serviceId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignServicePriceListService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignPriceList,
    isPending: isAssignPriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-service-pricelists-request'],
    mutationFn: (req: AssignPriceListRequest) => ServiceProfileService.assignPriceList(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignPriceList = useCallback(
    async (assignPriceListData: AssignPriceListRequest) => {
      try {
        assignPriceList(assignPriceListData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignPriceList]
  );

  return {
    onAssignPriceList,
    isAssignPriceListLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignServicePriceListService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignPriceList,
    isPending: isUnassignPriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-services-pricelist-request'],
    mutationFn: (req: UnassignPriceListRequest) => ServiceProfileService.unassignPriceList(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignPriceList = useCallback(
    async (unassignPriceListData: UnassignPriceListRequest) => {
      try {
        unassignPriceList(unassignPriceListData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignPriceList]
  );

  return {
    onUnassignPriceList,
    isUnassignPriceListLoading,
    data,
    error,
    isError,
  };
};
