import { selectUserInfo } from "features/user/userSlice";
import { Grid, InputAdornment, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ClientProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Client  } from "assets/SideBarIcons/customer.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import Loader from "layout/Loader";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import CustomerTabs from "./CustomerTabs";
import CommonCheckbox from "common/CheckBox/Checkbox";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GetClientByIdResponse } from "subModule/src/services/client/interface";

interface FormData { [key: string]: any; }

export default function CustomersModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, control, formState: { errors, dirtyFields, isDirty }, watch, setValue, reset } = useForm<FormData>({ defaultValues: {} });
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [showSecTitleField, setShowSecTitleField] = useState<boolean>(false);
    useEffect(() => {
        if (params?.id) {
            if (loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getByID = () => {
        ClientProfileService.getClientByID(+(params as any)?.id, 'customers').then((res: GetClientByIdResponse) => {
            if (res.success) {
                reset({ ...res?.data || {}, 
                    username: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test((res?.data as any)?.username) ? res?.data?.username : '',
                });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if (params?.id) {
            ClientProfileService.updateClient(+params?.id, { ...data, type: 170, email: data.username, }, 'customers').then((response: any) => {
                if (response.success) {
                    getByID();
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            delete data.showConfirmPassword; delete data.showPassword; delete data.confirmPassword;
            ClientProfileService.createClient({ ...data, 
                type: 170,
                email: data.username,
                active: true,
                business: {},
            }, 'customers').then((response: any) => {
                if (response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate('/customers');
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate('/customers')}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editCustomer') : t('addCustomer')}
            icon={<Client height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_CUSTOMER_CREATE")}
        />
        <div className={`${params?.id ? 'flex' : ''}`}>
            {params?.id ? <CustomerTabs params={params} selectedIndex={0} /> : null}
            {loading ? <Loader /> : 
                <SimpleModal
                    visable={true}
                    title={params?.id ? t('editCustomer') : t('addCustomer')}
                    isButtonVisable={true}
                    isVisableBtn={IsBtnEnabled}
                    btnclosetext="Back"
                    buttonText={params?.id ? "update" : "submit"}
                    attBtnNotshow={false}
                    notVisableBackbtn={true}
                    showPortList={handleSubmit(onSubmit)}
                    formSubmit={handleSubmit(onSubmit)}
                    content={
                        <Grid container component="form" id="form" className="items-center mb-2" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("name", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })}
                                    label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                    fullWidth
                                    error={!!errors.name}
                                    helperText={errors.name?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                            <Group onClick={() => setShowSecTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                        </InputAdornment>)
                                    }}
                                    sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                                />
                                {showSecTitleField && <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                        <TextField
                                            {...register("secondaryName")}
                                            label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                            fullWidth
                                            error={!!errors.secondaryName}
                                            helperText={errors.secondaryName?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                </div>}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="email"
                                    {...register("username", {
                                        required: t('This field is required.'),
                                        pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: t('validEmail') }
                                    },)}
                                    label={t("email") + ' *'}
                                    fullWidth
                                    error={!!errors.username}
                                    helperText={errors.username?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            {params?.id ? null : <>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        type={watch('showPassword') ? 'text' : 'password'}
                                        {...register('password', {
                                            required: t('This field is required.'),
                                            validate: { passwordLength: (value) => value.length >= 8 || t('minPasswordLength'), },
                                        })}
                                        label={t('password') + ' *'}
                                        fullWidth
                                        error={!!errors.password}
                                        helperText={errors.password?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (watch('password') ? <InputAdornment position="end" className="cursor-pointer">
                                                {watch('showPassword') ? (
                                                    <VisibilityIcon className="text-primary-color" onClick={() => setValue('showPassword', false)}/>
                                                ) : (
                                                    <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showPassword', true)} />
                                                )}
                                            </InputAdornment> : null),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        type={watch('showConfirmPassword') ? 'text' : 'password'}
                                        {...register("confirmPassword", {
                                            required: t('This field is required.'),
                                            validate: { passwordLength: (value) => (value === watch('password')) || t('confirmPasswordNewPassword'), },
                                        },)}
                                        label={t("confirmNewPassword") + ' *'}
                                        fullWidth
                                        error={!!errors.confirmPassword}
                                        helperText={errors.confirmPassword?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (watch('confirmPassword') ? <InputAdornment position="end" className="cursor-pointer">
                                                {watch('showConfirmPassword') ? (
                                                    <VisibilityIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', false)}/>
                                                ) : (
                                                    <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', true)} />
                                                )}
                                            </InputAdornment> : null),
                                        }}
                                    />
                                </Grid>
                            </>}
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="text"
                                    {...register("phone", {
                                        validate: {
                                            phoneLength: (value) =>
                                                value === '' || (value.length >= 5 && value.length <= 17) || t('phoneLength'),
                                            nonNegative: (value) =>
                                                value === '' || /^\+?[\p{N}]+$/u.test(value) || t('onlyPositiveNumbers'),
                                        },
                                    })}
                                    label={t("phoneNumber")}
                                    fullWidth
                                    error={!!errors.phone}
                                    helperText={errors.phone?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("address")}
                                    label={t('address')}
                                    fullWidth
                                    error={!!errors.address}
                                    helperText={errors.address?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    rows={1}
                                    sx={{
                                        '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                        '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("source")}
                                    label={t('source')}
                                    fullWidth
                                    error={!!errors.source}
                                    helperText={errors.source?.message as string}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="emailValid"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (<div className="relative top-[10px]">
                                        <CommonCheckbox
                                            {...field}
                                            label={t("Valid Email")}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                            checked={field.value}
                                            color="success"
                                        />
                                    </div>)}
                                />
                            </Grid>
                        </Grid>
                    }
                />}
        </div>
    </>)
}