import axios, { AxiosInstance, AxiosRequestConfig, CancelTokenSource, RawAxiosRequestHeaders } from 'axios';

export type BaseServiceOptions = {
  // If this is not passed then the service will create
  // its own axios instance.
  axiosInstance?: AxiosInstance;
  apiUrl: string | undefined;
};

export class BaseService {
  private axiosInstance: AxiosInstance;

  private cancelTokenSource: CancelTokenSource | null = null;

  protected apiUrl!: string | undefined;

  // protected coreApiServicePrefix: string;

  // protected coreApiUrl: string;

  constructor(opts: BaseServiceOptions) {
    this.axiosInstance =
      opts.axiosInstance ??
      axios.create({
        timeout: 15000,
        validateStatus: (status) => status >= 200 && status < 300,
      });
    this.apiUrl = opts.apiUrl;
    // this.coreApiServicePrefix = 'core-events';
    // this.coreApiUrl = `${this.apiUrl}/${this.coreApiServicePrefix}`;
  }

  // API Generic functions

  private setupCancelToken() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('New request initiated');
    }
    this.cancelTokenSource = axios.CancelToken.source();

    return this.cancelTokenSource.token;
  }

  public async get<TRes, TParams extends Record<string, string> = {}>(
    url: string,
    params?: TParams,
    options?: AxiosRequestConfig<unknown>
  ) {
    const config: AxiosRequestConfig<unknown> = { params, ...options, cancelToken: this.setupCancelToken() };

    return this.axiosInstance.get<TRes>(url, config).then((res) => res.data);
  }

  public async post<TRes, TVars>(url: string, data: TVars, config?: AxiosRequestConfig<TVars>) {
    return this.axiosInstance.post(url, data, config).then((res) => res.data as TRes);
  }

  public async put<TRes, TVars>(url: string, data: TVars, config?: AxiosRequestConfig<TVars>) {
    return this.axiosInstance.put(url, data, config).then((res) => res.data as TRes);
  }

  public async patch<TRes, TVars>(url: string, data: TVars) {
    return this.axiosInstance.patch(url, data).then((res) => res.data as TRes);
  }

  public async delete<TRes>(url: string, params?: Record<string, string>) {
    return this.axiosInstance.delete(url, { params }).then((res) => res.data as TRes);
  }

  public async deleteAcc<TRes, TVars>(url: string, data?: TVars, config?: RawAxiosRequestHeaders) {
    return this.axiosInstance.delete(url, { data: data, headers: config }).then((res) => res.data as TRes);
  }
}
