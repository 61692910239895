import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useNavigate } from "react-router-dom";

interface tabProps {
    selectedIndex: number;
}
export default function AccountSettingsTabs(props: tabProps) {
    const navigate = useNavigate();
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) navigate(`/account-settings/change-password`);
        else if (tabId === 1) navigate(`/account-settings`);
        else if (tabId === 2) navigate(`/account-settings/invoice-templates`);
    }
    const tabs = [
        { id: 0, title: `changePassword`, child: <></> },
        { id: 1, title: `businessSettings`, child: <></> },
        { id: 2, title: `invoiceTemplates`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
                return false;
            case 1:
                return !checkPrivileges('ROLE_BUSINESS_UPDATE');
            case 2:
                return false;
            default:
                return true;
        }
    });

    return (<>
        <VerticalTabs tabs={tabs} selectedIndex={props.selectedIndex} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
    </>);
}