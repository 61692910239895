import { Grid, InputAdornment, TextField, Tooltip, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Packages  } from "assets/SideBarIcons/pakage.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetPackageByIdResponse } from "subModule/src/services/packages/interface";
import Loader from "layout/Loader";
import DynamicSelect from "common/Select/Select";
import { PackagesProfileService, TaxProcedureProfileService } from "subModule/src/core/services";
import { useSelector } from "react-redux";
import { selectUserInfo } from "features/user/userSlice";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import CommonCheckbox from "common/CheckBox/Checkbox";
import PackageTabs from "./PackageTabs";
import { useGetPackageGroupDropdownService } from "subModule/src/services/packagegroup/usePackageGroup";
import FormDialog from "common/Modal/Modal";
import { ReactComponent as ModalBtn } from "assets/Modal/ModalBtn.svg"
import { useGetTaxProcedureDropdownService } from "subModule/src/services/taxProcedure/useTaxProcedure";
import Currency from 'common/Others/Currency';
import { GetDefaultTaxProcedureResponse } from "subModule/src/services/taxProcedure/interface";
import { useGetItemGroupDropdownService } from "subModule/src/services/itemgroup/useItemGroup";
import PackageGroupModal from "pages/PackageGroup/PackageGroupModal";
interface FormData { [key: string]: any; }
export default function PackagesModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, control, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, setValue, watch, getValues } = useForm<FormData>({ defaultValues: {
        applyToAllStations: true,
        pricingType: { value: 2, label: t('serviceSum') }
    } });
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [showSecTitleField, setShowSecTitleField] = useState<boolean>(false);
    const [addNew, setAddNew] = useState<boolean>(false);
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const loggedInUserCurrency = loggedInUserData?.currency;
    const defaultPriceList = loggedInUserData?.priceListTitle;
    const { data: itemGroupsData, refetch: itemGroupsRefetch } = useGetItemGroupDropdownService({ pageNumber: 1, size: 1000 });
    const { data: taxProceduresData, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 1000 });
    const { data: packageGroupsData, refetch: packageGroupsRefetch } = useGetPackageGroupDropdownService({ pageNumber: 1, size: 1000 });
    const itemGroups = (itemGroupsData?.pages[0]?.data?.content || []).map((item: { id?: number, title?: string }) => ({ value: item.id, label: item.title }));
    const taxProcedures = (taxProceduresData?.pages[0]?.data?.content || []).map((item: { id: number, title: string, percentageValue?: number }) => ({ value: item.id, label: item.title, percentageValue: item.percentageValue }))
    const packageGroups = (packageGroupsData?.pages[0]?.data?.content || []).map((item: { id?: number, title?: string }) => ({ value: item.id, label: item.title }));
    const groupOptions = [
        { value: 1, label: t('groups') },
        { value: 2, label: t('open') },
    ];
    const pricingTypes = [
        { value: 1, label: t('fixed') },
        { value: 2, label: t('serviceSum') },
    ]
    const currencyOptions = Currency.map((item) => ({ value: item.value, label: item.title, symbol: item.symbol }));
    const currencyLabel = currencyOptions.find((option:any) => option.value === loggedInUserCurrency)?.symbol || '';
    useEffect(() => {
        itemGroupsRefetch();
        taxProceduresRefetch();
        packageGroupsRefetch();
        if(!params?.id) getDefaultTaxProcedure();
    }, []);
    useEffect(() => {
        if (params?.id && itemGroupsData && packageGroupsData && taxProceduresData) {
            if(loading) getByID();
        }
    }, [params, itemGroupsData, packageGroupsData, taxProceduresData]);
    useEffect(() => {
        if(isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getDefaultTaxProcedure = () => {
        TaxProcedureProfileService.getDefaultTaxProcedure(4).then((res: GetDefaultTaxProcedureResponse) => {
            if (res.success) {
                setValue('taxProcedureId', { value: res.data?.id, label: res.data?.title, percentageValue: res.data?.percentageValue });
                setLoading(false);
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const getByID = () => {
        PackagesProfileService.getPackageById(+(params as any)?.id).then((res: GetPackageByIdResponse) => {
            if (res.success) {
                reset({
                    ...res?.data || {},
                    itemGroup: res.data?.itemGroup ? { value: res.data?.itemGroup, label: groupOptions.find((v) => v.value === res.data?.itemGroup)?.label || res.data?.itemGroup } : null,
                    itemGroupId: res.data?.itemGroupId ? { value: res.data?.itemGroupId, label: itemGroups.find((v) => v.value === res.data?.itemGroupId)?.label || res.data?.itemGroupId } : null,
                    taxProcedureId: res.data?.taxProcedureId ? { value: res.data?.taxProcedureId, label: taxProcedures.find((v) => v.value === res.data?.taxProcedureId)?.label || res.data?.taxProcedureId, percentageValue: taxProcedures.find((v) => v.value === res.data?.taxProcedureId)?.percentageValue } : null,
                    pricingType: res.data?.pricingType ? { value: res.data?.pricingType, label: pricingTypes.find((v) => v.value === res.data?.pricingType)?.label } : null,
                    packageGroupId: res.data?.packageGroupId ? { value: res.data?.packageGroupId, label: packageGroups.find((v) => v.value === res.data?.packageGroupId)?.label || res.data?.packageGroupId } : null,
                    taxValue: ((res?.data.price || 0) * ((taxProcedures.find((v) => v.value === res.data?.taxProcedureId)?.percentageValue || 0)/100))?.toFixed(2),
                });
                setLoading(false);
                setValue('priceWithTax', (parseFloat(watch('price')) + parseFloat(watch('taxValue'))));
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        delete data.taxValue; delete data.priceWithTax; delete data.tax;
        if (params?.id) {
            PackagesProfileService.updatePackages(+params?.id, {
                ...data,
                itemGroupId: data?.itemGroupId?.value || 0,
                taxProcedureId: data?.isTaxable && data?.taxProcedureId?.value ? data?.taxProcedureId?.value : 0,
                pricingType: data?.pricingType?.value || 0,
                itemGroup: data?.itemGroup?.value || 0,
                packageGroupId: data?.packageGroupId?.value || 0,
            }).then((response: any) => {
                if (response.success) {
                    getByID();
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('package') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            PackagesProfileService.createPackages({
                ...data,
                itemGroupId: data?.itemGroupId?.value || 0,
                taxProcedureId: data?.isTaxable && data?.taxProcedureId?.value ? data?.taxProcedureId?.value : 0,
                pricingType: data?.pricingType?.value || 0,
                itemGroup: data?.itemGroup?.value || 0,
                packageGroupId: data?.packageGroupId?.value || 0,
            }).then((response: any) => {
                if (response.success) {
                    navigate('/packages');
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('package') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate('/packages')}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('packages') : t('addPackages')}
            icon={<Packages height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_PACKAGE_CREATE")}
        />
        <div className={`${params?.id ? 'flex' : ''}`}>
            {params?.id ? <PackageTabs params={params} selectedIndex={0} formData={getValues()} /> : null}
            {loading ? <Loader /> : <SimpleModal
                visable={true}
                title={params?.id ? t('packages') : t('addPackages')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={<>
                    <Grid container component="form" id="form" spacing={2} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("packageCode", { validate: { required: (value) => value?.trim() !== '' || t('This field is required.'), } })}
                                    label={t('packageCode') + ' *'}
                                    fullWidth
                                    error={!!errors.packageCode}
                                    helperText={errors.packageCode?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("title", { validate: { required: (value) => value?.trim() !== '' || t('This field is required.'), } })}
                                    label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                    fullWidth
                                    error={!!errors.title}
                                    helperText={errors.title?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                            <Group onClick={() => setShowSecTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                        </InputAdornment>)
                                    }}
                                    sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                                />
                                {showSecTitleField && <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                        <TextField
                                            {...register("secondaryTitle")}
                                            label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                            fullWidth
                                            error={!!errors.secondaryTitle}
                                            helperText={errors.secondaryTitle?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                </div>}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="itemGroup"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t('Item')}
                                            {...field}
                                            options={groupOptions}
                                            isSearchable
                                            isClearable
                                            onChange={(selectedOption: any) => {field.onChange(selectedOption); setValue('itemGroupId', null);}}
                                            error={errors.itemGroup?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                            {watch("itemGroup")?.value === 1 ? <Grid item xs={12} md={3}>
                                <Controller
                                    name="itemGroupId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t("This field is required.") }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("itemGroup") + " *"}
                                            {...field}
                                            options={itemGroups}
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.itemGroupId?.message as string}
                                        />
                                    )}
                                />
                            </Grid> : null}
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12} md={3} className='grid' sx={{ gridTemplateColumns: '85% 10%' }}>
                                <Controller
                                    name="packageGroupId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t('packageGroup')}
                                            {...field}
                                            options={packageGroups}
                                            isSearchable
                                            isClearable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.packageGroupId?.message as string}
                                        />
                                    )}
                                />
                                {checkPrivileges('ROLE_PACKAGEGROUP_CREATE') && <Tooltip onClick={() => setAddNew(true)} title={t('addPackageGroup')}><ModalBtn className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'/></Tooltip>}
                                {addNew && <FormDialog
                                    open={addNew}
                                    onClose={(event, reason) => event && reason !== 'backdropClick' ? setAddNew(false) : ''}
                                    title={t('addPackageGroup')}
                                    createPopup={true}
                                    fullWidth
                                    hideActions={true}
                                    content={<PackageGroupModal setAddNew={setAddNew} refetch={packageGroupsRefetch} />}
                                />}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="applyToAllStations"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <CommonCheckbox
                                            {...field}
                                            label={t("applyToAllStations")}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                            checked={field.value}
                                            color="success"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="isTaxable"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <CommonCheckbox
                                            {...field}
                                            label={t("isTaxable")}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                field.onChange(event.target.checked);
                                                if(!(event.target.checked)) {setValue('taxProcedureId', null);}
                                                if(!params?.id && event.target.checked) getDefaultTaxProcedure();
                                            }}
                                            checked={field.value}
                                            color="success"
                                        />
                                    )}
                                />
                            </Grid>
                            {watch("isTaxable") ? <Grid item xs={12} md={3}>
                                <Controller
                                    name="taxProcedureId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t("This field is required.") }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("taxProcedure") + " *"}
                                            {...field}
                                            options={taxProcedures}
                                            isSearchable
                                            onChange={(selectedOption: any) => { 
                                                field.onChange(selectedOption); setValue('taxValue', (watch('price') * (watch('taxProcedureId')?.percentageValue/100))?.toFixed(2)); 
                                                setValue('priceWithTax', parseFloat(watch('price')) + ((watch('price') * (selectedOption.percentageValue/100))));
                                            }}
                                            error={errors.taxProcedureId?.message as string}
                                        />
                                    )}
                                />
                            </Grid> : null}
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12}>
                                <Typography className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('price')}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t(defaultPriceList)}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    {...register("price", { required: t('This field is required.') })}
                                    onChange={(e: any) => {
                                        const value = parseFloat(e.target.value);
                                        e.target.value = value < 0 ? 0 : value || 0;
                                        setValue('priceWithTax', (parseFloat(e.target.value) + (
                                            parseFloat(e.target.value) * 
                                            parseFloat((taxProcedures.find((option:any) => (option.value === watch('taxProcedureId')?.value))?.percentageValue || 0) as any)/100
                                          ))?.toFixed(2));
                                          setValue('taxValue', (parseFloat(e.target.value) * (watch('taxProcedureId')?.percentageValue/100))?.toFixed(2));
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t('price') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '') + ' *'}
                                    fullWidth
                                    error={!!errors.price}
                                    helperText={errors.price?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            {watch("isTaxable") && watch("taxProcedureId")?.value ? <>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        type="number"
                                        {...register("tax")}
                                        label={t('tax') + (' (%)')}
                                        fullWidth
                                        error={!!errors.tax}
                                        helperText={errors.tax?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true, endAdornment: (<InputAdornment position="end"><span className='font-Saira text-[14px]'>{(watch('taxValue') || 0) + (loggedInUserCurrency ? (' ' + currencyLabel) : '')}</span></InputAdornment>) }}
                                        value={taxProcedures.find((option: any) => option.value === watch('taxProcedureId')?.value)?.percentageValue || 0}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        type="number"
                                        {...register("priceWithTax")}
                                        label={t('priceWithTax') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '')}
                                        fullWidth
                                        error={!!errors.priceWithTax}
                                        helperText={errors.priceWithTax?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            e.target.value = value < 0 ? 0 : value || 0;
                                            const price = parseFloat(e.target.value) / (1 + (taxProcedures.find((option: any) => option.value === watch('taxProcedureId')?.value)?.percentageValue || 0)/100);
                                            setValue('price', (price - Math.floor(price)) > 0.9 ? Math.round(price) : price?.toFixed(2));
                                            setValue('taxValue', (watch('price') * (watch('taxProcedureId')?.percentageValue/100))?.toFixed(2));
                                            if(!IsBtnEnabled) setIsBtnEnabled(true);
                                        }}
                                    />
                                </Grid>
                            </> : null}
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="pricingType"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t("This field is required.") }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t('pricingType') + ' *'}
                                            {...field}
                                            options={pricingTypes}
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.pricingType?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] ml-[3px]'>
                            <Grid item xs={12}>
                                <Typography className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('extraNotes')}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField 
                                    type="number"
                                    {...register("sortOrder")} 
                                    onChange={(e: any) => {
                                        const value = parseInt(e.target.value);
                                        e.target.value = value < 1 ? 1 : value > 100 ? 100 : value || "";
                                        if(!IsBtnEnabled) setIsBtnEnabled(true);
                                    }}
                                    label={t("Sort Order (Value 1-100)")}
                                    fullWidth
                                    error={!!errors.sortOrder} 
                                    helperText={errors.sortOrder?.message as string} 
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("description")}
                                    label={t('description')}
                                    fullWidth
                                    error={!!errors.description}
                                    helperText={errors.description?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    rows={1}
                                    sx={{
                                        '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                        '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '78px' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("extraNotes")}
                                    label={t('extraNotes')}
                                    fullWidth
                                    error={!!errors.extraNotes}
                                    helperText={errors.extraNotes?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    rows={1}
                                    sx={{
                                        '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                        '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '78px' }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
            />}
        </div>
    </>)
}