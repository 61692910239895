import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useNavigate } from 'react-router-dom';
import FormDialog from 'common/Modal/Modal';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Vehicle  } from "../../assets/SideBarIcons/vehicle.svg";
import BackendPaginationTable from 'common/Table/TableWithPagination';
import VehicleSearchHeader from 'components/Vehicle/VehicleSearchHeader';
import { VehicleProfileService } from 'subModule/src/core/services';
import { ChangeStatusVehicleResponse, VehicleResponse } from 'subModule/src/services/vehicle/interface';
import { DeleteData } from 'subModule/src/type';
import Loader from 'layout/Loader';
import { useGetVehicleService } from 'subModule/src/services/vehicle/useVehicle';
import VehicleSearchHeaderMenu from 'components/Vehicle/VehicleSearchHeaderMenu';

interface SearchFormData {
    [key: string]: string | any;
}

export default function VehicleListing() {
    const navigate = useNavigate();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<string[]>(['platenumber']);
    const [search, setSearch] = useState<any>({});
    const [row, setRow] = useState<VehicleResponse>({});
    const [statusModal, setStatusModal] = useState(false);
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({ platenumber: '', vin: '', makeTitle: '', modelTitle: '', customerName: '', customerPhone: '', });
    const { data: tableData, isLoading, error, refetch } = useGetVehicleService({ pageNumber: pageNo, size: pageSize, ...search });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (search && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 300)
        return () => clearTimeout(getData)
    }, [search, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const onEdit = (row: any) => navigate('/vehicle/' + row.id);
    const showRowView = (row: any) => navigate('/vehicle/' + row.id);
    const onDelete = (row: any) => {
        setDeleteModal(true);
        setRow(row);
    }
    const clearSearch = () => {
        setFilter(['platenumber']);
        setSearchFormData({ platenumber: '', vin: '', makeTitle: '', modelTitle: '', customerName: '', customerPhone: '', });
        if (Object.keys(search).length) {
            setLoading(true);
            setSearch({});
        }
    }
    const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData =>
            Object.fromEntries(Object.keys(prevFormData).map(key =>
                [key, value.includes(key) ? prevFormData[key] : '']
            ))
        );
        if (!value.length && Object.keys(search).length) {
            clearSearch();
        }
    };
    const handleChangeSearch = (field: string, value: any) => {
        const val = value;
        setSearchFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
    };
    const handleSubmitSearch = () => {
        const filteredSearch: Record<string, string> = {};
        Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) {
                filteredSearch[key] = value?.trim();
            }
        });
        if(Object.keys(filteredSearch).length > 0 && Object.values(filteredSearch).some(value => value)) {
            setLoading(true);
            setSearch(filteredSearch);
            setPageNo(1);
            setPageSize(pageSize);
        }
    };
    const onChangeStatus = (row: any) => {
        setRow(row);
        setStatusModal(true);
    }
    const actionslist = [
        { label: "edit", onClick: onEdit, icon: <EditIcon /> },
        { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
    ];
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_VEHICLE_UPDATE')
            ? (checkPrivileges('ROLE_VEHICLE_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_VEHICLE_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }
    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/vehicle/create')}
                heading={t('vehicles')}
                icon={<Vehicle height={28} width={28} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_VEHICLE_CREATE")}
            />
            {loading ? <Loader /> : <BackendPaginationTable
                columns={[
                    { id: 'plateNumber', label: 'plateNumber', numeric: false },
                    { id: 'userName', label: 'clientName', numeric: false },
                    { id: 'userPhone', label: 'clientPhone', numeric: false },
                    { id: 'makeTitle', label: 'make', numeric: false },
                    { id: 'modelTitle', label: 'model', numeric: false },
                    { id: 'vin', label: 'VIN', numeric: false },
                    ...(checkPrivileges("ROLE_VEHICLE_ACTIVE") ? [{ id: 'active', label: 'status', numeric: false }] : []),
                    ...(adjustActions(actionslist).length ? [{ id: 'actions', label: 'actions', numeric: false }] : []),
                  ]}
                data={tableData?.pages[0]?.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={tableData?.pages[0]?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={adjustActions(actionslist)}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                rowClick={checkPrivileges("ROLE_VEHICLE_UPDATE")}
                showRowView={showRowView}
                loadingSearch={false}
                headerComponent={<>
                    <VehicleSearchHeader
                        searchFormData={searchFormData}
                        handleChangeFilter={handleChangeFilter}
                        handleChangeSearch={handleChangeSearch}
                        handleSubmitSearch={handleSubmitSearch}
                        clearSearch={clearSearch}
                        {...{ filter, search }}
                    />
                    {checkPrivileges('ROLE_VEHICLE_IMPORT') ? <VehicleSearchHeaderMenu refetch={refetch} /> : null}
                </>}
            />}
            {openDeleteModal &&
                <FormDialog
                    open={openDeleteModal}
                    onClose={() => setDeleteModal(false)}
                    title={t('delete') + ' ' + t('vehicle')}
                    // icon={<div><Home/></div>}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>
                                    Are you sure you want to delete?
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setDeleteModal(false);
                        setLoading(true);
                        VehicleProfileService.deleteVehicle((row as any).id).then((response: DeleteData) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                if (tableData?.pages[0].data.content.length === 1) handleChangePage(pageNo - 1);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vehicle') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="Delete"
                />}
            {statusModal &&
                <FormDialog
                    open={statusModal}
                    onClose={() => setStatusModal(false)}
                    title="Change Status"
                    // icon={<div><Home/></div>}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t(`Are you sure?`)}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setStatusModal(false);
                        setLoading(true);
                        VehicleProfileService.changeStatusVehicle((row as any).id, row.active ? "inactive" : "active", {}).then((response: ChangeStatusVehicleResponse) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('status') + ' ' + t('changed') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="OK"
                />
            }
        </Box>
    )
}