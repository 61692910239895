import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Accounts } from '../../assets/SideBarIcons/accounts.svg';
import { useAssignBusinessInvoice, useGetBusinessInvoiceService, useUnassignBusinessInvoice } from "subModule/src/services/accountsettings/useAccountSettings";
import AccountSettingsTabs from './AccountSettingsTabs';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';

export default function InvoiceTemplatesTable() {
    const loggedInUserData = useSelector(selectUserInfo);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const { data: tableData, isLoading, error, refetch } = useGetBusinessInvoiceService({ pageNumber: pageNo, size: pageSize }, loggedInUserData?.id);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    let allInvoicesAssigned: unknown = tableData?.pages[0].data?.content.map(item => item.linked).every(linked => linked === true);
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoading(true);
        if (isChecked) {
            onAssignService({ businessId: loggedInUserData.id, templateId: row.id });
        } else {
            onUnassignService({ businessId: loggedInUserData.id, templateId: row.id });
        }
    }
    const { onAssignService } = useAssignBusinessInvoice({
        onSuccess: async (msg) => {
            const response: any = {};
            Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
            if (response.success) {
                if (tableData?.pages[0].data.content.length === tableData?.pages[0].data.content.filter(invoice => invoice.linked).length) {
                    allInvoicesAssigned = true;
                }
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('invoice') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
            if (err instanceof Error) {
                console.error(err.stack);
            }
        }
    })
    const { onUnassignService } = useUnassignBusinessInvoice({
        onSuccess: async (data) => {
            const response: any = {};
            Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
            if (response.success) {
                if (tableData?.pages[0].data.content.length !== tableData?.pages[0].data.content.filter(invoice => invoice.linked).length) {
                    allInvoicesAssigned = false;
                }
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('invoice') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                await refetch();
                setLoading(false);
                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
            }
        },
        onError: (err: any) => {
            console.log("err====,  ", err);
            enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
    });

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={false}
                showSaveBtn={false}
                heading={t('accountSettings')}
                icon={<Accounts height={28} width={28} />}
                btnText="back"
                btnType="back"
            />
            <Box>
                <div className='flex'>
                    <AccountSettingsTabs selectedIndex={2} />
                    <BackendPaginationTable
                        columns={[
                            { id: 'invoiceTemplateName', label: 'name', numeric: false },
                            { id: 'description', label: 'description', numeric: false },
                        ]}
                        data={tableData?.pages[0]?.data?.content || []}
                        pageNo={pageNo}
                        pageSize={pageSize}
                        tableData={tableData?.pages[0]?.data}
                        showCheckbox={true}
                        checkBoxAll={'checkBoxAll'}
                        showActions={true}
                        enableSorting={true}
                        showHeader={false}
                        showSearch={true}
                        showFilter={true}
                        showCreateButton={false}
                        actions={[]}
                        handleChangePage={handleChangePage}
                        handleChangeRows={handleChangeRows}
                        assignUnassignSingleEntity={assignUnassignSingleEntity}
                        allEntitiesAssigned={allInvoicesAssigned}
                        loadingSearch={loading}
                        assignPrivilege={true}
                        unassignPrivilege={true}
                        headerComponent={<></>}
                    />
                </div>
            </Box>
        </Box>
    )
}