import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { ReactComponent as Pen } from "assets/Modal/book_pen.svg";
import { ReactComponent as Delete } from "assets/Modal/delete.svg";
import { useGetWorkOrderService } from 'subModule/src/services/workorder/useWorkOrders';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useNavigate } from 'react-router-dom';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as WorkOrder } from "assets/Modal/workorder.svg"
import Loader from 'layout/Loader';
import CardWithPagination from 'common/Cards/CardWithPagination';
import WorkOrderSearchHeader from 'components/WorkOrder/WorkOrderSearchHeader';
import { useGetStationDropdownService } from 'subModule/src/services/station/useStation';

interface SearchFormData {
    [key: string]: string | any;
}

export default function WorkOrderListing() {
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<string[]>(['workOrderNo', 'isActive']);
    const [search, setSearch] = useState<any>({isActive: true});
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({ workOrderNo: '', stationId: 0, vehiclePlate: '', vehicleVin: '', isActive: true });
    const { data: tableData, isLoading, error, refetch } = useGetWorkOrderService({ pageNumber: pageNo, size: pageSize, ...search });
    const { data: stationData, refetch: stationRefetch } = useGetStationDropdownService({pageNumber: 1, size: 50});
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        stationRefetch();
    }, []);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (search && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 300)
        return () => clearTimeout(getData)
    }, [search, loading]);
    useEffect(() => {
        if(loading) handleSubmitSearch();
      }, [searchFormData.isActive]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const showRowView = (row: any) => navigate('/work-order/' + row.id);
    const clearSearch = () => {
        setFilter(['workOrderNo', 'isActive']);
        setSearchFormData({ workOrderNo: '', stationId: 0, vehiclePlate: '', vehicleVin: '', isActive: true });
        setLoading(true);
        setSearch({isActive: true});
    }
    const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData => 
            Object.fromEntries(Object.keys(prevFormData).map(key => 
              [key, value.includes(key) ? prevFormData[key] : (typeof prevFormData[key] === 'string' ? '' : 0)]
            ))
        )
        if(!value.length && Object.keys(search).length) {
            clearSearch();
        }
    };
    const handleChangeSearch = (field: string, value: any) => {
        const val = field === 'stationId' ? (value ? parseInt(value.value) || 0 : 0)
        : field === 'isActive' ? Boolean(value)  
        : value;
        setSearchFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
        if(field === 'isActive') setLoading(true);
    };
    const handleSubmitSearch = () => {
        setLoading(true);
        const filteredSearch: Record<string, string | number | boolean> = {};
        Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) {
            filteredSearch[key] = typeof value === 'string' ? value?.trim() : value;
            }
        });
        setSearch(filteredSearch);
        setPageNo(1);
        setPageSize(pageSize);
    };
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_WORKORDER_UPDATE')
            ? (checkPrivileges('ROLE_WORKORDER_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_WORKORDER_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }
    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/work-order/:id')}
                heading={t('workOrder')}
                icon={<WorkOrder height={28} width={28} />}
                btnText={"create"}
                btnType={"create"}
                isBtnEnabled={false}
                showCreateBtn={false}
            />
            {loading ? <Loader /> : <CardWithPagination 
                data={tableData?.pages[0]?.data?.content || []}
                cardsData={tableData?.pages[0]?.data || {}}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                showRowView={showRowView}
                actions={adjustActions([
                    { label: "edit", onClick: showRowView, icon: <Pen className="float-end" /> },
                    { label: "delete", onClick: () => {}, icon: <Delete className='float-end' /> },
                ])}
                rowClick={checkPrivileges("ROLE_WORKORDER_UPDATE")}
                type="workOrder"
                {...{ pageNo, pageSize }}
                loadingSearch={loading}
                headerComponent={
                  <WorkOrderSearchHeader 
                    searchFormData={searchFormData}
                    handleChangeFilter={handleChangeFilter}
                    handleChangeSearch={handleChangeSearch}
                    handleSubmitSearch={handleSubmitSearch}
                    clearSearch={clearSearch}
                    stationData={stationData?.pages[0]?.data?.content || []}
                    {...{filter, search}}
                  />
                }
              />}
        </Box>
    )
}