import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useState } from 'react';
import DropdownMenu from 'common/DropDownMenu/DropDownMenu';
import FormDialog from 'common/Modal/Modal';
import { useImportService } from 'subModule/src/services/allcommon/useCommon';
import { enqueueSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { CommonProfileService } from 'subModule/src/core/services';
import { ImportResponse } from '@subModule/src/services/allcommon/interface';
import { UploadPreviewTable } from 'common/Misc/UploadPreviewTable';

type ServiceSearchHeaderMenuProps = {
  refetch?: () => void;
};
  
const ServiceSearchHeaderMenu: React.FC<ServiceSearchHeaderMenuProps> = ({ refetch }) => {
    const [modal, setModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [imports, setImports] = useState<any>([]);
    const [fileName, setFileName] = useState<string>('');
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      setFileName(file ? file.name : '');
    };
    const closeModal = () => {
      setModal(false); setFileName(''); setShowPreview(false); setImports([]);
    }
    const columns = [
      { id: 'serviceCode', label: t('serviceCode'), numeric: false },
      { id: 'title', label: t('title'), numeric: false },
      { id: 'itemGroup', label: t('Item'), numeric: false },
      { id: 'itemGroupTitle', label: t('itemGroup'), numeric: false },
      { id: 'serviceGroupTitle', label: t('serviceGroup'), numeric: false },
      { id: 'checklistMandatory', label: t('Is checklist mandatory?'), numeric: false },
      { id: 'checklistTitle', label: t('Checklist'), numeric: false },
      { id: 'applyToAllStations', label: t('applyToAllStations'), numeric: false },
      { id: 'taxable', label: t('taxable'), numeric: false },
      { id: 'taxProcedureTitle', label: t('taxProcedure'), numeric: false },
      { id: 'price', label: t('price'), numeric: false },
      { id: 'pricingType', label: t('pricingType'), numeric: false },
      { id: 'serviceTime', label: t('Service Time'), numeric: false },
      { id: 'minLabourPrice', label: t('minLabourPrice'), numeric: false },
      { id: 'serviceEveryMiles', label: t('Mileage (km)'), numeric: false },
      { id: 'serviceEveryPeriodTime', label: t('Number of Days'), numeric: false },
      { id: 'extraNotes', label: t('extraNotes'), numeric: false },
    ];
    const onExport = async () => {
      setDownloading(true);
      CommonProfileService.exportEntity({ typeName: 'services' }).then((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ServicesSample.xlsx`);
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
        enqueueSnackbar(<AlertMessages text={t('Sample') + ' ' + t('downloaded') + ' ' + t('successfully')} />, { variant: 'success' });
      }).catch((err) => {
        console.error("===", err);
        enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      }).finally(() => {
        setDownloading(false);
      });
    };
    
    const { onImport } = useImportService({
      onSuccess: (msg) => {
        const response: any = {};
        Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
        if (response.success) {
          setLoading(false);
          setShowPreview(true);
          setImports(response?.data);
        } else {
          enqueueSnackbar(<AlertMessages text={response.message} />, { variant: 'error' });
          setLoading(false);
        }
      },
      onError: (err: any) => {
        enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        if (err instanceof Error) {
          console.error(err.stack);
        }
        setLoading(false);
      }
    })
    const Import = () => {
      setLoading(true);
      CommonProfileService.importEntity(imports, 'services').then((response: ImportResponse) => {
          if(response.success) {
            refetch && refetch();
            enqueueSnackbar(<AlertMessages text={response?.totalAdded + ' ' + t('added') + ', ' + response?.totalFailed + ' ' + t('omitted')} />, { variant: 'success' });
            closeModal();
          } else {
            enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
          }
      }).catch((err:any) => { 
          console.error("===", err);
          enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
      }).finally(() => setLoading(false));
    }
  
    return <Box>
      <Button
        id="import-button"
        variant="contained"
        disableElevation
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        className={`bg-white text-primary-color font-Saira font-[600] text-[14px] h-[31px] px-4 py-1 cursor-pointer rounded-[6px] ms-[10px]`} 
        sx={{ outline: '2px solid' }}
      >{downloading ? <CircularProgress  className='text-primary-color' size={24} /> : t('Upload')}</Button>
      <DropdownMenu anchorEl={anchorEl} menuItems={[
          { label: (t('Download Sample') + ' ' + t('(Excel)')), onClick: () => onExport()},
          { label: (t('Upload') + ' ' + t('(Excel)')), onClick: () => setModal(true)},
        ]} 
        onClose={() => setAnchorEl(null)} 
      />
      {modal &&
        <FormDialog
          open={modal}
          onClose={(event: any, reason: any) => {
            if(event && (reason !== 'backdropClick') && !loading) closeModal();
          }}
          title={(showPreview ? t('Imported') : t('Upload')) + ' ' + t('services')}
          createPopup={true}
          hideActions={true}
          fullWidth={showPreview}
          content={  
            <Grid container className='items-center' component='form' noValidate={false} spacing={1} onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              const data = new FormData(e.target as HTMLFormElement);
              const file = data.get('file') as File;
              onImport({
                file: file,
                typeName: 'services',
              });
            }}>
              {!showPreview ? <><Grid item xs={12} md={8} id="importfile">
                <input type="file" name="file" 
                  required 
                  accept=".xls,.xlsx,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                  style={{ color: fileName ? 'inherit' : '#fff' }}
                  onChange={handleFileChange}
                  title={fileName}
                  // onInvalid={(e) => (e.target as HTMLInputElement).setCustomValidity(t('uploadFileRequired'))}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField type="submit" disabled={loading} margin='dense' value={t('Upload')} 
                  sx={{ '& div': {borderRadius: '8px', border: '0 !important'}, ['& input']: { fontFamily: "'Saira', sans-serif",
                    fontSize: '15px', lineHeight: '28px', fontWeight: 600, color:'#fff', padding: '0.52rem 2.22rem 0.6rem 2.22rem',
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                    backgroundImage: 'linear-gradient(135deg, #FC5F3A, #FE9303)', border: '0 !important', borderRadius: '8px', cursor: 'pointer',
                    textTransform: 'uppercase', height: '25px'}
                  }}
                />
              </Grid></> : null}
              {showPreview ? <>
                <Grid item xs={12}>
                  <UploadPreviewTable rows={imports} columns={columns} />
                </Grid>
                <Grid item xs={12} id="form" className='flex justify-end gap-2'>
                  <Button className="secondary" variant="contained" disableElevation onClick={() => closeModal()}>{t('cancel')}</Button>
                  <Button disabled={loading} className="primary" variant="contained" disableElevation onClick={() => Import()}>{t('submit')}</Button>
                </Grid>
              </> : null}
            </Grid>
          }
        />
      }
    </Box>;
};
  
  export default ServiceSearchHeaderMenu;