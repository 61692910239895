// PaymentService.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import {
  DeletePaymentResponse,
  GetPaymentByIdResponse,
  GetPaymentInvoiceResponse,
  GetPaymentResponse,
  GetPaymentsCreditResponse,
  PaymentsRequest,
  PaymentsResponse,
} from './interface';

export class PaymentProfile extends BaseService {
  async createPayment(req: PaymentsRequest) {
    return this.post<PaymentsResponse, PaymentsRequest>(`${this.apiUrl}/api/payments`, req);
  }

  async getPayment(params: PaginationParams) {
    return this.get<GetPaymentResponse>(`${this.apiUrl}/api/payments/paged`, params);
  }

  async updatePayment(id: number, req: PaymentsRequest) {
    return this.put<PaymentsResponse, PaymentsRequest>(`${this.apiUrl}/api/payments/${id}`, req);
  }

  async deletePayment(id: number) {
    return this.delete<DeletePaymentResponse>(`${this.apiUrl}/api/payments/${id}`);
  }

  async getPaymentsCredit(id: number) {
    return this.get<GetPaymentsCreditResponse>(`${this.apiUrl}/api/payments/credit/${id}`);
  }

  async getPaymentsInvoice(params: PaginationParams & { clientId: number }) {
    return this.get<GetPaymentInvoiceResponse>(`${this.apiUrl}/api/invoices/unpaid/paged`, params);
  }

  async getPaymentByID(id: number) {
    return this.get<GetPaymentByIdResponse>(`${this.apiUrl}/api/payments/${id}`);
  }
}
