import { Grid, InputAdornment, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { UserModuleProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Accounts } from '../../assets/SideBarIcons/accounts.svg';
import { useState } from "react";
import AccountSettingsTabs from "./AccountSettingsTabs";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from "react-redux";
import { clearUserInfo, selectUserInfo } from "features/user/userSlice";
import { useAuthLogoutService } from "subModule/src/services/userProfile/useProfile";
import { useNavigate } from "react-router-dom";

interface FormData { [key: string]: any; }

export default function ChangePassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors }, watch, setValue, reset } = useForm<FormData>({ defaultValues: {} });
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const loggedInUserData = useSelector(selectUserInfo);
    const loggedInUserEmail = loggedInUserData?.email;
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        UserModuleProfileService.changePassword({
            email: loggedInUserEmail,
            password: data.password,
            newPassword: data.newPassword,
        }).then((response: any) => {
            if (response.success) {
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('password') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                reset();
                localStorage.getItem('ACCESS_TOKEN') ? onLogOut() : navigate('/login');
            } else {
                enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }
            setIsBtnEnabled(true);
        }).catch((err: any) => {
            console.error("===", err);
            setIsBtnEnabled(true);
            enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
        });
    };
    const { onLogOut } = useAuthLogoutService({
        onSuccess: (response) => {
          if(response.success) {
            dispatch(clearUserInfo());
            ['userInfo','selectedSubItem', 'ACCESS_TOKEN'].forEach(item => localStorage.removeItem(item));
            navigate('/login');   
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err);
          if (err instanceof Error) {
            console.error(err.stack);
          }
        },
    });
    return (<>
        <ComponentHeaderTwo
            showBtn={false}
            showSaveBtn={false}
            heading={t('accountSettings')}
            icon={<Accounts height={28} width={28} />}
            btnText="back"
            btnType="back"
            isBtnEnabled={IsBtnEnabled}
        />
        <div className={`flex`}>
            <AccountSettingsTabs selectedIndex={0} />
            <SimpleModal
                visable={true}
                title={t('changePassword')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={"submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={
                    <Grid container component="form" id="form" className="items-center mb-2" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type={watch('showCurrentPassword') ? 'text' : 'password'}
                                {...register('password', {
                                    required: t('This field is required.'),
                                    validate: { passwordLength: (value) => value.length >= 8 || t('minPasswordLength'), },
                                })}
                                label={t('currentPassword') + ' *'}
                                fullWidth
                                error={!!errors.password}
                                helperText={errors.password?.message as string}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (watch('password') ? <InputAdornment position="end" className="cursor-pointer">
                                        {watch('showCurrentPassword') ? (
                                            <VisibilityIcon className="text-primary-color" onClick={() => setValue('showCurrentPassword', false)} />
                                        ) : (
                                            <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showCurrentPassword', true)} />
                                        )}
                                    </InputAdornment> : null),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type={watch('showPassword') ? 'text' : 'password'}
                                {...register('newPassword', {
                                    required: t('This field is required.'),
                                    validate: { 
                                        passwordLength: (value) => value.length >= 8 || t('minPasswordLength'), 
                                        differentFromOld: (value) => value !== watch('password') || t('currentPasswordNewPasword'),
                                    },
                                })}
                                label={t('newPassword') + ' *'}
                                fullWidth
                                error={!!errors.newPassword}
                                helperText={errors.newPassword?.message as string}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (watch('newPassword') ? <InputAdornment position="end" className="cursor-pointer">
                                        {watch('showPassword') ? (
                                            <VisibilityIcon className="text-primary-color" onClick={() => setValue('showPassword', false)} />
                                        ) : (
                                            <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showPassword', true)} />
                                        )}
                                    </InputAdornment> : null),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type={watch('showConfirmPassword') ? 'text' : 'password'}
                                {...register("confirmNewPassword", {
                                    required: t('This field is required.'),
                                    validate: { passwordLength: (value) => (value === watch('newPassword')) || t('confirmPasswordNewPassword'), },
                                },)}
                                label={t("confirmNewPassword") + ' *'}
                                fullWidth
                                error={!!errors.confirmNewPassword}
                                helperText={errors.confirmNewPassword?.message as string}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (watch('confirmNewPassword') ? <InputAdornment position="end" className="cursor-pointer">
                                        {watch('showConfirmPassword') ? (
                                            <VisibilityIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', false)} />
                                        ) : (
                                            <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', true)} />
                                        )}
                                    </InputAdornment> : null),
                                }}
                            />
                        </Grid>
                    </Grid>
                }
            />
        </div>
    </>)
}