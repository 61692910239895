// MakeService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusMakeResponse,
  DeleteMakeResponse,
  GetMakeByIdResponse,
  GetMakeDropdownResponse,
  GetMakeResponse,
  MakeRequest,
  MakeResponse,
} from './interface';

export class MakeProfile extends BaseService {
  async createMake(req: MakeRequest) {
    return this.post<MakeResponse, MakeRequest>(`${this.apiUrl}/api/makes`, req);
  }

  async getMake(params: PaginationParams) {
    return this.get<GetMakeResponse>(`${this.apiUrl}/api/makes/paged`, params);
  }

  async getMakeDropdown(params: PaginationParams) {
    return this.get<GetMakeDropdownResponse>(`${this.apiUrl}/api/makes/get`, params);
  }

  async updateMake(id: number, req: MakeRequest) {
    return this.put<MakeResponse, MakeRequest>(`${this.apiUrl}/api/makes/${id}`, req);
  }

  async deleteMake(id: number) {
    return this.delete<DeleteMakeResponse>(`${this.apiUrl}/api/makes/${id}`);
  }

  async changeStatusMake(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusMakeResponse, ChangeStatusRequest>(`${this.apiUrl}/api/makes/${active}/${id}`, req);
  }

  async getMakeByID(id: number) {
    return this.get<GetMakeByIdResponse>(`${this.apiUrl}/api/makes/${id}`);
  }
}
