import { Grid, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { StateProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import Loader from "layout/Loader";
import { GetStateByIdResponse } from "subModule/src/services/state/interface";

interface FormData { [key: string]: any;}
export default function StateModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, setValue } = useForm<FormData>({defaultValues: {}});
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    useEffect(() => {
        if(params?.id) {
            if(loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
      }, [isDirty, dirtyFields]);
    const getByID = () => {
        StateProfileService.getStateById(+(params as any)?.id).then((res: GetStateByIdResponse) => {
            if(res.success) {
                reset({ ...res?.data || {} });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if(params?.id) {
            StateProfileService.updateState(+params?.id, data).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('state') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
                setIsBtnEnabled(true);
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            StateProfileService.createState(data).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('state') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate(-1)}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editState') : t('addState')}
            icon={<PublicOutlinedIcon className="text-black w-[34px] h-[34px]" />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_STATE_CREATE")}
        />
        {loading ? <Loader /> : <SimpleModal
            visable={true}
            title={params?.id ? "updateState" : "addState"}
            isButtonVisable={true}
            isVisableBtn={IsBtnEnabled}
            btnclosetext="Back"
            buttonText={params?.id ? "update" : "submit"}
            attBtnNotshow={false}
            notVisableBackbtn={true}
            showPortList={handleSubmit(onSubmit)}
            formSubmit={handleSubmit(onSubmit)}
            content={ 
                <Grid container component="form" id="form" className="items-baseline" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                    <Grid item xs={12} md={3}>
                        <TextField 
                            {...register("name", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                            label={t('name') + ' *'}
                            fullWidth
                            error={!!errors.name} 
                            helperText={errors.name?.message as string} 
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
                                setValue("name", sanitizedValue, { shouldDirty: true });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField 
                            {...register("code", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                            label={t('code') + ' *'}
                            fullWidth
                            error={!!errors.code} 
                            helperText={errors.code?.message as string} 
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
                                setValue("code", sanitizedValue, { shouldDirty: true });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField 
                            {...register("region", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                            label={t('region') + ' *'}
                            fullWidth
                            error={!!errors.region} 
                            helperText={errors.region?.message as string} 
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
                                setValue("region", sanitizedValue, { shouldDirty: true });
                            }}
                        />
                    </Grid>
                </Grid>
            }
        />}
    </>)
}