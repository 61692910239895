// JobCardService.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import { GetJobCardByIdResponse, GetJobCardResponse } from './interface';

export class JobCardProfile extends BaseService {
  async getJobCard(params: PaginationParams) {
    return this.get<GetJobCardResponse>(`${this.apiUrl}/api/jobcards/paged`, params);
  }

  async getJobCardByID(id: number) {
    return this.get<GetJobCardByIdResponse>(`${this.apiUrl}/api/jobcards/${id}`);
  }
}
