import StyledField from 'common/TextField/TexField1';
import { Button, Grid } from '@mui/material';
import Filter from "common/Select/SelectMultiFilter";
import { useTranslation } from 'react-i18next';

interface PaymentSearchHeaderComponentProps {
  handleChangeFilter: (event: any) => void;
  handleChangeSearch: (field: string, value: any) => void;
  handleSubmitSearch: () => void;
  searchFormData: any;
  filter: string[];
  clearSearch: () => void;
  search: any;
}

const PaymentSearchHeader: React.FC<PaymentSearchHeaderComponentProps> = ({
  handleChangeFilter,
  handleChangeSearch,
  handleSubmitSearch,
  searchFormData,
  filter,
  clearSearch,
  search
}) => {
  const { t } = useTranslation();
  const filterOptions = ['paymentNo', 'referenceNo'];
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitSearch();
    }
  };
  
  return (
    <>
      <Grid container spacing={1} className='mt-3'>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Filter 
            label="Filter By"
            filterOptions={filterOptions}
            handleChangeFilter={handleChangeFilter}
            filter={filter}
          />
        </Grid>
        {filter.map((fieldName) => (
          <Grid item key={fieldName} xs={12} sm={6} md={3} lg={2}>
            <StyledField
              fullWidth={true}
              placeholder={t(fieldName)}
              displayPlaceholder={true}
              handleChange={(e) => handleChangeSearch(fieldName, e.target.value)}
              required={true}
              value={searchFormData?.[fieldName] || ''}
              handleKeyDown={handleKeyPress}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={2} className="text-left flex items-center gap-2 mt-0 pt-0">
          <Button 
            variant="contained"
            disabled={filter.length ? false : true}
            onClick={handleSubmitSearch}
            sx={{ boxShadow: 'none' }}
            className={`text-white text-ellipsis overflow-hidden font-Saira font-[600] text-[14px] ${filter.length > 0 ? 'bg-primary-color' : 'bg-disabled-color'} h-[35px] px-8 py-1`}
          >{t('submit')}</Button>
          {filter.length && Object.keys(search).length || filter.length > 1 ?
            <Button 
            variant="contained"
            onClick={clearSearch}
            sx={{ boxShadow: 'none' }}
            className={`text-primary-color text-ellipsis overflow-hidden font-Saira font-[600] text-[14px] bg-[#d8d1e0] h-[35px] px-8 py-1`}
          >{t('clear')}</Button>: null}
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentSearchHeader;