import { selectUserInfo } from "features/user/userSlice";
import { Button, Grid, InputAdornment, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BusinessProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Business} from '../../assets/SideBarIcons/business.svg';
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import CommonCheckbox from "common/CheckBox/Checkbox";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import DynamicSelect from "common/Select/Select";
import Currency from 'common/Others/Currency';
import Language from 'common/Others/Language';
import { useGetItemGroupDropdownService } from "subModule/src/services/itemgroup/useItemGroup";
import { useGetPriceListDropdownService } from "subModule/src/services/pricelist/usePriceList";
import { useGetTaxProcedureDropdownService } from "subModule/src/services/taxProcedure/useTaxProcedure";
import { GetBusinessByIdResponse } from "subModule/src/services/business/interface";
import Loader from "layout/Loader";
import BusinessMedia from "./BusinessMedia";
interface FormData { [key: string]: any;}
const steps = ['Details', 'branding'];
export default function BusinessModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const { register, control, handleSubmit, formState: { errors, isDirty, dirtyFields }, reset, watch, getValues, setValue } = useForm<FormData>({defaultValues: {
        sellBelowStock: true,
    }});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [showSecTitleField, setShowSecTitleField] = useState<boolean>(false);
    const { data: scrapItemGroupsData, refetch: scrapItemGroupsRefetch } = useGetItemGroupDropdownService({ pageNumber: 1, size: 1000 });
    const { data: priceListData, refetch: priceListRefetch } = useGetPriceListDropdownService({ pageNumber: 1, size: 1000 });
    const { data: taxProceduresData, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 1000 });
    const scrapItemGroups = (scrapItemGroupsData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const priceLists = (priceListData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const taxProcedures = (taxProceduresData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const currencyOptions = Currency.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }));
    const languageOptions = Language.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }));
    useEffect(() => {
        scrapItemGroupsRefetch();
        priceListRefetch();
        taxProceduresRefetch();
    }, []);
    useEffect(() => {
        setCurrentStep(0);
    }, []);
    useEffect(() => {
        if(loggedInUserData.type === -1 && watch('whiteLabel') && params?.id) {
            setIsBtnEnabled(true);
        }
    }, [currentStep]);
    useEffect(() => {
        if(params?.id && scrapItemGroupsData && priceListData) {
            if(loading) getByID();
        }
    }, [params, scrapItemGroupsData, priceListData]);
    useEffect(() => {
        if(isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getByID = () => {
        BusinessProfileService.getBusinessByID(+(params as any)?.id).then((res: GetBusinessByIdResponse) => {
            if (res.success) {
                reset({ ...res?.data || {}, 
                    primaryLanguage: res.data?.primaryLanguage ? { value: res.data?.primaryLanguage, label: res?.data?.primaryLanguage} : null,
                    secondaryLanguage: res.data?.secondaryLanguage ? { value: res.data?.secondaryLanguage, label: res?.data?.secondaryLanguage} : null,
                    currency: res.data?.currency ? { value: res.data?.currency, label: currencyOptions.find((v) => v.value === res.data?.currency)?.label || res.data?.currency} : null,
                    taxProcedureId: res.data?.taxProcedureId ? { value: res.data?.taxProcedureId, label: taxProcedures.find((v) => v.value === res.data?.taxProcedureId)?.label || res.data?.taxProcedureId } : null,
                    scrapItemGroupId: { value: res.data?.scrapItemGroupId, label: scrapItemGroups?.find((option) => option.value === res?.data?.scrapItemGroupId)?.label || res.data?.scrapItemGroupId},
                    priceListId: { value: res.data?.priceListId, label: priceLists?.find((option) => option.value === res?.data?.priceListId)?.label || res.data?.priceListId}
                });
                if(currentStep === 0 && loggedInUserData.type === -1 && res?.data?.whiteLabel) {
                    setIsBtnEnabled(true);
                }
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if (params?.id) {
            if(loggedInUserData.type === -1 && currentStep === 0 && watch('whiteLabel')) {
                setCurrentStep(currentStep + 1);
            } else {
                BusinessProfileService.updateBusiness(+params?.id, {...data, 
                    primaryLanguage: data.primaryLanguage?.value || '',
                    secondaryLanguage: data.secondaryLanguage?.value || '',
                    currency: data.currency?.value || '',
                    scrapItemGroupId: data.scrapItemGroupId.value,
                    priceListId: data.priceListId.value,
                    taxProcedureId: data.taxProcedureId?.value || 0,
                    whiteLabel: data.whiteLabel
                }).then((response: any) => {
                    if (response.success) {
                        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('business') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                        navigate(-1);
                    } else {
                        enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    }
                    setIsBtnEnabled(true);
                }).catch((err: any) => {
                    console.error("===", err);
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                });
            }
        } else {
            BusinessProfileService.createBusiness({...data, 
                primaryLanguage: data.primaryLanguage?.value || '',
                secondaryLanguage: data.secondaryLanguage?.value || '',
                currency: data.currency?.value || '',
                scrapItemGroupId: data.scrapItemGroupId.value,
                priceListId: data.priceListId.value,
                taxProcedureId: data.taxProcedureId?.value || 0,
                whiteLabel: data.whiteLabel
            }).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('business') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };
    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={params?.id ? false : true}
            onClick={() => navigate(-1)}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editBusiness') : t('addBusiness')}
            icon={<Business height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_BUSINESS_CREATE")}
        />
        {loading ? <Loader /> : <SimpleModal
            visable={true}
            title={params?.id ? "editBusiness" : "addBusiness"}
            isButtonVisable={true}
            isVisableBtn={IsBtnEnabled}
            btnclosetext="Back"
            buttonText={params?.id ? (loggedInUserData.type === -1 && currentStep === 0 && watch('whiteLabel') ? "Next" : "update") : "submit"}
            attBtnNotshow={false}
            notVisableBackbtn={true}
            showPortList={handleSubmit(onSubmit)}
            formSubmit={handleSubmit(onSubmit)}
            content={<>
                <Grid container component="form" id="form" spacing={2} onSubmit={handleSubmit(onSubmit)}>
                    {loggedInUserData.type === -1 && params?.id ? <Grid item xs={12}>
                        <Stepper className='reportSteps w-[100%]' activeStep={currentStep}>
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: { optional?: React.ReactNode; } = {};
                                return (
                                    <Step key={index} {...stepProps}><StepLabel {...labelProps} className={`${index === 1 && !watch('whiteLabel') ? 'opacity-25' : 'opacity-100'}`}>{t(label)}</StepLabel></Step>
                                );
                            })}
                        </Stepper>
                    </Grid> : null}
                    {currentStep === 0 ? <>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                {...register("title", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                fullWidth
                                error={!!errors.title} 
                                helperText={errors.title?.message as string} 
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ endAdornment: (<InputAdornment position="end">
                                    <Group onClick={() => setShowSecTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                </InputAdornment>)}}
                                sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                            />
                            {showSecTitleField && <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
                                    <TextField 
                                        {...register("secondaryTitle")} 
                                        label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                        fullWidth
                                        error={!!errors.secondaryTitle} 
                                        helperText={errors.secondaryTitle?.message as string} 
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="primaryLanguage"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("primaryLanguage")}
                                            {...field}
                                            options={languageOptions}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.primaryLanguage?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="secondaryLanguage"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("secondaryLanguage")}
                                            {...field}
                                            options={languageOptions}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.secondaryLanguage?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="currency"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("currency")}
                                            {...field}
                                            options={currencyOptions}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.currency?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="scrapItemGroupId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: t('This field is required.') }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("scrapItemGroup") + ' *'}
                                            {...field}
                                            options={scrapItemGroups}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.scrapItemGroupId?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="priceListId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: t('This field is required.') }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("defaultPriceList") + ' *'}
                                            {...field}
                                            options={priceLists}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.priceListId?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="taxProcedureId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("taxProcedureId")}
                                            {...field}
                                            options={taxProcedures}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.taxProcedureId?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="sellBelowStock"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <CommonCheckbox
                                        {...field}
                                        label={t("Sell Below Stock")}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                        checked={field.value}
                                        color="success"
                                    />
                                )}
                            />
                        </Grid>
                        {loggedInUserData.type === -1 ? <Grid item xs={12} md={3}>
                            <Controller
                                name="whiteLabel"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <CommonCheckbox
                                        {...field}
                                        label={t("branding")}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                        checked={field.value}
                                        color="success"
                                    />
                                )}
                            />
                        </Grid> : null}
                    </> : null}
                    {loggedInUserData.type === -1 && currentStep === 1 && params?.id ? 
                        <Grid item container xs={12} rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("attributes.serviceTitle")}
                                    label={t('adminTitle')}
                                    fullWidth
                                    error={!!(errors.attributes as any)?.serviceTitle}
                                    helperText={(errors.attributes as any)?.serviceTitle?.message as string}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("attributes.hostTitle")}
                                    label={t('posTitle')}
                                    fullWidth
                                    error={!!(errors.attributes as any)?.hostTitle}
                                    helperText={(errors.attributes as any)?.hostTitle?.message as string}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("attributes.footerText")}
                                    label={t('footerText')}
                                    fullWidth
                                    error={!!(errors.attributes as any)?.footerText}
                                    helperText={(errors.attributes as any)?.footerText?.message as string}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("attributes.about")}
                                    label={t('aboutUsLink')}
                                    fullWidth
                                    error={!!(errors.attributes as any)?.about}
                                    helperText={(errors.attributes as any)?.about?.message as string}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("attributes.privacyPolicyLink")}
                                    label={t('privacyPolicyLink')}
                                    fullWidth
                                    error={!!(errors.attributes as any)?.privacyPolicyLink}
                                    helperText={(errors.attributes as any)?.privacyPolicyLink?.message as string}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("attributes.termsLink")}
                                    label={t('termsLink')}
                                    fullWidth
                                    error={!!(errors.attributes as any)?.termsLink}
                                    helperText={(errors.attributes as any)?.termsLink?.message as string}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("attributes.hostUrl")}
                                    label={t('posUrl')}
                                    fullWidth
                                    error={!!(errors.attributes as any)?.hostUrl}
                                    helperText={(errors.attributes as any)?.hostUrl?.message as string}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("attributes.adminUrl")}
                                    label={t('adminUrl')}
                                    fullWidth
                                    error={!!(errors.attributes as any)?.adminUrl}
                                    helperText={(errors.attributes as any)?.adminUrl?.message as string}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("attributes.serverIp")}
                                    label={t('serverIp')}
                                    fullWidth
                                    error={!!(errors.attributes as any)?.serverIp}
                                    helperText={(errors.attributes as any)?.serverIp?.message as string}
                                />
                            </Grid>
                        </Grid>
                    : null}
                </Grid>
                {loggedInUserData.type === -1 && currentStep === 1 && params?.id ? <Grid item container xs={12} spacing={2} className="mt-[2px]">
                    <Grid item xs={12} md={3}>
                        <Typography className="font-Saira font-[600] text-[14px] text-txt-color text-start">{t('logo')}</Typography>
                        <BusinessMedia formData={getValues()} entity="logo" callback={(url: string) => setValue('attributes.logo', url)} setIsBtnEnabled={setIsBtnEnabled} />
                        <img src={watch("attributes.logo") || ''} className='w-[70px] mt-2' alt='' />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography className="font-Saira font-[600] text-[14px] text-txt-color text-start">{t('logInPageImage')}</Typography>
                        <BusinessMedia formData={getValues()} entity="logInPageImage" callback={(url: string) => setValue('attributes.logInPageImage', url)} setIsBtnEnabled={setIsBtnEnabled} />
                        <img src={watch("attributes.logInPageImage") || ''} className='w-[70px] mt-2' alt='' />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography className="font-Saira font-[600] text-[14px] text-txt-color text-start">{t('favicon')}</Typography>
                        <BusinessMedia formData={getValues()} entity="favIcon" callback={(url: string) => setValue('attributes.favIcon', url)} setIsBtnEnabled={setIsBtnEnabled} />
                        <img src={watch("attributes.favIcon") || ''} className='w-[70px] mt-2' alt='' />
                    </Grid>
                </Grid>: null}
                {currentStep > 0 ? <Grid item xs={12} className="text-start">
                    <Button variant="contained" 
                        className="absolute font-Saira font-semibold leading-[26px] bg-white text-black mt-[26px] px-[20px]"
                        onClick={() => setCurrentStep(0)}
                    >{t('Previous')}</Button>
                </Grid> : null}
            </>}
        />}
    </>)
}