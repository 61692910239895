import { Box, Button, Divider, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import FormDialog from "common/Modal/Modal";
import AddIcon from '@mui/icons-material/Add';
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService } from "subModule/src/core/services";
import { GetStockReceiptResponse, NewPurchaseOrderResponse, PurchaseOrderResponse, SaveStockReceiptResponse } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import { DeleteData } from "subModule/src/type";
import dayjs from "dayjs";

interface FormData {
    [key: string]: any;
}
interface StockReceivedProps {
    steps: string[];
    setCurrentSteps?: any;
}

const StockReceived: React.FC<StockReceivedProps> = ({ steps, setCurrentSteps }) => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField, getValues} = useForm<FormData>({defaultValues: {
        stockReceiptItems: [],
    }});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 500 });
    const [currentStep, setCurrentStep] = useState<number>(steps.indexOf('Stock Received'));
    const [confirm, setConfirm] = useState<boolean>(false);
    const [stockReceiptItems, setStockReceiptItems] = useState<any[]>([]);
    const [entity, setEntity] = useState<FormData>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [po, setSR] = useState<PurchaseOrderResponse>();
    const [IsDataAvailable, setIsDataAvailable] = useState<boolean>(false);
    const [checkStatus, setCheckStatus] = useState<number | null>(1);

    useEffect(() => {
        itemsRefetch();
        if(params?.id) {
            getStockReceiptByPOId();
        }
    }, [params]);
    const getStockReceiptByPOId = () => {
        InventoryProfileService.getStockReceiptByPOID(params?.id).then((res: GetStockReceiptResponse) => {
            if(!res.data) {
                setIsDataAvailable(false);
                getPOById();
            } else {
                setIsDataAvailable(true);
                setSR((res.data as any)[0]);
                setStockReceiptItems((res.data as any)[0]?.stockReceiptItems || []);
                setValue('stockReceiptItems', (res.data as any)[0]?.stockReceiptItems || []);
                setValue('poId', params?.id);
                setValue('supplierId', (res.data as any)[0]?.supplierId);
                setValue('type', 'stock');
                setCheckStatus((res.data as any)[0]?.status);
                setLoading(false);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const getPOById = () => {
        InventoryProfileService.getPurchaseOrderById(params?.id).then((res: NewPurchaseOrderResponse) => {
            if(res.success) {
                setSR(res.data);
                setStockReceiptItems(res.data.purchaseOrderItems.map(item => ({ ...item, receivedDate: dayjs(new Date()).format('YYYY-MM-DD') })));
                setValue('stockReceiptItems', res.data.purchaseOrderItems.map(item => ({ ...item, receivedDate: dayjs(new Date()).format('YYYY-MM-DD') })));
                setValue('storeId', res.data?.storeId);
                setValue('supplierId', res.data?.supplierId);
                setValue('poId', params?.id);
                setValue('type', 'stock');
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'supplierSKU', label: t('SKU'), numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitPrice', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'discount', label: t('Discount'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'batchNumber', label: t('Batch'), numeric: false, disablePadding: false, type: 'text' },
        { id: 'receivedDate', label: t('Received') + ' ' + t('Date'), numeric: false, disablePadding: false, type: 'date' },
        { id: 'expiryDate', label: t("Expiry") + ' ' + t('Date'), numeric: false, disablePadding: false, type: 'date' },
        // { id: 'total', label: t('Total') },
    ];
    const DeleteItem = (row: any, typeName: string) => {
        if(typeName === "stockReceiptItems" && IsDataAvailable) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'stockreceipt/items').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getStockReceiptByPOId();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else {
            setValue('stockReceiptItems', stockReceiptItems.filter((rows) => rows.id !== row.id));
            setStockReceiptItems((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        }
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "stockReceiptItems") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!IsDataAvailable) {
                (newItem as any).id = Date.now();
                (newItem as any).receivedDate = dayjs(new Date()).format('YYYY-MM-DD');
            }
            setStockReceiptItems((prevRows) => [...prevRows, newItem]);
        }
    };
    const handleItem = (row: any, typeName: string, action: string) => {
        if(typeName === "stockReceiptItems" && IsDataAvailable) {
            if(action === 'create') {
                InventoryProfileService.createStockReceiptItem({
                    ...row, stockReceiptId: po?.id, poId: params?.id, storeId: po?.storeId, 
                    expiryDate: row.expiryDate || null,
                    receivedDate: row.receivedDate?.split(' ')[0] || row.receivedDate || dayjs(new Date())?.format('YYYY-MM-DD'),
                }).then((response: SaveStockReceiptResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Stock Receipt Item') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getStockReceiptByPOId();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getStockReceiptByPOId();
                });
            } else {
                InventoryProfileService.updateStockReceiptItem({
                    ...row, stockReceiptId: po?.id, poId: params?.id, 
                    receivedDate: row.receivedDate?.split(' ')[0] || row.receivedDate,
                    expiryDate: row.expiryDate?.split(' ')[0] || row.expiryDate || null
                }).then((response: SaveStockReceiptResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Stock Receipt Item') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getStockReceiptByPOId();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getStockReceiptByPOId();
                });
            }
        }
    }

    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "stockReceiptItems") {
            const updatedItems = [...stockReceiptItems];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setStockReceiptItems(updatedItems);
        }
    };
    const onSubmit = (data: FormData) => {
        if(Object.values(data)?.length || Object.values(getValues())?.length) {
            if(checkStatus === 1) {
                const filteredItems = stockReceiptItems.filter((item: any) => item?.itemName);
                if(filteredItems.length === 0) {
                    enqueueSnackbar(<AlertMessages text="Please add at least one item" />, { variant: 'error' });
                    return;
                }
                if(IsDataAvailable) {
                    setConfirm(true);
                    setEntity(Object.values(data)?.length ? {...data} : getValues());
                } else {
                    setLoading(true);
                    InventoryProfileService.saveStockReceipt(Object.values(data)?.length ? {
                        ...data, stockReceiptItems: filteredItems
                    } : {...getValues(), stockReceiptItems: filteredItems}).then((response: SaveStockReceiptResponse) => {
                        if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Stock Receipt') + ' ' + t('saved') + ' ' + t('successfully')} />, { variant: 'success' });
                        else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                        getStockReceiptByPOId();
                        setLoading(false);
                    }).catch((err:any) => { 
                        console.error("===", err);
                        setLoading(false);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                }
            } else {
                if(currentStep + 1 === steps.indexOf('Payment')) {
                    setCurrentStep(steps.indexOf('Payment'));
                    setCurrentSteps(steps.indexOf('Payment'));
                } else {
                    setCurrentStep(steps.indexOf('Debit Note'));
                    setCurrentSteps(steps.indexOf('Debit Note'));
                }
            }
        }
    };

    return (
        loading ? <Loader /> : <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{IsDataAvailable ? t('Stock Received') : t('New Stock')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{IsDataAvailable ? (checkStatus === 1 ? t('Confirm') : t('Next')) : t('Save as received')}</Button>
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{IsDataAvailable ? t('Stock Received') : t('New Stock')}</Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{IsDataAvailable ? t('Purchase Order') + ' #' : t('Purchase Order') + ' #'}</span> <span>{po?.poNumber || (po as any)?.stockNumber || ''}</span></Typography>
                    {!IsDataAvailable ? <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Date')}</span> <span>{po?.poDate?.split('T')[0] || ''}</span></Typography> : <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Date')}</span> <span>{(po as any)?.receivedDate?.split('T')[0] || ''}</span></Typography>}
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Supplier')} {t('name')}</span> <br /><span className="font-medium">{po?.vendorTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('store') + ' ' + t('name')}</span> <br /><span className="font-medium">{po?.storeTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Requested')}</span> <br /><span className="font-medium">{po?.totalQuantity || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Received')}</span> <br /><span className="font-medium">{(po as any)?.totalReceivedQuantity || 0}</span></Typography>
                        {!IsDataAvailable ? <><Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Subtotal')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((po as PurchaseOrderResponse)?.subtotalAmount || 0) || 0}</span></Typography>
                        {/* <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">Tax</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((po as PurchaseOrderResponse)?.taxAmount || 0) || 0}</span></Typography> */}
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Discount')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((po as PurchaseOrderResponse)?.discount || 0) || 0}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Total')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((po as PurchaseOrderResponse)?.totalAmount || 0) || 0}</span></Typography></>
                         : <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Amount')}</span> <br /><span className="font-medium">{new Intl.NumberFormat('en-US', {}).format((po as any)?.totalAmount || 0) || 0}</span></Typography>}
                    </div>
                </Grid>
            </Grid>
            <Grid container><Grid item xs={12}>
                <NewPurchaseSteps form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} addNewItem={addNewItem} handleInputChange={handleInputChange} items={itemsData?.pages[0]?.data?.content || []} DeleteItem={DeleteItem} handleItem={handleItem} {...{currentStep, setCurrentStep, columns, stockReceiptItems, loggedInUserCurrency, params, IsDataAvailable, steps, setCurrentSteps, t}} />
            </Grid></Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{IsDataAvailable ? (checkStatus === 1 ? t('Confirm') : t('Next')) : t('Save as received')}</Button>
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            {confirm &&
                <FormDialog
                  open={confirm}
                  onClose={(event, reason) => event && reason !== 'backdropClick' ? setConfirm(false) : ''}
                  title={t('Confirm') + ' ' + t(steps[currentStep])}
                  saveFunction={() => {
                    delete entity.typeName;
                    entity.stockReceiptItems.every((row:any) => delete row.total);
                    setConfirm(false);
                    InventoryProfileService.changeStatusEntity({typeName: 'stockreceipt', entityId: po?.id, status: 2}).then((response: NewPurchaseOrderResponse) => {
                        if(response.success) {
                            setConfirm(false);
                            if(currentStep + 1 === steps.indexOf('Payment')) {
                                setCurrentStep(steps.indexOf('Payment'));
                                setCurrentSteps(steps.indexOf('Payment'));
                            } else {
                                setCurrentStep(steps.indexOf('Debit Note'));
                                setCurrentSteps(steps.indexOf('Debit Note'));
                            } 
                            getStockReceiptByPOId();
                            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Status') + ' ' + t('changed') + ' ' + t('successfully')} />, { variant: 'success' });
                        } else {
                            setConfirm(false);
                            enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                        }
                    }).catch((err:any) => { 
                        console.error("===", err);
                        setConfirm(false);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                }}
                  content={  
                    <><Typography>{t('Are you sure?')}</Typography></>
                  }
                  submitButtonText="OK"
                />
              }
        </Grid>
    )
}

interface NewPurchaseStepsProps {
    form: FormData;
    addNewItem: (typeName: string) => void;
    handleInputChange: (index: number, columnId: string, value: any, typeName: string, type: string) => void;
    currentStep: number;
    setCurrentStep: any;
    setCurrentSteps: any;
    columns: any[];
    stockReceiptItems: any[];
    items: any[];
    loggedInUserCurrency?: string;
    DeleteItem: (row: any, typeName: string) => void;
    params?: any;
    handleItem?: (row: any, typeName: string, action: string) => void;
    IsDataAvailable?: boolean;
    steps: string[];
    t?:any;
}

export const NewPurchaseSteps: React.FC<NewPurchaseStepsProps> = ({ form, addNewItem, handleInputChange, currentStep, columns, stockReceiptItems, items, loggedInUserCurrency, DeleteItem, params, handleItem, IsDataAvailable, steps, setCurrentStep, setCurrentSteps, t }) => {
    return (<>
        <Stepper activeStep={currentStep} connector={<></>}>
            {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode; } = {};
                return (
                    <Step key={index} {...stepProps} onClick={() => {
                        if(document.querySelector(`.MuiStep-root:nth-child(${index + 1})`)?.classList.contains('Mui-completed')) {
                            setCurrentStep(steps.indexOf(label));
                            setCurrentSteps(steps.indexOf(label));
                        }
                    }} className={`${document.querySelector(`.MuiStep-root:nth-child(${index + 1})`)?.classList.contains('Mui-completed') ? 'cursor-pointer' : ''}`}><StepLabel {...labelProps}>{t(label)}</StepLabel></Step>
                );
            })}
        </Stepper>
        <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
            <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t(steps[currentStep])}</Typography>
            <InventoryItemsTable form={form} columns={columns} handleInputChange={handleInputChange} rows={stockReceiptItems} typeName="stockReceiptItems" items={items} currency={loggedInUserCurrency} DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={1} viewDelete={IsDataAvailable ? false : true} />
            <Grid item xs={12} sm={6} className="hide-on-print">
                <div className="flex gap-2 justify-start">
                    <Button className="secondary flex gap-1" variant="contained" disableElevation onClick={() => addNewItem('stockReceiptItems')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                    {/* <Button className="secondary" variant="contained" disableElevation>{t('Import')}</Button>
                    <Button className="secondary" variant="contained" disableElevation>{t("Export")}</Button> */}
                </div>
            </Grid>
        </Box>
    </>);
}

export default StockReceived;
