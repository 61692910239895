import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import ChecklistIcon from '@mui/icons-material/Checklist';
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import ChecklistModalComponent from "./ChecklistModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import ChecklistItem from "pages/ChecklistItem";
interface ChecklistComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any;
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  statusModal: boolean;
  statusText: string;
  enableEdit?:boolean;
  pageNo:number;
  loadingSearch: boolean;
  pageSize:number;
  isBtnEnabled: boolean;
  loading: boolean;
  clearSearch: () => void;
  showRowView: (row: any) => void;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  tab: number;
  checkListId: number;
}

const ChecklistComponent: React.FC <ChecklistComponentProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  statusModal,
  statusText,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  loadingSearch,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  pageSize,
  isBtnEnabled,
  loading,
  clearSearch,
  showRowView,
  rowView,
  handleChangeTabs,
  checkListId,
}) => {
  const { t } = useTranslation();
  const tabs = [
    { 
      id: 0, title: `edit`, child: <ChecklistModalComponent
      handleSecField={handleSecField}
      showSecField={showSecField}
      secondaryLanguage={secondaryLanguage}
      primaryLanguage={primaryLanguage}
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
      />
    },
    { id: 1, title: `${t('ChecklistItem')}`, child: <ChecklistItem checkListId={checkListId} />},
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_CHECKLIST_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_CHECKLISTITEM_VIEW');
      default:
        return false;
    }
  });

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={!enableEdit && openForm ? "addChecklist" : enableEdit && openForm ? "editChecklist" : t('Checklists')}
            icon={<ChecklistIcon sx={{ fill: '#000', width: '30px', height: '30px', marginTop: '3px' }} />}
            btnText={openForm || rowView ? "back" : "create"}
            btnType={openForm || rowView ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_CHECKLIST_CREATE")}
            // btnType="create"
          />

          {openForm ? 
            <ChecklistModalComponent
            handleSecField={handleSecField}
            showSecField={showSecField}
            secondaryLanguage={secondaryLanguage}
            primaryLanguage={primaryLanguage}
              enableEdit={enableEdit}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              formData={formData}
              openForm={openForm}
              errorMessages={errorMessages}
              isBtnEnabled={isBtnEnabled}
            />
            : rowView ?
              <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
            : <Box>
                {loading ? <Loader /> :
                  <BackendPaginationTable
                    columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                    data={data.data?.content || []}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    tableData={data?.data || {}}
                    showCheckbox={false}
                    showActions={true}
                    enableSorting={true}
                    showHeader={true}
                    showSearch={true}
                    showFilter={true}
                    showCreateButton={true}
                    actions={actions1}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRows}
                    onChangeStatus={onChangeStatus}
                    rowClick={checkPrivileges("ROLE_CHECKLIST_UPDATE")}
                    showRowView={showRowView}
                    loadingSearch={loadingSearch}
                    headerComponent={
                    <Grid container spacing={2}>  
                      <Grid item xs={12} md={7} lg={5}>
                        <StyledInput
                          fullWidth
                          placeholder={t("title")}
                          handleSearchClick={handleSearchClick}
                          handleChange={handleInputChange}
                          value={search}
                          clearSearch={clearSearch}
                        />
                      </Grid>
                    </Grid>
                    }
                  />
                }
              </Box>
            }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title="Delete Checklist"
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    Are you sure you want to Delete?
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
        {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`Are you sure you want to ${statusText}`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }

        </Box>

    )
}

export default ChecklistComponent;