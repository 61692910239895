import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService } from "subModule/src/core/services";
import { GetUnstockResponse } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import { DeleteData } from "subModule/src/type";
import DynamicSelect from "common/Select/Select";
import { useGetStoreService } from "subModule/src/services/store/useStore";

interface FormData {
    [key: string]: any;
}

const UnstockCreateUpdate: React.FC = () => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField} = useForm<FormData>({defaultValues: {
        unStockItem: [],
    }});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 5000 });
    const { data: storesData, refetch: storesRefetch } = useGetStoreService({ pageNumber: 1, size: 1000 });
    const [unStockItem, setunStockItem] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [unstock, setUnstock] = useState<any>();

    useEffect(() => {
        itemsRefetch();
        storesRefetch();
        if(params?.id) {
            if(loading) getUnstock();
        }
    }, [params]);
    const getUnstock = () => {
        InventoryProfileService.getUnstockByID(params?.id).then((res: GetUnstockResponse) => {
            if(res.success) {
                setLoading(false);
                setUnstock((res.data as any));
                setValue('poId', res.data?.id);
                setValue('storeId', res.data?.storeId);
                setunStockItem(res.data.unStockItem || []);
                setValue('unStockItem', res.data.unStockItem || []);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const storeOptions = (storesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        { id: 'quantity', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitTitle' },
    ];
    const DeleteItem = (row: any, typeName: string) => {
        if(typeName === "unStockItem" && params?.id) {
            setLoading(true);
            InventoryProfileService.deleteEntityItem((row as any).id, 'unstock/item').then((response: DeleteData) => {
                if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                setLoading(false);
                getUnstock();
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            });
        } else if(typeName === "unStockItem" && !params?.id) {
            setunStockItem((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
        }
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "unStockItem") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            setunStockItem((prevRows) => [...prevRows, newItem]);
        }
    };
    const handleItem = (row: any, typeName: string, action: string) => {
        if(typeName === "unStockItem" && params?.id) {
            if(action === 'create') {
                InventoryProfileService.createUnstockItem({
                    ...row, unstockId: params?.id, storeId: unstock?.storeId, purchaseCreditDebitNoteId: unstock?.id
                }).then((response: GetUnstockResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Unstock Item') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getUnstock();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getUnstock();
                });
            } else {
                InventoryProfileService.updateUnstockItem({
                    ...row, unstockId: params?.id, purchaseCreditDebitNoteId: unstock?.id
                }).then((response: GetUnstockResponse) => {
                    if(response.success)  enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Unstock Item') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    getUnstock();
                }).catch((err:any) => { 
                    console.error("===", err);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    getUnstock();
                });
            }
        }
    }

    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "unStockItem") {
            const updatedItems = [...unStockItem];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setunStockItem(updatedItems);
        }
    };
    const onSubmit = (data: any) => {
        if(Object.values(data)?.length) {
            let filteredItems = unStockItem.filter((item: any) => item?.itemName);
            if(filteredItems.length === 0) {
                enqueueSnackbar(<AlertMessages text="Please add at least one item" />, { variant: 'error' });
                return;
            }
            filteredItems = filteredItems.map(item => ({ ...item, storeId: data.storeId.value }));
            InventoryProfileService.saveUnstock(filteredItems).then((response: GetUnstockResponse) => {
                if(response.success) {
                    InventoryProfileService.changeStatusEntity({typeName: 'unstock', entityId: response?.data?.id, status: 2}).then((res: any) => {
                        if(res.success) {
                            enqueueSnackbar(<AlertMessages statusCode={res.status} text={t('Unstock') + ' ' + t('saved') + ' ' + t('successfully')} />, { variant: 'success' });
                            navigate('/inventory/unstock');
                        } else {
                            enqueueSnackbar(<AlertMessages text={res?.message} />, { variant: 'error' });
                        }
                    }).catch((err:any) => { 
                        console.error("===", err);
                        enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                    });
                }
                else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (
        loading ? <Loader /> : <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{t('Unstock')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/unstock')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            {params?.id ? <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{t('Unstock')}</Typography>
                    <Typography className="font-Saira text-[14px] text-[#fff] text-left"><span className="font-bold">{t('Unstock Number')} # </span> <span>{unstock?.unStockNumber || ''}</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('storeName')}</span> <br /><span className="font-medium">{unstock?.storeTitle || ''}</span></Typography>
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('Total Quantity')}</span> <br /><span className="font-medium">{unstock?.totalQuantity || ''}</span></Typography>
                    </div>
                </Grid>
            </Grid> : null}
            <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('Unstock')} {t("Details")}</Typography></Grid>
            <Grid item xs={12} md={4} lg={3}>
                <Controller
                    name="storeId"
                    control={control}
                    defaultValue={null}
                    rules={{ required: t('This field is required.') }}
                    render={({ field }) => (
                        <DynamicSelect
                            label={t('stores')}
                            {...field}
                            options={params?.id ? [{ value: unstock?.storeId, label: unstock?.storeTitle }] : storeOptions}
                            isSearchable
                            isRequired
                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                            error={errors.storeId?.message as string}
                            value={params?.id ? { value: unstock?.storeId, label: unstock?.storeTitle } : watch('storeId')}
                            isDisabled={params?.id as any}
                        />
                    )}
                />
            </Grid>
            <Grid container><Grid item xs={12}>
                <Box className="bg-white py-[12px] px-[18px] rounded-[8px] mt-[16px]">
                    <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('Unstock')}</Typography>
                    <InventoryItemsTable form={{ control, register, handleSubmit, setValue, errors, watch, resetField }} columns={columns} handleInputChange={handleInputChange} rows={unStockItem} typeName="unStockItem" items={itemsData?.pages[0]?.data?.content || []} currency={loggedInUserCurrency} DeleteItem={DeleteItem} handleItem={handleItem} {...{params}} checkStatus={params?.id ? 2 : 1} viewDelete={params?.id ? false : true} />
                    <Grid item xs={12} sm={6} className="hide-on-print">
                        <div className="flex gap-2 justify-start">
                            <Button className="secondary flex gap-1" variant="contained" disableElevation onClick={() => addNewItem('unStockItem')} disabled={params?.id as any}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                        </div>
                    </Grid>
                </Box>
            </Grid></Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button>
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/unstock')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UnstockCreateUpdate;
