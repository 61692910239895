import { Controller, useForm } from "react-hook-form"
import { useSnackbar } from "notistack";
import AlertMessages from "common/Alert/AlertMessages";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress, Grid, Step, StepLabel, Stepper, Tab, Tabs, Typography } from "@mui/material";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { useEffect, useState } from "react";
import { ClientProfileService, ReportsProfileService, VehicleProfileService } from "subModule/src/core/services";
import { GetReportPreviewResponse, GetReportsListResponse } from "subModule/src/services/reports/interface";
import DynamicSelect from "common/Select/Select";
import { ReactComponent as EmptyReport  } from "assets/report-empty.svg";
import { GetClientsDropdownResponse } from "subModule/src/services/client/interface";
import CommonCheckbox from "common/CheckBox/Checkbox";
import { GetVehicleResponse } from "subModule/src/services/vehicle/interface";
import StyledInput from "common/SearchField/Search";
import BasicDatePicker from "common/Pickers/Pickers";
import dayjs from "dayjs";
import { useGetStationDropdownService } from "subModule/src/services/station/useStation";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ReportPreviewTable } from "./ReportPreviewTable";
interface FormData {
    [key: string]: any;
}
interface Option {
    label: string
    value: string
}
const steps = ['type', 'templates', 'units', 'dateInterval'];

const Reports: React.FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [reportOptions, setReportOptions] = useState<Option[]>([]);
    const [customers, setCustomers] = useState<any>();
    const [vehicles, setVehicles] = useState<any>();
    const [filteredCustomers, setFilteredCustomers] = useState<any>();
    const [filteredVehicles, setFilteredVehicles] = useState<any>();
    const [tab, setTab] = useState<number>(0);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
    const [showReportPreview, setShowReportPreview] = useState<boolean>(false);
    const [report, setReport] = useState<any[]>([]);
    const [searchText, setSearchText] = useState('');
    const { control, formState: { errors }, handleSubmit, watch, trigger, getValues, reset, resetField } = useForm<FormData>({defaultValues: {}});
    const { data: stationsData, refetch: stationsRefetch } = useGetStationDropdownService({ pageNumber: 1, size: 1000 });
    const stations = (stationsData?.pages[0]?.data?.content || []).map((item: { id: number, name: string }) => ({ value: item.id, label: item.name }));
    useEffect(() => {
        if(currentStep === 1) {fetchReportsList(); stationsRefetch();}
        if(currentStep === 2 && watch('reportType')?.value !== 2) {
            fetchCustomers();
            fetchVehicles();
        }
        if(currentStep < (steps?.length - 1)) {setShowReportPreview(false); setReport([]); setSearchText('');}
    }, [currentStep]);
    const fetchReportsList = () => {
        setLoading(true);
        ReportsProfileService.getReportsList({type: watch('reportType')?.value}).then((res: GetReportsListResponse) => {
            if (res.success) {
                setReportOptions(Object.keys(res.data || {})?.map((key) => ({
                    label: key?.replace(/_/g, ' ')?.toLowerCase()?.replace(/\b\w/g, (char) => char.toUpperCase()),
                    value: (res.data as any)[key],
                    key,
                })));
                setLoading(false);
            } else {
                setReportOptions([]);
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err: any) => { console.error("===", err); setReportOptions([]); setLoading(false); });
    }
    const fetchCustomers = () => {
        setLoading(true);
        ClientProfileService.getClientsDropdown({ page: 1, size: 1000 }).then((res: GetClientsDropdownResponse) => {
            if (res.success) {
                setCustomers((res.data as any).content || []);
                setFilteredCustomers((res.data as any).content || []);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err: any) => { console.error("===", err); setLoading(false); });
    }
    const fetchVehicles = () => {
        VehicleProfileService.getVehicle({ page: 1, size: 1000 }).then((res: GetVehicleResponse) => {
            if (res.success) {
                setVehicles((res.data as any).content || []);
                setFilteredVehicles((res.data as any).content || []);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err: any) => { console.error("===", err); setLoading(false); });
    }
    const handleSearch = (event: any, typeName: string) => {
        const searchValue = event.target.value?.toLowerCase()?.trim();
        if(typeName === 'customers') {
            setFilteredCustomers(customers.filter((customer: any) =>
                `${customer.name}${customer.phone || ''}`.toLowerCase().includes(searchValue)
            ));
        } else {
            setFilteredVehicles(vehicles.filter((vehicle: any) =>
                `${vehicle.makeTitle} ${vehicle.modelTitle} ${vehicle.modelEngineTitle || ''} ${vehicle.vin || ''}`
                    .toLowerCase()
                    .includes(searchValue)
            ));
        }
    };
    const handleSearchPreview = (value: any) => {
        setSearchText(value);
        if (!value) {
          getPreview();
        } else {
          const filtered = (report || []).filter((item) =>
            Object.values(item).some((val) =>
              String(val).toLowerCase().includes(value.toLowerCase())
            )
          );
          setReport(filtered);
        }
    };
    const drawColumns = (obj: any) => {
        const cols:any = [];
        Object.keys(obj).map((key) => {
          if(key != 'paymentMethods') {
            cols.push({
              label: t(key) || key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
              id: key,
            })
          }
        })
        return cols;
    }
    const getPreview = () => {
        setLoading(true);
        const payload = {...getValues(), 
            reportNumber: getValues().reportNumber.value,
            clientId: getValues().clientId?.length ? getValues().clientId?.join(',') : null,
            vehicleId: getValues().vehicleId?.length ? getValues().vehicleId?.join(',') : null,
            ...(getValues().stationId?.value && { stationId: getValues().stationId.value }),
            page: 1, size: 10000
        };
        delete payload.reportType;
        ReportsProfileService.getReportPreview(payload).then((res: GetReportPreviewResponse) => {
            if (res.success) {
                setReport(res?.data?.content || []);
                setShowReportPreview(true); setLoading(false);
            } else {
                setReportOptions([]);
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
                setLoading(false);
            }
        }).catch((err: any) => { console.error("===", err); setLoading(false); });
    }
    const onSubmit = (data: FormData) => {
        if(Object.values(data)?.length) {
            setIsBtnEnabled(true);
            const payload = {...data, 
                reportNumber: data.reportNumber.value,
                clientId: data.clientId?.length ? data.clientId : null,
                vehicleId: data.vehicleId?.length ? data.vehicleId : null,
                ...(data.stationId?.value && { stationId: data.stationId.value }),
            };
            delete payload.reportType;
            ReportsProfileService.downloadReport(payload).then((response: Blob) => {
                const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${data.reportNumber.label}.xlsx`);
                document.body.appendChild(link);
                link.click();

                link.remove();
                window.URL.revokeObjectURL(url);
                enqueueSnackbar(<AlertMessages text={t('report') + ' ' + t('downloaded') + ' ' + t('successfully')} />, { variant: 'success' });
                reset();
                setCurrentStep(0);
                setIsBtnEnabled(false);
                setShowReportPreview(false); setReport([]); setSearchText('');
            }).catch((err:any) => { 
                setIsBtnEnabled(false);
                console.error("===>>>", err);
                enqueueSnackbar(<AlertMessages statusCode={err?.status} />, { variant: 'error' });
            });
        }
    };

    return (
        <Grid container component="form" id="form" className="px-[8px] items-baseline reports" rowGap={2.5} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
                <ComponentHeaderTwo
                    showBtn={false}
                    showSaveBtn={false}
                    heading={t('reports')}
                    icon={<AssessmentOutlinedIcon sx={{ fill: '#000', width: '30px', height: '30px', marginTop: '3px' }} />}
                    showCreateBtn={false}
                />
                <Stepper className='hide-on-print mt-5 reportSteps' activeStep={currentStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode; } = {};
                        return (
                            <Step key={index} {...stepProps}><StepLabel {...labelProps} className={`${index === 2 && watch('reportType')?.value === 2 ? 'opacity-25' : 'opacity-100'}`}>{t(label)}</StepLabel></Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12} md={4}>
                    {currentStep === 0 ? <Controller
                        name="reportType"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (<Box className="ps-[15px]">
                            <DynamicSelect
                                label={t('select') + ' ' + t('reportType') + ' *'}
                                {...field}
                                options={[
                                    { value: 1, label: t('sales') },
                                    { value: 2, label: t('Inventory') },
                                ]}
                                isSearchable
                                onChange={(selectedOption: any) => {field.onChange(selectedOption); trigger('reportType'); reset({ reportType: getValues("reportType") });}}
                                error={errors.reportType?.message as string}
                                value={{ label: t(watch('reportType')?.label), value: watch('reportType')?.value }}
                            />
                        </Box>)}
                    /> : null}
                    {currentStep === 1 && <>
                        <Controller
                            name="reportNumber"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.') }}
                            render={({ field }) => (<Box className="ps-[15px]">
                                <DynamicSelect
                                    label={t('selectReportTemplate') + ' *'}
                                    {...field}
                                    options={reportOptions?.map((report) => ({ ...report, label: t(report.label), }))}
                                    isSearchable
                                    isLoading={loading}
                                    onChange={(selectedOption: any) => {field.onChange(selectedOption); trigger('reportNumber'); resetField('stationId')}}
                                    error={errors.reportNumber?.message as string}
                                    value={{ label: t(watch('reportNumber')?.label), value: watch('reportNumber')?.value }}
                                />
                            </Box>)}
                        />
                        {watch('reportNumber')?.value === 4 ? <Controller
                            name="stationId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: t('This field is required.') }}
                            render={({ field }) => (<Box className="ps-[15px]">
                                <DynamicSelect
                                    label={t('station') + ' *'}
                                    {...field}
                                    options={stations}
                                    isSearchable
                                    isLoading={loading}
                                    onChange={(selectedOption: any) => {field.onChange(selectedOption); trigger('stationId');}}
                                    error={errors.stationId?.message as string}
                                />
                            </Box>)}
                        /> : null}
                    </>}
                    {currentStep === 2 ? <Box className='ps-[15px]'>
                        <Box className='flex justify-between items-center'>
                            <Typography className='font-Saira text-[14px] text-[#343434] font-semibold mt-[-12px]'>{t('select')}</Typography>
                            <Tabs value={tab} aria-label="tabs" onChange={(_, value: number) => setTab(value)}>
                                <Tab label={t('customers')} id="tab-0" aria-controls="tabpanel-0" />
                                <Tab label={t('vehicles')} id="tab-1" aria-controls="tabpanel-1" className="ml-[10px]" />
                            </Tabs>
                        </Box>
                        <Box role="tabpanel" className='report-tab' hidden={tab !== 0} id="simple-tabpanel-0" aria-labelledby={`simple-tab-0`}>
                            <StyledInput
                                fullWidth
                                placeholder={t("customers")}
                                hideBtn
                                handleChange={(value: any) => handleSearch(value, 'customers')}
                            />
                            {loading ? <CircularProgress className='text-primary-color' size={30} /> 
                            : <Box className="reportUnits mt-4">{filteredCustomers?.map((row:any) => (
                                <Controller
                                    name="clientId"
                                    control={control}
                                    defaultValue={[]}
                                    rules={{ validate: (value) => {
                                        if (watch('reportNumber')?.value === 4 && value.length !== 1) return t('selectOnlyOneCustomer');
                                        return true;
                                    }}}
                                    render={({ field }) => {
                                        const selectedIds = field.value as number[];
                                        const isChecked = selectedIds.includes(row.id);
                                        return (
                                            <CommonCheckbox
                                                label={`${row.name}${row.phone ? ' | ' + row.phone : ''}`}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const newIds = event.target.checked ? [...selectedIds, row.id] : selectedIds.filter((id) => id !== row.id);
                                                    field.onChange(newIds);
                                                    trigger('clientId');
                                                }}
                                                checked={isChecked}
                                                color="success"
                                            />
                                        );
                                    }}
                                />)
                            )}</Box>}
                            <Typography className='text-[#FF0000] font-[500] text-[12px] font-Saira italic text-left ml-[10px] absolute'>
                                {t(errors.clientId?.message as string)}
                            </Typography>
                        </Box>
                        <Box role="tabpanel" className='report-tab' hidden={tab !== 1} id="simple-tabpanel-1" aria-labelledby={`simple-tab-1`}>
                            <StyledInput
                                fullWidth
                                placeholder={t("vehicles")}
                                hideBtn
                                handleChange={(value: any) => handleSearch(value, 'vehicles')}
                            />
                            {loading ? <CircularProgress className='text-primary-color' size={30} /> 
                                : <Box className="reportUnits mt-4">{filteredVehicles?.map((row:any) => (
                                    <Controller
                                        name="vehicleId"
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => {
                                            const selectedIds = field.value as number[];
                                            const isChecked = selectedIds.includes(row.id);
                                            return (
                                                <CommonCheckbox
                                                    label={`${row.makeTitle + ' ' + row.modelTitle + ' ' + (row.modelEngineTitle ? row.modelEngineTitle : '') + (row.vin ? ' | ' + row.vin : '')}`}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        const newIds = event.target.checked
                                                        ? [...selectedIds, row.id]
                                                        : selectedIds.filter((id) => id !== row.id);
                                                        field.onChange(newIds);
                                                    }}
                                                    checked={isChecked}
                                                    color="success"
                                                />
                                            );
                                        }}
                                    />
                                ))}</Box>
                            }
                        </Box>
                    </Box> : null}
                    {currentStep === 3 ? <Box className='ps-[15px]'>
                        <Controller
                            name="start"
                            control={control}
                            rules={{ validate: (value) => {
                                const date = new Date(value);
                                return isNaN(date.getTime()) ? t("Invalid Date") : true;
                            } }}
                            render={({ field }) => (
                                <BasicDatePicker
                                    type="datePicker"
                                    {...field}
                                    label={t('startDate')}
                                    fullWidth
                                    onChange={(value: any) => {field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-')); trigger('start');}}
                                    required={false}
                                    maxDate={dayjs()}
                                    error={errors.start?.message as string}
                                />
                            )}
                        />
                        <Controller
                            name="end"
                            control={control}
                            rules={{ validate: (value) => {
                                const date = new Date(value);
                                if (isNaN(date.getTime())) return t("Invalid Date");
                                const startDate = new Date(getValues("start"));
                                if (startDate && date < startDate) return t("endDateOrderSame");
                                return true;
                            } }}
                            render={({ field }) => (
                                <BasicDatePicker
                                    type="datePicker"
                                    {...field}
                                    label={t('endDate')}
                                    fullWidth
                                    onChange={(value: any) => {field.onChange(new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-')); trigger('end');}}
                                    required={false}
                                    maxDate={dayjs()}
                                    error={errors.end?.message as string}
                                />
                            )}
                        />
                    </Box> : null}
                    <Box className={`flex ps-[15px] mt-5 ${currentStep === 0 ? 'justify-end' : 'justify-between'}`}>
                        {currentStep !== 0 ? <Button className="secondary" variant="contained" disableElevation onClick={() => setCurrentStep(watch('reportType')?.value === 2 && currentStep === 3 ? currentStep - 2 : currentStep - 1)}>{t('back')}</Button> : null}
                        {currentStep !== (steps.length - 1) ? <Button className="primary" variant="contained" disableElevation onClick={async() => {
                            const isValid = await trigger();
                            if (isValid) {
                                setCurrentStep(watch('reportType')?.value === 2 && currentStep === 1 ? currentStep + 2 : currentStep + 1);
                                setLoading(true);
                            }
                        }}>{t('Next')}</Button> : null}
                        {currentStep === (steps.length - 1) ? <Box>
                            <Button className="primary" variant="contained" disableElevation onClick={async() => {
                                const isValid = await trigger();
                                if (isValid) {setShowReportPreview(true); setReport([]); getPreview();}
                            }}>{t('submit')}</Button>
                            <Button type="submit" disabled={IsBtnEnabled} className="primary downloadbtn" variant="contained" disableElevation onClick={() => onSubmit}>{IsBtnEnabled ? <CircularProgress className='text-white' size={24} /> : <FileDownloadOutlinedIcon />}</Button>
                        </Box> : null}
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} className="pl-[25px]">
                    {showReportPreview ? 
                        loading ? <CircularProgress className='text-primary-color' size={30} /> : 
                            <>
                                <Box className='flex justify-between items-center'>
                                    <Typography className='font-Saira text-[18px] text-[#CDC4D4] uppercase font-semibold'>{t(watch('reportNumber')?.label) || ''}</Typography>
                                    <StyledInput
                                        fullWidth
                                        placeholder={t("search")}
                                        hideBtn
                                        handleChange={(event: any) => handleSearchPreview(event.target.value)}
                                        value={searchText}
                                    />
                                </Box>
                                <ReportPreviewTable rows={report} columns={drawColumns(report?.[0] || {})} {...{searchText}} />
                            </>
                        : <>
                            <Typography className='font-Saira text-[18px] text-[#CDC4D4] uppercase font-semibold'>{t(watch('reportNumber')?.label) || ''}</Typography>
                            <EmptyReport className="d-block mx-auto" />
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Reports;
