// ManufacturerService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusManufacturerResponse,
  DeleteManufacturerResponse,
  GetManufacturerByIdResponse,
  GetManufacturerDropdownResponse,
  GetManufacturerResponse,
  ManufacturerRequest,
  ManufacturerResponse,
} from './interface';

export class ManufacturerProfile extends BaseService {
  async createManufacturer(req: ManufacturerRequest) {
    return this.post<ManufacturerResponse, ManufacturerRequest>(`${this.apiUrl}/api/manufacturers`, req);
  }

  async getManufacturer(params: PaginationParams) {
    return this.get<GetManufacturerResponse>(`${this.apiUrl}/api/manufacturers/paged`, params);
  }

  async getManufacturerDropdown(params: PaginationParams) {
    return this.get<GetManufacturerDropdownResponse>(`${this.apiUrl}/api/manufacturers/get`, params);
  }

  async updateManufacturer(id: number, req: ManufacturerRequest) {
    return this.put<ManufacturerResponse, ManufacturerRequest>(`${this.apiUrl}/api/manufacturers/${id}`, req);
  }

  async deleteManufacturer(id: number) {
    return this.delete<DeleteManufacturerResponse>(`${this.apiUrl}/api/manufacturers/${id}`);
  }

  async changeStatusManufacturer(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusManufacturerResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/manufacturers/${active}/${id}`,
      req
    );
  }

  async getManufacturerByID(id: number) {
    return this.get<GetManufacturerByIdResponse>(`${this.apiUrl}/api/manufacturers/${id}`);
  }
}
