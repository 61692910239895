import { BaseService } from '../../core/BaseService';
import { DeleteData, PaginationParams } from '../../type';
import { GetStateByIdResponse, GetStateResponse, State, StateResponse } from './interface';

export class StateProfile extends BaseService {
  async createState(req: State) {
    return this.post<StateResponse, State>(`${this.apiUrl}/api/states`, req);
  }

  async getState(params: PaginationParams) {
    return this.get<GetStateResponse>(`${this.apiUrl}/api/states/paged`, params);
  }

  async updateState(id: number, req: State) {
    return this.put<State, State>(`${this.apiUrl}/api/states/${id}`, req);
  }

  async deleteState(id: number) {
    return this.delete<DeleteData>(`${this.apiUrl}/api/states/${id}`);
  }

  async getStateById(id: number | undefined) {
    return this.get<GetStateByIdResponse>(`${this.apiUrl}/api/states/${id}`);
  }
}
