// src/features/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface UserState {
  username: string;
  email: string;
  business: {
    id: number;
    scrapItemGroupId: number;
    primaryLanguage: string;
    secondaryLanguage: string;
    priceListId: number;
    priceListTitle: string;
    taxProcedureId: number;
    currency: string;
    title: string;
    secondaryTitle: string;
    active: boolean;
    whiteLabel: boolean;
    sellBelowStock: boolean;
  },
  type: number;
  privileges: string[];
}

const storedUserInfo = localStorage.getItem('userInfo');
const initialState: UserState = storedUserInfo ? JSON.parse(storedUserInfo) : {
  username: '',
  email: '',
  business: {
    id: 0,
    scrapItemGroupId: 0,
    primaryLanguage: '',
    secondaryLanguage: '',
    priceListId: 0,
    priceListTitle: '',
    taxProcedureId: 0,
    currency: '',
    title: '',
    secondaryTitle: '',
    active: false,
    whiteLabel: false,
    sellBelowStock: true,
  },
  type: 0,
  privileges: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserState>) => {
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
      return {
        ...state,
        ...action.payload,
      };
    },
    clearUserInfo: () => {
      localStorage.removeItem('userInfo');
      return initialState;
    },
  },
});

export const { setUserInfo, clearUserInfo } = userSlice.actions;
export default userSlice.reducer;

export const selectUserInfo = (state: RootState) => {
  return {
    username: state.user.username,
    email: state.user.email,
    id: state.user?.business?.id,
    scrapItemGroupId: state.user?.business?.scrapItemGroupId,
    primaryLanguage: state.user?.business?.primaryLanguage,
    secondaryLanguage: state.user?.business?.secondaryLanguage,
    priceListId: state.user?.business?.priceListId,
    priceListTitle: state.user?.business?.priceListTitle,
    taxProcedureId: state.user?.business?.taxProcedureId,
    currency: state.user?.business?.currency,
    title: state.user?.business?.title,
    secondaryTitle: state.user?.business?.secondaryTitle,
    active: state.user?.business?.active,
    whiteLabel: state.user?.business?.whiteLabel,
    sellBelowStock: state.user?.business?.sellBelowStock,
    privileges: state?.user?.privileges,
    type: state?.user?.type,
  };
};
