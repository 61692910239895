import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import Loader from 'layout/Loader';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import StyledInput from 'common/SearchField/Search';
import { useGetStateService } from 'subModule/src/services/state/useState';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { State } from 'subModule/src/services/state/interface';
import FormDialog from 'common/Modal/Modal';
import { StateProfileService } from 'subModule/src/core/services';
import { DeleteData } from 'subModule/src/type';

export default function StateListing() {
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [row, setRow] = useState<State>();
    const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetStateService({ pageNumber: pageNo, size: pageSize, name: searchTerm }) : useGetStateService({ pageNumber: pageNo, size: pageSize });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (searchTerm !== null && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 1000);
        return () => clearTimeout(getData)
    }, [searchTerm, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const onEdit = (row: any) => navigate('/state/' + row.id);
    const showRowView = (row: any) => navigate('/state/' + row.id);
    const onDelete = (row: any) => {
        setDeleteModal(true);
        setRow(row);
    }
    const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if (searchTerm) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setLoading(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
    };
    const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
        }
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_STATE_UPDATE')
            ? (checkPrivileges('ROLE_STATE_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_STATE_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/state/create')}
                heading={t('states')}
                icon={<PublicOutlinedIcon className="text-black w-[34px] h-[34px]" />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_STATE_CREATE")}
            />
            <Box>
                {loading ? <Loader /> :
                    <div id="mainListingTable">
                        <BackendPaginationTable
                            columns={[
                                { id: 'name', label: 'name', numeric: false },
                                { id: 'code', label: 'code', numeric: false },
                                { id: 'region', label: 'region', numeric: false },
                                { id: 'actions', label: 'actions', numeric: false },
                            ]}
                            data={tableData?.pages[0]?.data?.content || []}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            tableData={tableData?.pages[0]?.data || {}}
                            showCheckbox={false}
                            showActions={true}
                            enableSorting={true}
                            showHeader={true}
                            showSearch={true}
                            showFilter={true}
                            showCreateButton={true}
                            actions={adjustActions([
                                { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                                { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                            ])}
                            handleChangePage={handleChangePage}
                            handleChangeRows={handleChangeRows}
                            rowClick={checkPrivileges("ROLE_STATE_UPDATE")}
                            showRowView={showRowView}
                            loadingSearch={false}
                            headerComponent={<Grid container spacing={2}>
                                <Grid item xs={12} md={7} lg={5}>
                                    <StyledInput
                                        fullWidth
                                        placeholder={t("title")}
                                        handleSearchClick={handleSearchClick}
                                        handleChange={handleInputChange}
                                        value={search}
                                        clearSearch={clearSearch}
                                    />
                                </Grid>
                            </Grid>}
                        />
                    </div>
                }
            </Box>
            {openDeleteModal &&
                <FormDialog
                    open={openDeleteModal}
                    onClose={() => setDeleteModal(false)}
                    title={t('delete') + ' ' + t('state')}
                    // icon={<div><Home/></div>}
                    content={<Grid container>
                        <Grid item xs={12}>
                            <Typography>{t('Are you sure you want to delete?')}</Typography>
                        </Grid>
                    </Grid>}
                    saveFunction={() => {
                        setLoading(true);
                        setDeleteModal(false);
                        StateProfileService.deleteState((row as any).id).then((response: DeleteData) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('state') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="Delete"
                />}
        </Box>
    )
}