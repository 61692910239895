// ChecklistItemService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusChecklistItemResponse,
  ChecklistItemRequest,
  ChecklistItemResponse,
  DeleteChecklistItemResponse,
  GetChecklistItemResponse,
} from './interface';

export class ChecklistItemProfile extends BaseService {
  async createChecklistItem(req: ChecklistItemRequest) {
    return this.post<ChecklistItemResponse, ChecklistItemRequest>(`${this.apiUrl}/api/checklistitems`, req);
  }

  async getChecklistItem(params: PaginationParams & { inspectionformId: number }) {
    return this.get<GetChecklistItemResponse>(`${this.apiUrl}/api/checklistitems/paged`, params);
  }

  async updateChecklistItem(id: number, req: ChecklistItemRequest) {
    return this.put<ChecklistItemResponse, ChecklistItemRequest>(`${this.apiUrl}/api/checklistitems/${id}`, req);
  }

  async deleteChecklistItem(id: number) {
    return this.delete<DeleteChecklistItemResponse>(`${this.apiUrl}/api/checklistitems/${id}`);
  }

  async ChangeStatusChecklistItemResponse(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusChecklistItemResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/checklistitems/${active}/${id}`,
      req
    );
  }
}
