// ItemCategoryProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ItemCategoryProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusItemCategoryResponse,
  DeleteItemCategoryResponse,
  ItemCategoryRequest,
  ItemCategoryResponse,
} from './interface';
import { useCallback } from 'react';

type UseItemCategoryServiceProps = {
  onSuccess: (data: ItemCategoryResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateItemCategoryServiceProps = {
  onSuccess: (data: ItemCategoryResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteItemCategoryServiceProps = {
  onSuccess: (data: DeleteItemCategoryResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusItemCategoryServiceProps = {
  onSuccess: (data: ChangeStatusItemCategoryResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useItemCategoryService = ({ onError, onSuccess }: UseItemCategoryServiceProps) => {
  const {
    mutateAsync: createItemCategory,
    isPending: isCreateItemCategoryLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-ItemCategory-request'],
    mutationFn: (req: ItemCategoryRequest) => ItemCategoryProfileService.createItemCategory(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateItemCategory = useCallback(
    async (ItemCategoryData: ItemCategoryRequest) => {
      try {
        createItemCategory(ItemCategoryData);
      } catch (err) {
        console.error(err);
      }
    },
    [createItemCategory]
  );

  return {
    onCreateItemCategory,
    isCreateItemCategoryLoading,
    data,
    error,
    isError,
  };
};

export const useGetItemCategoryService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-ItemCategory-data'],
      queryFn: async ({ pageParam }) => {
        return ItemCategoryProfileService.getItemCategory({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          unitTitle: initialParams.unitTitle,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateItemCategoryService = ({ id, onError, onSuccess }: UpdateItemCategoryServiceProps) => {
  const {
    mutateAsync: updateItemCategory,
    isPending: isUpdateItemCategoryLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-ItemCategory-request'],
    mutationFn: (req: ItemCategoryRequest) => ItemCategoryProfileService.updateItemCategory(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateItemCategory = useCallback(
    async (req: ItemCategoryRequest) => {
      try {
        updateItemCategory(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateItemCategory]
  );

  return {
    onUpdateItemCategory,
    isUpdateItemCategoryLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteItemCategoryService = ({ id, onError, onSuccess }: DeleteItemCategoryServiceProps) => {
  const {
    mutateAsync: deleteItemCategory,
    isPending: isDeleteItemCategoryLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-ItemCategory-request'],
    mutationFn: () => ItemCategoryProfileService.deleteItemCategory(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteItemCategory = useCallback(async () => {
    try {
      deleteItemCategory();
    } catch (err) {
      console.error(err);
    }
  }, [deleteItemCategory]);

  return {
    onDeleteItemCategory,
    isDeleteItemCategoryLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusItemCategoryService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusItemCategoryServiceProps) => {
  const {
    mutateAsync: changeStatusItemCategory,
    isPending: isChangeStatusItemCategoryLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-itemCategory-request'],
    mutationFn: (req: ChangeStatusRequest) => ItemCategoryProfileService.changeStatusItemCategory(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusItemCategory = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusItemCategory(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusItemCategory]
  );

  return {
    onChangeStatusItemCategory,
    isChangeStatusItemCategoryLoading,
    data,
    error,
    isError,
  };
};
