import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useGetCreditNoteService } from 'subModule/src/services/inventory/useInventory';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import Loader from 'layout/Loader';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import CreditIcon from '@mui/icons-material/CreditScore';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import StyledInput from 'common/SearchField/Search';

export default function CreditNoteListing() {
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetCreditNoteService({ pageNumber: pageNo, size: pageSize, pCDNNumber: searchTerm }) : useGetCreditNoteService({ pageNumber: pageNo, size: pageSize });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
          if (searchTerm !== null && !isLoading && !error && loading) {
            await refetch();
            setLoading(false);
          }
        }, 1000);
        return () => clearTimeout(getData)
      }, [searchTerm, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const showRowView = (row: any) => navigate('/inventory/debit-note/' + row.id);
    const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setLoading(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
    };
    
    const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
    };
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/inventory/new-debit-note')}
                heading={t('Debit Notes')}
                icon={<CreditIcon sx={{ fill: '#000', width: '30px', height: '30px', marginTop: '3px' }} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_CHECKLIST_CREATE")}
            />
            <Box>
                {loading ? <Loader /> :
                    <div id="mainListingTable">
                        <BackendPaginationTable
                            columns={[
                                { id: 'creditDebitNoteNumber', label: 'Debit Note Number', numeric: false },
                                { id: 'creditDebitDate', label: 'Date', numeric: false },
                                { id: 'vendorTitle', label: 'Supplier', numeric: false },
                                { id: 'storeTitle', label: 'Store', numeric: false },
                                { id: 'amount', label: 'Amount', numeric: false },
                            ]}
                            data={tableData?.pages[0]?.data?.content || []}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            tableData={tableData?.pages[0]?.data || {}}
                            showCheckbox={false}
                            showActions={true}
                            enableSorting={true}
                            showHeader={true}
                            showSearch={true}
                            showFilter={true}
                            showCreateButton={true}
                            actions={[]}
                            handleChangePage={handleChangePage}
                            handleChangeRows={handleChangeRows}
                            rowClick={checkPrivileges("ROLE_CHECKLIST_UPDATE")}
                            showRowView={showRowView}
                            loadingSearch={false}
                            headerComponent={<Grid container spacing={2}>  
                            <Grid item xs={12} md={7} lg={5}>
                            <StyledInput
                                fullWidth
                                placeholder={t("Debit Note Number")}
                                handleSearchClick={handleSearchClick}
                                handleChange={handleInputChange}
                                value={search}
                                clearSearch={clearSearch}
                            />
                            </Grid>
                        </Grid>}
                        />
                    </div>
                }
            </Box>
        </Box>
    )
}