import SimpleModal from 'common/Form/Form';
import { Grid, Typography } from '@mui/material';
import Card from 'common/Cards/CardDetailWithPricing';
import { useTranslation } from 'react-i18next';
import CardSummary from 'common/Cards/CardSummary';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Invoice } from "assets/Modal/invoice.svg"
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { InvoiceProfileService } from 'subModule/src/core/services';
import { GetInvoiceByIdResponse } from 'subModule/src/services/invoice/interface';
import AlertMessages from 'common/Alert/AlertMessages';
import Loader from 'layout/Loader';

export default function InvoiceModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [invoice, setInvoice] = useState<any>({});
    useEffect(() => {
        if (params?.id) {
            if (loading) getByID();
        }
    }, [params]);
    const getByID = () => {
        InvoiceProfileService.getInvoiceByID(+(params as any)?.id).then((res: GetInvoiceByIdResponse) => {
            if (res.success) {
                setInvoice(res?.data || {});
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }

    return (
        <div>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate(-1)}
                onSubmit={() => { }}
                heading={t('invoiceDetails')}
                icon={<Invoice height={28} width={28} />}
                btnText={"back"}
                btnType={"back"}
                isBtnEnabled={false}
                showCreateBtn={false}
            />
            {loading ? <Loader /> : <SimpleModal
                visable={true}
                title={"invoiceDetails"}
                isButtonVisable={false}
                isVisableBtn={false}
                btnclosetext="Back"
                buttonText={""}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                formSubmit={() => { }}
                content={
                    <Grid container spacing={2}>
                        {invoice?.invoicePackages?.length === 0 &&
                            invoice?.invoiceServices?.length === 0 &&
                            invoice?.invoiceItems?.length === 0 ?
                            <Typography className="font-Saira text-black text-center font-[700] my-3  w-[100%] text-[21px] leading-[33px]">{t("No Details Found!")}</Typography> :
                            <>
                                {invoice?.invoicePackages?.length > 0 &&
                                    <Grid item xs={12} className="mt-4 p-2 rounded-lg ml-2">
                                        <Typography className="font-Saira text-start text-black line-clamp-1 font-[600] mb-2  text-[21px] leading-[33px]" >
                                            {t("packageDetails")}
                                        </Typography>
                                        <Grid item xs={12}>
                                            <Card data={invoice?.invoicePackages} type="package" />
                                        </Grid>
                                    </Grid>
                                }
                                {invoice?.invoiceServices?.length > 0 &&
                                    <Grid item xs={12} className="mt-4 p-2 rounded-lg ml-2">
                                        <Typography className="font-Saira text-start text-black line-clamp-1 font-[600] mb-2  text-[21px] leading-[33px]" >
                                            {t("serviceDetails")}
                                        </Typography>
                                        <Card data={invoice?.invoiceServices} type="service" />
                                    </Grid>
                                }
                                {invoice?.invoiceItems?.length > 0 &&
                                    <Grid item xs={12} className="mt-4 p-2 rounded-lg ml-2">
                                        <Typography className="font-Saira text-start text-black line-clamp-1 font-[600] mb-2  text-[21px] leading-[33px]" >
                                            {t("itemDetails")}
                                        </Typography>
                                        <Grid item xs={12}>
                                            <Card data={invoice?.invoiceItems} type="item" />
                                        </Grid>
                                    </Grid>
                                }
                                <CardSummary data={invoice} />
                            </>
                        }
                    </Grid>
                }
            />}
        </div>
    );
}