import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useGetStockAdjustmentService } from 'subModule/src/services/inventory/useInventory';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import Loader from 'layout/Loader';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { useGetStoreDropdownService } from 'subModule/src/services/store/useStore';
import StockAdjustmentSearchHeader from 'components/Inventory/StockAdjustmentSearchHeader';

interface SearchFormData {
    [key: string]: string | number;
}
export default function StockAdjustmentListing() {
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<string[]>(['itemCode']);
    const [search, setSearch] = useState<any>({});
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({ itemCode: '', storeId: 0 });
    const { data: tableData, isLoading, error, refetch } = useGetStockAdjustmentService({ pageNumber: pageNo, size: pageSize, ...search });
    const { data: storesData, refetch: storesRefetch } = useGetStoreDropdownService({ pageNumber: 1, size: 1000 });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
          if (search && !isLoading && !error && loading) {
            await refetch();
            setLoading(false);
          }
        }, 300)
        return () => clearTimeout(getData)
    }, [search]);
    useEffect(() => {
        storesRefetch();
    }, [])
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const showRowView = (row: any) => navigate('/inventory/stock-adjustment/' + row.id);
    const clearSearch = () => {
        setFilter(['itemCode']);
        setSearchFormData({ itemCode: '', storeId: 0 });
        setLoading(true);
        setSearch({});
    }
    const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData =>
            Object.fromEntries(Object.keys(prevFormData).map(key => 
                [key, value.includes(key) ? prevFormData[key] : (typeof prevFormData[key] === 'string' ? '' : 0)]
            ))
        );
        if (!value.length && Object.keys(search).length) {
            clearSearch();
        }
    };
    const handleChangeSearch = (field: string, value: any) => {
        const val = field === 'storeId' ? (value ? parseInt(value.value) || 0 : 0) : value;
        setSearchFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
    };
    const handleSubmitSearch = () => {
        const filteredSearch: Record<string, string | number> = {};
        Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) {
              filteredSearch[key] = typeof value === 'string' ? value?.trim() : value;
            }
        });
        setLoading(true);
        setSearch(filteredSearch);
        setPageNo(1);
        setPageSize(pageSize);
    };

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/inventory/stock-adjustment')}
                heading={t('StockAdjustments')}
                icon={<WidgetsOutlinedIcon sx={{ fill: '#000', width: '30px', height: '30px', marginTop: '3px' }} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_INVENTORYADJUSTMENT_CREATE")}
            />
            <Box>
                {loading ? <Loader /> :
                    <div id="mainListingTable">
                        <BackendPaginationTable
                            columns={[
                                { id: 'itemCode', label: t('itemCode'), numeric: false },
                                { id: 'storeTitle', label: t('store'), numeric: false },
                                { id: 'quantityAdjusted', label: 'Quantity', numeric: false },
                                { id: 'stockAdjustmentType', label: 'adjustmentType', numeric: false },
                                { id: 'createdAt', label: 'createdAt', numeric: false },
                                { id: 'createdBy', label: 'createdBy', numeric: false },
                            ]}
                            data={tableData?.pages[0]?.data?.content || []}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            tableData={tableData?.pages[0]?.data || {}}
                            showCheckbox={false}
                            showActions={true}
                            enableSorting={true}
                            showHeader={true}
                            showSearch={true}
                            showFilter={true}
                            showCreateButton={true}
                            handleChangePage={handleChangePage}
                            handleChangeRows={handleChangeRows}
                            rowClick={checkPrivileges("ROLE_INVENTORYADJUSTMENT_VIEW")}
                            showRowView={showRowView}
                            loadingSearch={false}
                            headerComponent={
                                <StockAdjustmentSearchHeader
                                    searchFormData={searchFormData}
                                    handleChangeFilter={handleChangeFilter}
                                    handleChangeSearch={handleChangeSearch}
                                    handleSubmitSearch={handleSubmitSearch}
                                    clearSearch={clearSearch}
                                    storesData={storesData?.pages[0]?.data?.content || []}
                                    {...{ filter, search }}
                                />
                            }
                        />
                    </div>
                }
            </Box>
        </Box>
    )
}