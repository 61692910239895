import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';

interface Data {
  [key: string]: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface UploadPreviewTableProps {
  columns: any[];
  rows: any[];
}

export const UploadPreviewTable: React.FC<UploadPreviewTableProps> = ({ columns, rows }) => {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = ( event: React.MouseEvent<unknown>, property: keyof Data, ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  return (
    <Box sx={{ width: '100%' }} className='reportsPreviewTable grid' id="inventorytable">
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.numeric ? 'right' : 'left'}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center" className='py-3'>
                  <Typography className="font-Saira text-primary-color font-[500] text-[16px] italic">{t('noRecordFound')}</Typography>
                </TableCell>
              </TableRow>
            ) : visibleRows.map((row, index) => {
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  {columns.map((column, colIndex) => {
                    const error:boolean = (row.invalidValues as any)?.some((invalid: any) => invalid.Column === colIndex);
                    const errorText = (row.invalidValues as any)?.find((invalid: any) => invalid.Column === colIndex)?.Error;
                    return (
                      <Tooltip title={errorText || ''}>
                        <TableCell
                          key={column.id}
                          align={column.numeric ? 'right' : 'left'}
                          padding={column.disablePadding ? 'none' : 'normal'}
                          component={undefined}
                          id={undefined}
                          scope={undefined}
                          sx={{ width: 'auto', background: error ? 'red' : 'transparent', color: error ? '#fff !important' : 'inherit' }}
                        >
                          {row[column.id] && typeof row[column.id] === 'string' && (row[column.id] as any).includes('T') 
                            ? (row[column.id] as any)?.split('T')[0] 
                            : (column.id === "shippingMethod") ? [
                              { value: 1, label: t('Air') },
                              { value: 2, label: t('Sea') },
                              { value: 3, label: t('Ground') },
                            ].find((option: any) => option.value === row[column.id])?.label
                            : (column.id === "itemGroup") ? [
                              { value: 1, label: t('groups') },
                              { value: 2, label: t('open') },
                            ].find((option: any) => option.value === row[column.id])?.label
                            : (column.id === "pricingType") ? [
                              { value: 2, label: t('Part & Service') },
                              { value: 1, label: t('Service only') },
                            ].find((option: any) => option.value === row[column.id])?.label
                            : (column.id === "pricingTypePackage") ? [
                              { value: 1, label: t('fixed') },
                              { value: 2, label: t('serviceSum') },
                            ].find((option: any) => option.value === row['pricingType'])?.label
                            : (column.id === "taxable" || column.id === "isTaxable" || column.id === "haveScrapItem" || column.id === "sellBelowCost" || column.id === "applyToAllStations" || column.id === "checklistMandatory") ? row[column.id] ? t('yes') : t('no')
                            : row[column.id]
                          }
                        </TableCell>
                      </Tooltip>
                    )
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 200]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='hide-on-print'
        labelRowsPerPage={t('rowsPerPage')}
      />
    </Box>
  );
};