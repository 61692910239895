import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useNavigate } from "react-router-dom";

interface tabProps {
    params: any;
    selectedIndex: number;
}
export default function ItemTabs(props: tabProps) {
    const navigate = useNavigate();
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) navigate(`/part/${props.params?.id}`);
        else if (tabId === 1) navigate(`/part/assign/unit/${props.params?.id}`);
        else if (tabId === 2) navigate(`/part/assign/model/${props.params?.id}`);
        else if (tabId === 3) navigate(`/part/assign/vendor/${props.params?.id}`);
        else if (tabId === 4) navigate(`/part/assign/price-list/${props.params?.id}`);
        else if (tabId === 5) navigate(`/part/assign/store/${props.params?.id}`);
    }
    const tabs = [
        { id: 0, title: `edit`, child: <></> },
        { id: 1, title: `units`, child: <></> },
        { id: 2, title: `models`, child: <></> },
        { id: 3, title: `vendors`, child: <></> },
        { id: 4, title: `priceLists`, child: <></> },
        { id: 5, title: `stores`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
                return !checkPrivileges('ROLE_ITEM_UPDATE');
            case 1:
                return !checkPrivileges('ROLE_ITEM_UNIT_ASSIGN') && !checkPrivileges('ROLE_ITEM_UNIT_UNASSIGN');
            case 2:
                return !checkPrivileges('ROLE_ITEM_MODEL_ASSIGN') && !checkPrivileges('ROLE_ITEM_MODEL_UNASSIGN');
            case 3:
                return !checkPrivileges('ROLE_ITEM_VENDOR_ASSIGN') && !checkPrivileges('ROLE_ITEM_VENDOR_UNASSIGN');
            case 4:
                return !checkPrivileges('ROLE_ITEM_PRICELIST_ASSIGN') && !checkPrivileges('ROLE_ITEM_PRICELIST_UNASSIGN');
            case 5:
                return !checkPrivileges('ROLE_STORE_ITEM_ASSIGN') && !checkPrivileges('ROLE_STORE_ITEM_UNASSIGN');
            default:
                return false;
        }
    });

    return (<>
        <VerticalTabs tabs={tabs} selectedIndex={props.selectedIndex} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
    </>);
}