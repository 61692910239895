// ChecklistService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusChecklistResponse,
  ChecklistRequest,
  ChecklistResponse,
  DeleteChecklistResponse,
  GetChecklistResponse,
} from './interface';

export class ChecklistProfile extends BaseService {
  async createChecklist(req: ChecklistRequest) {
    return this.post<ChecklistResponse, ChecklistRequest>(`${this.apiUrl}/api/checklists`, req);
  }

  async getChecklist(params: PaginationParams) {
    return this.get<GetChecklistResponse>(`${this.apiUrl}/api/checklists/paged`, params);
  }

  async getChecklistsDropdown(params: PaginationParams) {
    return this.get<GetChecklistResponse>(`${this.apiUrl}/api/checklists/get`, params);
  }

  async updateChecklist(id: number, req: ChecklistRequest) {
    return this.put<ChecklistResponse, ChecklistRequest>(`${this.apiUrl}/api/checklists/${id}`, req);
  }

  async deleteChecklist(id: number) {
    return this.delete<DeleteChecklistResponse>(`${this.apiUrl}/api/checklists/${id}`);
  }

  async changeStatusChecklist(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusChecklistResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/checklists/${active}/${id}`,
      req
    );
  }
}
