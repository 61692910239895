import { selectUserInfo } from "features/user/userSlice";
import { Grid, InputAdornment, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UserModuleProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as User } from '../../assets/SideBarIcons/users.svg';
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetUserByIdResponse } from "subModule/src/services/user/interface";
import Loader from "layout/Loader";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import DynamicSelect from "common/Select/Select";
import UserTabs from "./UserTabs";
import { useGetRoleDropdownService } from "subModule/src/services/role/useRole";
import CommonCheckbox from "common/CheckBox/Checkbox";
import language from "common/Others/Language";
import currency from "common/Others/Currency";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReplayIcon from '@mui/icons-material/Replay';
interface FormData { [key: string]: any; }

export default function UsersModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, control, formState: { errors, dirtyFields, isDirty }, reset, watch, setValue } = useForm<FormData>({ defaultValues: {} });
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [showSecTitleField, setShowSecTitleField] = useState<boolean>(false);
    const [showTitleField, setShowTitleField] = useState<boolean>(false);
    const { data: rolesData, refetch: rolesRefetch } = useGetRoleDropdownService({ pageNumber: 1, size: 1000 });
    const roles = (rolesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const loggedInUserType = loggedInUserData.type;
    const userTypesData = [
        { value: 100, label: t('Reseller') },
        { value: 110, label: t('Maintenance Head') },
        { value: 120, label: t('Service Provider') },
        { value: 130, label: t('Station Manager') },
        { value: 140, label: t('Service Manager') },
        { value: 150, label: t('Technician') },
    ];
    const userTypesOption = loggedInUserType === -1 ?
        userTypesData.filter(option => option.value === 100 || option.value === 110)
        : loggedInUserType === 100 ?
            userTypesData.filter(option => option.value === 110)
            : userTypesData.filter(option => option.value > loggedInUserType);
    useEffect(() => {
        rolesRefetch();
        if (params?.id) {
            if (loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const getByID = () => {
        UserModuleProfileService.getUserByID(+(params as any)?.id).then((res: GetUserByIdResponse) => {
            if (res.success) {
                reset({ ...res?.data || {}, 
                    roleId: { value: res.data?.roleId, label: res?.data?.roleTitle }, 
                    business: {...res?.data?.business,
                        currency: { value: res.data.business?.currency || '', label: res?.data.business?.currency || '' },
                        primaryLanguage: { value: res.data.business?.primaryLanguage || '', label: res?.data.business?.primaryLanguage || '' },
                        secondaryLanguage: { value:  res?.data.business?.secondaryLanguage || '', label:  res?.data.business?.secondaryLanguage || '' },
                    }
                });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err: any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if (params?.id) {
            let payload:any = {};
            if(data.type !== 110) {
                payload = { ...data, roleId: data?.roleId?.value || 0, email: data.username, };
                delete payload?.business;
            } else {
                payload = { ...data, 
                    roleId: data?.roleId?.value || 0, email: data.username,
                    business: {...data.business,
                        currency: data.business.currency.value,
                        primaryLanguage: data.business.primaryLanguage.value,
                        secondaryLanguage: data.business.secondaryLanguage.value,
                    }
                };
            }
            UserModuleProfileService.updateUser(+params?.id, payload).then((response: any) => {
                if (response.success) {
                    getByID();
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('user') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            delete data.showConfirmPassword; delete data.showPassword; delete data.confirmPassword;
            let payload:any = {};
            if(data.type.value !== 110) {
                payload = {...data, 
                    roleId: data?.roleId?.value || 0,
                    type: data.type.value,
                    email: data.username,
                    active: true,
                };
                delete payload?.business;
            } else {
                payload = {
                    ...data, 
                    roleId: data?.roleId?.value || 0,
                    type: data.type.value,
                    email: data.username,
                    active: true,
                    business: {...data?.business,
                        currency: data?.business?.currency.value || '',
                        primaryLanguage: data?.business?.primaryLanguage.value || '',
                        secondaryLanguage: data?.business?.secondaryLanguage.value || '',
                    }
                }
            }
            UserModuleProfileService.createUser(payload).then((response: any) => {
                if (response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('user') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate('/users');
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err: any) => {
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate('/users')}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editUser') : t('addUser')}
            icon={<User height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_USER_CREATE")}
        />
        {loading ? <Loader /> : <div className={`${params?.id ? 'flex' : ''}`}>
            {params?.id ? <UserTabs params={params} selectedIndex={0} /> : null}
            <SimpleModal
                visable={true}
                title={params?.id ? t('editUser') : t('addUser')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={
                    <Grid container component="form" id="form" className="items-center mb-2" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        {params?.id ? null : <Grid item container xs={12} spacing={3}>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="type"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t('This field is required.') }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("userType") + ' *'}
                                            {...field}
                                            options={userTypesOption}
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.type?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>}
                        <Grid item xs={12}>
                            <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('userDetails')}</h1>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("name", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })}
                                label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                fullWidth
                                error={!!errors.name}
                                helperText={errors.name?.message as string}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (<InputAdornment position="end">
                                        <Group onClick={() => setShowSecTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                    </InputAdornment>)
                                }}
                                sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                            />
                            {showSecTitleField && <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                    <TextField
                                        {...register("secondaryName")}
                                        label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                        fullWidth
                                        error={!!errors.secondaryName}
                                        helperText={errors.secondaryName?.message as string}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                type="email"
                                {...register("username", {
                                    required: t('This field is required.'),
                                    pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: t('validEmail') }
                                },)}
                                label={t("email") + ' *'}
                                fullWidth
                                error={!!errors.username}
                                helperText={errors.username?.message as string}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        {params?.id ? null : <>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type={watch('showPassword') ? 'text' : 'password'}
                                    {...register('password', {
                                        required: t('This field is required.'),
                                        validate: { passwordLength: (value) => value.length >= 8 || t('minPasswordLength'), },
                                    })}
                                    label={t('password') + ' *'}
                                    fullWidth
                                    error={!!errors.password}
                                    helperText={errors.password?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (watch('password') ? <InputAdornment position="end" className="cursor-pointer">
                                            {watch('showPassword') ? (
                                                <VisibilityIcon className="text-primary-color" onClick={() => setValue('showPassword', false)}/>
                                            ) : (
                                                <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showPassword', true)} />
                                            )}
                                        </InputAdornment> : null),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type={watch('showConfirmPassword') ? 'text' : 'password'}
                                    {...register("confirmPassword", {
                                        required: t('This field is required.'),
                                        validate: { passwordLength: (value) => (value === watch('password')) || t('confirmPasswordNewPassword'), },
                                    },)}
                                    label={t("confirmNewPassword") + ' *'}
                                    fullWidth
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (watch('confirmPassword') ? <InputAdornment position="end" className="cursor-pointer">
                                            {watch('showConfirmPassword') ? (
                                                <VisibilityIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', false)}/>
                                            ) : (
                                                <VisibilityOffIcon className="text-primary-color" onClick={() => setValue('showConfirmPassword', true)} />
                                            )}
                                        </InputAdornment> : null),
                                    }}
                                />
                            </Grid>
                        </>}
                        <Grid item xs={12} md={3}>
                            <TextField
                                type="text"
                                {...register("phone", {
                                    validate: {
                                        phoneLength: (value) =>
                                            value === '' || (value.length >= 5 && value.length <= 17) || t('phoneLength'),
                                        nonNegative: (value) =>
                                            value === '' || /^\+?[\p{N}]+$/u.test(value) || t('onlyPositiveNumbers'),
                                    },
                                })}
                                label={t("phoneNumber")}
                                fullWidth
                                error={!!errors.phone}
                                helperText={errors.phone?.message as string}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register("address")}
                                label={t('address')}
                                fullWidth
                                error={!!errors.address}
                                helperText={errors.address?.message as string}
                                InputLabelProps={{ shrink: true }}
                                multiline
                                rows={1}
                                sx={{
                                    '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                    '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                                }}
                            />
                        </Grid>
                        {params?.id ? null : <Grid item xs={12} md={3}>
                            <Controller
                                name="customRole"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (<div className="relative top-[10px]">
                                    <CommonCheckbox
                                        {...field}
                                        label={t("useCustomRole")}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            field.onChange(event.target.checked);
                                            if (!event.target.checked) setValue('roleId', 0);
                                        }}
                                        checked={field.value}
                                        color="success"
                                    />
                                </div>)}
                            />
                        </Grid>}
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="roleId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: params?.id ? t('This field is required.') : (watch('customRole') ? t('This field is required.') : false) }}
                                render={({ field }) => (
                                    <DynamicSelect
                                        label={t("role") + ' *'}
                                        {...field}
                                        options={roles}
                                        isSearchable
                                        isDisabled={params?.id ? false : (watch('customRole') ? false : true)}
                                        onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                        error={errors.roleId?.message as string}
                                    />
                                )}
                            />
                        </Grid>
                        {loggedInUserType === -1 || checkPrivileges('ROLE_LOGIN_AS') ? <Grid item container xs={12} spacing={3}>
                            <Grid item xs={12} md={5} className="flex items-center">
                                <TextField
                                    {...register("loginToken")}
                                    label={t('publicLoginLink')}
                                    fullWidth
                                    error={!!errors.loginToken}
                                    helperText={errors.loginToken?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (<InputAdornment position="end">
                                            <ReplayIcon onClick={() => {
                                                const token = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                                                let newToken = '';
                                                for(let i = 0; i < 32; i++) {
                                                    newToken += token.charAt(Math.floor(Math.random() * token.length));
                                                }
                                                setValue('loginToken', newToken);
                                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                                            }} sx={{ cursor: 'pointer', marginRight: '5px' }} />
                                        </InputAdornment>)
                                    }}
                                    sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                                />
                                {watch('loginToken') ? <span onClick={() => {setValue('loginToken', ''); if(!IsBtnEnabled) setIsBtnEnabled(true);}}
                                    className="underline text-primary-color font-Saira ms-[10px] italic text-[14px] font-semibold cursor-pointer"
                                >{t('Clear')}</span> : null}
                            </Grid>
                        </Grid> : null}
                        {watch('type')?.value === 110 || watch('type') === 110 ? <Grid item container xs={12} spacing={3}>
                            <Grid item xs={12}>
                                <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('business') + ' ' + t('Details')}</h1>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    {...register("business.title", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })}
                                    label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${primaryLanguage})` : ''} *`}
                                    fullWidth
                                    error={!!(errors.business as any)?.title}
                                    helperText={(errors.business as any)?.title?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                            <Group onClick={() => setShowTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                        </InputAdornment>)
                                    }}
                                    sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                                />
                                {showTitleField && <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                        <TextField
                                            {...register("business.secondaryTitle")}
                                            label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                                            fullWidth
                                            error={!!(errors.business as any)?.secondaryTitle}
                                            helperText={(errors.business as any)?.secondaryTitle?.message as string}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                </div>}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="business.primaryLanguage"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("primaryLanguage")}
                                            {...field}
                                            options={language.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }))}
                                            isSearchable
                                            isClearable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={(errors.business as any)?.primaryLanguage?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="business.secondaryLanguage"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("secondaryLanguage")}
                                            {...field}
                                            options={language.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }))}
                                            isSearchable
                                            isClearable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={(errors.business as any)?.secondaryLanguage?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="business.currency"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("currency")}
                                            {...field}
                                            options={currency.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }))}
                                            isSearchable
                                            isClearable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={(errors.business as any)?.currency?.message as string}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid> : null}
                    </Grid>
                }
            />
        </div>}
    </>)
}