// bankDepositService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  BankDepositRequest,
  BankDepositResponse,
  ChangeStatusBankDepositResponse,
  DeleteBankDepositResponse,
  GetBankDepositByIdResponse,
  GetBankDepositsResponse,
} from './interface';

export class BankDepositProfile extends BaseService {
  async createBankDeposit(req: BankDepositRequest) {
    return this.post<BankDepositResponse, BankDepositRequest>(`${this.apiUrl}/api/bankdeposits`, req);
  }

  async getBankDeposits(params: PaginationParams) {
    return this.get<GetBankDepositsResponse>(`${this.apiUrl}/api/bankdeposits/paged`, params);
  }

  async updateBankDeposit(id: number, req: BankDepositRequest) {
    return this.put<BankDepositResponse, BankDepositRequest>(`${this.apiUrl}/api/bankdeposits/${id}`, req);
  }

  async deleteBankDeposit(id: number) {
    return this.delete<DeleteBankDepositResponse>(`${this.apiUrl}/api/bankdeposits/${id}`);
  }

  async changeStatusBankDeposit(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusBankDepositResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/bankdeposits/${active}/${id}`,
      req
    );
  }

  async getBankDepositByID(id: number) {
    return this.get<GetBankDepositByIdResponse>(`${this.apiUrl}/api/bankdeposits/${id}`);
  }
}
