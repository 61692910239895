import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useNavigate } from 'react-router-dom';
import FormDialog from 'common/Modal/Modal';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as User } from '../../assets/SideBarIcons/users.svg';
import BackendPaginationTable from 'common/Table/TableWithPagination';
import { UserModuleProfileService } from 'subModule/src/core/services';
import { DeleteData } from 'subModule/src/type';
import Loader from 'layout/Loader';
import { useGetUserService } from 'subModule/src/services/user/useUser';
import { UserResponse, ChangeStatusUserResponse } from 'subModule/src/services/user/interface';
import UserSearchHeader from 'components/Users/UserSearchHeader';
import { selectUserInfo } from 'features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';

interface SearchFormData {
    [key: string]: string | number;
}
export default function UsersListing() {
    const navigate = useNavigate();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<string[]>(['name']);
    const [search, setSearch] = useState<any>({});
    const [row, setRow] = useState<UserResponse>({});
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({ name: '', userName: '', type: 0 });
    const { data: tableData, isLoading, error, refetch } = useGetUserService({ pageNumber: pageNo, size: pageSize, ...search });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loggedInUserData = useSelector(selectUserInfo);
    const loggedInUserType = loggedInUserData.type;
    const userTypesData = [
        { value: 100, label: t('Reseller') },
        { value: 110, label: t('Maintenance Head') },
        { value: 120, label: t('Service Provider') },
        { value: 130, label: t('Station Manager') },
        { value: 140, label: t('Service Manager') },
        { value: 150, label: t('Technician') },
    ];
    const userTypesOption = loggedInUserType === -1 ? 
      userTypesData.filter(option => option.value === 100 || option.value === 110) 
    : loggedInUserType === 100 ? 
      userTypesData.filter(option => option.value === 110) 
    : userTypesData.filter(option => option.value > loggedInUserType);
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (search && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 300)
        return () => clearTimeout(getData)
    }, [search]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const onEdit = (row: any) => navigate('/user/' + row.id);
    const showRowView = (row: any) => navigate('/user/' + row.id);
    const onDelete = (row: any) => {
        setDeleteModal(true);
        setRow(row);
    }
    const clearSearch = () => {
        setFilter(['name']);
        setSearchFormData({ name: '', userName: '', type: 0 });
        if (Object.keys(search).length) {
            setLoading(true);
            setSearch({});
        }
    }
    const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData =>
            Object.fromEntries(Object.keys(prevFormData).map(key =>
                [key, value.includes(key) ? prevFormData[key] : (typeof prevFormData[key] === 'string' ? '' : 0)]
            ))
        );
        if (!value.length && Object.keys(search).length) {
            clearSearch();
        }
    };
    const handleChangeSearch = (field: string, value: any) => {
        const val = field === 'type' ? (value ? parseInt(value.value) || 0 : 0) : value;
        setSearchFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
    };
    const handleSubmitSearch = () => {
        const filteredSearch: Record<string, string | number> = {};
        Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) {
                filteredSearch[key] = typeof value === 'string' ? value?.trim() : value;
            }
        });
        if(Object.keys(filteredSearch).length > 0 && Object.values(filteredSearch).some(value => value)) {
            setLoading(true);
            setSearch(filteredSearch);
            setPageNo(1);
            setPageSize(pageSize);
        }
    };
    const onChangeStatus = (row: any) => {
        setRow(row);
        setStatusModal(true);
    }
    const actionslist = [
        { label: "edit", onClick: onEdit, icon: <EditIcon /> },
        { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
    ];
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_USER_UPDATE')
            ? (checkPrivileges('ROLE_USER_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_USER_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }
    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/user/create')}
                heading={t('users')}
                icon={<User height={28} width={28} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_USER_CREATE")}
            />
            {loading ? <Loader /> : <BackendPaginationTable
                columns={[
                    { id: 'name', label: 'name', numeric: false },
                    { id: 'username', label: 'email', numeric: false },
                    { id: 'type', label: 'type', numeric: false },
                    { id: 'roleTitle', label: 'role', numeric: false },
                    ...(loggedInUserType === -1 ? [{ id: 'loginAsUser', label: t('loginAsUser'), numeric: false }] : []),
                    ...(checkPrivileges("ROLE_USER_ACTIVE") ? [{ id: 'active', label: 'status', numeric: false }] : []),
                    ...(adjustActions(actionslist).length ? [{ id: 'actions', label: 'actions', numeric: false }] : []),
                ]}
                data={tableData?.pages[0]?.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={tableData?.pages[0]?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={adjustActions(actionslist)}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                rowClick={checkPrivileges("ROLE_USER_UPDATE")}
                showRowView={showRowView}
                loadingSearch={false}
                {...{dispatch}}
                headerComponent={
                    <UserSearchHeader 
                        searchFormData={searchFormData}
                        handleChangeFilter={handleChangeFilter}
                        handleChangeSearch={handleChangeSearch}
                        handleSubmitSearch={handleSubmitSearch}
                        clearSearch={clearSearch}
                        {...{filter, search, userTypesOption}}
                    />
                }
            />}
            {openDeleteModal &&
                <FormDialog
                    open={openDeleteModal}
                    onClose={() => setDeleteModal(false)}
                    title={t('delete') + ' ' + t('user')}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t("Are you sure you want to delete?")}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setDeleteModal(false);
                        setLoading(true);
                        UserModuleProfileService.deleteUser((row as any).id).then((response: DeleteData) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                if (tableData?.pages[0].data.content.length === 1) handleChangePage(pageNo - 1);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('user') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="Delete"
                />}
            {statusModal &&
                <FormDialog
                    open={statusModal}
                    onClose={() => setStatusModal(false)}
                    title="Change Status"
                    // icon={<div><Home/></div>}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t(`Are you sure?`)}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setStatusModal(false);
                        setLoading(true);
                        UserModuleProfileService.changeStatusUser((row as any).id, row.active ? "inactive" : "active", {}).then((response: ChangeStatusUserResponse) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('status') + ' ' + t('changed') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="OK"
                />
            }
        </Box>
    )
}