import { selectUserInfo } from "features/user/userSlice";
import { Grid, InputAdornment, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StationGroupProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Stationgroup  } from "../../assets/SideBarIcons/station-group.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetStationGroupByIdResponse } from "subModule/src/services/stationgroup/interface";
import Loader from "layout/Loader";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import StationGroupTabs from "./StationGroupTabs";

interface FormData { [key: string]: any;}
export default function StationGroupsModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset } = useForm<FormData>({defaultValues: {}});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [showSecAccountTitleField, setShowSecAccountTitleField] = useState<boolean>(false);
    useEffect(() => {
        if(params?.id) {
            if(loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
      }, [isDirty, dirtyFields]);
    const getByID = () => {
        StationGroupProfileService.getStationGroupByID(+(params as any)?.id).then((res: GetStationGroupByIdResponse) => {
            if(res.success) {
                reset({ ...res?.data || {} });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if(params?.id) {
            StationGroupProfileService.updateStationGroup(+params?.id, {...data}).then((response: any) => {
                if(response.success) {
                    getByID();
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('stationGroup') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            StationGroupProfileService.createStationGroup(data).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('stationGroup') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate('/station-groups');
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate('/station-groups')}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('stationsGroup') : t('addStationGroup')}
            icon={<Stationgroup height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_STATIONGROUP_CREATE")}
        />
        <div className={`${params?.id ? 'flex' : ''}`}>
            {params?.id ? <StationGroupTabs params={params} selectedIndex={0} /> : null}
            {loading ? <Loader /> : <SimpleModal
                visable={true}
                title={params?.id ? t('stationsGroup') : t('addStationGroup')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={ 
                    <Grid container component="form" id="form" className="items-baseline mb-2" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                {...register("title", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                fullWidth
                                error={!!errors.title} 
                                helperText={errors.title?.message as string} 
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ endAdornment: (<InputAdornment position="end">
                                    <Group onClick={() => setShowSecAccountTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                </InputAdornment>)}}
                                sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                            />
                            {showSecAccountTitleField && <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
                                    <TextField 
                                        {...register("secondaryTitle")} 
                                        label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                        fullWidth
                                        error={!!errors.secondaryTitle} 
                                        helperText={errors.secondaryTitle?.message as string} 
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>}
                        </Grid>
                    </Grid>
                }
            />}
        </div>
    </>)
}