// InspectionSectionService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusInspectionSectionResponse,
  DeleteInspectionSectionResponse,
  GetInspectionSectionByIdResponse,
  GetInspectionSectionResponse,
  InspectionSectionRequest,
  InspectionSectionResponse,
} from './interface';

export class InspectionSectionProfile extends BaseService {
  async createInspectionSection(req: InspectionSectionRequest) {
    return this.post<InspectionSectionResponse, InspectionSectionRequest>(
      `${this.apiUrl}/api/inspectionforms/section`,
      req
    );
  }

  async getInspectionSection(params: PaginationParams & { id: number }) {
    return this.get<GetInspectionSectionResponse>(
      `${this.apiUrl}/api/inspectionforms/section/inspectionformid`,
      params
    );
  }

  async updateInspectionSection(req: InspectionSectionRequest) {
    return this.put<InspectionSectionResponse, InspectionSectionRequest>(
      `${this.apiUrl}/api/inspectionforms/section/update`,
      req
    );
  }

  async deleteInspectionSection(id: number) {
    return this.delete<DeleteInspectionSectionResponse>(`${this.apiUrl}/api/inspectionforms/section/archive/${id}`);
  }

  async ChangeStatusInspectionSection(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusInspectionSectionResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/inspectionforms/section/${active}/${id}`,
      req
    );
  }

  async getInspectionSectionById(id: number | undefined) {
    return this.get<GetInspectionSectionByIdResponse>(`${this.apiUrl}/api/inspectionforms/section/byid/${id}`);
  }
}
