// RoleService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignPrivilegeRequest,
  AssignUnassignResponse,
  ChangeStatusRoleResponse,
  DeleteRoleResponse,
  GetRoleByIdResponse,
  GetRolePrivilegeResponse,
  GetRoleResponse,
  GetRolesDropdownResponse,
  RoleRequest,
  RoleResponse,
} from './interface';

export class RoleProfile extends BaseService {
  async createRole(req: RoleRequest) {
    return this.post<RoleResponse, RoleRequest>(`${this.apiUrl}/api/roles`, req);
  }

  async getRole(params: PaginationParams) {
    return this.get<GetRoleResponse>(`${this.apiUrl}/api/roles/paged`, params);
  }

  async getRolesDropdown(params: PaginationParams) {
    return this.get<GetRolesDropdownResponse>(`${this.apiUrl}/api/roles/get`, params);
  }

  async updateRole(id: number, req: RoleRequest) {
    return this.put<RoleResponse, RoleRequest>(`${this.apiUrl}/api/roles/${id}`, req);
  }

  async deleteRole(id: number) {
    return this.delete<DeleteRoleResponse>(`${this.apiUrl}/api/roles/${id}`);
  }

  async changeStatusRole(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusRoleResponse, ChangeStatusRequest>(`${this.apiUrl}/api/roles/${active}/${id}`, req);
  }

  async getRolePrivilege(params: PaginationParams & { roleId: number }) {
    return this.get<GetRolePrivilegeResponse>(`${this.apiUrl}/api/privileges/byroleid`, params);
  }

  async assignPrivilege(req: AssignUnassignPrivilegeRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignPrivilegeRequest>(
      `${this.apiUrl}/api/roles/privilege/assign`,
      req
    );
  }

  async unassignPrivilege(req: AssignUnassignPrivilegeRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignPrivilegeRequest>(
      `${this.apiUrl}/api/roles/privilege/unassign`,
      req
    );
  }

  async getRoleByID(id: number) {
    return this.get<GetRoleByIdResponse>(`${this.apiUrl}/api/roles/${id}`);
  }
}
