// BankDepositProfileService.tsx
import { useInfiniteQuery } from '@tanstack/react-query';
import { BankDepositProfileService } from '../../core/services';
import { PageSize } from '../../type';

export const useGetBankDepositsService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-bankdeposits-data'],
      queryFn: async ({ pageParam }) => {
        return BankDepositProfileService.getBankDeposits({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          receiptNumber: initialParams.receiptNumber,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};
