// Navbar.tsx
import React, { useState } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { ReactComponent as Applogo } from "assets/Navbar/sianty-logo-01.svg";
import { ReactComponent as Menu } from "assets/Navbar/Menu.svg";
import { ReactComponent as Language } from "assets/Modal/Group.svg";
import { ReactComponent as NavUser } from "assets/Modal/NavUser.svg";
import { useSelector, useDispatch } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { clearUserInfo } from 'features/user/userSlice';
import DropdownMenu from 'common/DropDownMenu/DropDownMenu';
import { useNavigate } from 'react-router-dom';
import { useAuthLogoutService } from 'subModule/src/services/userProfile/useProfile';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AlertMessages from 'common/Alert/AlertMessages';

interface NavbarProps {
  onMenuClick: () => void;
  handleLanguageSelect: (lang: string) => void;

}

const Navbar: React.FC<NavbarProps> = ({ onMenuClick , handleLanguageSelect}) => {
  const { t } = useTranslation();
  const loggedInUserData = useSelector(selectUserInfo);

  const menuItems = [
    { label: (t(loggedInUserData?.username || '')), onClick: () => {}},
    { label: (t('accountSettings')), onClick: () =>  navigate ('/account-settings' )},
    { label: (t('logOut')), onClick: () => localStorage.getItem('ACCESS_TOKEN') ? onLogOut() : window.location.href = '/login' },
  ];
  const langItems = [
    { label: 'English', onClick: () => handleLanguageSelect('en') },
    { label: 'عربي', onClick: () => handleLanguageSelect('ar') },
    { label: 'اردو', onClick: () => handleLanguageSelect('ur') },
    { label: 'Français', onClick: () => handleLanguageSelect('fr') },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [lang, setLang] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLang(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLang(null);
  };
  const { onLogOut } = useAuthLogoutService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if(response.success) {
        dispatch(clearUserInfo());
        ['userInfo','selectedSubItem', 'ACCESS_TOKEN'].forEach(item => localStorage.removeItem(item));
        navigate('/login');   
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err) => {
      console.error('Login error======', err);
      if (err instanceof Error) {
        console.error(err.stack);
      }
    },
  });

  return (
    <AppBar  position="fixed" className='hide-on-print' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1,boxShadow:"none", color:"black" }}>
    <Toolbar 
    className='bg-layoutbg-color'
    >
    <div style={{ display: "flex", flex:1, alignItems: "center"}}>
        <Menu onClick={onMenuClick} className=' focus:outline-none transform transition duration-100 hover:scale-95' style={{ margin: "15px 18px 16px 0px" , cursor:"pointer" }} width={36} height={36}/>
      <Applogo width={123.14} height={40} style={{ marginRight: 16 }} />
      <div className='flex flex-1 justify-between py-2'>
      <div className='border-l-2 border-primary-color flex h-10 pl-4 pr-2 items-center justify-start'>
        <span className='font-Saira font-[500] text-[18px] text-txt-color text-ellipsis'>{loggedInUserData?.username || ''}</span>
      </div>
      
      {/* <Typography variant="subtitle1" className='font-Saira' component="div" sx={{ flexGrow: 1,display:'flex', cursor: 'pointer' ,alignItems:'center',justifyContent:'end' }}> */}
      <div className='flex justify-between px-2 h-10'>
      <span className='bg-unselected-color h-10 cursor-pointer rounded-[10px] mr-4 w-10 flex text-center items-center justify-center' onClick={handleClickLang}>{<Language />}</span>
      <span  className='bg-unselected-color h-10 cursor-pointer rounded-[10px] w-10 flex text-center items-center justify-center' onClick={handleClick}>{<NavUser height={'40px'} width={'40px'} />}</span>
      </div>
      </div>
      {/* </Typography> */}
      <DropdownMenu anchorEl={anchorEl} menuItems={menuItems} onClose={handleClose} />
      <DropdownMenu anchorEl={lang} langItems={langItems} onClose={handleClose} />
      </div>
    </Toolbar>
    </AppBar>
      );
};

export default Navbar;
