import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Button, ButtonGroup, Grid, TextField, Typography } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import CommonCheckbox from 'common/CheckBox/Checkbox';
import StyledInputArea from 'common/TextArea/TextArea';
import { useTranslation } from 'react-i18next';
import Currency from 'common/Others/Currency';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface ItemsModalProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  manufacturersData: any;
  taxProceduresData: any;
  unitsData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
  scrapItemsData: any;
  defaultPriceList: string;
  loggedInUserCurrency: string;
  storesData: any;
  prevFormData: object;
}

const ItemsModal: React.FC<ItemsModalProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  manufacturersData,
  taxProceduresData,
  unitsData,
  openForm,
  enableEdit,
  errorMessages,
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  isBtnEnabled,
  scrapItemsData,
  defaultPriceList,
  loggedInUserCurrency,
  storesData,
  prevFormData
}) => {
  const { t } = useTranslation();
  const handleScroll = () => {
    // Handle scroll logic here
  };
  const handleSearch = () => {
    // Handle search logic here 
  };
  const shippingMethodOptions = [
    { value: 1, label: 'Air' },
    { value: 2, label: 'Sea' },
    { value: 3, label: 'Ground' },
  ]
  // const chargedPerOptions = [
  //   { value: 1, label: 'Free' },
  //   { value: 2, label: 'Fixed' },
  //   { value: 3, label: 'Per Quantity' },
  // ]
  // const baseItemQuantityTypeOptions = [
  //   { value: 1, label: 'Min. Quantity' },
  //   { value: 2, label: 'Max Quantity' },
  //   { value: 3, label: 'Avg. Quantity' },
  // ]
  const manufacturersOptions = manufacturersData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const taxProcedureOptions = taxProceduresData.map((item: { id: number, title: string, percentageValue?: number }) => ({ value: item.id, label: item.title, percentageValue: item.percentageValue }));
  const unitsOptions = unitsData.map((item: { id: number, unitTitle: string }) => ({ value: item.id, label: item.unitTitle }));
  const scrapItemOptions = scrapItemsData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const storesOptions = storesData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const currencyOptions = Currency.map((item) => ({ value: item.value, label: item.title, symbol: item.symbol }));
  const currencyLabel = currencyOptions.find((option) => option.value === loggedInUserCurrency)?.symbol || '';
  useEffect(() => {
    const priceWithTax = (parseFloat(formData?.price) + (
      parseFloat(formData?.price) * 
      parseFloat((taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue || 0))/100
    ))?.toFixed(2);
    handleChange('priceWithTax', priceWithTax);
  }, [formData?.taxProcedureId, taxProceduresData]);
  
  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateItems" : "addItems"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container>
            <Grid container rowGap={0}>
              <Grid item xs={12} sm={12} md={12} lg={12} className='py-0'>
                <h1 className='flex font-Saira font-[800] mb-2 text-[20px] text-[#000000]'>{t('general')}</h1>
              </Grid>
              <Grid container columnSpacing={2}>
                {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    label="itemPictureUrl"
                    handleChange={(e) => handleChange('itemPictureUrl', e.target.value)}
                    required={false}
                    fullWidth
                    // error={errorMessages?.itemPictureUrl}
                    value={formData?.itemPictureUrl || ''}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    label="partNo"
                    placeholder="Write your itemCode here"
                    handleChange={(e) => handleChange('itemCode', e.target.value)}
                    required={true}
                    fullWidth
                    error={errorMessages?.itemCode}
                    value={formData?.itemCode || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                    search='language'
                    handleSecField={handleSecField}
                    placeholder="Write your Title here"
                    handleChange={(e) => handleChange('title', e.target.value)}
                    required={true}
                    fullWidth
                    error={errorMessages?.title}
                    value={formData?.title || ''}
                  />
                  {showSecField &&
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <div style={{ position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            top: -8,
                            left: 0,
                            zIndex: 1,
                            display: showSecField ? 'flex' : 'none',
                            backgroundColor: "white",
                            height: '5rem',
                            width: '100%',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                          }}
                        >
                          <div className='w-full mx-2 mt-1'>
                            <StyledField
                              label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                              placeholder="Write your Secondary Title here"
                              handleChange={(e) => handleChange('secondaryTitle', e.target.value)}
                              required={false}
                              fullWidth
                              // error={errorMessages?.secondaryTitle}
                              value={formData?.secondaryTitle || ''}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    label="itemCode"
                    handleChange={(e) => handleChange('partNo', e.target.value)}
                    required={false}
                    fullWidth
                    // error={errorMessages?.partNo}
                    value={formData?.partNo || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <DynamicSelect
                    defaultValue={enableEdit ? manufacturersOptions.find((option: any) => option.value === formData?.manufacturer) : null}
                    value={manufacturersOptions.find((option: any) => option.value === formData?.manufacturer)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    // fullWidth={false}
                    isSearchable={true}
                    name="manufacturer"
                    options={manufacturersOptions}
                    onScroll={handleScroll}
                    onInputChange={handleSearch}
                    // isMulti
                    isRequired={false}
                    onChange={(e) => handleChange('manufacturer', e)}
                    // error={errorMessages?.manufacturerError}
                    label="manufacturer"
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <DynamicSelect
                    defaultValue={enableEdit ? unitsOptions.find((option: any) => option.value === formData?.unitId) : null}
                    value={unitsOptions.find((option: any) => option.value === formData?.unitId)}
                    isDisabled={enableEdit && (prevFormData as any)?.unitId}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    // fullWidth={false}
                    isSearchable={true}
                    name="unitId"
                    options={unitsOptions}
                    onScroll={handleScroll}
                    onInputChange={handleSearch}
                    // isMulti
                    isRequired={true}
                    onChange={(e) => handleChange('unitId', e)}
                    error={errorMessages?.unitIdError}
                    label="baseUnitId"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-baseline justify-start`} >
                  <CommonCheckbox
                    label='taxable'
                    onChange={(e) => handleChange('taxable', e.target.checked)}
                    color="success"
                    required={false}
                    // error={errorMessages?.haveExpiryError}
                    checked={formData?.taxable}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  {formData.taxable &&
                    <DynamicSelect
                      defaultValue={taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)}
                      value={taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      // fullWidth={false}
                      isSearchable={true}
                      name="color"
                      options={taxProcedureOptions}
                      onScroll={handleScroll}
                      onInputChange={handleSearch}
                      // isMulti
                      isRequired={formData?.taxable ? true : false}
                      onChange={(e) => handleChange('taxProcedureId', e)}
                      error={errorMessages?.taxProcedureIdError}
                      label="taxProcedure"
                    />
                  }
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} className='mt-2'>
                <Grid item xs={12} sm={12} md={12} lg={12} className='px-4'>
                  <h1 className='flex font-Saira font-[800] text-[20px] text-[#000000]'>Price</h1>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className='px-4 flex justify-start items-center'>
                  <h3 className='flex font-Saira font-[800] text-[16px] text-[#000000]'>{defaultPriceList}</h3>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    type='number'
                    label={t('price') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '')}
                    placeholder="Write Price here"
                    handleChange={(e) => {
                      handleChange('priceWithTax', (parseFloat(e.target.value) + (
                        parseFloat(e.target.value) * 
                        parseFloat((taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue || 0))/100
                      ))?.toFixed(2));
                      handleChange('price', e.target.value);
                    }}
                    required={true}
                    fullWidth
                    error={errorMessages?.priceError}
                    value={formData?.price || 0}
                  />
                </Grid>
                {formData?.taxable && formData?.taxProcedureId ? <><Grid item xs={12} sm={6} md={4} lg={3} className='relative'>
                  <StyledField
                    type='number'
                    label={t('tax') + ' (%)'}
                    required={false}
                    fullWidth
                    value={taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue || '0'}
                    readOnly={true}
                  />
                  <span className='font-Saira absolute top-[30px] right-[10px] text-[14px]'>{formData?.price ? (
                        parseFloat(formData?.price) * 
                        parseFloat((taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue || 0))/100
                      )?.toFixed(2) + (loggedInUserCurrency ? ' ' + currencyLabel + '' : '') : null}</span>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    type='number'
                    label={t('priceWithTax') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '')}
                    handleChange={(e) => {
                      handleChange('priceWithTax', e.target.value);
                      const price = parseFloat(e.target.value) / (
                        1 + (parseFloat((taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)?.percentageValue))/100)
                      );
                      handleChange('price', (price - Math.floor(price)) > 0.9 ? Math.round(price) : price?.toFixed(2));
                    }}
                    required={false}
                    fullWidth
                    value={formData?.priceWithTax || 0}
                  />
                </Grid></> : null}
              </Grid>
              <Grid container columnSpacing={2} >
                <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-baseline justify-start`} >
                  <CommonCheckbox
                    label='haveScrapItem'
                    onChange={(e) => handleChange('haveScrapItem', e.target.checked)}
                    color="success"
                    required={false}
                    // error={errorMessages?.haveScrapItemError}
                    checked={formData?.haveScrapItem}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  {formData?.haveScrapItem && 
                    <DynamicSelect
                      defaultValue={enableEdit ? scrapItemOptions.find((option: any) => option.value === formData?.scrapItemId) : null}
                      value={scrapItemOptions.find((option: any) => option.value === formData?.scrapItemId)}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      // fullWidth={false}
                      isSearchable={true}
                      name="color"
                      options={scrapItemOptions}
                      onScroll={handleScroll}
                      onInputChange={handleSearch}
                      // isMulti
                      isRequired={formData?.haveScrapItem ? true : false}
                      onChange={(e) => handleChange('scrapItemId', e)}
                      error={errorMessages?.scrapItemIdError}
                      label="scrapItem"
                    />
                  }
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} >
                {/* <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-baseline justify-start`}>
                  <CommonCheckbox
                    label='applyExtraServiceCharge'
                    onChange={(e) => handleChange('applyExtraServiceCharge', e.target.checked)}
                    color="success"
                    required={false}
                    // error={errorMessages?.applyExtraServiceChargeError}
                    checked={formData?.applyExtraServiceCharge}
                  />
                </Grid> */}
                {/* {
                  formData?.applyExtraServiceCharge && ( */}
                {/* <>
                      <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <StyledField
                          label="chargedName"
                          isDisabled={formData?.applyExtraServiceCharge===false}
                          placeholder="Write Charged Name here"
                          handleChange={(e) => handleChange('chargedName', e.target.value)}
                          required={formData?.applyExtraServiceCharge ? true : false}
                          fullWidth
                          error={errorMessages?.chargedName}
                          value={formData?.chargedName || ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <StyledField
                          label="secondaryChargedName"
                          isDisabled={formData?.applyExtraServiceCharge===false}
                          placeholder="Write Secondary Charged Name here"
                          handleChange={(e) => handleChange('secondaryChargedName', e.target.value)}
                          required={formData?.applyExtraServiceCharge ? true : false}
                          fullWidth
                          error={errorMessages?.secondaryChargedName}
                          value={formData?.secondaryChargedName || ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <StyledField
                          label="chargedValue"
                          isDisabled={formData?.applyExtraServiceCharge===false}
                          placeholder="Write Charged Value here"
                          handleChange={(e) => handleChange('chargedValue', e.target.value)}
                          required={formData?.applyExtraServiceCharge ? true : false}
                          fullWidth
                          error={errorMessages?.chargedValue}
                          value={formData?.chargedValue || ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <DynamicSelect
                          defaultValue={enableEdit ? chargedPerOptions.find((option: any) => option.value === formData?.chargedPer) : null}
                          value={chargedPerOptions.find((option: any) => option.value === formData?.chargedPer)}
                          isDisabled={formData?.applyExtraServiceCharge===false}
                          isLoading={false}
                          isClearable={true}
                          isRtl={false}
                          // fullWidth={false}
                          isSearchable={true}
                          name="chargedPer"
                          options={chargedPerOptions}
                          onScroll={handleScroll}
                          onInputChange={handleSearch}
                          // isMulti
                          isRequired={formData?.applyExtraServiceCharge ? true : false}
                          onChange={(e) => handleChange('chargedPer', e)}
                          error={errorMessages?.chargedPerError}
                          label="chargedPer"
                        />
                      </Grid>
                    </> */}
                {/* )
                } */}
              </Grid>
            </Grid>


            {/* <------------------Stock-Info---------------------> */}


            <Grid container spacing={2} className='mt-2'>
              <Grid item xs={12} sm={12} md={12} lg={12} className=' px-4'>
                <h1 className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('stockInfo')}</h1>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label="stockReferanceNumber"
                  placeholder="Write Stock Referance Number here"
                  handleChange={(e) => handleChange('stockReferanceNumber', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.stockReferanceNumber}
                  value={formData?.stockReferanceNumber || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label={t('itemCost') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '')}
                  placeholder="Write Item Cost here"
                  handleChange={(e) => handleChange('itemCost', e.target.value)}
                  required={!enableEdit}
                  fullWidth
                  error={errorMessages?.itemCostError}
                  value={formData?.itemCost || 0}
                  isDisabled={enableEdit && (prevFormData as any)?.itemCost}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DynamicSelect
                  defaultValue={enableEdit ? storesOptions.find((option: any) => option.value === formData?.storeId) : null}
                  value={storesOptions.find((option: any) => option.value === formData?.storeId)}
                  isDisabled={enableEdit && (prevFormData as any)?.storeId}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  // fullWidth={false}
                  isSearchable={true}
                  name="storeId"
                  options={storesOptions}
                  onScroll={handleScroll}
                  onInputChange={handleSearch}
                  // isMulti
                  isRequired={true}
                  onChange={(e) => handleChange('storeId', e)}
                  error={errorMessages?.storeIdError}
                  label="stores"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography className="font-Saira font-[600] text-left text-[14px] text-txt-color line-clamp-1">{t('Quantity')}</Typography>
                <ButtonGroup className='flex'>
                  <Button aria-label="reduce" sx={{ borderRadius: '8px 0 0 8px', background: '#F2E5E9 !important', borderColor: '#FC5C57', width: '36px' }}
                    onClick={() => handleChange('stockQuantity', Math.max(formData?.stockQuantity - 1, 0))}
                    disabled={enableEdit && (prevFormData as any)?.stockQuantity}
                  >
                    <RemoveIcon fontSize="small" sx={{ color: '#FC5C57' }} />
                  </Button>
                  <TextField 
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleChange('stockQuantity', parseInt(e.target.value) < 1 ? 1 : parseInt(e.target.value))}
                    value={formData?.stockQuantity}
                    onBlur={(e) => handleChange('stockQuantity', parseInt(e.target.value) < 1 || !e.target.value ? 1 : parseInt(e.target.value))}
                    sx={{
                      height: '36px',
                      '& input': { padding: '6.5px 15px' }, 
                      '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%' },
                      '& input[type=number]': { MozAppearance: 'textfield', },
                      '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none', margin: 0,
                      },
                    }}
                    disabled={enableEdit && (prevFormData as any)?.stockQuantity}
                  />
                  <Button aria-label="increase" sx={{ borderRadius: '0 8px 8px 0', background: '#17AE361A !important', borderColor: '#17AE3680', width: '36px' }}
                    onClick={() => handleChange('stockQuantity', formData?.stockQuantity + 1)}
                    disabled={enableEdit && (prevFormData as any)?.stockQuantity}
                  >
                    <AddIcon fontSize="small" sx={{ color: '#17AE36' }} />
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <StyledField
                  type='number'
                  label={t('minPrice') + (loggedInUserCurrency ? ' (' + currencyLabel + ')' : '')}
                  placeholder="Write Minimum Price here"
                  handleChange={(e) => handleChange('minPrice', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.minPrice}
                  value={formData?.minPrice || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label="minQuantity"
                  placeholder="Write Minimum Quantity here"
                  handleChange={(e) => handleChange('minQuantity', e.target.value)}
                  required={false}
                  fullWidth
                  error={errorMessages?.minQuantityError}
                  value={formData?.minQuantity || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label="maxQuantity"
                  placeholder="Write Maximum Quantity here"
                  handleChange={(e) => handleChange('maxQuantity', e.target.value)}
                  required={false}
                  fullWidth
                  error={errorMessages?.maxQuantityError}
                  value={formData?.maxQuantity || 0}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                <DynamicSelect
                  defaultValue={enableEdit ? baseItemQuantityTypeOptions.find((option: any) => option.value === formData?.baseItemQuantityType) : null}
                  value={baseItemQuantityTypeOptions.find((option: any) => option.value === formData?.baseItemQuantityType)}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  // fullWidth={false}
                  isSearchable={true}
                  name="baseItemQuantityType"
                  options={baseItemQuantityTypeOptions}
                  onScroll={handleScroll}
                  onInputChange={handleSearch}
                  // isMulti
                  isRequired={false}
                  onChange={(e) => handleChange('baseItemQuantityType', e)}
                  // error={errorMessages?.baseItemQuantityType}
                  label="baseItemQuantityType"
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  label="itemSupplierNumber"
                  placeholder="Write Item Supplier Number here"
                  handleChange={(e) => handleChange('itemSupplierNumber', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.itemSupplierNumber}
                  value={formData?.itemSupplierNumber || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  label="warranty"
                  placeholder="Write Warranty here"
                  handleChange={(e) => handleChange('warranty', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.warranty}
                  value={formData?.warranty || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label="itemWarrantyServiceId"
                  handleChange={(e) => handleChange('itemWarrantyServiceId', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.itemWarrantyServiceIdError}
                  value={formData?.itemWarrantyServiceId || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <DynamicSelect
                  defaultValue={enableEdit ? shippingMethodOptions.find((option: any) => option.value === formData?.shippingMethod) : null}
                  value={shippingMethodOptions.find((option: any) => option.value === formData?.shippingMethod)}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  // fullWidth={false}
                  isSearchable={true}
                  name="color"
                  options={shippingMethodOptions}
                  onScroll={handleScroll}
                  onInputChange={handleSearch}
                  // isMulti
                  isRequired={false}
                  onChange={(e) => handleChange('shippingMethod', e)}
                  // error={errorMessages?.shippingMethod}
                  label="shippingMethod"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label="salesMarkupPercentage"
                  placeholder="Write Sales Markup Percentage here"
                  handleChange={(e) => handleChange('salesMarkupPercentage', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.salesMarkupPercentage}
                  value={formData?.salesMarkupPercentage || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-baseline justify-start`}>
                <CommonCheckbox
                  label='sellBelowCost'
                  onChange={(e) => handleChange('sellBelowCost', e.target.checked)}
                  color="success"
                  required={false}
                  // error={errorMessages?.sellBelowCostError}
                  checked={formData?.sellBelowCost}
                />
              </Grid>

            </Grid>

            {/* <---------------------ReplacementTime---------------> */}

            <Grid container spacing={2} className='mt-2'>
              <Grid item xs={12} sm={12} md={12} lg={12} className='py-0 px-4'>
                <h1 className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('replacementTime')}</h1>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label="Mileage (km)"
                  handleChange={(e) => handleChange('itemEveryMile', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.itemEveryMileError}
                  value={formData?.itemEveryMile || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label="Number of Days"
                  handleChange={(e) => handleChange('itemEveryPeriodType', e.target.value)}
                  required={false}
                  fullWidth={true}
                  // error={errorMessages?.itemEveryPeriodTypeError}
                  value={formData?.itemEveryPeriodType || ''}
                />
              </Grid>


            </Grid>


            {/* <----------------------ExtraNotes-------------------> */}
            <Grid container spacing={2} className='mt-2'>
              <Grid item xs={12} sm={12} md={12} lg={12} className='py-0 px-4'>
                <h1 className='flex font-Saira font-[800] text-[20px] text-[#000000]'>{t('extraNotes')}</h1>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type='number'
                  label="Sort Order (Value 1-100)"
                  handleChange={(e) => handleChange('sortOrder', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.sortOrder}
                  value={formData?.sortOrder || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <StyledInputArea
                  label="extraNotes"
                  handleChange={(e) => handleChange('extraNotes', e.target.value)}
                  required={false}
                  rowsNo={2}
                  fullWidth
                  // error={errorMessages?.extraNotes}
                  value={formData?.extraNotes || ''}
                />
              </Grid>


            </Grid>


          </Grid>
        }
      />
    </div>
  );
};

export default ItemsModal;
