import { clearUserInfo, selectUserInfo } from "features/user/userSlice";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BusinessProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Accounts } from '../../assets/SideBarIcons/accounts.svg';
import { useEffect, useState } from "react";
import CommonCheckbox from "common/CheckBox/Checkbox";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import DynamicSelect from "common/Select/Select";
import Currency from 'common/Others/Currency';
import Language from 'common/Others/Language';
import { useGetItemGroupDropdownService } from "subModule/src/services/itemgroup/useItemGroup";
import { useGetTaxProcedureDropdownService } from "subModule/src/services/taxProcedure/useTaxProcedure";
import Loader from "layout/Loader";
import AccountSettingsTabs from "./AccountSettingsTabs";
import FormDialog from "common/Modal/Modal";
import { useAuthLogoutService } from "subModule/src/services/userProfile/useProfile";

interface FormData { [key: string]: any;}
export default function BusinessSettings() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { register, control, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset } = useForm<FormData>({defaultValues: {
        sellBelowStock: true
    }});
    const [updateBusinessModal, setUpdateBusinessModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [business, setBusiness] = useState<any>({});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
    const [showSecTitleField, setShowSecTitleField] = useState<boolean>(false);
    const { data: scrapItemGroupsData, refetch: scrapItemGroupsRefetch } = useGetItemGroupDropdownService({ pageNumber: 1, size: 1000 });
    const { data: taxProceduresData, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 1000 });
    const scrapItemGroups = (scrapItemGroupsData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const taxProcedures = (taxProceduresData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const currencyOptions = Currency.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }));
    const languageOptions = Language.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }));
    useEffect(() => {
        scrapItemGroupsRefetch();
        taxProceduresRefetch();
    }, []);
    useEffect(() => {
        if(scrapItemGroupsData && taxProceduresData) {
            reset({
                ...loggedInUserData || {},
                primaryLanguage: loggedInUserData?.primaryLanguage ? { value: loggedInUserData?.primaryLanguage, label: loggedInUserData?.primaryLanguage } : null,
                secondaryLanguage: loggedInUserData?.secondaryLanguage ? { value: loggedInUserData?.secondaryLanguage, label: loggedInUserData?.secondaryLanguage } : null,
                scrapItemGroupId: loggedInUserData?.scrapItemGroupId ? { value: loggedInUserData?.scrapItemGroupId, label: scrapItemGroups.find((v) => v.value === loggedInUserData?.scrapItemGroupId)?.label || loggedInUserData?.scrapItemGroupId } : null,
                taxProcedureId: loggedInUserData?.taxProcedureId ? { value: loggedInUserData?.taxProcedureId, label: taxProcedures.find((v) => v.value === loggedInUserData?.taxProcedureId)?.label || loggedInUserData?.taxProcedureId } : null,
                currency: loggedInUserData?.currency ? { value: loggedInUserData?.currency, label: loggedInUserData?.currency } : null,
            });
            setLoading(false);
        }
    }, [scrapItemGroupsData, taxProceduresData]);
    useEffect(() => {
        if(isDirty) setIsBtnEnabled(true);
    }, [isDirty, dirtyFields]);
    const onSubmit = (data: any) => {
        delete data.privileges; delete data.type; delete data.username; delete data.email; delete data.priceListTitle;
        setIsBtnEnabled(false);
        setUpdateBusinessModal(true);
        setBusiness({...data, 
            primaryLanguage: data.primaryLanguage?.value || '',
            secondaryLanguage: data.secondaryLanguage?.value || '',
            currency: data.currency?.value || '',
            scrapItemGroupId: data.scrapItemGroupId.value,
            taxProcedureId: data.taxProcedureId?.value || 0,
        });
    };
    const { onLogOut } = useAuthLogoutService({
        onSuccess: (response) => {
          if(response.success) {
            dispatch(clearUserInfo());
            ['userInfo','selectedSubItem', 'ACCESS_TOKEN'].forEach(item => localStorage.removeItem(item));
            navigate('/login');   
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err);
          if (err instanceof Error) {
            console.error(err.stack);
          }
        },
    });
    return (<>
        <ComponentHeaderTwo
            showBtn={false}
            showSaveBtn={false}
            heading={t('accountSettings')}
            icon={<Accounts height={28} width={28} />}
            btnText="back"
            btnType="back"
            isBtnEnabled={IsBtnEnabled}
        />
        <div className={`flex`}>
            <AccountSettingsTabs selectedIndex={1} />
            {loading ? <Loader /> :<SimpleModal
                visable={true}
                title={"editBusiness"}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={"update"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={
                    <Grid container component="form" id="form" className="items-center" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                {...register("title", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                                label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                fullWidth
                                error={!!errors.title} 
                                helperText={errors.title?.message as string} 
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ endAdornment: (<InputAdornment position="end">
                                    <Group onClick={() => setShowSecTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                </InputAdornment>)}}
                                sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                            />
                            {showSecTitleField && <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
                                    <TextField 
                                        {...register("secondaryTitle")} 
                                        label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                        fullWidth
                                        error={!!errors.secondaryTitle} 
                                        helperText={errors.secondaryTitle?.message as string} 
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="primaryLanguage"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("primaryLanguage")}
                                            {...field}
                                            options={languageOptions}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.primaryLanguage?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="secondaryLanguage"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("secondaryLanguage")}
                                            {...field}
                                            options={languageOptions}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.secondaryLanguage?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="currency"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("currency")}
                                            {...field}
                                            options={currencyOptions}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.currency?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="scrapItemGroupId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: t('This field is required.') }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("scrapItemGroup") + ' *'}
                                            {...field}
                                            options={scrapItemGroups}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.scrapItemGroupId?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="taxProcedureId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: false }}
                                    render={({ field }) => (
                                        <DynamicSelect
                                            label={t("taxProcedureId")}
                                            {...field}
                                            options={taxProcedures}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.taxProcedureId?.message as string}
                                        />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="sellBelowStock"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (<div className="relative top-[10px]">
                                    <CommonCheckbox
                                        {...field}
                                        label={t("Sell Below Stock")}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                        checked={field.value}
                                        color="success"
                                    />
                                </div>)}
                            />
                        </Grid>
                    </Grid>
                }
            />}
        </div>
        {updateBusinessModal && <FormDialog
            open={updateBusinessModal}
            onClose={(event: any, reason: any) => { if(event && (reason !== 'backdropClick')) { setUpdateBusinessModal(false); setIsBtnEnabled(true);}}}
            title={t('update') + ' ' + t('business') + ' ' +  t('settings')}
            content={
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}  >
                  <Typography>{t('applyBusinessSettingsMessage')}</Typography>
                </Grid>
              </Grid>
            }
            saveFunction={() => {
                BusinessProfileService.updateBusiness(loggedInUserData?.id, business).then((response: any) => {
                    if(response.success) {
                        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                        setUpdateBusinessModal(false);
                        localStorage.getItem('ACCESS_TOKEN') ? onLogOut() : navigate('/login');
                    } else {
                        setIsBtnEnabled(true);
                        enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    }
                }).catch((err:any) => { 
                    console.error("===", err);
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
                });
            }}
            submitButtonText="OK"
        />}
    </>)
}