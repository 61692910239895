import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useNavigate } from 'react-router-dom';
import FormDialog from 'common/Modal/Modal';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as ManuFacture  } from "assets/SideBarIcons/manufacter.svg";
import BackendPaginationTable from 'common/Table/TableWithPagination';
import { ManufacturerProfileService } from 'subModule/src/core/services';
import { DeleteData } from 'subModule/src/type';
import Loader from 'layout/Loader';
import { useGetManufacturerService } from 'subModule/src/services/manufacturer/useManufacturer';
import { ManufacturerResponse, ChangeStatusManufacturerResponse } from 'subModule/src/services/manufacturer/interface';
import StyledInput from "common/SearchField/Search";

export default function ManufacturersListing() {
    const navigate = useNavigate();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<any>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [row, setRow] = useState<ManufacturerResponse>({});
    const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetManufacturerService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetManufacturerService({ pageNumber: pageNo, size: pageSize });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (searchTerm !== null && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 1000);
        return () => clearTimeout(getData)
    }, [searchTerm, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const onEdit = (row: any) => navigate('/manufacturer/' + row.id);
    const showRowView = (row: any) => navigate('/manufacturer/' + row.id);
    const onDelete = (row: any) => {
        setDeleteModal(true);
        setRow(row);
    }
    const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if (searchTerm) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setLoading(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
    };
    const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') handleSearch(trimmedSearch);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };
    const onChangeStatus = (row: any) => {
        setRow(row);
        setStatusModal(true);
    }
    const actionslist = [
        { label: "edit", onClick: onEdit, icon: <EditIcon /> },
        { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
    ];
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_MANUFACTURER_UPDATE')
            ? (checkPrivileges('ROLE_MANUFACTURER_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_MANUFACTURER_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }
    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/manufacturer/create')}
                heading={t('manufacturers')}
                icon={<ManuFacture height={28} width={28} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_MANUFACTURER_CREATE")}
            />
            {loading ? <Loader /> : <BackendPaginationTable
                columns={[
                    { id: 'title', label: 'title', numeric: false },
                    ...(checkPrivileges("ROLE_MANUFACTURER_ACTIVE") ? [{ id: 'active', label: 'status', numeric: false }] : []),
                    ...(adjustActions(actionslist).length ? [{ id: 'actions', label: 'actions', numeric: false }] : []),
                ]}
                data={tableData?.pages[0]?.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={tableData?.pages[0]?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={adjustActions(actionslist)}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                rowClick={checkPrivileges("ROLE_MANUFACTURER_UPDATE")}
                showRowView={showRowView}
                loadingSearch={false}
                headerComponent={<Grid container spacing={2}>
                    <Grid item xs={12} md={7} lg={5}>
                        <StyledInput
                            fullWidth
                            placeholder={t("title")}
                            handleSearchClick={handleSearchClick}
                            handleChange={handleInputChange}
                            value={search}
                            clearSearch={clearSearch}
                        />
                    </Grid>
                </Grid>}
            />}
            {openDeleteModal &&
                <FormDialog
                    open={openDeleteModal}
                    onClose={() => setDeleteModal(false)}
                    title={t('delete') + ' ' + t('manufacturer')}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t("Are you sure you want to delete?")}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setDeleteModal(false);
                        setLoading(true);
                        ManufacturerProfileService.deleteManufacturer((row as any).id).then((response: DeleteData) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                if (tableData?.pages[0].data.content.length === 1) handleChangePage(pageNo - 1);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('manufacturer') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="Delete"
                />}
            {statusModal &&
                <FormDialog
                    open={statusModal}
                    onClose={() => setStatusModal(false)}
                    title="Change Status"
                    // icon={<div><Home/></div>}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t(`Are you sure?`)}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setStatusModal(false);
                        setLoading(true);
                        ManufacturerProfileService.changeStatusManufacturer((row as any).id, row.active ? "inactive" : "active", {}).then((response: ChangeStatusManufacturerResponse) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('status') + ' ' + t('changed') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="OK"
                />
            }
        </Box>
    )
}