import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState, useRef } from 'react';
import { StationProfileService } from 'subModule/src/core/services';
import { UploadImageResponse } from 'subModule/src/services/station/interface';
import AlertMessages from 'common/Alert/AlertMessages';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

type StationMediaProps = {
  refetch?: () => void;
  formData?: any;
  method?: string;
  callback?: (url: string) => void;
  setIsBtnEnabled?: any;
};
const StationMedia: React.FC<StationMediaProps> = ({ formData, method, callback, setIsBtnEnabled }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const formRef = useRef<HTMLFormElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && !file.type.startsWith('image/')) {
        formRef.current?.reset();
        setFileName('');
        return enqueueSnackbar(<AlertMessages text={t('onlyImagesAllowed')} />, { variant: 'error' });
    }
    setFileName(file ? file.name : '');
  };
  const onUpload = (data: any) => {
    setIsBtnEnabled?.(false);
    StationProfileService.uploadImage(data).then((response: UploadImageResponse) => {
      if (response.success) {
        enqueueSnackbar(<AlertMessages text={t('uploaded') + ' ' + t('successfully')} />, { variant: 'success' });
        if(method === 'create') {
          navigate('/stations');
        } else {
            callback?.(response?.data)
            formRef.current?.reset();
            setFileName('');
        }
      } else {
        enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
      }
    }).catch((err: any) => {
      console.error("===", err);
      enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
    }).finally(() => {setLoading(false);  setIsBtnEnabled?.(true); });
  };
  return (
    <Box>
      <Grid container className='items-center min-w-[max-content]' component='form' noValidate={false} spacing={1} onSubmit={(e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData(e.target as HTMLFormElement);
        const file = data.get('file') as File;
        onUpload({ file: file, entityId: formData?.id, oldUrl: formData?.imgUrl || '' });
      }} ref={formRef}>
        <Grid item xs={12} md={8} id="importfile">
          {/* <span className='italic font-Saira bg-[#e5e5e5] text-[14px] font-medium absolute mt-[5px] ms-[11px] py-[3px] w-[92px] text-center cursor-default'>{t('choose')}</span> */}
          <input
            type="file"
            name="file"
            required
            accept="image/*"
            style={{ color: fileName ? 'inherit' : 'transparent', width: '100%', paddingTop: '4px', paddingBottom: '4px' }}
            onChange={handleFileChange}
            title={fileName}
          />
        </Grid>
        <Grid item xs={12} md={4} id="form">
          <Button type="submit" disabled={loading} className="primary block" variant="contained" disableElevation>{t('Upload')}</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StationMedia;