// BankAccountProfileService.tsx
import { useInfiniteQuery } from '@tanstack/react-query';
import { BankAccountProfileService } from '../../core/services';
import { PageSize } from '../../type';

export const useGetBankAccountsService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-bankaccounts-data'],
      queryFn: async ({ pageParam }) => {
        return BankAccountProfileService.getBankAccounts({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          bankName: initialParams.bankName,
          accountNumber: initialParams.accountNumber,
          accountTitle: initialParams.accountTitle,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetBankAccountsDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-bankaccounts-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return BankAccountProfileService.getBankAccountsDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          bankName: initialParams.bankName,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};
