// StoreService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignItemRequest,
  AssignUnassignResponse,
  ChangeStatusStoreResponse,
  DeleteStoreResponse,
  GetStoreByIdResponse,
  GetStoreItemResponse,
  GetStoreResponse,
  StoreRequest,
  StoreResponse,
} from './interface';

export class StoreProfile extends BaseService {
  async createStore(req: StoreRequest) {
    return this.post<StoreResponse, StoreRequest>(`${this.apiUrl}/api/stores`, req);
  }

  async getStore(params: PaginationParams) {
    return this.get<GetStoreResponse>(`${this.apiUrl}/api/stores/paged`, params);
  }

  async getStoreDropdown(params: PaginationParams) {
    return this.get<GetStoreResponse>(`${this.apiUrl}/api/stores/get`, params);
  }

  async updateStore(id: number, req: StoreRequest) {
    return this.put<StoreResponse, StoreRequest>(`${this.apiUrl}/api/stores/${id}`, req);
  }

  async deleteStore(id: number) {
    return this.delete<DeleteStoreResponse>(`${this.apiUrl}/api/stores/${id}`);
  }

  async changeStatusStore(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusStoreResponse, ChangeStatusRequest>(`${this.apiUrl}/api/stores/${active}/${id}`, req);
  }

  async getStoreItem(params: PaginationParams & { storeId: number }) {
    return this.get<GetStoreItemResponse>(`${this.apiUrl}/api/items/bystoreid`, params);
  }

  async assignItem(req: AssignUnassignItemRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignItemRequest>(`${this.apiUrl}/api/stores/item/assign`, req);
  }

  async unassignItem(req: AssignUnassignItemRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignItemRequest>(`${this.apiUrl}/api/stores/item/unassign`, req);
  }

  async assignAllItems(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stores/item/assignall`,
      req
    );
  }

  async unassignAllItems(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stores/item/unassignall`,
      req
    );
  }

  async getStoreByID(id: number) {
    return this.get<GetStoreByIdResponse>(`${this.apiUrl}/api/stores/${id}`);
  }
}
