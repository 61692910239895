import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import { InventoryItemsTable } from "components/Inventory/InventoryItemsTable";
import { useSnackbar } from "notistack";
import { useGetItemsService } from "subModule/src/services/items/useItems";
import { selectUserInfo } from "features/user/userSlice";
import { useSelector } from "react-redux";
import AlertMessages from "common/Alert/AlertMessages";
import { useNavigate, useParams } from "react-router-dom";
import { InventoryProfileService } from "subModule/src/core/services";
import { GetStockAdjustmentByIdResponse, StockAdjustment } from "subModule/src/services/inventory/interface";
import Loader from "layout/Loader";
import { useTranslation } from "react-i18next";
import { useGetStoreDropdownService } from "subModule/src/services/store/useStore";
import DynamicSelect from "common/Select/Select";
import AddIcon from '@mui/icons-material/Add';

interface FormData {
    [key: string]: any;
}

const StockAdjustmentCreateUpdate: React.FC = () => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedInUserCurrency = useSelector(selectUserInfo)?.currency;
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue, formState: { errors }, watch, resetField, trigger} = useForm<FormData>({defaultValues: {}});
    const { data: itemsData, refetch: itemsRefetch } = useGetItemsService({ pageNumber: 1, size: 8000, storeId: watch('storeId')?.value, active: true });
    const { data: storesData, refetch: storesRefetch } = useGetStoreDropdownService({ pageNumber: 1, size: 1000 });
    const [StockAdjustmentItem, setStockAdjustmentItem] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [StockAdjustment, setStockAdjustment] = useState<StockAdjustment>();
    const adjustmentTypesList = [
        { value: 1, label: t('remove') },
        { value: 2, label: t('add') },
    ];
    useEffect(() => {
        storesRefetch();
        if(params?.id) {
            if(loading) getStockAdjustmentById();
        }
    }, [params]);
    useEffect(() => {
        if(watch('storeId')?.value) {
            setStockAdjustmentItem([]);
            setValue('StockAdjustmentItem', []);
            itemsRefetch();
        }
    }, [watch('storeId')]);
    const getStockAdjustmentById = () => {
        InventoryProfileService.getStockAdjustmentById(params?.id).then((res: any) => {
            if(res.success) {
                setLoading(false);
                setStockAdjustment((res.data as any));
                setStockAdjustmentItem([{...res.data, adjustmentType: { value: res.data.adjustmentType, label: adjustmentTypesList?.find((option) => option.value === res?.data?.adjustmentType)?.label || res.data.adjustmentType }}]);
                setValue('StockAdjustmentItem', [{...res.data, adjustmentType: { value: res.data.adjustmentType, label: adjustmentTypesList?.find((option) => option.value === res?.data?.adjustmentType)?.label || res.data.adjustmentType }}]);
                setValue('storeId', res.data?.storeId);
                setValue('id', params?.id);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); setLoading(false); });
    }
    const storeOptions = (storesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    const columns = [
        { id: 'itemId' },
        { id: 'itemCode'},
        { id: 'unitId' },
        { id: 'itemName', label: t('Item') + ' *', numeric: false, disablePadding: false, type: 'text' },
        ...(params?.id ? [] : [{ id: 'baseItemQuantity', label: t('availableQuantity') }]),
        { id: 'quantityAdjusted', label: t('Quantity'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitTitle' },
        { id: 'adjustmentType', label: t('adjustmentType'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'unitCostAdjusted', label: t('Cost'), numeric: false, disablePadding: false, type: 'number' },
        { id: 'adjustmentReason', label: t('adjustmentReason') + ' *', numeric: false, disablePadding: false, type: 'text' },
    ];
    const DeleteItem = (row: any) => {
        setValue('StockAdjustmentItem', StockAdjustmentItem.filter((rows) => rows.id !== row.id));
        setStockAdjustmentItem((prevRows) => prevRows.filter((rows) => rows.id !== row.id));
    }
    const addNewItem = (typeName: string) => {
        if(typeName === "StockAdjustmentItem") {
            const newItem = columns.reduce((acc, column) => ({ ...acc, [column.id]: column.type === 'number' ? 0 : '' }), {});
            if(!params?.id) (newItem as any).id = Date.now();
            setStockAdjustmentItem((prevRows) => [...prevRows, newItem]);
        }
    };
    const handleInputChange = (index: number, columnId: string, value: any, typeName: string, type: string) => {
        if(typeName === "StockAdjustmentItem") {
            const updatedItems = [...StockAdjustmentItem];
            updatedItems[index][columnId] = type === 'number' ? parseFloat(value) : value;
            setStockAdjustmentItem(updatedItems);
        }
    };
    const onSubmit = (data: FormData) => {
        if(Object.values(data)?.length) {
            const filteredItems = StockAdjustmentItem.filter((item: any) => item?.itemName);
            if(filteredItems.length === 0) {
                enqueueSnackbar(<AlertMessages text={t('addAtLeastOneItem')} />, { variant: 'error' });
                return;
            }
            filteredItems.every((row: any) => { delete row.id; delete row.unitTitle; delete row.unitPrice; delete row.quantity; });
            const payload = filteredItems.map((item) => ({
                ...item,
                adjustmentType: item.adjustmentType || 1,
                adjustmentReason: item.adjustmentReason || '',
                storeId: data.storeId.value,
            }));
            InventoryProfileService.createStockAdjustment(payload).then((response: GetStockAdjustmentByIdResponse) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('StockAdjustment') + ' ' + t('saved') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate('/inventory/stock-adjustments');
                } else enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }).catch((err:any) => { 
                console.error("===", err);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (
        loading ? <Loader /> : <Grid container component="form" id="form" className="px-[8px] items-baseline"  rowGap={2.5} columnGap={4} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="items-center">
                <Grid item xs={12} sm={6} className="text-start">
                    <Typography className="font-Saira text-[12px] font-semibold text-primary-color">{params?.id ? t('StockAdjustment') : t('newStockAdjustment')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="hide-on-print">
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/stock-adjustments')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider className="bg-[#AEB3C4] hide-on-print" /></Grid>
            {params?.id ? <Grid container className="bg-primary-color py-[12px] px-[18px] rounded-[8px] mb-[16px] items-center">
                <Grid item xs={4}>
                    <Typography className="font-Saira text-[16px] font-bold text-[#fff] text-left">{t('StockAdjustment')}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className="flex justify-end gap-10">
                        <Typography className="font-Saira text-[12px] text-[#fff] text-center"><span className="font-bold">{t('store')}</span> <br /><span className="font-medium">{StockAdjustment?.storeTitle || ''}</span></Typography>
                    </div>
                </Grid>
            </Grid> : null}
            <Grid item xs={12}><Typography className="font-Saira text-primary-color text-[14px] leading-[16px] font-semibold text-left">{t('StockAdjustment')} {t("Details")}</Typography></Grid>
            <Grid container item xs={12} gap={2.5} className="flex-nowrap">
                <Grid item xs={12} md={4} lg={3}>
                    <Controller
                        name="storeId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: t('This field is required.') }}
                        render={({ field }) => (
                            <DynamicSelect
                                label={t('from') + ' ' + t('store')}
                                {...field}
                                options={storeOptions}
                                isSearchable
                                isRequired
                                onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                error={errors.storeId?.message as string}
                                value={params?.id ? { value: StockAdjustment?.storeId, label: StockAdjustment?.storeTitle } : watch('storeId')}
                                isDisabled={params?.id ? true : false}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Box className="bg-white py-[12px] px-[18px] rounded-[8px]">
                        <Typography className="font-Saira text-[12px] font-semibold text-[#171826] text-left">{t('StockAdjustment')}</Typography>
                        <InventoryItemsTable form={{ control, register, handleSubmit, setValue, errors, watch, resetField, trigger }} columns={columns} handleInputChange={handleInputChange} rows={StockAdjustmentItem} typeName="StockAdjustmentItem" items={itemsData?.pages[0]?.data?.content || []} currency={loggedInUserCurrency} DeleteItem={DeleteItem} {...{params, adjustmentTypesList}} checkStatus={params?.id ? 2 : 1} viewDelete={params?.id ? false : true} />
                        <Grid item xs={12} sm={6} className="hide-on-print">
                            <div className="flex gap-2 justify-start">
                                <Button className="secondary flex gap-1" disabled={params?.id ? true : false} variant="contained" disableElevation onClick={() => addNewItem('StockAdjustmentItem')}><AddIcon className="w-[20px] h-[20px]" /> {t('Add More Items')}</Button>
                                {/* <Button className="secondary" variant="contained" disableElevation>{t('Import')}</Button>
                                <Button className="secondary" variant="contained" disableElevation>{t("Export")}</Button> */}
                            </div>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} className="hide-on-print"><Divider className="bg-[#AEB3C4] mt-[8px]" /></Grid>
            <Grid container className="items-center hide-on-print">
                <Grid item xs={12} sm={6} className="text-start"></Grid>
                <Grid item xs={12} sm={6}>
                    <div className="flex gap-2 justify-end">
                        {params?.id ? null : <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => onSubmit(handleSubmit(onSubmit))}>{t('save')}</Button>}
                        {params?.id ? <>{/*  <Button className="secondary" variant="contained" disableElevation>{t('email')}</Button> */}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => window.print()}>{t('Print')}</Button></> : null}
                        <Button className="secondary" variant="contained" disableElevation onClick={() => navigate('/inventory/stock-adjustments')}>{t('Back to list')}</Button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StockAdjustmentCreateUpdate;
