import { BaseService } from '../../core/BaseService';
import { DeleteData, PaginationParams } from '../../type';
import {
  ChangeStatusEntityRequest,
  CreditNotePaginationResponse,
  GetCreditNoteResponse,
  GetPurchaseInvoiceResponse,
  GetPurchaseOrderResponse,
  GetStockReceiptResponse,
  GetUnstockResponse,
  NewPurchaseOrderResponse,
  PurchaseOrder,
  PurchaseOrderItems,
  PurchaseOrderServices,
  SaveCreditNote,
  SaveInvoice,
  SaveInvoiceResponse,
  SaveStockReceipt,
  SaveStockReceiptResponse,
  StockReceiptPaginationResponse,
  UnstockPaginationResponse,
} from './interface';

export class InventoryProfile extends BaseService {
  async savePurchaseOrder(req: PurchaseOrder) {
    return this.post<NewPurchaseOrderResponse, PurchaseOrder>(`${this.apiUrl}/api/purchaseorder`, req);
  }

  async getPurchaseOrder(params: PaginationParams) {
    return this.get<GetPurchaseOrderResponse>(`${this.apiUrl}/api/purchaseorder/paged`, params);
  }

  async getPurchaseInvoices(params: PaginationParams) {
    return this.get<GetPurchaseInvoiceResponse>(`${this.apiUrl}/api/purchaseinvoice/paged`, params);
  }

  async deletePurchaseOrder(id: number) {
    return this.delete<DeleteData>(`${this.apiUrl}/api/purchaseorder/${id}`);
  }

  async getEntityById(id: string | undefined, typeName: string) {
    return this.get<NewPurchaseOrderResponse | GetStockReceiptResponse | SaveInvoiceResponse | GetCreditNoteResponse>(
      `${this.apiUrl}/api/${typeName}/${id}`
    );
  }

  async getPurchaseOrderById(id: string | undefined) {
    return this.get<NewPurchaseOrderResponse>(`${this.apiUrl}/api/purchaseorder/${id}`);
  }

  async createPurchaseOrderItem(req: PurchaseOrderItems) {
    return this.post<NewPurchaseOrderResponse, PurchaseOrderItems>(`${this.apiUrl}/api/purchaseorder/items`, req);
  }

  async updatePurchaseOrderItem(req: PurchaseOrderItems) {
    return this.put<NewPurchaseOrderResponse, PurchaseOrderItems>(`${this.apiUrl}/api/purchaseorder/items/update`, req);
  }

  async deleteEntityItem(id: number, typeName: string) {
    return this.delete<DeleteData>(`${this.apiUrl}/api/${typeName}/${id}`);
  }

  async createPurchaseOrderService(req: PurchaseOrderServices) {
    return this.post<NewPurchaseOrderResponse, PurchaseOrderServices>(`${this.apiUrl}/api/purchaseorder/service`, req);
  }

  async updatePurchaseOrderService(req: PurchaseOrderServices) {
    return this.put<NewPurchaseOrderResponse, PurchaseOrderServices>(
      `${this.apiUrl}/api/purchaseorder/service/update`,
      req
    );
  }

  async deletePurchaseOrderService(id: number) {
    return this.delete<DeleteData>(`${this.apiUrl}/api/purchaseorder/service/${id}`);
  }

  async changeStatusEntity(req: ChangeStatusEntityRequest) {
    return this.put<NewPurchaseOrderResponse, ChangeStatusEntityRequest>(
      `${this.apiUrl}/api/${req.typeName}/${req.entityId}/status/${req.status}`,
      req
    );
  }

  async saveStockReceipt(req: SaveStockReceipt) {
    return this.post<SaveStockReceiptResponse, SaveStockReceipt>(`${this.apiUrl}/api/stockreceipt`, req);
  }

  async getStockReceipt(params: PaginationParams) {
    return this.get<StockReceiptPaginationResponse>(`${this.apiUrl}/api/stockreceipt/paged`, params);
  }

  async getStockReceiptByPOID(id: string | undefined) {
    return this.get<GetStockReceiptResponse>(`${this.apiUrl}/api/stockreceipt/bypoid/${id}`);
  }

  async createStockReceiptItem(req: PurchaseOrderItems) {
    return this.post<SaveStockReceiptResponse, PurchaseOrderItems>(`${this.apiUrl}/api/stockreceipt/items`, req);
  }

  async updateStockReceiptItem(req: PurchaseOrderItems) {
    return this.put<SaveStockReceiptResponse, PurchaseOrderItems>(`${this.apiUrl}/api/stockreceipt/items/update`, req);
  }

  async savePurchaseInvoice(req: SaveInvoice) {
    return this.post<SaveInvoiceResponse, SaveInvoice>(`${this.apiUrl}/api/purchaseinvoice`, req);
  }

  async getInvoiceByPOID(id: string | undefined) {
    return this.get<SaveInvoiceResponse>(`${this.apiUrl}/api/purchaseinvoice/bypoid/${id}`);
  }

  async createPurchaseInvoiceItem(req: PurchaseOrderItems) {
    return this.post<SaveInvoiceResponse, PurchaseOrderItems>(`${this.apiUrl}/api/purchaseinvoiceitems`, req);
  }

  async updatePurchaseInvoiceItem(req: PurchaseOrderItems) {
    return this.post<SaveInvoiceResponse, PurchaseOrderItems>(`${this.apiUrl}/api/purchaseinvoiceitems/update`, req);
  }

  async createPurchaseInvoiceService(req: PurchaseOrderServices) {
    return this.post<SaveInvoiceResponse, PurchaseOrderServices>(`${this.apiUrl}/api/purchaseinvoiceservices`, req);
  }

  async updatePurchaseInvoiceService(req: PurchaseOrderServices) {
    return this.put<SaveInvoiceResponse, PurchaseOrderServices>(
      `${this.apiUrl}/api/purchaseinvoiceservices/update`,
      req
    );
  }

  async saveStockInvoice(req: SaveInvoice) {
    return this.post<SaveInvoiceResponse, SaveInvoice>(`${this.apiUrl}/api/purchaseinvoice/stockinvoice`, req);
  }

  async getCreditNote(params: PaginationParams) {
    return this.get<CreditNotePaginationResponse>(`${this.apiUrl}/api/purchasecreditdebitnote/paged`, params);
  }

  async getCreditNoteByPOID(id: string | undefined) {
    return this.get<GetCreditNoteResponse>(`${this.apiUrl}/api/purchasecreditdebitnote/bypoid/${id}`);
  }

  async saveCreditNote(req: SaveCreditNote) {
    return this.post<GetCreditNoteResponse, SaveCreditNote>(`${this.apiUrl}/api/purchasecreditdebitnote`, req);
  }

  async createCreditNoteItem(req: PurchaseOrderItems) {
    return this.post<GetCreditNoteResponse, PurchaseOrderItems>(`${this.apiUrl}/api/purchasecreditdebitnote/item`, req);
  }

  async updateCreditNoteItem(req: PurchaseOrderItems) {
    return this.put<GetCreditNoteResponse, PurchaseOrderItems>(
      `${this.apiUrl}/api/purchasecreditdebitnote/item/update`,
      req
    );
  }

  async createCreditNoteService(req: PurchaseOrderServices) {
    return this.post<GetCreditNoteResponse, PurchaseOrderServices>(
      `${this.apiUrl}/api/purchasecreditdebitnote/service`,
      req
    );
  }

  async updateCreditNoteService(req: PurchaseOrderServices) {
    return this.put<GetCreditNoteResponse, PurchaseOrderServices>(
      `${this.apiUrl}/api/purchasecreditdebitnote/service/update`,
      req
    );
  }

  async getUnstockByPOID(id: string | undefined) {
    return this.get<GetUnstockResponse>(`${this.apiUrl}/api/unstock/bypoid/${id}`);
  }

  async getUnstockByID(id: string | undefined) {
    return this.get<GetUnstockResponse>(`${this.apiUrl}/api/unstock/byid/${id}`);
  }

  async saveUnstock(req: unknown) {
    return this.post<GetUnstockResponse, unknown>(`${this.apiUrl}/api/unstock`, req);
  }

  async createUnstockItem(req: PurchaseOrderItems) {
    return this.post<GetUnstockResponse, PurchaseOrderItems>(`${this.apiUrl}/api/unstock/item`, req);
  }

  async updateUnstockItem(req: PurchaseOrderItems) {
    return this.put<GetUnstockResponse, PurchaseOrderItems>(`${this.apiUrl}/api/unstock/item/update`, req);
  }

  async getUnstock(params: PaginationParams) {
    return this.get<UnstockPaginationResponse>(`${this.apiUrl}/api/unstock/paged`, params);
  }
}
