import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useNavigate } from "react-router-dom";

interface tabProps {
    params: any;
    selectedIndex: number;
}
export default function StationTabs(props: tabProps) {
    const navigate = useNavigate();
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) navigate(`/station/${props.params?.id}`);
        else if (tabId === 1) navigate(`/station/assign/store/${props.params?.id}`);
        else if (tabId === 2) navigate(`/station/assign/bank-account/${props.params?.id}`);
        else if (tabId === 3) navigate(`/station/assign/user/${props.params?.id}`);
        // else if (tabId === 4) navigate(`/station/assign/bay/${props.params?.id}`);
        // else if (tabId === 5) navigate(`/station/assign/warehouse/${props.params?.id}`);
    }
    const tabs = [
        { id: 0, title: `edit`, child: <></> },
        { id: 1, title: `store`, child: <></> },
        { id: 2, title: `bankAccount`, child: <></> },
        { id: 3, title: `users`, child: <></> },
        // { id: 4, title: `bay`, child: <></> },
        // { id: 5, title: `warehouse`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
                return !checkPrivileges('ROLE_STATION_UPDATE');
            case 1:
                return !checkPrivileges('ROLE_STATION_STORE_ASSIGN') && !checkPrivileges('ROLE_STATION_STORE_UNASSIGN');
            case 2:
                return !checkPrivileges('ROLE_STATION_BANKACCOUNT_ASSIGN') && !checkPrivileges('ROLE_STATION_BANKACCOUNT_UNASSIGN');
            case 3:
                return !checkPrivileges('ROLE_STATION_USER_ASSIGN') && !checkPrivileges('ROLE_STATION_USER_UNASSIGN');
            case 4:
                return !checkPrivileges('ROLE_STATION_BAY_ASSIGN') && !checkPrivileges('ROLE_STATION_BAY_UNASSIGN');
            case 5:
                return !checkPrivileges('ROLE_STATION_WAREHOUSE_ASSIGN') && !checkPrivileges('ROLE_STATION_WAREHOUSE_UNASSIGN');  
            default:
                return false;
        }
    });

    return (<>
        <VerticalTabs tabs={tabs} selectedIndex={props.selectedIndex} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
    </>);
}