import React, { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { FormControl, ListItemIcon, Select } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import Loader from 'layout/Loader';
import { CheckRTL } from 'Helpers/CheckRTL';

interface Column {
  id: string;
  label: string;
  numeric: boolean;
}

interface BackendPaginationTableProps {
  columns: Column[];
  data: any[];
  showCheckbox: boolean;
  showActions:boolean;
  enableSorting: boolean;
  showHeader: boolean;
  showSearch: boolean;
  showFilter: boolean;
  showCreateButton: boolean;
    // actions:  any[];
  tableData? :any;
  actions?: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  headerComponent?:React.ReactNode,
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  onChangeStatus?: (row: any) => void;
  pageNo:number;
  pageSize:number;
  rowClick?: boolean;
  showRowView?: (row: any) => void;
  assignUnassignSingleEntity?: (isChecked: boolean, row: any) => void;
  assignUnassignAllEntities?: (isChecked: boolean) => void;
  allEntitiesAssigned?: unknown;
  loadingSearch?: boolean;
  assignPrivilege?: boolean;
  unassignPrivilege?: boolean;
}

const BackendPaginationTable: React.FC<BackendPaginationTableProps> = ({
  columns,
  data,
  showCheckbox,
  showActions,
  enableSorting,
  showHeader,
  showSearch,
  actions,
  headerComponent,
  handleChangePage,
  handleChangeRows,
  tableData,
  // pageNo,
  pageSize,
  onChangeStatus,
  rowClick,
  showRowView,
  assignUnassignSingleEntity,
  assignUnassignAllEntities,
  allEntitiesAssigned,
  loadingSearch,
  assignPrivilege,
  unassignPrivilege
}) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [selected, setSelected] = useState<number[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(pageSize);
  const [selectedRow, setSelectedRow] = useState({});

  // const [openModal, setOpenModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {t} = useTranslation();
  const isRTL = CheckRTL();

  const handleClickmenu = (event: React.MouseEvent<HTMLElement>, row: any) => { 
    setSelectedRow(row)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n, index) => index);
      setSelected(newSelected);
      assignUnassignAllEntities?.(true);
      return;
    } else {
      assignUnassignAllEntities?.(false);
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, index: number, row: any) => {
    const target = event.target as HTMLInputElement;
    const selectedIndex = selected.indexOf(index);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    assignUnassignSingleEntity?.(target.checked, row);
  };

  // const handleChangePage1 = (event: unknown, newPage: number) => {
  //      handleChangePage(newPage)
  //     setPage(newPage+1);
  // };
  
 const handleChangePage1 = (action:string) => {
    // let { pagination } = this.props;

    if (tableData) {
      if (action === "first") {
        if (tableData?.pageable?.pageNumber > 0) {
         handleChangePage(0);
        }
      } else if (action === "prev") {
        if (tableData.pageable && tableData?.pageable?.pageNumber > 0) {
          handleChangePage(tableData?.pageable?.pageNumber - 1);
        }
      } else if (action === "next") {
        if (
          !tableData?.last
          //  && tableData.page &&
          // tableData.lastPage &&
          // tableData.lastPage > tableData.page
        ) {
          handleChangePage(tableData?.pageable?.pageNumber + 1);
        }
      } else if (action === "last") {
        if (
          !tableData?.last 
          // && tableData.lastPage &&
          // tableData.lastPage > tableData.page
        ) {
          handleChangePage(tableData?.totalPages-1);
        }
      }
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleChangeRows(parseInt(event.target.value))
    setPage(0);
  };


  const isSelected = (index: number) => selected.indexOf(index) !== -1;
  // const emptyRows = Math.max(0, rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage));

  const stableSort = (array: any[], comparator: (a: any, b: any) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const visibleRows = useMemo(
    () => {
      if (enableSorting) {
        return stableSort(data, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        );
      } else {
        return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }
    },
    [data, enableSorting, order, orderBy, page, rowsPerPage],
  );

  
  return (
    <Box className='w-[100%] '>
      <Paper className='w-[100%] mb-[2]  bg-table-bg-color !important ' sx={{ boxShadow: 'none' ,}}>

        {showHeader && showSearch ? 
        <div 
          style={{minHeight: rowClick ? "56px" : "48px", display:"flex", alignItems:"center"}}
        >{headerComponent}</div> :null }
        {loadingSearch ? <Loader /> :
          <>
            <TableContainer sx={rowClick ? { borderRadius:"8px", marginTop:"10px" } : { borderRadius:"0px", marginTop:"10px", border:'none' }}>
              <Table
                className={`${!rowClick ? "w-full border-separate border-spacing-y-2 " : "min-w-[750]"}`}
                size='small'
               >
                <TableHead
                sx={{ backgroundColor: '#fff', }}
                >
                <TableRow
                  sx={rowClick ? undefined : {
                    height: '48px',
                    backgroundColor: 'white',
                    '.MuiTableCell-head': {
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    textAlign: 'left',
                    border: '0px',
                    '&: last-child': { borderRadius: '0 8px 8px 0' }
                      },
                    }}
                  >
                    {showCheckbox && (
                      <TableCell padding="checkbox" className={`${!rowClick ? "border-none rounded-l-[8px]" : ""}`}>
                        <Checkbox
                          className='text-primary-color'
                          // indeterminate={selected.length > 0 && selected.length < data.length}
                          // checked={data.length > 0 && selected.length === data.length}
                          checked={(allEntitiesAssigned && tableData?.content?.length !== 0) ? true : false}
                          onChange={handleSelectAllClick}
                          inputProps={{
                            'aria-label': 'select all items',
                          }}
                          disabled={tableData?.content?.length ? 
                            (assignPrivilege && !allEntitiesAssigned) ? false 
                              : (unassignPrivilege && allEntitiesAssigned) ? false
                              : true
                            : true
                          }
                          sx={{
                            opacity: 1,
                            '&.Mui-disabled': {
                              opacity: 0.3
                            } 
                          }}
                        />
                      </TableCell>
                    )}
                    {showActions && columns.map((column, columnIndex) => (
                      <TableCell 
                        key={column.id}
                        sx={{textAlign: isRTL ? 'right' : 'left'}}
                        padding={column.id === 'actions' ? 'normal' : 'normal'}
                        sortDirection={orderBy === column.id ? order : false}
                        className={`font-Saira font-[400] text-[14px] ${columnIndex !== columns.length - 1 && rowClick ? 'borderRightApplied' : ''}`}
                      >
                        {column.id !== 'actions' && (
                          <TableSortLabel
                          className='font-Saira font-[600] text-[14px] '
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={() => handleRequestSort(column.id)}
                          >
                            {t(column.label)}
                          </TableSortLabel>
                        )}
                        {column.id === 'actions' && (
                          <TableSortLabel
                          className='font-Saira font-[600] text-[14px] text-black'
                          >
                            {t(column.label)}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {visibleRows.map((row, index) => {
                  return (
                    <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected(page * rowsPerPage + index)}
                    tabIndex={-1}
                    key={index}
                    selected={isSelected(page * rowsPerPage + index)}
                    sx={rowClick ? { backgroundColor: index % 2 === 0 ? '#F1F6F9' : '#fff', cursor: 'pointer'  } : {
                      height: '48px',
                      backgroundColor:'white',
                      padding: '16px',
                      cursor:'pointer',
                      '&:last-child td, &:last-child th': { border: 0 },
                        }}
                    >
                      {showCheckbox && (
                        <TableCell padding="checkbox" className={`${!rowClick ? "border-none rounded-l-[8px]" : ""}`}>
                          <Checkbox
                            onClick={(event) => handleClick(event, page * rowsPerPage + index, row)}
                            className='text-primary-color'
                            checked={row.linked ? true : false}
                            inputProps={{
                              'aria-labelledby': `enhanced-table-checkbox-${index}`,
                            }}
                            disabled={(assignPrivilege && !row.linked) ? false 
                              : (unassignPrivilege && row.linked) ? false
                              : true
                            }
                            sx={{
                              opacity: 1,
                              '&.Mui-disabled': {
                                opacity: 0.3
                              } 
                            }}
                          />
                        </TableCell>
                      )}
                      {showActions && columns.map((
                        column,
                         columnIndex
                      ) => (
                        <TableCell
                        onClick={() => {(rowClick && column.id !== 'active' && column.id !== 'actions') ? showRowView?.(row) : ''}}
                        className={`font-Saira font-[400] text-[14px] ${columnIndex !== columns.length - 1 ? 'borderRightApplied' : ''} ${isRTL ? 'text-right':'text-left'}`}
                        sx={!rowClick ? { border: '0 !important', 
                          '&: last-child': { borderRadius: '0 8px 8px 0' } 
                        } : {}}
                          component={column.id === 'actions' ? 'div' : undefined}
                          padding={column.id === 'actions' ? 'none' : 'normal'}
                          align={'left'}
                        >
                          {column.id === 'actions' ? (
                            <Box >
                              <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={(event) => handleClickmenu(event, row)}
                              >
                              <MoreHorizIcon/>
                            </IconButton>
                            <Menu
                            slotProps={{
                              root:{
                                sx:{
                                  zIndex:0
                                }
                              }
                            }}
                                id="long-menu"
                                disableScrollLock
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.05))',
                                    // mt: 1.5,
                                    '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      // ml: -0.5,
                                      // mr: 1,
                                    },
                                    '&::before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      left: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0,
                                    },
                                  },
                                }}
                              >
                                {actions?.map((option) => (
                                  <div onClick={handleClose}>
                                  <MenuItem className='font-Saira font-[400] text-[14]' key={option.label}  onClick={()=>option.onClick(selectedRow)}>
                                      <ListItemIcon>
                                      {option.icon}
                                    </ListItemIcon>
                                    {t(option.label)}
                                  </MenuItem>
                                  </div>
                                ))}
                              </Menu>
                            </Box>
                          ) : (
                            (column.id === 'startDate' || column.id === 'endDate'|| column.id === 'packageTime' || column.id === 'serviceTime' || column.id === 'creditPeriod' || column.id === 'date' || column.id === 'paymentDate' || column.id === 'recipientDate' || column.id === 'poDate' || column.id === 'invoiceDate' || column.id === 'creditDebitDate' || column.id === 'receivedDate') && row[column.id] ? row[column.id].split('T')[0]
                            : (column.id === "userName") ? row["user"]?.name 
                            : (column.id === "userPhone") ? row["user"]?.phone 
                            : (column.id === "haveExpiry" || column.id === "default") ? row[column.id] ? 'Yes' : 'No'
                            : (column.id === "pricingType") ? row[column.id] === 2 ? 'Item & Service' : row[column.id] === 1 ? 'Service only' : ''
                            : (column.id === "pricingTypePackages") ? row["pricingType"] === 1 ? 'Fixed' : row["pricingType"] === 2 ? 'Service Sum' : ''
                            : (column.id === "itemGroup") ? row[column.id] === 1 ? 'Group' : row[column.id] === 2 ? 'Open' : ''
                            : (column.id === "stockType") ? row[column.id] === 4 ? 'Stock First' : row[column.id] === 5 ? 'Payment First' : ''
                            : (column.id === "status") ? row[column.id] === 1 ? 'Draft' : row[column.id] === 2 ? 'Approved' : ''
                            : (column.id === "accountTitleClient") ? row["account"]?.title
                            : (column.id === "typeLabel") ? row.type 
                            : (column.id === "type") ? [
                              { value: 100, label: 'Reseller' },
                              { value: 110, label: 'Maintenance Head' },
                              { value: 120, label: 'Service Provider' },
                              { value: 130, label: 'Station Manager' },
                              { value: 140, label: 'Service Manager' },
                              { value: 150, label: 'Technician' },
                            ].find((option: any) => option.value === row[column.id])?.label
                            : (column.id === "adjustmentType") ? [
                              { value: 591, label: 'FIFO' },
                              { value: 592, label: 'MANUAL' },
                            ].find((option: any) => option.value === row[column.id])?.label
                            : (column.id === "accountContractNumber") ? row["account"]?.contractNumber 
                            : (column.id === 'active') ? row[column.id] ? 
                              <a onClick={() => onChangeStatus?.(row)} className='text-green-500 hover:text-primary-color cursor-pointer font-medium '>
                                {t("active")}
                              </a> 
                              : <a onClick={() => onChangeStatus?.(row)} className='text-red-500 hover:text-primary-color cursor-pointer font-medium '>
                                  {t("inactive")}
                                </a>
                            : row[column.id]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  )})}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{height: 33  * emptyRows }}>
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={columns.length + (showCheckbox ? 1 : 0)} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
            
            <div
                style={{
                  padding: "0 20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span  className="hidden-xs mr-2 font-Saira font-[400] text-[14px]">
                {t("rowsPerPage")}
                </span>
                <span style={{ marginRight: 10, minWidth: 60 }}>
                {/* <strong>{tableData && tableData.pageable.pageSize}</strong>{" "} */}

                <FormControl className='m-1 min-w-[70]'  size="small">
                  <Select
                  className='font-Saira font-[500] text-[14px] h-[25px] mt-[2px]'
                    value={tableData && tableData?.pageable?.pageSize || pageSize}
                    // value={rowsPerPageOptions[2]}
                    onChange={(event) => handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)}
                    // style={{height:"30px"}}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                  {rowsPerPageOptions.map((option) => (
                      <MenuItem className='font-Saira font-[500] text-[14px]' key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                  {/* <TextField
                    id="rowsPerPage"
                    select
                    style={{ width: 60 }}
                    value={(5}
                    onChange={(e) => handleChangeRowsPerPage(e)}
                    margin="dense"
                  >
                    {rowsPerPageOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField> */}
                </span>
                <span style={{ flex: 1 }}></span>

                <span
                  // style={{ marginRight: 5, whiteSpace: "nowrap", maxWidth: 300 }}
                  className='mr-2 whitespace-nowrap max-w-[300] font-Saira font-[400] text-[14px]'
                >
                {t("page")}
                  <strong className='font-Saira ml-2 font-[700] text-[15px]' >{tableData && tableData.pageable ? tableData.pageable.pageNumber + 1 : 1}</strong>{" "}
                  <span className="visible-responsive">
                  </span>
                </span>
                <span  className="hidden-responsive font-Saira mr-2 font-[400] text-[14px]">
                
                {t("outOf")}

                  <strong className='ml-2 font-Saira font-[700] text-[15px]'>{tableData && tableData.totalPages ? tableData.totalPages : 1}</strong>
                </span>
                <span className="hidden-responsive font-Saira mr-2 font-[400] text-[14px]">
                {t("totalRecords")}
                  {/* )}{" "}  */}
                  <strong className='ml-2 font-Saira font-[700] text-[15px]'>{tableData && tableData.totalElements ? tableData.totalElements : 0}</strong>
                </span>
                <span className='mr-2'>
                  <FirstPageIcon
                    fontSize="small"
                    onClick={() => handleChangePage1("first")}
                    style={{ 
                      color: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.first
                      ) ? "#1F2020" : "#B8C0C6",
                      cursor: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.first
                      ) ? "pointer" : "text",
                    }}
                  />
                </span>
                <span className='mr-2'>
                  <NavigateBeforeIcon
                    fontSize="small"
                    onClick={() => handleChangePage1("prev")}
                    style={{ 
                      color: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.first
                      ) ? "#1F2020" : "#B8C0C6",
                      cursor: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.first
                      ) ? "pointer" : "text",
                    }}
                  />
                </span>
                <span className='mr-2'>
                  <NavigateNextIcon
                    fontSize="small"
                    onClick={() => handleChangePage1("next")}
                    style={{ 
                      color: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.last
                      ) ? "#1F2020" : "#B8C0C6",
                      cursor: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.last
                      ) ? "pointer" : "text",
                    }}
                  />
                </span>
                <span className='mr-2'>
                  <LastPageIcon
                    fontSize="small"
                    onClick={() => handleChangePage1("last")}
                    style={{ 
                      color: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.last
                      ) ? "#1F2020" : "#B8C0C6",
                      cursor: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.last
                      ) ? "pointer" : "text",
                    }}
                  />
                </span>
            </div>
          </>
        }
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100, 500]}
          component="div"
          count={tableData.totalElements || 0}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage1}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={`Rows per page: `}
          // labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} rows`}
          labelDisplayedRows={({count}) => `Page ${pageNo} of ${tableData.totalPages} Total Records : ${count}`}
        /> */}
      </Paper>
    </Box>
  );
};

export default BackendPaginationTable;
const rowsPerPageOptions = [5, 10, 15, 20, 25, 50, 100, 500];
