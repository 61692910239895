import { ServiceResponse } from "subModule/src/services/service/interface";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useNavigate } from "react-router-dom";

interface tabProps {
    params: any;
    selectedIndex: number;
    formData?: ServiceResponse;
}
export default function ServiceTabs(props: tabProps) {
    const navigate = useNavigate();
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) navigate(`/service/${props.params?.id}`);
        else if (tabId === 1) navigate(`/service/assign/part/${props.params?.id}`);
        else if (tabId === 2) navigate(`/service/assign/vehicle-type/${props.params?.id}`);
        else if (tabId === 3) navigate(`/service/assign/station/${props.params?.id}`);
        else if (tabId === 4) navigate(`/service/assign/model/${props.params?.id}`);
        else if (tabId === 5) navigate(`/service/assign/price-list/${props.params?.id}`);
    }
    const tabs = [
        { id: 0, title: `edit`, child: <></> },
        { id: 1, title: `items`, child: <></> },
        { id: 2, title: `vehicletypes`, child: <></> },
        { id: 3, title: `stations`, child: <></> },
        { id: 4, title: `models`, child: <></> },
        { id: 5, title: `priceList`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
              return !checkPrivileges('ROLE_SERVICE_UPDATE');
            case 1:
              return !checkPrivileges('ROLE_SERVICE_ITEM_ASSIGN') && !checkPrivileges('ROLE_SERVICE_ITEM_UNASSIGN');
            case 2:
              return !checkPrivileges('ROLE_SERVICE_VEHICLETYPE_ASSIGN') && !checkPrivileges('ROLE_SERVICE_VEHICLETYPE_UNASSIGN');
            case 3:
              return !checkPrivileges('ROLE_SERVICE_STATION_ASSIGN') && !checkPrivileges('ROLE_SERVICE_STATION_UNASSIGN');
            case 4:
              return !checkPrivileges('ROLE_SERVICE_MODEL_ASSIGN') && !checkPrivileges('ROLE_SERVICE_MODEL_UNASSIGN');
            case 5:
              return !checkPrivileges('ROLE_SERVICE_PRICELIST_ASSIGN') && !checkPrivileges('ROLE_SERVICE_PRICELIST_UNASSIGN');
            default:
              return false;
        }
    });
    const checkDisabledTabs = () => {
        const updatedDisableTabs = [...disableTabs];
        if (props?.formData?.applyToAllStations === true) {
          if (!updatedDisableTabs.includes(3)) {
            updatedDisableTabs.push(3);
          }
        }
        return updatedDisableTabs;
    }
    return (<>
        <VerticalTabs tabs={tabs} selectedIndex={props.selectedIndex} handleChangeTabs={handleChangeTabs} disableTabNo={checkDisabledTabs()} />
    </>);
}