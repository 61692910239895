import { selectUserInfo } from "features/user/userSlice";
import { Box, Grid, InputAdornment, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { InspectionFormItemProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Inspection  } from "assets/SideBarIcons/inspection.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetIFIByIdResponse } from "subModule/src/services/inspectionformitem/interface";
import Loader from "layout/Loader";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import InspectionFormTabs from "./InspectionFormTabs";
import IFHeader from 'components/InspectionFormItem/IFHeader';
import InspectionVehicle from "assets/inspection-vehicle.svg";
import ImageClickCoordinates from "components/InspectionFormItem/ClickableImage";
import { useGetInspectionSectionService } from "subModule/src/services/inspectionsection/useInspectionSection";
import DynamicSelect from "common/Select/Select";

interface FormData { [key: string]: any;}
export default function InspectionFormItemModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, control, formState: { errors, dirtyFields, isDirty }, reset, watch, setValue } = useForm<FormData>({defaultValues: {
        coordinates: [149,75]
    }});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.ifid ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.ifid ? false : true);
    const [showSecAccountTitleField, setShowSecAccountTitleField] = useState<boolean>(false);
    const { data: sectionsData, refetch: sectionsRefetch } = useGetInspectionSectionService({ pageNumber: 1, size: 1000 }, +(params as any)?.id);
    const sections = (sectionsData?.pages[0]?.data?.content || []).map((item: { id?: number, title?: string }) => ({ value: item.id, label: item.title }));
    useEffect(() => {
        sectionsRefetch();
    }, []);
    useEffect(() => {
        if(params?.ifid && sectionsData) {
            if(loading) getByID();
        }
    }, [params, sectionsData]);
    useEffect(() => {
        if (isDirty && params?.ifid) setIsBtnEnabled(true);
      }, [isDirty, dirtyFields]);
    const getByID = () => {
        InspectionFormItemProfileService.getInspectionFormItemById(+(params as any)?.ifid).then((res: GetIFIByIdResponse) => {
            if(res.success) {
                reset({ ...res?.data || {}, 
                    coordinates: (res?.data.coordinates as string)?.split(",")?.map(Number),
                    inspectionFormSectionId: { value: res.data?.inspectionFormSectionId, label: sections?.find((v) => v.value === res.data?.inspectionFormSectionId)?.label || res.data?.inspectionFormSectionId },
                });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if(params?.ifid) {
            InspectionFormItemProfileService.updateInspectionFormItem(+(params as any)?.ifid, { ...data, 
                inspectionFormId: +(params as any)?.id,
                inspectionFormSectionId: data.inspectionFormSectionId?.value,
                coordinates: data.coordinates.toString(),
            }).then((response: any) => {
                if(response.success) {
                    navigate(-1);
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('inspectionFormItem') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                    setIsBtnEnabled(true);
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            InspectionFormItemProfileService.createInspectionFormItem({ ...data, 
                inspectionFormId: +(params as any)?.id,
                inspectionFormSectionId: data.inspectionFormSectionId?.value,
                coordinates: data.coordinates.toString(),
             }).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('inspectionFormItem') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };
    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={false}
            onClick={() => navigate(`/inspection-forms`)}
            heading={t('inspectionForms')}
            icon={<Inspection height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            showCreateBtn={false}
        />
        <div className={`flex`}>
            <InspectionFormTabs params={params} selectedIndex={2} />
            <Box className='w-[100%] mt-[10px]'>
                <IFHeader
                    showBtn={false}
                    showSaveBtn={true}
                    onClick={() => navigate(-1)}
                    onSubmit={handleSubmit(onSubmit)}
                    heading={params?.ifid ? t('editInspectionFormItem') : t('addInspectionFormItem')}
                    icon={<Inspection height={28} width={28} />}
                    btnText={"back"}
                    btnType={"back"}
                    isBtnEnabled={IsBtnEnabled}
                    showCreateBtn={checkPrivileges("ROLE_INSPECTIONFORMITEM_CREATE")}
                />
                {loading ? <Loader /> : <SimpleModal
                    visable={true}
                    title={params?.ifid ? t('editInspectionFormItem') : t('addInspectionFormItem')}
                    isButtonVisable={true}
                    isVisableBtn={IsBtnEnabled}
                    btnclosetext="Back"
                    buttonText={params?.ifid ? "update" : "submit"}
                    attBtnNotshow={false}
                    notVisableBackbtn={true}
                    showPortList={handleSubmit(onSubmit)}
                    formSubmit={handleSubmit(onSubmit)}
                    content={ 
                        <Grid container component="form" id="form" className="mb-2" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12} md={3}>
                                <TextField 
                                    {...register("title", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                                    label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                    fullWidth
                                    error={!!errors.title} 
                                    helperText={errors.title?.message as string} 
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ endAdornment: (<InputAdornment position="end">
                                        <Group onClick={() => setShowSecAccountTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                    </InputAdornment>)}}
                                    sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                                />
                                {showSecAccountTitleField && <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
                                        <TextField 
                                            {...register("secondaryTitle")} 
                                            label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                            fullWidth
                                            error={!!errors.secondaryTitle} 
                                            helperText={errors.secondaryTitle?.message as string} 
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                </div>}
                                <Controller
                                    name="inspectionFormSectionId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: t('This field is required.') }}
                                    render={({ field }) => (<div className="mt-[30px]">
                                        <DynamicSelect
                                            label={t('InspectionSection')}
                                            {...field}
                                            options={sections}
                                            isSearchable
                                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                            error={errors.inspectionFormSectionId?.message as string}
                                        />
                                    </div>)}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField 
                                    {...register("description")} 
                                    label={t('description')}
                                    fullWidth
                                    error={!!errors.description} 
                                    helperText={errors.description?.message as string}
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    rows={1}
                                    sx={{ 
                                        '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                        '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <ImageClickCoordinates 
                                    src={InspectionVehicle} 
                                    alt='' 
                                    callback={(coordinates: number[]) => setValue('coordinates', `${coordinates}`)} 
                                    value={watch('coordinates')}
                                    setIsBtnEnabled={setIsBtnEnabled}
                                />
                            </Grid>
                        </Grid>
                    }
                />}
            </Box>
        </div>
    </>)
}