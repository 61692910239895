import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './Navbar';

import { ReactComponent as PurchaseRequest } from "assets/Sidebar/PurchaseRequest.svg";
import { ReactComponent as TransferInventory } from "assets/Sidebar/TransferInventory.svg";
import { ReactComponent as Inventory } from "assets/Sidebar/Inventory.svg";
// import { ReactComponent as StockAdjustment } from "assets/Sidebar/StockAdjustment.svg";
// import { ReactComponent as StockOnHand } from "assets/Sidebar/StockOnHand.svg";
// import { ReactComponent as VendorManagement } from "assets/Sidebar/vendorManagement.svg";
import { ReactComponent as User } from '../assets/SideBarIcons/users.svg';
import { ReactComponent as Warehouse } from '../assets/SideBarIcons/warehouse.svg'
import { ReactComponent as Vendor } from '../assets/SideBarIcons/vendor.svg'
import { ReactComponent as Units } from '../assets/SideBarIcons/units.svg'
import { ReactComponent as Store } from '../assets/SideBarIcons/store.svg'
import { ReactComponent as Services } from '../assets/SideBarIcons/services.svg'
import { ReactComponent as Station } from '../assets/SideBarIcons/station.svg'
import { ReactComponent as Pricelist } from '../assets/SideBarIcons/price-tag.svg'
import { ReactComponent as Roles } from '../assets/SideBarIcons/roles.svg'
import { ReactComponent as Bank } from '../assets/SideBarIcons/bank.svg'
import { ReactComponent as Bay } from '../assets/SideBarIcons/bay.svg'
import { ReactComponent as Business } from '../assets/SideBarIcons/business.svg'
import { ReactComponent as Client } from '../assets/SideBarIcons/customer.svg'
import { ReactComponent as Make } from '../assets/SideBarIcons/make.svg'
import { ReactComponent as Privileges } from '../assets/SideBarIcons/privileges.svg'
import { ReactComponent as Model } from '../assets/SideBarIcons/car-model.svg'
import { ReactComponent as Packages } from '../assets/SideBarIcons/pakage.svg'
import { ReactComponent as Inspection } from '../assets/SideBarIcons/inspection.svg'
import { ReactComponent as StockTransfer } from '../assets/SideBarIcons/stock-transfer.svg'
import { ReactComponent as Access } from '../assets/SideBarIcons/access.svg'
import { ReactComponent as Definition } from '../assets/SideBarIcons/defination.svg'
import { ReactComponent as MoneyTransfer } from '../assets/SideBarIcons/money-transfer.svg'
import { ReactComponent as Status } from '../assets/SideBarIcons/status.svg'
import InventoryIcon from '@mui/icons-material/InventoryOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';

import DrawerComponent from './Sidebar/components';
import AppRoutes from 'Routes';
import { useLocation } from 'react-router-dom';
import { checkPrivileges } from 'Helpers/CheckPrivileges';



const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface SidebarComponentProps {
  handleLanguageSelect: (lang: string) => void;
}

const Sidebar: React.FC<SidebarComponentProps> = ({ handleLanguageSelect }) => {
  const [navigation, setNavigation] = useState<any>([]);
  const location = useLocation();

  const isLoginRoute = location.pathname === '/login';

  const [open, setOpen] = useState(false);
  useEffect(() => {
    const fetchNavigationData = () => {
      const navigationData = {
        transactions: {
          checked: true,
          title: "Transactions",
          icon: <MoneyTransfer height={24} width={24} />,
          tooltip: "Transactions",
          subItems: {
            bank_account: {
              checked: checkPrivileges("ROLE_BANKACCOUNT_VIEW"),
              href: "/bank-account",
              icon: <Bank height={24} width={24} />,
              tooltip: "bankAccounts",
              title: "bankAccounts",
            },
            bank_desposit: {
              checked: checkPrivileges("ROLE_BANKDEPOSIT_VIEW"),
              href: "/bank-desposit",
              icon: <Bank height={24} width={24} />,
              tooltip: "bankDesposits",
              title: "bankDesposits",
            },
            jobcard: {
              checked: checkPrivileges("ROLE_JOBCARD_VIEW"),
              title: "jobCards",
              href: "/job-card",
              icon: <Bay height={24} width={24} />,
              tooltip: "jobCard",
            },
            workorder: {
              checked: checkPrivileges("ROLE_WORKORDER_VIEW"),
              title: "workOrders",
              href: "/work-order",
              icon: <Bay height={24} width={24} />,
              tooltip: "workOrder",
            },
            invoice: {
              checked: checkPrivileges("ROLE_INVOICE_VIEW"),
              title: "invoices",
              href: "/invoices",
              icon: <Bay height={24} width={24} />,
              tooltip: "invoices",
            },
            payment: {
              checked: checkPrivileges("ROLE_PAYMENT_VIEW"),
              title: "payments",
              href: "/payments",
              icon: <Bay height={24} width={24} />,
              tooltip: "payments",
            },
            payment_mode: {
              checked: checkPrivileges("ROLE_BUSINESSSTATUS_VIEW"),
              title: "Payment Modes",
              href: "/payment-modes",
              icon: <Bay height={24} width={24} />,
              tooltip: "Payment Modes",
            },
          }
        },


        vehicle: {
          checked: true,
          title: "vehicles",
          icon: <Make height={24} width={24} />,
          tooltip: "vehicles",
          subItems: {
            vehicle: {
              checked: checkPrivileges("ROLE_VEHICLE_VIEW"),
              title: "vehicles",
              href: "/vehicle",
              icon: <TransferInventory height={24} width={24} />,
              tooltip: "vehicles",
            },
            make: {
              checked: checkPrivileges("ROLE_MAKE_VIEW"),
              title: "Makes",
              icon: <Make height={24} width={24} />,
              tooltip: "Makes",
              href: "/make"
            },
            model: {
              checked: checkPrivileges("ROLE_MODEL_VIEW"),
              title: "Models",
              icon: <Model height={24} width={24} />,
              tooltip: "Models",
              href: "/model"
            },
            modelEngine: {
              checked: checkPrivileges("ROLE_MODELENGINE_VIEW"),
              title: "Model Engines",
              icon: <User height={24} width={24} />,
              tooltip: "Model Engines",
              href: "/model-engine"
            },
            vehicle_type: {
              checked: checkPrivileges("ROLE_VEHICLETYPE_VIEW"),
              title: "vehicleTypes",
              href: "/vehicle-type",
              icon: <TransferInventory height={24} width={24} />,
              tooltip: "vehicleTypes",
            },
            vehicle_status: {
              checked: checkPrivileges("ROLE_BUSINESSSTATUS_VIEW"),
              title: "VehicleStatuses",
              href: "/vehicle-statuses",
              icon: <TransferInventory height={24} width={24} />,
              tooltip: "VehicleStatuses",
            },
          }
        },


        access: {
          checked: true,
          title: "Access",
          icon: <Access height={24} width={24} />,
          tooltip: "Access",
          subItems: {
            // accountsettings: {
            //   checked: true,
            //   title: "businessSettings",
            //   icon: <Accounts height={24} width={24} />,
            //   tooltip: "businessSettings",
            //   href: "/accountsettings",
            // },
            users: {
              checked: checkPrivileges("ROLE_USER_VIEW"),
              title: "users",
              icon: <User height={26} width={26} />,
              tooltip: "users",
              href: "/users",
            },
            client: {
              checked: checkPrivileges("ROLE_CLIENT_VIEW"),
              title: "Clients",
              icon: <Client height={24} width={24} />,
              tooltip: "Clients",
              href: "/client"
            },
            business: {
              checked: checkPrivileges("ROLE_BUSINESS_VIEW"),
              href: "/business",
              icon: <Business height={24} width={24} />,
              tooltip: "Businessses",
              title: "Businessses",
            },
            role: {
              checked: checkPrivileges("ROLE_ROLE_VIEW"),
              href: "/roles",
              icon: <Roles height={24} width={24} />,
              tooltip: "Roles",
              title: "Roles",
            },
            manufacturer: {
              checked: checkPrivileges("ROLE_MANUFACTURER_VIEW"),
              href: "/manufacturer",
              icon: <Privileges height={24} width={24} />,
              tooltip: "manufacturers",
              title: "manufacturers",
            },

            user_group: {
              checked: checkPrivileges("ROLE_USERGROUP_VIEW"),
              href: "/user-groups",
              icon: <PurchaseRequest height={24} width={24} />,
              tooltip: "usersGroup",
              title: "usersGroup",
            },
            client_group: {
              checked: checkPrivileges("ROLE_CLIENTGROUP_VIEW"),
              href: "/client-group",
              icon: <PurchaseRequest height={24} width={24} />,
              tooltip: "clientsGroup",
              title: "clientsGroup",
            },
            businessGroup: {
              checked: checkPrivileges("ROLE_BUSINESSGROUP_VIEW"),
              href: "/business-group",
              icon: <Business height={24} width={24} />,
              tooltip: "businessesGroup",
              title: "businessesGroup",
            },
          }
        },


        definitions: {
          checked: true,
          title: "Definitions",
          icon: <Definition height={24} width={24} />,
          tooltip: "Definitions",
          subItems: {
            items: {
              checked: checkPrivileges("ROLE_ITEM_VIEW"),
              title: "items",
              href: "/parts",
              icon: <Inventory height={24} width={24} />,
              tooltip: "items",
            },
            service: {
              checked: checkPrivileges("ROLE_SERVICE_VIEW"),
              title: "services",
              href: "/service",
              icon: <Services height={24} width={24} />,
              tooltip: "services",
            },
            packages: {
              checked: checkPrivileges("ROLE_PACKAGE_VIEW"),
              title: "packages",
              href: "/packages",
              icon: <Packages height={24} width={24} />,
              tooltip: "packages",
            },
            itemgroup: {
              checked: checkPrivileges("ROLE_ITEMGROUP_VIEW"),
              href: "/part-group",
              icon: <PurchaseRequest height={24} width={24} />,
              tooltip: "itemsGroup",
              title: "itemsGroup",
            },
            servicegroup: {
              checked: checkPrivileges("ROLE_SERVICEGROUP_VIEW"),
              href: "/service-group",
              icon: <PurchaseRequest height={24} width={24} />,
              tooltip: "servicesGroup",
              title: "servicesGroup",
            },
            packagegroup: {
              checked: checkPrivileges("ROLE_PACKAGEGROUP_VIEW"),
              href: "/package-group",
              icon: <PurchaseRequest height={24} width={24} />,
              tooltip: "packagesGroup",
              title: "packagesGroup",
            },
            itemcategory: {
              checked: checkPrivileges("ROLE_ITEMCATEGORY_VIEW"),
              title: "itemsCategory",
              href: "/part-category",
              icon: <Inventory height={24} width={24} />,
              tooltip: "itemsCategory",
            },
            units: {
              checked: checkPrivileges("ROLE_UNIT_VIEW"),
              title: "units",
              icon: <Units height={24} width={24} />,
              tooltip: "units",
              href: "/units"
            },
            taxprocedure: {
              checked: checkPrivileges("ROLE_TAXPROCEDURE_VIEW"),
              title: "taxProcedures",
              icon: <Inventory height={24} width={24} />,
              tooltip: "taxProcedures",
              href: "/taxprocedure"
            },
            price_list: {
              checked: checkPrivileges("ROLE_PRICELIST_VIEW"),
              title: "priceLists",
              href: "/price-list",
              icon: <Pricelist height={24} width={24} />,
              tooltip: "priceLists",
            },
            status: {
              checked: checkPrivileges("ROLE_BUSINESSSTATUS_VIEW"),
              title: "Statuses",
              href: "/statuses",
              icon: <Status height={24} width={24} />,
              tooltip: "Statuses",
            },
            checklist: {
              checked: checkPrivileges("ROLE_CHECKLIST_VIEW"),
              title: "Checklists",
              href: "/checklists",
              icon: <ChecklistIcon height={24} width={24} />,
              tooltip: "Checklists",
            },
          }
        },


        stations: {
          checked: true,
          title: "stations",
          icon: <Station height={24} width={24} />,
          tooltip: "stations",
          subItems: {
            station: {
              checked: checkPrivileges("ROLE_STATION_VIEW"),
              title: "stations",
              href: "/station",
              icon: <Station height={24} width={24} />,
              tooltip: "stations",
            },
            stationgroup: {
              checked: checkPrivileges("ROLE_STATIONGROUP_VIEW"),
              title: "stationsGroup",
              href: "/stationgroup",
              icon: <TransferInventory height={24} width={24} />,
              tooltip: "stationsGroup",
            },
            store: {
              checked: checkPrivileges("ROLE_STORE_VIEW"),
              title: "stores",
              href: "/store",
              icon: <Store height={24} width={24} />,
              tooltip: "stores",
            },
            bay: {
              checked: checkPrivileges("ROLE_BAY_VIEW"),
              href: "/bay",
              icon: <Bay height={24} width={24} />,
              tooltip: "bays",
              title: "bays",
            },
            inspection_form: {
              checked: checkPrivileges("ROLE_INSPECTIONFORM_VIEW"),
              href: "/inspection-form",
              icon: <Inspection height={24} width={24} />,
              tooltip: "inspectionForms",
              title: "inspectionForms",
            },
          }
        },
        stock_transaction: {
          checked: true,
          title: "stockTransactions",
          icon: <StockTransfer height={24} width={24} />,
          tooltip: "stockTransactions",
          subItems: {
            warehouse: {
              checked: checkPrivileges("ROLE_WAREHOUSE_VIEW"),
              title: "warehouses",
              href: "/warehouse",
              icon: <Warehouse height={24} width={24} />,
              tooltip: "warehouses",
            },
            vendor: {
              checked: checkPrivileges("ROLE_VENDOR_VIEW"),
              title: "vendors",
              href: "/vendor",
              icon: <Vendor height={24} width={24} />,
              tooltip: "vendors",
            },
          }
        },
        inventory: {
          checked: true,
          title: "Inventory",
          icon: <InventoryIcon sx={{ fill: 'black', width: '28px', height: '28px' }} />,
          tooltip: "Inventory",
          subItems: {
            purchase_order: {
              checked: checkPrivileges("ROLE_CHECKLIST_VIEW"),
              title: "Purchase Order",
              href: "/inventory/purchase-orders",
              icon: <InventoryIcon sx={{ fill: 'black', width: '28px', height: '28px' }} />,
              tooltip: "Purchase Order",
            },
            purchase_invoice: {
              checked: checkPrivileges("ROLE_CHECKLIST_VIEW"),
              title: "Payment",
              href: "/inventory/purchase-payments",
              icon: <InventoryIcon sx={{ fill: 'black', width: '28px', height: '28px' }} />,
              tooltip: "Payment",
            },
            stock_received: {
              checked: checkPrivileges("ROLE_CHECKLIST_VIEW"),
              title: "Stock Received",
              href: "/inventory/stock-receives",
              icon: <InventoryIcon sx={{ fill: 'black', width: '28px', height: '28px' }} />,
              tooltip: "Stock Received",
            },
            credit_note: {
              checked: checkPrivileges("ROLE_CHECKLIST_VIEW"),
              title: "Debit Note",
              href: "/inventory/debit-note",
              icon: <InventoryIcon sx={{ fill: 'black', width: '28px', height: '28px' }} />,
              tooltip: "Debit Note",
            },
            unstock: {
              checked: checkPrivileges("ROLE_CHECKLIST_VIEW"),
              title: "Unstock",
              href: "/inventory/unstock",
              icon: <InventoryIcon sx={{ fill: 'black', width: '28px', height: '28px' }} />,
              tooltip: "Unstock",
            },
          }
        },
      };
      setNavigation(navigationData);
    };

    if (!isLoginRoute) {
      fetchNavigationData();
    }
  }, [isLoginRoute]);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  if (!localStorage.getItem('ACCESS_TOKEN') && location.pathname !== '/login') {
    window.location.href = '/login';
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* Navabr */}
      {!isLoginRoute && <Navbar onMenuClick={handleDrawerOpen} handleLanguageSelect={handleLanguageSelect} />}
      {/* Sidebar */}
      {!isLoginRoute && <DrawerComponent open={open} navigation={navigation} drawerHeader={<DrawerHeader />} />}



      <Box component="main" sx={{ flexGrow: 1, p: isLoginRoute ? 0 : 3 }} className='bg-background-color min-h-screen' >
        {!isLoginRoute && <DrawerHeader className="hide-on-print" />}
        {/* Inner Components */}
        <AppRoutes />

      </Box>
    </Box>
  );
}
export default Sidebar;
