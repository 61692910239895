import { selectUserInfo } from "features/user/userSlice";
import { Button, Grid, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TaxProcedureProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import DynamicSelect from "common/Select/Select";

interface FormData { [key: string]: any;}
interface TaxProcedureProps {
    setAddNew?: any;
    taxProceduresRefetch?: any;
}
export default function TaxProcedures(props: TaxProcedureProps) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { control, register, handleSubmit, formState: { errors } } = useForm<FormData>({defaultValues: {}});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const typeOptions = [
        { value: 1, label: 'Normal' },
        { value: 2, label: 'Part Default' },
        { value: 3, label: 'Service Default' },
        { value: 4, label: 'Package Default' },
    ];
    const onSubmit = (data: any) => {
        TaxProcedureProfileService.createTaxProcedure({...data, type: data.type.value}).then((response: any) => {
            if(response.success) {
                props?.setAddNew('');
                props?.taxProceduresRefetch();
                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('taxProcedure') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
            } else {
                enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
            }
        }).catch((err:any) => { 
            console.error("===", err);
            enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
        });
    };

    return (
        <Grid container component="form" id="form" className="items-baseline" spacing={3} onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12} md={4}>
                <TextField 
                    {...register("title", { required: t('This field is required.') })} 
                    placeholder={t("title")}
                    label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${primaryLanguage})` : ''} *`}
                    fullWidth
                    error={!!errors.title} 
                    helperText={errors.title?.message as string} 
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Controller
                    name="type"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "This field is required." }}
                    render={({ field }) => (
                        <DynamicSelect
                            label={t("type")}
                            {...field}
                            options={typeOptions}
                            isSearchable
                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                            error={errors.type?.message as string}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField 
                    type="number"
                    {...register("percentageValue", { 
                        required: t('This field is required.'), 
                        min: { value: 0, message: t('Value must be at least 0.') },
                        max: { value: 100, message: t('Value must be at most 100.') }
                    })} 
                    placeholder={t("percentageValue")}
                    label={t("percentageValue")}
                    fullWidth
                    error={!!errors.percentageValue} 
                    helperText={errors.percentageValue?.message as string} 
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={12}>
                <div className="flex gap-2 justify-end">
                    <Button type="submit" className="primary" variant="contained" disableElevation onClick={() => handleSubmit(onSubmit)}>{t('submit')}</Button>
                </div>
            </Grid>
        </Grid>
    )
}