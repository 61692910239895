import { selectUserInfo } from "features/user/userSlice";
import { Grid, InputAdornment, TextField, Tooltip } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ModelEngineProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Enginemodel  } from "assets/SideBarIcons/engine-model.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import { GetModelEngineByIdResponse, GetModelsByMakeResponse } from "subModule/src/services/modelengine/interface";
import Loader from "layout/Loader";
import { ReactComponent as Group } from '../../assets/Modal/Group.svg'
import DynamicSelect from "common/Select/Select";
import { ReactComponent as ModalBtn } from "assets/Modal/ModalBtn.svg"
import FormDialog from "common/Modal/Modal";
import MakeModal from "pages/Make/MakeModal";
import { useGetMakeDropdownService } from "subModule/src/services/make/useMake";
import ModelModal from "pages/Model/ModelModal";

interface FormData { [key: string]: any;}
export default function ModelEngineModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, control, formState: { errors, dirtyFields, isDirty }, reset, setValue, watch } = useForm<FormData>({defaultValues: {}});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    const [showSecAccountTitleField, setShowSecAccountTitleField] = useState<boolean>(false);
    const [addNew, setAddNew] = useState<string>('');
    const [models, setModels] = useState<any>([]);
    const { data: makesData, refetch: makesRefetch } = useGetMakeDropdownService({ pageNumber: 1, size: 1000 });
    const makes = (makesData?.pages[0]?.data?.content || []).map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
    useEffect(() => {
        makesRefetch();
        if(params?.id) {
            if(loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
      }, [isDirty, dirtyFields]);
    const fetchModels = (make: any) => {
        ModelEngineProfileService.getModelsByMake({ page: 1, size: 1000 }, make).then((response: GetModelsByMakeResponse) => {
            if (response.success) {
                setModels(response?.data?.content?.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title })) || []);
            } else {
                enqueueSnackbar(<AlertMessages text={response.message} />, { variant: 'error' });
            }
        }).catch((err: any) => {
            console.error("===", err);
        });
    }
    const getByID = () => {
        ModelEngineProfileService.getModelEngineByID(+(params as any)?.id).then((res: GetModelEngineByIdResponse) => {
            if(res.success) {
                reset({ ...res?.data || {}, 
                    makeId: { value: res.data?.makeId, label: res?.data?.makeTitle }, 
                    modelId: { value: res.data?.modelId, label: res?.data?.modelTitle },
                });
                fetchModels(res.data?.makeId);
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if(params?.id) {
            ModelEngineProfileService.updateModelEngine(+params?.id, {
                ...data, makeId: data.makeId.value, modelId: data.modelId.value
            }).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('modelEngine') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
                setIsBtnEnabled(true);
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            ModelEngineProfileService.createModelEngine({...data, makeId: data.makeId.value, modelId: data.modelId.value}).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('modelEngine') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate(-1)}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editModelEngines') : t('addModelEngines')}
            icon={<Enginemodel height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_MODELENGINE_CREATE")}
        />
        {loading ? <Loader /> : 
            <SimpleModal
                visable={true}
                title={params?.id ? t('editModelEngines') : t('addModelEngines')}
                isButtonVisable={true}
                isVisableBtn={IsBtnEnabled}
                btnclosetext="Back"
                buttonText={params?.id ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit(onSubmit)}
                formSubmit={handleSubmit(onSubmit)}
                content={ 
                    <Grid container component="form" id="form" className="items-baseline" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12} md={3} className='grid' sx={{ gridTemplateColumns: '85% 10%' }}>
                            <Controller
                                name="makeId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: t('This field is required.') }}
                                render={({ field }) => (
                                    <DynamicSelect
                                        label={t("make") + ' *'}
                                        {...field}
                                        options={makes}
                                        isSearchable
                                        onChange={(selectedOption: any) => {
                                            field.onChange(selectedOption);
                                            fetchModels(selectedOption.value);
                                            setValue('modelId', null);
                                        }}
                                        error={errors.makeId?.message as string}
                                    />
                                )}
                            />
                            {checkPrivileges('ROLE_MAKE_CREATE') && <Tooltip onClick={() => setAddNew('makes')} title={t('addMake')}><ModalBtn className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'/></Tooltip>}
                            {addNew === 'makes' ? <FormDialog
                                open={addNew === 'makes'}
                                onClose={(event, reason) => event && reason !== 'backdropClick' ? setAddNew('') : ''}
                                title={t('addMake')}
                                createPopup={true}
                                fullWidth
                                hideActions={true}
                                content={<MakeModal setAddNew={setAddNew} makesRefetch={makesRefetch} />}
                            /> : null}
                        </Grid>
                        <Grid item xs={12} md={3} className='grid' sx={{ gridTemplateColumns: '85% 10%' }}>
                            <Controller
                                name="modelId"
                                control={control}
                                defaultValue={null}
                                rules={{ required: t('This field is required.') }}
                                render={({ field }) => (
                                    <DynamicSelect
                                        label={t('Model')}
                                        {...field}
                                        options={models}
                                        isSearchable
                                        isDisabled={!watch('makeId')?.value}
                                        onChange={(selectedOption: any) => field.onChange(selectedOption)}
                                        error={errors.modelId?.message as string}
                                    />
                                )}
                            />
                            {checkPrivileges('ROLE_MODEL_CREATE') && <Tooltip onClick={() => setAddNew('models')} title={t('addModel')}><ModalBtn className='mt-5 mx-2 mb-2 cursor-pointer h-[38px]'/></Tooltip>}
                            {addNew === 'models' ? <FormDialog
                                open={addNew === 'models'}
                                onClose={(event, reason) => event && reason !== 'backdropClick' ? setAddNew('') : ''}
                                title={t('addModel')}
                                createPopup={true}
                                fullWidth
                                hideActions={true}
                                content={<ModelModal setAddNew={setAddNew} makesRefetch={makesRefetch} />}
                            /> : null}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                {...register("title", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''} *`}
                                fullWidth
                                error={!!errors.title} 
                                helperText={errors.title?.message as string} 
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ endAdornment: (<InputAdornment position="end">
                                    <Group onClick={() => setShowSecAccountTitleField((prevValue) => !prevValue)} style={{ background: 'rgba(76, 0, 85, 0.15)', borderRadius: '8px', padding: '8px', width: '36px', height: '36px', cursor: 'pointer' }} />
                                </InputAdornment>)}}
                                sx={{ '& .MuiOutlinedInput-root': { paddingRight: '0px !important' } }}
                            />
                            {showSecAccountTitleField && <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', padding: '10px', zIndex: 10, backgroundColor: "white", width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
                                    <TextField 
                                        {...register("secondaryTitle")} 
                                        label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${t(secondaryLanguage)})` : ''}`}
                                        fullWidth
                                        error={!!errors.secondaryTitle} 
                                        helperText={errors.secondaryTitle?.message as string} 
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>}
                        </Grid>
                    </Grid>
                }
            />
        }
    </>)
}