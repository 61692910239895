// InspectionFormItemService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusInspectionFormItemResponse,
  DeleteInspectionFormItemResponse,
  GetIFIByIdResponse,
  GetInspectionFormItemResponse,
  InspectionFormItemRequest,
  InspectionFormItemResponse,
} from './interface';

export class InspectionFormItemProfile extends BaseService {
  async createInspectionFormItem(req: InspectionFormItemRequest) {
    return this.post<InspectionFormItemResponse, InspectionFormItemRequest>(
      `${this.apiUrl}/api/inspectionformitems`,
      req
    );
  }

  async getInspectionFormItem(params: PaginationParams & { inspectionformId: number }) {
    return this.get<GetInspectionFormItemResponse>(`${this.apiUrl}/api/inspectionformitems/paged`, params);
  }

  async updateInspectionFormItem(id: number, req: InspectionFormItemRequest) {
    return this.put<InspectionFormItemResponse, InspectionFormItemRequest>(
      `${this.apiUrl}/api/inspectionformitems/${id}`,
      req
    );
  }

  async deleteInspectionFormItem(id: number) {
    return this.delete<DeleteInspectionFormItemResponse>(`${this.apiUrl}/api/inspectionformitems/${id}`);
  }

  async ChangeStatusInspectionFormItem(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusInspectionFormItemResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/inspectionformitems/${active}/${id}`,
      req
    );
  }

  async getInspectionFormItemById(id: number | undefined) {
    return this.get<GetIFIByIdResponse>(`${this.apiUrl}/api/inspectionformitems/${id}`);
  }
}
