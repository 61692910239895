import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
// import CommonCheckbox from 'common/CheckBox/Checkbox';
import DynamicSelect from 'common/Select/Select';
import Currency from 'common/Others/Currency';
import Language from 'common/Others/Language';
import { useTranslation } from 'react-i18next';
import CommonCheckbox from 'common/CheckBox/Checkbox';

interface BusinessModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  enableEdit?: boolean;
  isBtnEnabled: boolean;
  scrapItemGroupsData: any;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  priceListData: any;
  taxProceduresData: any;
}

const BusinessModalComponent: React.FC<BusinessModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled,
  scrapItemGroupsData,
  loadNext,
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  priceListData,
  taxProceduresData
}) => {
  const scrapItemGroupOptions = scrapItemGroupsData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const priceListOptions = priceListData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const taxProcedureOptions = taxProceduresData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const currencyOptions = Currency.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }))
  const languageOptions = Language.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }))

  const { t } = useTranslation();

  const handleScroll = () => {
  };
  const handleSearch = () => {
  }

  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateBusiness" : "addBusiness"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth
                search='language'
                handleSecField={handleSecField}
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
              {showSecField &&
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: -8,
                        left: 0,
                        zIndex: 1,
                        display: showSecField ? 'flex' : 'none',
                        backgroundColor: "white",
                        height: '5rem',
                        width: '100%',
                        justifyContent: 'center',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div className='w-full mx-2 mt-1'>
                        <StyledField
                          label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                          handleChange={(e) => handleChange('secondaryTitle', e.target.value)}
                          required={false}
                          fullWidth
                          // error={errorMessages?.secondaryTitle}
                          value={formData?.secondaryTitle || ''}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3} >
              <DynamicSelect
                defaultValue={enableEdit ? languageOptions.find((option: any) => option.value === formData?.primaryLanguage) : null}
                value={languageOptions.find((option: any) => option.value === formData?.primaryLanguage)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="primaryLanguage"
                options={languageOptions}
                // onInputChange={(e) => handleDropdownSearch('primaryLanguage', e)}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('primaryLanguage', e)}
                // error={errorMessages?.primaryLanguageError}
                label="primaryLanguage"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? languageOptions.find((option: any) => option.value === formData?.secondaryLanguage) : null}
                value={languageOptions.find((option: any) => option.value === formData?.secondaryLanguage)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="secondaryLanguage"
                options={languageOptions}
                // onInputChange={(e) => handleDropdownSearch('secondaryLanguage', e)}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('secondaryLanguage', e)}
                // error={errorMessages?.secondaryLanguageError}
                label="secondaryLanguage"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? currencyOptions.find((option: any) => option.value === formData?.currency) : null}
                value={currencyOptions.find((option: any) => option.value === formData?.currency)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="currency"
                options={currencyOptions}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('currency', e)}
                // error={errorMessages?.currency}
                label="currency"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? scrapItemGroupOptions.find((option: any) => option.value === formData?.scrapItemGroupId) : null}
                value={scrapItemGroupOptions.find((option: any) => option.value === formData?.scrapItemGroupId)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="makeId"
                options={scrapItemGroupOptions}
                onInputChange={(e) => handleDropdownSearch('scrapItemGroupId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('scrapItemGroupId', e)}
                error={errorMessages?.scrapItemGroupIdError}
                label="scrapItemGroup"
                onMenuScrollToBottom={(e) => { loadNext('scrapItemGroupId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('scrapItemGroupId', e); }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? priceListOptions.find((option: any) => option.value === formData?.priceListId) : null}
                value={priceListOptions.find((option: any) => option.value === formData?.priceListId)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="priceListId"
                options={priceListOptions}
                onInputChange={(e) => handleDropdownSearch('priceListId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('priceListId', e)}
                error={errorMessages?.priceListIdError}
                label="defaultPriceList"
                onMenuScrollToBottom={(e) => { loadNext('priceListId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('priceListId', e); }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                fullWidth={true}
                label="currency"
                placeholder="Write currency here"
                handleChange={(e) => handleChange('currency', e.target.value)}
                required={false}
                // error={errorMessages?.currency}
                value={formData?.currency || ''}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DynamicSelect
                defaultValue={enableEdit ? taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId) : null}
                value={taxProcedureOptions.find((option: any) => option.value === formData?.taxProcedureId)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="color"
                options={taxProcedureOptions}
                // onScroll={handleScroll}
                // onInputChange={handleSearch}
                // isMulti
                isRequired={false}
                onChange={(e) => handleChange('taxProcedureId', e)}
                // error={errorMessages?.taxProcedureIdError}
                label="taxProcedureId"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-start`}>
              <CommonCheckbox
                label="Sell Below Stock"
                onChange={(e) => handleChange('sellBelowStock', e.target.checked)}
                color="success"
                required={false}
                // error={errorMessages?.sellBelowStockError}
                checked={formData?.sellBelowStock}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default BusinessModalComponent;
