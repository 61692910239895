import DynamicSelect from "common/Select/Select";
import { Button, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";

interface FormData { [key: string]: any;}
interface PlateNumberProps {
    setValue?: (field: string, value: any) => void;
    setOpenPlateNumberDetails?: any;
    plate?: any;
    region?: number;
    state?: number | null;
    setIsBtnEnabled?: any;
    statesList?: any;
}
export default function PlateNumberModal(props: PlateNumberProps) {
    const { t } = useTranslation();
    const { register, control, trigger, formState: { errors }, watch, resetField, getValues } = useForm<FormData>({defaultValues: {
        plateRegion: props?.region || 1,
        state: props?.region == 1 && props?.plate?.length > 0 ? {label: props?.statesList?.find((state: any) => state?.value === props?.state)?.label || props?.state, value: props?.state} : null,
        code:  props?.region == 1 ? props?.plate?.split(' ')?.slice(1, -1).join(' ') || '' : props?.plate,
        number: props?.plate?.substring(props?.plate?.lastIndexOf(' ') + 1) || ''
    }});
    const submission = (data: any) => {
        const fullPlateNumber = watch('plateRegion') === 1 ? data.state.label + ' ' + (data.code as string).replace(/ /g, '') + ' ' + data.number : data.code;
        props?.setValue?.('plateNumber', fullPlateNumber);
        props?.setValue?.('plateRegion', data.plateRegion);
        if(watch('plateRegion') === 1) props?.setValue?.('stateId', data.state.value);
        else props?.setValue?.('stateId', null);
        props?.setOpenPlateNumberDetails(false); props?.setIsBtnEnabled(true);
    };
    return (
        <Grid container component="form" id="form" className="items-baseline" columnSpacing={3}>
            <Grid item xs={12}>
                <Controller
                    name="plateRegion"
                    control={control}
                    render={({ field }) => (<>
                        <label className="text-left block mb-2">{t('select') + ' ' + t('plateRegion') + ':'}</label>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" {...field} onChange={(e: any) => {
                            const value = parseInt(e.target.value, 10);
                            field.onChange(value);
                            if (value === 1) { resetField('state'); resetField('number'); }
                        }}>
                            <FormControlLabel value={1} control={<Radio />} label={t('local')} sx={{ '& .MuiTypography-root': { position: 'relative', top: '-8px' }, }} />
                            <FormControlLabel value={2} control={<Radio />} label={t('generic')} sx={{ '& .MuiTypography-root': { position: 'relative', top: '-8px', whiteSpace: 'nowrap', width: '100px', }, }} />
                        </RadioGroup>
                    </>)}
                />
            </Grid>
            {watch('plateRegion') == 1 ? <Grid item xs={12} md={4}>
                <Controller
                    name="state"
                    control={control}
                    defaultValue={null}
                    rules={{ required: watch("plateRegion") == 1 ? t("This field is required.") : false }}
                    render={({ field }) => (
                        <DynamicSelect
                            label={t("state") + ' *'}
                            {...field}
                            options={props?.statesList || []}
                            isSearchable
                            onChange={(selectedOption: any) => field.onChange(selectedOption)}
                            error={errors.state?.message as string}
                        />
                    )}
                />
            </Grid> : null}
            <Grid item xs={12} md={4}>
                <TextField 
                    {...register("code", { required: t("This field is required.") })} 
                    placeholder={watch('plateRegion') === 1 ? t('code') : t('plateNumber')}
                    label={`${(watch('plateRegion') === 1 ? t('code') : t('plateNumber')) + ' *'}`}
                    fullWidth
                    error={!!errors.code} 
                    helperText={errors.code?.message as string} 
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => (e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))}
                />
            </Grid>
            {watch('plateRegion') == 1 ? <Grid item xs={12} md={4}>
                <TextField 
                    type="number"
                    {...register("number", { 
                        required: t('This field is required.'), 
                        min: { value: 0, message: t('Negative numbers not allowed.') }
                    })} 
                    placeholder={t("number")}
                    label={t("number") + ' *'}
                    fullWidth
                    error={!!errors.number} 
                    helperText={errors.number?.message as string} 
                    InputLabelProps={{ shrink: true }}
                />
            </Grid> : null}
            <Grid item xs={12}>
                <div className="flex gap-2 justify-end">
                    <Button className="primary" variant="contained" disableElevation onClick={async() => {
                        const isValid = await trigger();
                        if(isValid) submission(getValues());
                    }}>{t('submit')}</Button>
                </div>
            </Grid>
        </Grid>
    )
}