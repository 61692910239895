// UserGroupService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignResponse,
  AssignUnassignUserRequest,
  ChangeStatusUserGroupResponse,
  DeleteUserGroupResponse,
  GetUserGroupByIdResponse,
  GetUserGroupResponse,
  GetUserGroupUserResponse,
  UserGroupRequest,
  UserGroupResponse,
} from './interface';

export class UserGroupProfile extends BaseService {
  async createUserGroup(req: UserGroupRequest) {
    return this.post<UserGroupResponse, UserGroupRequest>(`${this.apiUrl}/api/usergroups`, req);
  }

  async getUserGroup(params: PaginationParams) {
    return this.get<GetUserGroupResponse>(`${this.apiUrl}/api/usergroups/paged`, params);
  }

  async updateUserGroup(id: number, req: UserGroupRequest) {
    return this.put<UserGroupResponse, UserGroupRequest>(`${this.apiUrl}/api/usergroups/${id}`, req);
  }

  async deleteUserGroup(id: number) {
    return this.delete<DeleteUserGroupResponse>(`${this.apiUrl}/api/usergroups/${id}`);
  }

  async changeStatusUserGroup(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusUserGroupResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/usergroups/${active}/${id}`,
      req
    );
  }

  async getUserGroupUser(params: PaginationParams & { userGroupId: number }) {
    return this.get<GetUserGroupUserResponse>(`${this.apiUrl}/api/users/byusergroupid`, params);
  }

  async assignUser(req: AssignUnassignUserRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignUserRequest>(
      `${this.apiUrl}/api/usergroups/user/assign`,
      req
    );
  }

  async unassignUser(req: AssignUnassignUserRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignUserRequest>(
      `${this.apiUrl}/api/usergroups/user/unassign`,
      req
    );
  }

  async assignAllUsers(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/usergroups/user/assignall`,
      req
    );
  }

  async unassignAllUsers(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/usergroups/user/unassignall`,
      req
    );
  }

  async getUserGroupByID(id: number) {
    return this.get<GetUserGroupByIdResponse>(`${this.apiUrl}/api/usergroups/${id}`);
  }
}
