import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useNavigate } from "react-router-dom";

interface tabProps {
    params: any;
    selectedIndex: number;
}
export default function ItemGroupTabs(props: tabProps) {
    const navigate = useNavigate();
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) navigate(`/part-group/${props.params?.id}`);
        else if (tabId === 1) navigate(`/part-group/assign/part/${props.params?.id}`);
    }
    const tabs = [
        { id: 0, title: `edit`, child: <></> },
        { id: 1, title: `items`, child: <></> },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
            case 0:
                return !checkPrivileges('ROLE_ITEMGROUP_UPDATE');
            case 1:
                return !checkPrivileges('ROLE_ITEMGROUP_ITEM_ASSIGN') && !checkPrivileges('ROLE_ITEMGROUP_ITEM_UNASSIGN');
            default:
                return false;
        }
    });

    return (<>
        <VerticalTabs tabs={tabs} selectedIndex={props.selectedIndex} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
    </>);
}