import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useGetStatusesService } from 'subModule/src/services/statuses/useStatuses';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useNavigate, useParams } from 'react-router-dom';
import FormDialog from 'common/Modal/Modal';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Statuses } from "assets/SideBarIcons/status.svg";
import BackendPaginationTable from 'common/Table/TableWithPagination';
import { StatusesProfileService } from 'subModule/src/core/services';
import { StatusesResponse } from 'subModule/src/services/statuses/interface';
import { DeleteData } from 'subModule/src/type';
import Loader from 'layout/Loader';
import StyledInput from 'common/SearchField/Search';
import VerticalTabs from 'common/ComponentSidebar/ComponentSideBar';
export default function StatusesListing() {
    const navigate = useNavigate();
    const params = useParams();
    let statusType = params?.typename === 'packages' ? 1 : params?.typename === 'services' ? 2 : 3;
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [row, setRow] = useState<StatusesResponse>({});
    const [search, setSearch] = useState<any>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetStatusesService({pageNumber: pageNo, size: pageSize, name: searchTerm}, statusType) : useGetStatusesService({pageNumber: pageNo, size: pageSize}, statusType);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, tableData, statusType]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (searchTerm !== null && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 1000);
        return () => clearTimeout(getData)
    }, [searchTerm, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    const onEdit = (row: any) => navigate(`/status/${params?.typename}/edit/` + row.id);
    const showRowView = (row: any) => navigate(`/status/${params?.typename}/edit/` + row.id);
    const onDelete = (row: any) => {
        setDeleteModal(true);
        setRow(row);
    }
    const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if (searchTerm) { handleSearch(''); }
    }
    const handleSearch = (search: string) => {
        setLoading(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
    };
    const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') handleSearch(trimmedSearch);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
    };
    const actionslist = [
        { label: "edit", onClick: onEdit, icon: <EditIcon /> },
        { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
    ];
    const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) {clearSearch(); setLoading(true); statusType = 1; navigate(`/status/packages`);}
        else if (tabId === 1) {clearSearch(); setLoading(true); statusType = 2; navigate(`/status/services`);}
        else if (tabId === 2) {clearSearch(); setLoading(true); statusType = 3; navigate(`/status/parts`);}
    }
    const tabs = [
        { id: 0, title: `packages`, child: <></> },
        { id: 1, title: `services`, child: <></> },
        { id: 2, title: `items`, child: <></> },
    ];
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_BUSINESSSTATUS_UPDATE')
          ? (checkPrivileges('ROLE_BUSINESSSTATUS_ARCHIVE')
            ? actions
            : actions.filter(action => action.label !== 'delete'))
          : (checkPrivileges('ROLE_BUSINESSSTATUS_ARCHIVE')
            ? actions.filter(action => action.label !== 'edit')
            : []);
    }
    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate(`/status/${`${params?.typename}`}/create`)}
                heading={t('Statuses')}
                icon={<Statuses height={28} width={28} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_BUSINESSSTATUS_CREATE")}
            />
            <div className={`flex`}>
                <VerticalTabs tabs={tabs} selectedIndex={statusType - 1} handleChangeTabs={handleChangeTabs} />
                {loading ? <Loader /> : <BackendPaginationTable
                    columns={[
                        { id: 'name', label: 'name', numeric: false },
                        { id: 'default', label: 'isDefault', numeric: false },
                        ...(adjustActions(actionslist).length ? [{ id: 'actions', label: 'actions', numeric: false }] : []),
                    ]}
                    data={tableData?.pages[0]?.data?.content || []}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    tableData={tableData?.pages[0]?.data || {}}
                    showCheckbox={false}
                    showActions={true}
                    enableSorting={true}
                    showHeader={true}
                    showSearch={true}
                    showFilter={true}
                    showCreateButton={true}
                    actions={adjustActions(actionslist)}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRows}
                    rowClick={checkPrivileges("ROLE_BUSINESSSTATUS_UPDATE")}
                    showRowView={showRowView}
                    loadingSearch={false}
                    headerComponent={
                        <StyledInput
                            fullWidth
                            placeholder={t("name")}
                            handleSearchClick={handleSearchClick}
                            handleChange={handleInputChange}
                            value={search}
                            clearSearch={clearSearch}
                        />
                    }
                />}
            </div>
            {openDeleteModal &&
                <FormDialog
                    open={openDeleteModal}
                    onClose={() => setDeleteModal(false)}
                    title={t('delete') + ' ' + t('status')}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t("Are you sure you want to delete?")}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setDeleteModal(false);
                        setLoading(true);
                        StatusesProfileService.deleteStatuses((row as any).id).then((response: DeleteData) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                if (tableData?.pages[0].data.content.length === 1) handleChangePage(pageNo - 1);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('status') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="Delete"
                />}
        </Box>
    )
}