// ServiceGroupProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ServiceGroupProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignResponse,
  AssignUnassignServiceRequest,
  ChangeStatusServiceGroupResponse,
  DeleteServiceGroupResponse,
  ServiceGroupRequest,
  ServiceGroupResponse,
} from './interface';
import { useCallback } from 'react';

type UseServiceGroupServiceProps = {
  onSuccess: (data: ServiceGroupResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateServiceGroupServiceProps = {
  onSuccess: (data: ServiceGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteServiceGroupServiceProps = {
  onSuccess: (data: DeleteServiceGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type ChangeStatusServiceGroupServiceProps = {
  onSuccess: (data: ChangeStatusServiceGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useServiceGroupService = ({ onError, onSuccess }: UseServiceGroupServiceProps) => {
  const {
    mutateAsync: createServiceGroup,
    isPending: isCreateServiceGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-service-groups-request'],
    mutationFn: (req: ServiceGroupRequest) => ServiceGroupProfileService.createServiceGroup(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateServiceGroup = useCallback(
    async (serviceGroupData: ServiceGroupRequest) => {
      try {
        createServiceGroup(serviceGroupData);
      } catch (err) {
        console.error(err);
      }
    },
    [createServiceGroup]
  );

  return {
    onCreateServiceGroup,
    isCreateServiceGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetServiceGroupService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-service-groups-data'],
      queryFn: async ({ pageParam }) => {
        return ServiceGroupProfileService.getServiceGroup({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetServiceGroupDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-service-groups-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return ServiceGroupProfileService.getServiceGroupDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateServiceGroupService = ({ id, onError, onSuccess }: UpdateServiceGroupServiceProps) => {
  const {
    mutateAsync: updateServiceGroup,
    isPending: isUpdateServiceGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-service-groups-request'],
    mutationFn: (req: ServiceGroupRequest) => ServiceGroupProfileService.updateServiceGroup(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateServiceGroup = useCallback(
    async (req: ServiceGroupRequest) => {
      try {
        updateServiceGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateServiceGroup]
  );

  return {
    onUpdateServiceGroup,
    isUpdateServiceGroupLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteServiceGroupService = ({ id, onError, onSuccess }: DeleteServiceGroupServiceProps) => {
  const {
    mutateAsync: deleteServiceGroup,
    isPending: isDeleteServiceGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-service-groups-request'],
    mutationFn: () => ServiceGroupProfileService.deleteServiceGroup(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteServiceGroup = useCallback(async () => {
    try {
      deleteServiceGroup();
    } catch (err) {
      console.error(err);
    }
  }, [deleteServiceGroup]);

  return {
    onDeleteServiceGroup,
    isDeleteServiceGroupLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusServiceGroupService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusServiceGroupServiceProps) => {
  const {
    mutateAsync: changeStatusServiceGroup,
    isPending: isChangeStatusServiceGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-service-groups-request'],
    mutationFn: (req: ChangeStatusRequest) => ServiceGroupProfileService.changeStatusServiceGroup(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusServiceGroup = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusServiceGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusServiceGroup]
  );

  return {
    onChangeStatusServiceGroup,
    isChangeStatusServiceGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetServiceGroupServiceService = (initialParams: PageSize, serviceGroupId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-servicegroups-services-data'],
      queryFn: async ({ pageParam }) => {
        return ServiceGroupProfileService.getServiceGroupService({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
          serviceCode: initialParams.serviceCode,
          serviceGroupId: serviceGroupId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignServiceService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignService,
    isPending: isAssignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-services-request'],
    mutationFn: (req: AssignUnassignServiceRequest) => ServiceGroupProfileService.assignService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignService = useCallback(
    async (assignServiceData: AssignUnassignServiceRequest) => {
      try {
        assignService(assignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignService]
  );

  return {
    onAssignService,
    isAssignServiceLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignServiceService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignService,
    isPending: isUnassignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-services-request'],
    mutationFn: (req: AssignUnassignServiceRequest) => ServiceGroupProfileService.unassignService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignService = useCallback(
    async (unassignServiceData: AssignUnassignServiceRequest) => {
      try {
        unassignService(unassignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignService]
  );

  return {
    onUnassignService,
    isUnassignServiceLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllServiceService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllServices,
    isPending: isAssignAllServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-services-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceGroupProfileService.assignAllServices(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllServices = useCallback(
    async (assignAllServiceData: AssignUnassignAllRequest) => {
      try {
        assignAllServices(assignAllServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllServices]
  );

  return {
    onAssignAllServices,
    isAssignAllServiceLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllServiceService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllServices,
    isPending: isUnassignAllServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-services-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ServiceGroupProfileService.unassignAllServices(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllServices = useCallback(
    async (unassignAllServiceData: AssignUnassignAllRequest) => {
      try {
        unassignAllServices(unassignAllServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllServices]
  );

  return {
    onUnassignAllServices,
    isUnassignAllServiceLoading,
    data,
    error,
    isError,
  };
};
