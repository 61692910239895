import { useMutation } from '@tanstack/react-query';
import { CommonProfileService } from '../../core/services';
import { UploadRequest, UploadResponse } from './interface';
import { useCallback } from 'react';

type UseCommonServiceProps = {
  onSuccess: (data: UploadResponse) => void;
  onError: (err: unknown) => void;
};

export const useImportService = ({ onError, onSuccess }: UseCommonServiceProps) => {
  const {
    mutateAsync: Import,
    isPending: isImportLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['import-request'],
    mutationFn: (req: UploadRequest) => CommonProfileService.uploadEntity(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onImport = useCallback(
    async (importData: UploadRequest) => {
      try {
        Import(importData);
      } catch (err) {
        console.error(err);
      }
    },
    [Import]
  );

  return {
    onImport,
    isImportLoading,
    data,
    error,
    isError,
  };
};
