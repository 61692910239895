import { selectUserInfo } from "features/user/userSlice";
import { Grid, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StatusesProfileService } from "subModule/src/core/services";
import AlertMessages from "common/Alert/AlertMessages";
import { useSnackbar } from "notistack";
import SimpleModal from "common/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Statuses } from "assets/SideBarIcons/status.svg";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import { useEffect, useState } from "react";
import Loader from "layout/Loader";
import { GetStatusByIdResponse } from "subModule/src/services/statuses/interface";

interface FormData { [key: string]: any;}
export default function PaymentModeModal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset } = useForm<FormData>({defaultValues: {}});
    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const [loading, setLoading] = useState<boolean>(params?.id ? true : false);
    const [IsBtnEnabled, setIsBtnEnabled] = useState<boolean>(params?.id ? false : true);
    useEffect(() => {
        if(params?.id) {
            if(loading) getByID();
        }
    }, [params]);
    useEffect(() => {
        if (isDirty && params?.id) setIsBtnEnabled(true);
      }, [isDirty, dirtyFields]);
    const getByID = () => {
        StatusesProfileService.getStatusByID(+(params as any)?.id, {type: 7}).then((res: GetStatusByIdResponse) => {
            if(res.success) {
                reset({ ...res?.data || {} });
                setLoading(false);
            } else {
                enqueueSnackbar(<AlertMessages text={res.message || "Something went wrong"} />, { variant: 'error' });
            }
        }).catch((err:any) => { console.error("===", err); });
    }
    const onSubmit = (data: any) => {
        setIsBtnEnabled(false);
        if(params?.id) {
            StatusesProfileService.updateStatuses(+params?.id, data).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('PaymentMode') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
                setIsBtnEnabled(true);
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        } else {
            StatusesProfileService.createStatuses({...data, type: 7}).then((response: any) => {
                if(response.success) {
                    enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('PaymentMode') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
                    navigate(-1);
                } else {
                    setIsBtnEnabled(true);
                    enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
                }
            }).catch((err:any) => { 
                console.error("===", err);
                setIsBtnEnabled(true);
                enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
            });
        }
    };

    return (<>
        <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={true}
            onClick={() => navigate(-1)}
            onSubmit={handleSubmit(onSubmit)}
            heading={params?.id ? t('editPaymentMode') : t('addPaymentMode')}
            icon={<Statuses height={28} width={28} />}
            btnText={"back"}
            btnType={"back"}
            isBtnEnabled={IsBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_BUSINESSSTATUS_CREATE")}
        />
        {loading ? <Loader /> : <SimpleModal
            visable={true}
            title={params?.id ? "editPaymentMode" : "addPaymentMode"}
            isButtonVisable={true}
            isVisableBtn={IsBtnEnabled}
            btnclosetext="Back"
            buttonText={params?.id ? "update" : "submit"}
            attBtnNotshow={false}
            notVisableBackbtn={true}
            showPortList={handleSubmit(onSubmit)}
            formSubmit={handleSubmit(onSubmit)}
            content={ 
                <Grid container component="form" id="form" className="items-baseline" spacing={3} onSubmit={handleSubmit(onSubmit)}>
                    <Grid item xs={12} md={3}>
                        <TextField 
                            {...register("name", { validate: { required: (value) => value.trim() !== '' || t('This field is required.'), } })} 
                            label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${t(primaryLanguage)})` : ''}`}
                            fullWidth
                            error={!!errors.name} 
                            helperText={errors.name?.message as string} 
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField 
                            {...register("description")} 
                            label={t('description')}
                            fullWidth
                            error={!!errors.description} 
                            helperText={errors.description?.message as string}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            rows={1}
                            sx={{ 
                                '& .MuiInputBase-fullWidth.MuiInputBase-multiline': { width: '100%', paddingRight: '0px', paddingBottom: 0 },
                                '& .MuiInputBase-inputMultiline': { resize: 'vertical', minHeight: '29px' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField 
                            type="number"
                            {...register("sortOrder")} 
                            onChange={(e: any) => {
                                const value = parseInt(e.target.value);
                                e.target.value = value < 1 ? 1 : value > 100 ? 100 : value || "";
                                if(!IsBtnEnabled) setIsBtnEnabled(true);
                            }}
                            label={t("Sort Order (Value 1-100)")}
                            fullWidth
                            error={!!errors.sortOrder} 
                            helperText={errors.sortOrder?.message as string} 
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            }
        />}
    </>)
}