import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useNavigate } from 'react-router-dom';
import FormDialog from 'common/Modal/Modal';
import ComponentHeaderTwo from 'common/ComponentHeaderTwo/ComponentHeaderTwo';
import { ReactComponent as Payment } from "assets/SideBarIcons/money-transfer.svg";
import BackendPaginationTable from 'common/Table/TableWithPagination';
import PaymentSearchHeader from 'components/Payment/PaymentSearchHeader';
import { PaymentProfileService } from 'subModule/src/core/services';
import { PaymentsResponse } from 'subModule/src/services/payment/interface';
import { DeleteData } from 'subModule/src/type';
import Loader from 'layout/Loader';
import { useGetPaymentService } from 'subModule/src/services/payment/usePayments';

interface SearchFormData {
    [key: string]: string | any;
}

export default function PaymentListing() {
    const navigate = useNavigate();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<string[]>(['paymentNo']);
    const [search, setSearch] = useState<any>({});
    const [row, setRow] = useState<PaymentsResponse>({});
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({ paymentNo: '', referenceNo: '' });
    const { data: tableData, isLoading, error, refetch } = useGetPaymentService({ pageNumber: pageNo, size: pageSize, ...search });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isLoading && !error) refetch();
        if (tableData) {
            setLoading(false);
            if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
                enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
            }
        }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
        const getData = setTimeout(async () => {
            if (search && !isLoading && !error && loading) {
                await refetch();
                setLoading(false);
            }
        }, 300)
        return () => clearTimeout(getData)
    }, [search, loading]);
    const handleChangePage = (pageNo: number) => {
        setLoading(true);
        setPageNo(pageNo + 1);
        setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize: number) => {
        setLoading(true);
        setPageSize(pageSize);
        setPageNo(1);
    }
    // const onEdit = (row: any) => navigate('/payment/' + row.id);
    // const showRowView = (row: any) => navigate('/payment/' + row.id);
    const onDelete = (row: any) => {
        setDeleteModal(true);
        setRow(row);
    }
    const clearSearch = () => {
        setFilter(['paymentNo']);
        setSearchFormData({ paymentNo: '', referenceNo: '' });
        if (Object.keys(search).length) {
            setLoading(true);
            setSearch({});
        }
    }
    const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData =>
            Object.fromEntries(Object.keys(prevFormData).map(key =>
                [key, value.includes(key) ? prevFormData[key] : '']
            ))
        );
        if (!value.length && Object.keys(search).length) {
            clearSearch();
        }
    };
    const handleChangeSearch = (field: string, value: any) => {
        const val = value;
        setSearchFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
    };
    const handleSubmitSearch = () => {
        const filteredSearch: Record<string, string> = {};
        Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) {
                filteredSearch[key] = value?.trim();
            }
        });
        if(Object.keys(filteredSearch).length > 0 && Object.values(filteredSearch).some(value => value)) {
            setLoading(true);
            setSearch(filteredSearch);
            setPageNo(1);
            setPageSize(pageSize);
        }
    };
    const actionslist = [
        // { label: "edit", onClick: onEdit, icon: <EditIcon /> },
        { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
    ];
    const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_PAYMENT_UPDATE')
            ? (checkPrivileges('ROLE_PAYMENT_ARCHIVE')
                ? actions
                : actions.filter(action => action.label !== 'delete'))
            : (checkPrivileges('ROLE_PAYMENT_ARCHIVE')
                ? actions.filter(action => action.label !== 'edit')
                : []);
    }
    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={true}
                showSaveBtn={false}
                onClick={() => navigate('/payment/create')}
                heading={t('payments')}
                icon={<Payment height={28} width={28} />}
                btnText={"create"}
                btnType={"create"}
                showCreateBtn={checkPrivileges("ROLE_PAYMENT_CREATE")}
            />
            {loading ? <Loader /> : <BackendPaginationTable
                columns={[
                    { id: 'paymentNo', label: 'Payment Number', numeric: false },
                    { id: 'referenceNo', label: 'referenceNo', numeric: false },
                    { id: 'paymentModeTitle', label: 'PaymentMode', numeric: false },
                    { id: 'clientName', label: 'clientName', numeric: false },
                    { id: 'amount', label: 'amount', numeric: false },
                    { id: 'paymentDate', label: 'paymentDate', numeric: false },
                    { id: 'adjustmentType', label: 'adjustmentType', numeric: false },
                    ...(adjustActions(actionslist).length ? [{ id: 'actions', label: 'actions', numeric: false }] : []),
                ]}
                data={tableData?.pages[0]?.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={tableData?.pages[0]?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={adjustActions(actionslist)}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                rowClick={checkPrivileges("ROLE_PAYMENT_UPDATE")}
                // showRowView={showRowView}
                loadingSearch={false}
                headerComponent={
                    <PaymentSearchHeader
                        searchFormData={searchFormData}
                        handleChangeFilter={handleChangeFilter}
                        handleChangeSearch={handleChangeSearch}
                        handleSubmitSearch={handleSubmitSearch}
                        clearSearch={clearSearch}
                        {...{ filter, search }}
                    />
                }
            />}
            {openDeleteModal &&
                <FormDialog
                    open={openDeleteModal}
                    onClose={() => setDeleteModal(false)}
                    title={t('delete') + ' ' + t('Payment')}
                    content={
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12}  >
                                <Typography>{t("Are you sure you want to delete?")}</Typography>
                            </Grid>
                        </Grid>
                    }
                    saveFunction={() => {
                        setDeleteModal(false);
                        setLoading(true);
                        PaymentProfileService.deletePayment((row as any).id).then((response: DeleteData) => {
                            if (response.success) {
                                setLoading(false);
                                refetch();
                                if (tableData?.pages[0].data.content.length === 1) handleChangePage(pageNo - 1);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Payment') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
                            } else {
                                setLoading(false);
                                enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
                            }
                        }).catch((err: any) => {
                            console.error("===", err);
                            setLoading(false);
                            enqueueSnackbar(<AlertMessages text={err || err?.message || "Something went wrong"} />, { variant: 'error' });
                        });
                    }}
                    submitButtonText="Delete"
                />}
        </Box>
    )
}