// WarehouseService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusWarehouseResponse,
  DeleteWarehouseResponse,
  GetWarehouseByIdResponse,
  GetWarehouseResponse,
  WarehouseRequest,
  WarehouseResponse,
} from './interface';

export class WarehouseProfile extends BaseService {
  async createWarehouse(req: WarehouseRequest) {
    return this.post<WarehouseResponse, WarehouseRequest>(`${this.apiUrl}/api/warehouses`, req);
  }

  async getWarehouse(params: PaginationParams) {
    return this.get<GetWarehouseResponse>(`${this.apiUrl}/api/warehouses/paged`, params);
  }

  async updateWarehouse(id: number, req: WarehouseRequest) {
    return this.put<WarehouseResponse, WarehouseRequest>(`${this.apiUrl}/api/warehouses/${id}`, req);
  }

  async deleteWarehouse(id: number) {
    return this.delete<DeleteWarehouseResponse>(`${this.apiUrl}/api/warehouses/${id}`);
  }

  async changeStatusWarehouse(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusWarehouseResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/warehouses/${active}/${id}`,
      req
    );
  }

  async getWarehouseById(id: number) {
    return this.get<GetWarehouseByIdResponse>(`${this.apiUrl}/api/warehouses/${id}`);
  }
}
