// ChecklistItemProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ChecklistItemProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusChecklistItemResponse,
  ChecklistItemRequest,
  ChecklistItemResponse,
  DeleteChecklistItemResponse,
} from './interface';
import { useCallback } from 'react';

type UseChecklistItemServiceProps = {
  onSuccess: (data: ChecklistItemResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateChecklistItemServiceProps = {
  onSuccess: (data: ChecklistItemResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteChecklistItemServiceProps = {
  onSuccess: (data: DeleteChecklistItemResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type ChangeStatusChecklistItemProps = {
  onSuccess: (data: ChangeStatusChecklistItemResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useChecklistItemService = ({ onError, onSuccess }: UseChecklistItemServiceProps) => {
  const {
    mutateAsync: createChecklistItem,
    isPending: isCreateChecklistItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-checklistitems-request'],
    mutationFn: (req: ChecklistItemRequest) => ChecklistItemProfileService.createChecklistItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateChecklistItem = useCallback(
    async (ChecklistItemData: ChecklistItemRequest) => {
      try {
        createChecklistItem(ChecklistItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [createChecklistItem]
  );

  return {
    onCreateChecklistItem,
    isCreateChecklistItemLoading,
    data,
    error,
    isError,
  };
};

export const useGetChecklistItemService = (initialParams: PageSize, inspectionformId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Items-data'],
      queryFn: async ({ pageParam }) => {
        return ChecklistItemProfileService.getChecklistItem({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          inspectionformId: inspectionformId,
          type: initialParams.type,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateChecklistItemService = ({ id, onError, onSuccess }: UpdateChecklistItemServiceProps) => {
  const {
    mutateAsync: updateChecklistItem,
    isPending: isUpdateChecklistItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-checklistitems-request'],
    mutationFn: (req: ChecklistItemRequest) => ChecklistItemProfileService.updateChecklistItem(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateChecklistItem = useCallback(
    async (req: ChecklistItemRequest) => {
      try {
        updateChecklistItem(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateChecklistItem]
  );

  return {
    onUpdateChecklistItem,
    isUpdateChecklistItemLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteChecklistItemService = ({ id, onError, onSuccess }: DeleteChecklistItemServiceProps) => {
  const {
    mutateAsync: deleteChecklistItem,
    isPending: isDeleteChecklistItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-checklistitem-request'],
    mutationFn: () => ChecklistItemProfileService.deleteChecklistItem(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteChecklistItem = useCallback(async () => {
    try {
      deleteChecklistItem();
    } catch (err) {
      console.error(err);
    }
  }, [deleteChecklistItem]);

  return {
    onDeleteChecklistItem,
    isDeleteChecklistItemLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusChecklistItemService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusChecklistItemProps) => {
  const {
    mutateAsync: changeStatusChecklistItem,
    isPending: isChangeStatusChecklistItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-checklistitem-request'],
    mutationFn: (req: ChangeStatusRequest) =>
      ChecklistItemProfileService.ChangeStatusChecklistItemResponse(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusChecklistItem = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusChecklistItem(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusChecklistItem]
  );

  return {
    onChangeStatusChecklistItem,
    isChangeStatusChecklistItemLoading,
    data,
    error,
    isError,
  };
};
