// VehicleTypeService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusVehicleTypeResponse,
  DeleteVehicleTypeResponse,
  GetVehicleTypeByIdResponse,
  GetVehicleTypeDropdownResponse,
  GetVehicleTypeResponse,
  VehicleTypeRequest,
  VehicleTypeResponse,
} from './interface';

export class VehicleTypeProfile extends BaseService {
  async createVehicleType(req: VehicleTypeRequest) {
    return this.post<VehicleTypeResponse, VehicleTypeRequest>(`${this.apiUrl}/api/vehicletypes`, req);
  }

  async getVehicleType(params: PaginationParams) {
    return this.get<GetVehicleTypeResponse>(`${this.apiUrl}/api/vehicletypes/paged`, params);
  }

  async getVehicleTypeDropdown(params: PaginationParams) {
    return this.get<GetVehicleTypeDropdownResponse>(`${this.apiUrl}/api/vehicletypes/get`, params);
  }

  async updateVehicleType(id: number, req: VehicleTypeRequest) {
    return this.put<VehicleTypeResponse, VehicleTypeRequest>(`${this.apiUrl}/api/vehicletypes/${id}`, req);
  }

  async deleteVehicleType(id: number) {
    return this.delete<DeleteVehicleTypeResponse>(`${this.apiUrl}/api/vehicletypes/${id}`);
  }

  async changeStatusVehicleType(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusVehicleTypeResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/vehicletypes/${active}/${id}`,
      req
    );
  }

  async getVehicleTypeByID(id: number) {
    return this.get<GetVehicleTypeByIdResponse>(`${this.apiUrl}/api/vehicletypes/${id}`);
  }
}
