import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState, useRef } from 'react';
import { BusinessProfileService } from 'subModule/src/core/services';
import { UploadImageResponse } from 'subModule/src/services/business/interface';
import AlertMessages from 'common/Alert/AlertMessages';
import { enqueueSnackbar } from 'notistack';
type BusinessMediaProps = {
  refetch?: () => void;
  formData?: any;
  entity?: string;
  callback?: (url: string) => void;
  setIsBtnEnabled?: any;
};
const BusinessMedia: React.FC<BusinessMediaProps> = ({ formData, entity, callback, setIsBtnEnabled }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const formRef = useRef<HTMLFormElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && !file.type.startsWith('image/')) {
        formRef.current?.reset();
        setFileName('');
        return enqueueSnackbar(<AlertMessages text={t('onlyImagesAllowed')} />, { variant: 'error' });
    }
    setFileName(file ? file.name : '');
  };
  const onUpload = (data: any) => {
    setIsBtnEnabled?.(false);
    BusinessProfileService.uploadImage(data).then((response: UploadImageResponse) => {
      if (response.success) {
        enqueueSnackbar(<AlertMessages text={t('uploaded') + ' ' + t('successfully')} />, { variant: 'success' });
        callback?.(response?.data)
        formRef.current?.reset();
        setFileName('');
      } else {
        enqueueSnackbar(<AlertMessages text={response?.message} />, { variant: 'error' });
      }
    }).catch((err: any) => {
      console.error("===", err);
      enqueueSnackbar(<AlertMessages text={err?.message || err?.data?.message || "Something went wrong"} />, { variant: 'error' });
    }).finally(() => {setLoading(false);  setIsBtnEnabled?.(true); });
  };
  return (
    <Box>
      <Grid container className='items-center' component='form' noValidate={false} spacing={1} onSubmit={(e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData(e.target as HTMLFormElement);
        const file = data.get('file') as File;
        onUpload({ file: file, entityId: formData?.id, entity: entity, 
            oldUrl: entity === 'logo' ? formData?.attributes?.logo || '' 
                        : entity === 'logInPageImage' ? formData?.attributes?.logInPageImage || ''
                        : formData?.attributes?.favIcon || ''
        });
      }} ref={formRef}>
        <Grid item xs={12} md={8} id="importfile">
          <input
            type="file"
            name="file"
            required
            accept="image/*"
            style={{ color: fileName ? 'inherit' : 'transparent', width: '100%', paddingTop: '4px', paddingBottom: '4px' }}
            onChange={handleFileChange}
            title={fileName}
          />
        </Grid>
        <Grid item xs={12} md={4} id="form">
          <Button type="submit" disabled={loading} className="primary block" variant="contained" disableElevation>{t('Upload')}</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessMedia;