// VendorService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusVendorResponse,
  DeleteVendorResponse,
  GetVendorByIdResponse,
  GetVendorResponse,
  VendorRequest,
  VendorResponse,
} from './interface';

export class VendorProfile extends BaseService {
  async createVendor(req: VendorRequest) {
    return this.post<VendorResponse, VendorRequest>(`${this.apiUrl}/api/vendors`, req);
  }

  async getVendor(params: PaginationParams) {
    return this.get<GetVendorResponse>(`${this.apiUrl}/api/vendors/paged`, params);
  }

  async updateVendor(id: number, req: VendorRequest) {
    return this.put<VendorResponse, VendorRequest>(`${this.apiUrl}/api/vendors/${id}`, req);
  }

  async deleteVendor(id: number) {
    return this.delete<DeleteVendorResponse>(`${this.apiUrl}/api/vendors/${id}`);
  }

  async changeStatusVendor(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusVendorResponse, ChangeStatusRequest>(`${this.apiUrl}/api/vendors/${active}/${id}`, req);
  }

  async getVendorById(id: number) {
    return this.get<GetVendorByIdResponse>(`${this.apiUrl}/api/vendors/${id}`);
  }
}
