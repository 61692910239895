import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';


interface ChecklistModalComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
}

const ChecklistModalComponent: React.FC<ChecklistModalComponentProps> = ({
  handleSecField,
  primaryLanguage,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled
}) => {

  const { t } = useTranslation();

  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateChecklist" : "addChecklist"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                handleSecField={handleSecField}
                placeholder="Write your title here"
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default ChecklistModalComponent;
