// BusinessService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  BusinessRequest,
  BusinessResponse,
  ChangeStatusBusinessResponse,
  DeleteBusinessResponse,
  GetBusinessByIdResponse,
  GetBusinessResponse,
  UploadImageRequest,
  UploadImageResponse,
} from './interface';

export class BusinessProfile extends BaseService {
  async createBusiness(req: BusinessRequest) {
    return this.post<BusinessResponse, BusinessRequest>(`${this.apiUrl}/api/businesses`, req);
  }

  async getBusiness(params: PaginationParams) {
    return this.get<GetBusinessResponse>(`${this.apiUrl}/api/businesses/paged`, params);
  }

  async updateBusiness(id: number, req: BusinessRequest) {
    return this.put<BusinessResponse, BusinessRequest>(`${this.apiUrl}/api/businesses/${id}`, req);
  }

  async deleteBusiness(id: number) {
    return this.delete<DeleteBusinessResponse>(`${this.apiUrl}/api/businesses/${id}`);
  }

  async changeStatusBusiness(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusBusinessResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/businesses/${active}/${id}`,
      req
    );
  }

  async getBusinessByID(id: number) {
    return this.get<GetBusinessByIdResponse>(`${this.apiUrl}/api/businesses/${id}`);
  }

  async uploadImage(req: UploadImageRequest) {
    const headers = { 'Content-Type': 'multipart/form-data' };

    const formData = new FormData();

    Object.entries(req).forEach(([key, value]) => {
      if (value !== null) {
        formData.append(key, value);
      }
    });

    return this.post<UploadImageResponse, FormData>(`${this.apiUrl}/api/businesses/upload`, formData, { headers });
  }
}
