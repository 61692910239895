// InspectionFormItemProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { InspectionFormItemProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusInspectionFormItemResponse,
  DeleteInspectionFormItemResponse,
  InspectionFormItemRequest,
  InspectionFormItemResponse,
} from './interface';
import { useCallback } from 'react';

type UseInspectionFormItemServiceProps = {
  onSuccess: (data: InspectionFormItemResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateInspectionFormItemServiceProps = {
  onSuccess: (data: InspectionFormItemResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteInspectionFormItemServiceProps = {
  onSuccess: (data: DeleteInspectionFormItemResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type ChangeStatusInspectionFormItemProps = {
  onSuccess: (data: ChangeStatusInspectionFormItemResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useInspectionFormItemService = ({ onError, onSuccess }: UseInspectionFormItemServiceProps) => {
  const {
    mutateAsync: createInspectionFormItem,
    isPending: isCreateInspectionFormItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-inspectionformitems-request'],
    mutationFn: (req: InspectionFormItemRequest) => InspectionFormItemProfileService.createInspectionFormItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateInspectionFormItem = useCallback(
    async (inspectionFormItemData: InspectionFormItemRequest) => {
      try {
        createInspectionFormItem(inspectionFormItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [createInspectionFormItem]
  );

  return {
    onCreateInspectionFormItem,
    isCreateInspectionFormItemLoading,
    data,
    error,
    isError,
  };
};

export const useGetInspectionFormItemService = (initialParams: PageSize, inspectionformId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Items-data'],
      queryFn: async ({ pageParam }) => {
        return InspectionFormItemProfileService.getInspectionFormItem({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          inspectionformId: inspectionformId,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateInspectionFormItemService = ({
  id,
  onError,
  onSuccess,
}: UpdateInspectionFormItemServiceProps) => {
  const {
    mutateAsync: updateInspectionFormItem,
    isPending: isUpdateInspectionFormItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-inspectionformitems-request'],
    mutationFn: (req: InspectionFormItemRequest) => InspectionFormItemProfileService.updateInspectionFormItem(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateInspectionFormItem = useCallback(
    async (req: InspectionFormItemRequest) => {
      try {
        updateInspectionFormItem(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateInspectionFormItem]
  );

  return {
    onUpdateInspectionFormItem,
    isUpdateInspectionFormItemLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteInspectionFormItemService = ({
  id,
  onError,
  onSuccess,
}: DeleteInspectionFormItemServiceProps) => {
  const {
    mutateAsync: deleteInspectionFormItem,
    isPending: isDeleteInspectionFormItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-inspectionformitem-request'],
    mutationFn: () => InspectionFormItemProfileService.deleteInspectionFormItem(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteInspectionFormItem = useCallback(async () => {
    try {
      deleteInspectionFormItem();
    } catch (err) {
      console.error(err);
    }
  }, [deleteInspectionFormItem]);

  return {
    onDeleteInspectionFormItem,
    isDeleteInspectionFormItemLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusInspectionFormItemService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusInspectionFormItemProps) => {
  const {
    mutateAsync: changeStatusInspectionFormItem,
    isPending: isChangeStatusInspectionFormItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-inspectionformitem-request'],
    mutationFn: (req: ChangeStatusRequest) =>
      InspectionFormItemProfileService.ChangeStatusInspectionFormItem(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusInspectionFormItem = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusInspectionFormItem(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusInspectionFormItem]
  );

  return {
    onChangeStatusInspectionFormItem,
    isChangeStatusInspectionFormItemLoading,
    data,
    error,
    isError,
  };
};
